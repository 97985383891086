import styled from 'styled-components';

export default styled.div`
  max-width: 30rem;
  padding: 10px;
  overflow: auto;
  background-color: ${props => props.theme.color4};
  color: ${props => props.theme.color2};
  text-align: left;
`;
