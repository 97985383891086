import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { addLocaleData } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'react-intl-redux';
import { render } from 'react-dom';
import de from 'react-intl/locale-data/de';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import fr from 'react-intl/locale-data/fr';
import nl from 'react-intl/locale-data/nl';
import en from 'react-intl/locale-data/nl';
import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';

import './index.css';
import './lib/nodelist.foreach.polyfill';
import App from './App';
import ConnectedThemeProvider from './ConnectedThemeProvider';
import OpenSansBoldWoff from './fonts/open-sans-v15-latin-700.woff';
import OpenSansBoldWoff2 from './fonts/open-sans-v15-latin-700.woff2';
import OpenSansCondensedWoff from './fonts/open-sans-condensed-v12-latin-700.woff';
import OpenSansCondensedWoff2 from './fonts/open-sans-condensed-v12-latin-700.woff2';
import OpenSansWoff from './fonts/open-sans-v15-latin-regular.woff';
import OpenSansWoff2 from './fonts/open-sans-v15-latin-regular.woff2';
import AntonWoff2 from './fonts/anton-v9-latin-regular.woff2';
import AntonWoff from './fonts/anton-v9-latin-regular.woff';
import FranklinkGothicWoff2 from './fonts/franklin-gothic-italic.woff2';
import FranklinkGothicWoff from './fonts/franklin-gothic-italic.woff';
import NovecentoWoff2 from './fonts/Novecento-WideBold.woff2';
import NovecentoWoff from './fonts/Novecento-WideBold.woff';
import ScrollToTop from './components/ScrollTop/ScrollTop';
import WithParams from './components/WithParams/WithParams';
import store from './configureStore';
import * as serviceWorker from './serviceWorker';

const GlobalStyle = createGlobalStyle`
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(${OpenSansWoff2}) format('woff2'),
        url(${OpenSansWoff}) format('woff');
  }
  /* open-sans-800 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
        url(${OpenSansBoldWoff2}) format('woff2'),
        url(${OpenSansBoldWoff}) format('woff');
  }
  /* open-sans-condensed-700 - latin */
  @font-face {
    font-family: 'Open Sans Condensed';
    font-weight: 700;
    src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'),
        url(${OpenSansCondensedWoff2}) format('woff2'),
        url(${OpenSansCondensedWoff}) format('woff');
  }
  @font-face {
    font-family: 'Anton';
    font-weight: 400;
    src: local('Anton Regular'), local('Anton-Regular'),
        url(${AntonWoff2}) format('woff2'),
        url(${AntonWoff}) format('woff');
    }

  @font-face {
    font-family: 'Franklin-Italic';
    font-weight: 400;
    src: url(${FranklinkGothicWoff2}) format('woff2'),
        url(${FranklinkGothicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Novecento';
    src: url(${NovecentoWoff2}) format('woff2'),
      url(${NovecentoWoff}) format('woff');
    font-weight: 400;
  }
`;
addLocaleData([...de, ...it, ...es, ...fr, ...nl, ...en]);

const rootElement = document.getElementById('root');

if (rootElement !== null) {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedThemeProvider>
          <ScrollToTop>
            <WithParams>
              <>
                <GlobalStyle />
                <App />
              </>
            </WithParams>
          </ScrollToTop>
        </ConnectedThemeProvider>
      </BrowserRouter>
    </Provider>,
    rootElement,
    () => {
      serviceWorker.unregister();
    }
  );
}
