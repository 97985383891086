import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import TagManager from 'react-gtm-module';

import StudioWrapper from './components/StudioWrapper';
import StudioNameElement from './components/StudioNameElement';
import StudioLocation from './components/StudioLocation';
import SelectStudioButton from './components/SelectStudioButton';
import { selectHomebase } from '../../../../../../../../data/checkout';
import { fetchContracts } from '../../../../../../../../data/contracts';

export const Studio = props => (
  <StudioWrapper
    className="mcfit-tracking-checkout-1"
    i={props.i + 1}
    onClick={() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'studioFinderTrigger',
          studioId: props.location.StudioMark
        }
      });

      props.handleClick(props.location);
      return (
        <Switch>
          <Route path="/checkout" />
        </Switch>
      );
    }}
    to={props.url}
  >
    <StudioNameElement {...props} />
    <StudioLocation>
      {props.location.distance.toFixed(2)}
      KM
    </StudioLocation>
    <SelectStudioButton id={props.location.StudioMark} />
  </StudioWrapper>
);

Studio.propTypes = {
  i: PropTypes.number.isRequired,
  location: PropTypes.shape({
    distance: PropTypes.number.isRequired,
    StudioMark: PropTypes.string.isRequired
  }).isRequired,
  accuracy: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  intl: intlShape.isRequired,
  contractId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  url: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired
};

Studio.defaultProps = {
  contractId: false
};

const mapStateToProps = state => ({
  accuracy: state.data.checkout.gpsLocation.accuracy,
  brand: state.data.theme.themeName,
  countryCode: state.data.tracking.tracklang
});

const mapDispatchToProps = dispatch => ({
  handleClick: studio => {
    dispatch(selectHomebase(studio.StudioMark, studio.CountryCode));
    dispatch(fetchContracts(studio));
  }
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Studio)
);
