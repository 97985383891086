import React from 'react';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import { FormattedMessage } from 'react-intl';

import Modal from '../Modal/Modal';
import CTAButton from '../CTAButton/CTAButton';

const Wrapper = styled.div`
  height: 100%;
  min-height: 50vh;
  background-color: black;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const CameraFeed = ({ brand, onClose, onPictureTaken }) => {
  const webcamRef = React.useRef();
  const videoConstraints = { facingMode: 'user' };
  return (
    <Modal isOpen brand={brand} title={''} customTitle close={onClose}>
      <Wrapper>
        <Webcam
          audio={false}
          width={'100%'}
          height={'100%'}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          videoConstraints={videoConstraints}
          mirrored
          screenshotQuality={0.5}
        />
      </Wrapper>
      <ButtonWrapper>
        <CTAButton
          type="button"
          onClick={e => {
            e.preventDefault();
            if (webcamRef.current) {
              const fileUri = webcamRef.current.getScreenshot();
              onPictureTaken(fileUri);
            }
          }}
        >
          <FormattedMessage
            id="checkout.takePicture"
            defaultMessage="Take Picture"
          />
        </CTAButton>
      </ButtonWrapper>
    </Modal>
  );
};
