import styled from 'styled-components';
import { H2 } from '../Typo/index';

import { media } from '../../lib/styledMixins';

const Headline = styled(H2)`
  color: ${props => props.theme.primary};
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 1em;
  text-align: center;

  ${media.md`
    font-size: 1.5em;
  `};
`;

export default Headline;
