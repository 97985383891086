import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import { Formik } from 'formik';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import CheckboxGroup from '../CheckboxGroup';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormAside from '../../../../../../../../components/FormAside/FormAside';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputGroupWrapper from '../../../../../../../../components/InputGroup/components/InputGroupWrapper';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import LegalWrapper from '../LegalWrapper';
import SelectedContract from '../../../SelectedContract';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { schemaML, flexSchema } from './contractFormSchemaFR';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';

export class ContractFormFR extends Component {
  static propTypes = {
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  state = {
    lastCheckedIBAN: ''
  };

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  timer;

  render() {
    const { brand, selectedStudioOpening } = this.props;
    const {
      Id,
      CountryCode,
      tsp,
      tspCharges,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate,
      maxAge27,
      contractTextBlocks
    } = this.props.selectedContract;
    const hasTSP = tsp && !!tspCharges;
    const isFlexContract =
      Id === 2426082500 ||
      Id === 2439433250 ||
      Id === 2439376541 ||
      Id === 2439376540;
    const schema = isFlexContract ? flexSchema : schemaML;

    return (
      <Formik
        onSubmit={(
          {
            AccountPassword,
            City,
            Email,
            FirstName,
            Gender,
            HouseNumber,
            IBAN,
            LastName,
            Marketing,
            Phone,
            PostalCode,
            Street,
            StudioMark,
            year,
            month,
            day,
            signature,
            ReferrerType,
            hasPremium,
            selectedStudioOpening,
            referralCode
          },
          actions
        ) => {
          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark: StudioMark || this.props.match.params.studioId,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing,
              Phone,
              PostalCode,
              Street,
              ReferrerType
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date()
          };

          this.props.handleSubmit(data, actions);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          CountryCode,
          brand,
          hasPremium,
          referralCode: this.props.referralCode || '',
          maxAge27
        }}
        validationSchema={schema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) =>
          this.props.success ? (
            <Redirect
              to={`/checkout${this.props.intl.formatMessage({
                id: 'route.success'
              })}`}
            />
          ) : (
            <FormWrapper
              onSubmit={prop => {
                console.log(values, errors);
                handleSubmit(prop);
              }}
            >
              <FormSidebar>
                <FormGroup>
                  <FormGroupHeadlineWrap>
                    <FormGroupHeadline>Données de compte</FormGroupHeadline>
                    <RequiredNote>*Champs obligatoires</RequiredNote>
                  </FormGroupHeadlineWrap>
                  <InputGroup
                    autoComplete="email"
                    error={errors.mail}
                    label="E-Mail"
                    name="Email"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Email}
                    type="email"
                    value={values.Email}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Données personnelles</FormGroupHeadline>
                  <InputGroup
                    autoComplete="sex"
                    error={errors.Gender}
                    label="Geschlecht"
                    name="Gender"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={[
                      { name: 'Homme', value: 'M' },
                      { name: 'Femme', value: 'W' }
                    ]}
                    required
                    touched={touched.Gender}
                    type="radio"
                    value={values.Gender}
                  />
                  {errors && touched && errors.gender && touched.gender ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  <InputGroup
                    autoComplete="given-name"
                    error={errors.FirstName}
                    label="Prénom"
                    name="FirstName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.FirstName}
                    type="text"
                    value={values.FirstName}
                  />
                  <InputGroup
                    autoComplete="family-name"
                    error={errors.LastName}
                    label="Nom"
                    name="LastName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.LastName}
                    type="text"
                    value={values.LastName}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Date de naissance</FormGroupHeadline>
                  <FormDescription as="div">
                    Vous devez être âgé d'au moins 18 ans pour conclure un
                    contrat en ligne.
                  </FormDescription>
                  <InputGroup
                    error={errors.day}
                    label="Jour"
                    name="day"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 31 }, (v, k) => ++k)}
                    required
                    touched={touched.day}
                    type="select"
                    value={values.day}
                    width="thirds"
                  />
                  <InputGroup
                    error={errors.month}
                    label="Mois"
                    name="month"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 12 }, (v, k) => ++k)}
                    required
                    touched={touched.month}
                    type="select"
                    value={values.month}
                    width="thirds"
                  />
                  <InputGroup
                    error={errors.year}
                    label="Année"
                    name="year"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={Array.from(
                      { length: 83 },
                      // eslint-disable-next-line no-return-assign,no-param-reassign
                      (v, k) => (k += getYear(subYears(Date.now(), 100)))
                    ).reverse()}
                    required
                    touched={touched.year}
                    type="select"
                    value={values.year}
                    width="thirds"
                  />
                  {errors &&
                  touched &&
                  ((errors.day && touched.day) ||
                    (errors.month && touched.month) ||
                    (errors.year && touched.year)) ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  {errors && errors.olderThan18 ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.birthday" />
                    </ErrorMessage>
                  ) : null}
                  <input type={'hidden'} name="maxAge27" value={maxAge27} />
                  {maxAge27 &&
                  errors &&
                  !errors.is18 &&
                  errors.youngerThan27 ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.birthday27" />
                    </ErrorMessage>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Coordonnées</FormGroupHeadline>
                  <InputGroup
                    autoComplete="address-line1"
                    error={errors.Street}
                    label="Rue"
                    name="Street"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Street}
                    type="text"
                    value={values.Street}
                    width="middle"
                  />
                  <InputGroup
                    error={errors.HouseNumber}
                    label="Numéro"
                    name="HouseNumber"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.HouseNumber}
                    type="text"
                    value={values.HouseNumber}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="postal-code"
                    error={errors.PostalCode}
                    label="Code postal"
                    name="PostalCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.PostalCode}
                    type="text"
                    value={values.PostalCode}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="locality"
                    error={errors.City}
                    label="Ville"
                    name="City"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.City}
                    type="text"
                    value={values.City}
                    width="middle"
                  />
                  <InputGroup
                    autoComplete="tel"
                    error={errors.Phone}
                    label="Numéro de téléphone"
                    name="Phone"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.Phone}
                    type="tel"
                    value={values.Phone}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>
                    <FormattedMessage
                      id="checkout.referralCode"
                      defaultMessage="Referral Code"
                    />
                  </FormGroupHeadline>
                  <InputGroup
                    error={errors.referralCode}
                    label={this.props.intl.formatMessage({
                      id: 'checkout.referralCode.placeholder'
                    })}
                    name="referralCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.referralCode}
                    type="text"
                    value={values.referralCode}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Coordonnées bancaires</FormGroupHeadline>
                  <FormDescription as="div">
                    Le titulaire du compte doit correspondre au souscripteur du
                    contrat. Si ce n'est pas le cas, veuillez-vous rendre dans
                    l'un de nos clubs de sport pour conclure le contrat.
                  </FormDescription>
                  <InputGroup
                    autoComplete="name"
                    error={errors.AccountHolder}
                    label="Titulaire du compte"
                    name="AccountHolder"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    readOnly
                    required
                    touched={touched.AccountHolder}
                    type="text"
                    value={`${values.FirstName ||
                      'TITULAIRE DU COMPTE'} ${values.LastName || ''}`}
                  />
                  <InputGroup
                    error={!this.props.BIC && 'checkout.ibanError'}
                    fetching={this.props.fetchingIBAN}
                    label="IBAN"
                    name="IBAN"
                    onBlur={event => {
                      const { value } = event.target;
                      handleBlur(event);
                      handleChange(event);
                      this.handleVerifyIBAN(value);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.IBAN}
                    type="text"
                    uppercase
                    value={values.IBAN}
                  />

                  <FormGroup>
                    <InputField
                      autoComplete="off"
                      id="SEPA"
                      name="SEPA"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.SEPA}
                      type="checkbox"
                      value={values.SEPA}
                    />
                    <Label htmlFor="SEPA" type="checkbox" hide={false}>
                      <StyledCheckbox
                        checkMark
                        checked={values.SEPA}
                        error={errors.SEPA}
                      />
                      <FormDescription as="div"></FormDescription>
                      <CheckboxText>
                        * J’accepte par la présente les prélèvements
                        automatiques SEPA et autorise RSG Group France SCS à
                        envoyer des instructions à ma banque pour débiter mon
                        compte et ma banque à débiter mon compte conformément
                        aux instructions de RSG Group France SCS.
                      </CheckboxText>
                    </Label>
                  </FormGroup>

                  <CheckboxGroup>
                    {contractTextBlocks
                      ?.filter(({ title }) => {
                        switch (title) {
                          case 'Conditions générales de vente':
                          case 'Règlement intérieur':
                          case 'Droit de rétractation':
                          case 'Politique de confidentialité':
                            return true;
                          default:
                            return false;
                        }
                      })
                      .map(
                        ({
                          id,
                          title,
                          text,
                          hasSignature,
                          isConfirmationRequired,
                          attachedDocument
                        }) => (
                          <InputGroupWrapper width="full" type="checkbox">
                            <InputField
                              autoComplete="off"
                              id={`${title}_${id}`}
                              onBlur={event => {
                                handleBlur(event);
                                handleChange(event);
                              }}
                              onChange={handleChange}
                              touched={touched[`${title}_${id}`]}
                              type="checkbox"
                              value={values[`${title}_${id}`]}
                              required={hasSignature || isConfirmationRequired}
                            />
                            <Label
                              htmlFor={`${title}_${id}`}
                              type="checkbox"
                              hide={false}
                            >
                              <StyledCheckbox
                                checkMark
                                checked={values[`${title}_${id}`]}
                              />
                              <CheckboxText>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${
                                      hasSignature || isConfirmationRequired
                                        ? '* '
                                        : ''
                                    }${text}`
                                  }}
                                ></div>
                                {attachedDocument ? (
                                  <>
                                    <br />
                                    <a
                                      href={attachedDocument.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {attachedDocument.fileName}
                                    </a>
                                  </>
                                ) : null}
                              </CheckboxText>
                            </Label>
                          </InputGroupWrapper>
                        )
                      )}
                    <InputGroupWrapper width="full" type="checkbox">
                      <InputField
                        autoComplete="off"
                        id="Marketing"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Marketing}
                        type="checkbox"
                        value={values.Marketing}
                      />
                      <Label htmlFor="Marketing" type="checkbox" hide={false}>
                        <StyledCheckbox checkMark checked={values.Marketing} />
                        <CheckboxText>
                          J’accepte que RSG Group France SCS, RSG Group GmbH et
                          sa filiale Gold’s Gym Trading GmbH utilisent mes
                          données personnelles à des fins de marketing pour
                          proposer des offres de produits des sociétés
                          susmentionnées par e-mail. Je confirme avoir pris
                          connaissance des informations concernant la Newsletter
                          dans la{' '}
                          <a
                            href={
                              'https://johnreed.fitness/fr/politique-de-confidentialite'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Politique de Confidentialité et de Protection des
                            Données Personnelles
                          </a>
                          .
                        </CheckboxText>
                      </Label>
                    </InputGroupWrapper>
                  </CheckboxGroup>
                  <CTAButton
                    type="submit"
                    className="mcfit-tracking-checkout-3"
                    disabled={isSubmitting}
                  >
                    {this.props.sending
                      ? 'En envoyant'
                      : 'COMMANDE AVEC OBLIGATION DE PAIEMENT'}
                  </CTAButton>
                  <LegalWrapper>
                    ** Les prix préventes Lyon Part-Dieu sont valables jusqu‘au
                    15.09.2022 pour un abonnement conclu en ligne ou dans le
                    club de sport JOHN REED Centre Commercial Part-Dieu, 17 rue
                    du docteur Bouchut, 69003 Lyon. |Ouverture prévue du club :
                    15.09.2022 | La date d'ouverture et de début du contrat
                    peuvent être reportés en raison de fermeture administrative
                    ou mesures réglementaires empêchant l’exploitation du club.
                    Dans ce cas la date exacte d'ouverture sera annoncée sur
                    www.johnreed.fitness/fr | Prix préventes Lyon Part-Dieu pour
                    les 12 premiers mois : pour Flex Gold, le prix prévente des
                    cotisations est de 85€/mois (cluster 4) ; pour Flex Silver,
                    le prix prévente des cotisations est de 65€/mois (cluster 4)
                    ; pour Gold (engagement de 12 mois): le prix prévente des
                    cotisatoins est de 65€/mois (cluster 4) ; pour le Silver
                    (engagement de 12 mois): le prix prévente des cotisations
                    est de 45€/mois (cluster 4) |Les prix préventes s’appliquent
                    les 12 premiers mois, ensuite les prix des cotisations
                    passent aux prix classiques respectivement pour Flex Gold :
                    100€/mois (cluster 4) ; pour Flex Silver : 80€/mois (cluster
                    4) ; pour Gold (engagement de 12 mois): 80€/mois (cluster 4)
                    ; pour le Silver (engagement de 12 mois): 60€/mois (cluster
                    4)| GOLD : Le contrat d’abonnement GOLD donne le droit à
                    l’abonné d’utiliser les clubs de sport exploités par RSG
                    Group France sous les enseignes de la ligne « John Reed »
                    jusqu'au seuil de prix 4 inclus (Cluster 4 inclus) pendant
                    leurs heures d’ouverture respectives. Une liste des clubs «
                    JOHN REED » avec le seuil de prix 4 inclus (Cluster 4) est
                    accessible sur le site johnreed.fitness/fr. En tant
                    qu’Abonné Gold, il est possible d’amener gratuitement une
                    personne à chaque séance au club John Reed (club en fonction
                    de l’abonnement). L'accès au club avec la carte Friend
                    Option n'est possible qu'avec l’abonné pendant les heures
                    d'entraînement entre 09:00 et 21:00 du vendredi au dimanche
                    (ces horaires peuvent varier). Le Kids Club n’est pas inclus
                    pour cette personne, est exclu aussi le prêt de serviettes
                    ou le forfait boisson, si applicable. En tant qu’abonné
                    Gold, il est possible de bénéficier gratuitement du Kids
                    Club conformément au Règlement Intérieur Kids Club. | SILVER
                    : Le contrat d’abonnement donne le droit à l’abonné
                    d’utiliser le club de sport situé dans le Centre Commercial
                    Part-Dieu à Lyon (69003), 17 rue du docteur Bouchut exploité
                    par RSG Group France| Les frais d’inscription s’élèvent à
                    50,00 € | Pour le remplacement d’une carte d’abonné, un
                    montant de 25,00 € est dû | Contrat avec engagement 12 mois
                    : souscription d’un abonnement avec engagement d’une durée
                    initiale ferme de 12 mois et tacite reconduction pour une
                    durée indéterminée. L’abonnement peut être suspendu pour un
                    maximum de Gold 6 mois, Silver 4 mois par an |Contrat sans
                    engagement : souscription d’un abonnement à durée
                    indéterminée. L’abonnement peut être suspendu pour un
                    maximum de 2 mois par an | Le règlement intérieur ainsi que
                    les conditions générales de vente sont applicables dans les
                    clubs de sport |En cas de conclusion d'un contrat en ligne,
                    le délai légal pour exercer le droit de rétractation est de
                    14 jours |Tous les prix affichés incluent la TVA | Les prix
                    et les horaires d'ouverture sont susceptibles de varier d’un
                    club de sport à un autre | Les personnes de moins de 15 ans
                    ne peuvent pas devenir abonnées, sont aussi exclues les
                    personnes ayant déjà souscrit un abonnement avec JOHN REED |
                    RSG Group France SCS, 2 Rue du Bouloi, 75001 Paris, capital
                    social de 20.000.000 euros, R.C.S. de Paris 844 150 813.
                  </LegalWrapper>
                </FormGroup>
              </FormSidebar>
              <FormAside>
                <SelectedContract
                  button={false}
                  sidebar
                  {...this.props.selectedContract}
                  highlight={false}
                />
              </FormAside>
            </FormWrapper>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: async (data, actions) => {
    await dispatch(sendData(data));
    actions.setSubmitting(false);
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractFormFR))
);
