import styled from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

export default styled.aside`
  background-color: ${props => props.theme.color6};
  padding: 1em;

  ${media.md`
    display: block;
    flex: 1 1 430px;
  `};

  ${media.lg`
    padding: 1.9em 3.6em 3.6em 3.6em;
  `};
`;
