import styled, { css } from 'styled-components/macro';

export default styled.header`
  background-color: ${props => props.theme.active1};
  color: ${props => props.theme.backgroundMain};
  font-family: ${props => props.theme.ffBrand};
  font-size: 1.3rem;
  justify-content: center;
  padding: 32px 64px 32px 32px;
  position: relative;
  text-align: center;

  ${props =>
    props.brand === 'high5' &&
    css`
      font-size: 1.6rem;
    `};
`;
