import styled from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

const AppGrid = styled.div`
  display: grid;
  grid-template-areas: 'header' 'stoerer' 'content' 'footer';
  grid-template-columns: 100%;
  grid-template-rows: 5em auto 1fr auto;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;

  ${media.md`grid-template-rows: 7.5em auto 1fr auto;`};
`;

export default AppGrid;
