import styled from 'styled-components';

export default styled.p`
  margin: 0 auto 4rem auto;
  max-width: 30rem;
  text-align: center;

  img {
    height: 40px;
    margin-right: 20px;
  }

  a {
    color: ${props => props.theme.active1};
  }
`;
