import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchLocations } from '../../../../../../data/checkout';
import Spinner from '../../../../../../components/Spinner/Spinner';
import addResponsive from '../../../../../../components/addResponsive/addResponsive';
import LocationInputWrapper from './components/LocationInputWrapper';
import StyledInput from './components/StyledInput';
import ClearInputIcon from './components/ClearInputIcon';
import LocationPinIcon from './components/LocationPinIcon';

export class LocationInput extends Component {
  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    handleFetch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    results: PropTypes.PropTypes.arrayOf(PropTypes.shape()).isRequired,
    wide: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      animated: false,
      location: ''
    };
    this.timer = setTimeout(() => false, 0);
  }

  componentDidMount() {
    if (document.getElementById('woosmap-multisearch'))
      return this.setState({ loaded: true });
    const script = document.createElement('script');
    script.src = 'https://sdk.woosmap.com/multisearch/multisearch.js';
    script.id = 'woosmap-multisearch';
    script.onload = () => this.setState({ loaded: true });

    document.body.appendChild(script);
  }

  getPlaceholderText = () => {
    if (this.props.wide) {
      return 'checkout.locationPlaceholderLong';
    }
    return 'checkout.locationPlaceholder';
  };

  handleHover = () => {
    // Wait for animation to trigger once
    setTimeout(() => {
      this.setState({
        animated: true
      });
    }, 300);
  };

  handleClear = () => {
    this.setState({
      location: ''
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      location: this.input.value
    });
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.state.location.length) {
        this.props.handleFetch(
          this.state.location,
          this.props.match.params.contractId || null
        );
      }
    }, 500);
  };

  handleChange = event => {
    const { value } = event.target;
    this.setState({
      location: value
    });
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.state.location.length >= 3) {
        this.props.handleFetch(
          this.state.location,
          this.props.match.params.contractId || null
        );
      }
    }, 500);
  };

  render() {
    const {
      results,
      fetching,
      intl: { formatMessage }
    } = this.props;
    const { animated, location } = this.state;

    return (
      <LocationInputWrapper
        locations={results}
        fetching={fetching}
        onMouseEnter={this.handleHover}
      >
        <LocationPinIcon animated={animated} />
        <form onSubmit={this.handleSubmit}>
          <StyledInput
            ref={el => {
              this.input = el;
            }}
            onChange={this.handleChange}
            onBlur={this.handleChange}
            type="text"
            placeholder={formatMessage({ id: this.getPlaceholderText() })}
            value={location}
          />
        </form>
        {!fetching && (location.length || results.length) ? (
          <ClearInputIcon handleClear={this.handleClear} />
        ) : null}
        {fetching ? <Spinner /> : null}
      </LocationInputWrapper>
    );
  }
}

const mapStateToProps = state => ({
  results: state.data.checkout.locations,
  fetching: state.data.checkout.fetching
});

const mapDispatchToProps = dispatch => ({
  handleFetch: (location, id) => {
    dispatch(fetchLocations(location, id));
  }
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(addResponsive(LocationInput))
  )
);
