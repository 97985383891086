import styled from 'styled-components';
import Button from '../Button/Button';

const LinkButton = styled(Button)`
  border: 0;
  border-bottom: 2px solid ${props => props.theme.breaker};
  color: ${props => (props.color ? props.color : props.theme.secondary)};
  font-size: 1em;
  height: auto;
  line-height: 1;
  margin: 0 1em 1em;
  min-width: 0;
  padding: 1em 0 0.5em;
  position: relative;
  text-decoration: none;
  transition: border-color 0.2s;

  &:hover {
    background-color: transparent;
    border-color: ${props => props.theme.active};
  }
`;

export default LinkButton;
