import React from 'react';

import Wrapper from './components/Wrapper';
import Headline from './components/CardHeadline';
import Text from './components/CardText';

const UnderConstruction = ({ children }) => (
  <Wrapper>
    <Headline>Umbauarbeiten</Headline>
    <Text>{children}</Text>
  </Wrapper>
);

export default UnderConstruction;
