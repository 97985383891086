import React from 'react';

import themesObj from '../../themes';

import Table from './components/Table';
import Row from './components/Row';
import Example from './components/Example';

const Colors = () => {
  const themes = Object.keys(themesObj).map(theme => theme);

  const mapThemesOnRow = themesArr =>
    Object.keys(themesObj[themesArr[0]]).map(key => (
      <Row key={key}>
        <div>{key}</div>
        {themesArr.map(currentTheme => (
          <div key={`${currentTheme}_${key}`}>
            {themesObj[currentTheme][key]}
            <Example color={themesObj[currentTheme][key]} />
          </div>
        ))}
      </Row>
    ));

  return (
    <div>
      <Table>
        <Row first>
          <div>Variable</div>
          {themes.map(theme => (
            <div key={theme}>{themesObj[theme].title}</div>
          ))}
        </Row>
        {mapThemesOnRow(themes)}
      </Table>
    </div>
  );
};

export default Colors;
