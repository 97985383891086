import React from 'react';
import PropTypes from 'prop-types';

import ContractFormDE from './components/ContractFormDE/ContractFormDE';
import ContractFormES from './components/ContractFormES/ContractFormES';
import ContractFormIT from './components/ContractFormIT/ContractFormIT';
import ContractFormFR from './components/ContractFormFR/ContractFormFR';
import ContractFormNL from './components/ContractFormNL/ContractFormNL';
import ContractFormUK from './components/ContractFormUK/ContractFormUK';

const ContractForm = ({ lang }) => {
  switch (lang) {
    case 'es':
      return <ContractFormES />;
    case 'it':
      return <ContractFormIT />;
    case 'fr':
      return <ContractFormFR />;
    case 'nl':
      return <ContractFormNL />;
    case 'uk':
    case 'en':
    case 'gb':
      return <ContractFormUK />;
    case 'de':
    case 'at':
      return <ContractFormDE austria={lang === 'at'} />;
    default:
      return null;
  }
};

ContractForm.propTypes = {
  lang: PropTypes.string
};

export default ContractForm;
