import React from 'react';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Wrapper from '../Wrapper';
import Headline from '../Headline';
import Subtext from '../Subtext';
import CybPromoWrapper from '../CybPromoWrapper';
import LooxPromoWrapper from '../LooxPromoWrapper';

import looxPromo from '../../assets/Loox_Hintergrundgrafik_crop.png';
import cybPromo from '../../assets/cyberobics_hintergrundgrafik.png';

const Success = ({
  match: {
    params: { type }
  }
}) => (
  <Wrapper>
    <Headline>
      {type === 'cyberobics' && (
        <FormattedMessage id="tsp.successHeadline.cyberobics" />
      )}
      {type === 'loox' && <FormattedMessage id="tsp.successHeadline.loox" />}
    </Headline>
    <>
      {type === 'cyberobics' && (
        <>
          <Subtext>
            <FormattedHTMLMessage id="tsp.success.cyberobics" />
          </Subtext>
          <Subtext>
            <FormattedHTMLMessage id="tsp.cyberobics.playstore" />
            <FormattedHTMLMessage id="tsp.cyberobics.appstore" />
          </Subtext>
          <CybPromoWrapper>
            <img src={cybPromo} alt="" />
          </CybPromoWrapper>
        </>
      )}
      {type === 'loox' && (
        <>
          <Subtext>
            <FormattedMessage id="tsp.success.loox" />
          </Subtext>
          <Subtext>
            <FormattedHTMLMessage id="tsp.loox.playstore" />
            <FormattedHTMLMessage id="tsp.loox.appstore" />
          </Subtext>
          <LooxPromoWrapper>
            <img src={looxPromo} alt="" />
          </LooxPromoWrapper>
        </>
      )}
    </>
  </Wrapper>
);

export default injectIntl(Success);
