import styled from 'styled-components/macro';

const InputFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 0 0 0.5em;
`;

export default InputFieldWrapper;
