import styled, { css } from 'styled-components/macro';

import ContractName from './ContractName';
import { ContractPrizeWrapper } from './ContractPrize';
import ContractMinimumTerm from './ContractMinimumTerm';
import ContractCopy from './ContractCopy';
import ContractBenefits from './ContractBenefits';
import ContractCTA from './ContractCTA';
import { media } from '../../../lib/styledMixins';

const ContractInnerWrapper = styled.div`
  background-color: ${props => props.theme.color6};
  height: 100%;
  padding: 0.5rem;
  position: relative;
  text-align: center;

  ${props =>
    props.highlight &&
    css`
      border: 4px solid ${props.theme.active1};
    `}
  padding-bottom: ${props => (props.button === 'true' ? '5rem' : '1rem')};


  ${props =>
    props.brand === 'johnreed' &&
    css`
      box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.1);
    `};

  ${props =>
    props.brand === 'high5' &&
    css`
      background-image: linear-gradient(151deg, #d7d7d7, #fff 50%, #d7d7d7);
      border: solid 1px rgba(125, 125, 125, 0.5);
    `};

  ${props =>
    props.loading &&
    css`
      ${ContractName}, ${ContractPrizeWrapper},
        ${ContractMinimumTerm},
        ${ContractBenefits},
        ${ContractCopy} {
        background-color: ${props.theme.lightGrey};
        color: ${props.theme.lightGrey};
      }
      ${ContractCTA} {
        color: ${props.theme.active1};
      }
    `};

  ${props =>
    props.sticky &&
    css`
      ${media.md`
        box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.3);
        height: auto;
        position: fixed;
        top: 2em;
        width: 370px;
      `};
    `};
`;

export default ContractInnerWrapper;
