import styled from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

export default styled.div`
  display: none;

  ${media.md`
    color: ${props => props.theme.color8};
    display: block;
    height: 18px;
    margin-right: .5em;
    width: 24px;
  `};
`;
