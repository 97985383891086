import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { login } from '../../../data/tsp';

import Wrapper from './components/Wrapper';
import Headline from './components/Headline';
import FormWrapper from './components/FormWrapper';
import FieldWrapper from './components/FieldWrapper';
import InputField from './components/InputField';
import InputFieldWrapper from '../../../components/InputFieldWrapper/InputFieldWrapper';
import SVGIcon from './components/SVGIcon';
import { IconUser, IconPassword } from '../../../components/Icons';
import CTAButton from '../../../components/CTAButton/CTAButton';

function Login({ match, login, handleLogin }) {
  return login ? (
    <Redirect from={match.url} to="/tsp/resend" />
  ) : (
    <>
      <Wrapper>
        <Headline>TSP Resend</Headline>
        <Formik
          onSubmit={data => {
            handleLogin(data);
          }}
          initialValues={{
            user: '',
            pw: ''
          }}
          render={({
            handleBlur,
            handleSubmit,
            values,
            errors,
            handleChange
          }) => (
            <FormWrapper onSubmit={handleSubmit}>
              <FieldWrapper>
                <InputFieldWrapper>
                  <SVGIcon value={values.user}>
                    <IconUser />
                  </SVGIcon>
                  <InputField
                    name="user"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user}
                    placeholder="User"
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <SVGIcon value={values.pw}>
                    <IconPassword />
                  </SVGIcon>
                  <InputField
                    name="pw"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pw}
                    placeholder="Passwort"
                  />
                </InputFieldWrapper>
              </FieldWrapper>
              <CTAButton type="submit">Login</CTAButton>
            </FormWrapper>
          )}
        />
      </Wrapper>
    </>
  );
}

const mapStateToProps = state => ({
  login: state.data.tsp.login
});

const mapDispatchToProps = dispatch => ({
  handleLogin: ({ user, pw }) => {
    dispatch(login({ user, password: pw }));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
