import styled from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

export default styled.a`
  z-index: 1;
  color: ${props => props.theme.color8};
  margin-right: 1em;
  text-decoration: none;
  text-transform: uppercase;

  ${media.md`
    border-right: 1px solid ${props => props.theme.color1};
    display: flex;
    flex-direction: row;
    padding: 1.9rem 2em 1.9rem 0;
  `};
`;
