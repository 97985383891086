import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const FooterBorder = styled.div`
  border-width: 1px 0;
  border-color: ${props => props.theme.color1};
  border-style: solid;
  margin-bottom: 2rem;
  padding: 0 1.9rem;

  ${media.md`
    margin-bottom: 0;
  `};
`;

export default FooterBorder;
