const backgroundMain = '#242f3a';
const backgroundThird = '#242f3a';
const backgroundCard = '#33414f';

const contractBorder = '1px solid rgba(255, 255, 255, 0)';
const gradient = 'to right, #ffcc00 0%, #ffcc00 100%';

const studioDividerFirstThickness = '3';
const studioDividerThickness = '2';

const mcfit = {
  color1: '#454e57',
  color2: '#91979c',
  color3: '#fff',
  color4: '#fff',
  color5: '#91979c',
  color6: '#fff',
  color7: '#ffdd00',
  color8: '#91979c',
  color9: '#e9e9e9',
  color10: '#fff',
  color11: '#91979c',

  active1: '#ffdd00',
  active2: '#ffdd00',
  active3: '#fff',
  active4: '#242f3a',
  active5: '#242f3a',

  alert: '#e12819',
  warn: '#F85901',
  info: '#33414f',
  success: '#0eda65',

  buttonTextSecondary: '#242f3a',
  backgroundMain: '#242f3a',
  backgroundSecondary: '#242f3a',
  backgroundThird: '#242f3a',
  backgroundCard: '#33414f',
  backgroundForm: '#F0F0F0',

  studioDividerFirstThickness,
  studioDividerThickness,

  // General
  mainBg: backgroundMain,
  footerBg: backgroundThird,
  cardBg: backgroundCard,
  studioActiveLayer: gradient,
  contractBorder,
  ctaMainHover: gradient,
  loginBtn: gradient,
  lightGrey: '#e9e9e9',
  white: '#fff',
  // Font Families
  ffRegular: "'Open Sans', sans-serif",
  ffCondensed: "'Open Sans Condensed', sans-serif",
  ffBrand: "'Novecento', sans-serif",

  brandId: 1,
  title: 'McFIT',
  logo: 'mcfit.png'
};

export default mcfit;
