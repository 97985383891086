import styled from 'styled-components/macro';

import { media, placeholderMixin } from '../../../../../../../lib/styledMixins';

const StyledInput = styled.input`
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid ${props => props.theme.color3};
  color: ${props => props.theme.color3};
  font-family: inherit;
  font-size: 1em;
  padding: 0.75em 2.75em 0.75em 2em;
  position: relative;
  transition: opacity 0.3s;
  width: 100%;

  &:placeholder-shown {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }

  ${placeholderMixin`
    color: ${props => props.theme.color5};
    opacity: 1;
  `} ${media.md`
    font-size: 1.5em;
    padding-left: 4rem;
  `};
`;

export default StyledInput;
