import styled from 'styled-components';
import { css } from 'styled-components/macro';

const SecondaryCTAButton = styled.button`
  max-width: 100%;
  min-width: 12.5em;
  width: 100%;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  background-color: white;
  border: 0;
  color: #91979c;
  font-size: 1em;
  font-weight: 400;
  border: 2px solid #e9e9e9;
  font-family: 'Open Sans Condensed', sans-serif;
  flex: 1;

  &:hover {
    border: 2px solid #242f3a;
  }

  ${props =>
    props.hovered &&
    css`
      border: 2px solid #242f3a;
    `};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      color: #bec7cf;
    `}
`;

export default SecondaryCTAButton;
