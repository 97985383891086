import styled from 'styled-components/macro';

export const SVGIcon = styled.div`
  color: ${props => props.theme.primary};
  cursor: ${props =>
    props.value.length && props.pointer ? 'pointer' : 'auto'};
  flex-shrink: 0;
  opacity: ${props => (props.value ? '1' : '0.3')};
  transition: opacity 0.3s;
  width: 1.5em;
  height: 1.5em;
`;

export default SVGIcon;
