import styled from 'styled-components/macro';

const InputFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 0 0 0.5em;

  &:first-child {
    border-bottom: 2px solid ${props => props.theme.color1};
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
  }
`;

export default InputFieldWrapper;
