import styled from 'styled-components/macro';

const Text = styled.h1`
  font-family: ${props => props.theme.ffBrand};
  font-size: 1.125em;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
`;

export default Text;
