import React from 'react';

import FooterWrapper from './components/FooterWrapper';
import FooterNavigation from './components/FooterNavigation';
import SocialNavigation from './components/SocialNavigation';
import FooterInnerWrapper from './components/FooterInnerWrapper';
import FooterBorder from './components/FooterBorder';
import FlexWrapper from './components/FlexWrapper';

const Footer = () => (
  <FooterWrapper>
    <FooterBorder>
      <FooterInnerWrapper>
        <FlexWrapper>
          <FooterNavigation />
          <SocialNavigation />
        </FlexWrapper>
      </FooterInnerWrapper>
    </FooterBorder>
  </FooterWrapper>
);

export default Footer;
