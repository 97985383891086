import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  min-height: 2.5em;
  padding: 1em 0;
  align-items: center;
  ${props =>
    props.first
      ? `
            font-weight: bold;
            font-size: 1.2em;
        `
      : `
            font-weight: normal;
            font-size: 1em;
        `} div {
    flex: 1 1 0;
  }
`;

export default Row;
