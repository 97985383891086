import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const FlexWrapper = styled.div`
  text-align: center;

  ${media.md`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};
`;

export default FlexWrapper;
