import styled from 'styled-components';
import { H1, H2 } from '../Typo/index';

import { media } from '../../lib/styledMixins';

const Headline = styled(H1)`
  color: ${props => props.theme.primary};
  font-size: 1.4em;
  font-weight: 700;
  margin: 1em 0 0.625em;
  text-align: center;
  text-transform: uppercase;

  span {
    text-transform: none;
  }

  ${media.md`
    font-size: 2em;
    margin: 1em auto 0.625em;
    max-width: 25rem;
    transition: margin 0.3s ease-out;
  `};
`;

export const Headline2 = styled(H2)`
  color: ${props => props.theme.primary};
  font-size: 1em;
  font-weight: 700;
  margin: 0 0 0.625em 0;
  text-align: left;
  text-transform: uppercase;

  ${media.md`
    font-size: 1.1em;
    margin: 0 0 0.625em 0;
    max-width: 25rem;
    transition: margin 0.3s ease-out;
  `};
`;

export default Headline;
