import styled from 'styled-components/macro';

const LegalWrapper = styled.div`
  color: ${props =>
    // eslint-disable-next-line
    props.background ? props.theme.buttonTextSecondary : props.theme.color2};
  padding: 1.5em;
  order: 4;
`;

export default LegalWrapper;
