import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

import StudioFinder from '../Checkout/components/StudioFinder/StudioFinder';
import NoService from './components/NoService';

const TestTraining = ({ match, intl }) => (
  <Switch>
    <Route exact path={match.url} component={StudioFinder} />
    <Route
      path={`/probetraining${intl.formatMessage({
        id: 'route.enterData'
      })}/:studioId`}
      render={({ ...renderProps }) => <NoService {...renderProps} />}
    />
  </Switch>
);

TestTraining.propTypes = {
  match: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/no-typos
  intl: intlShape.isRequired
};

export default withRouter(injectIntl(TestTraining));
