import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const StyledLink = styled(NavLink)`
  color: ${props => props.theme.color5};
  display: inline-block;
  font-size: 80%;
  padding: 1.5rem 0 0 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color ease-out 0.5s;
  width: 100%;

  ${props =>
    props.step < props.currentstep &&
    css`
      color: ${props.theme.active1};
    `};
  ${props =>
    props.step === props.currentstep &&
    css`
      color: ${props.theme.active1};
    `};

  ::after {
    background-color: ${props => props.theme.color5};
    ${props =>
      props.step < props.currentstep &&
      css`
        background-color: ${props.theme.active1};
      `};
    border-radius: 6px;
    content: '';
    height: 10px;
    left: calc(50% - 5px);
    position: absolute;
    top: -5px;
    width: 10px;
    z-index: 101;
    ${props =>
      props.step === props.currentstep &&
      css`
        background-color: ${props.theme.white};
        height: 12px;
        left: calc(50% - 6px);
        top: -6px;
        width: 12px;
      `};
  }

  ::before {
    ${props =>
      props.step < props.currentstep &&
      css`
        background-color: ${props.theme.active1};
        content: '';
      `};
    border-radius: 15px;

    height: 24px;
    left: calc(50% - 12px);
    position: absolute;
    top: -12px;
    transition: transform ease-out 0.2s;

    width: 24px;
    z-index: 100;

    ${props =>
      props.step === props.currentstep &&
      css`
        background-color: ${props.theme.active1};
        content: '';
        height: 30px;
        left: calc(50% - 15px);
        top: -15px;
        width: 30px;
      `};
  }
`;

export const NoLink = styled(StyledLink)`
  cursor: default;
`;

export default StyledLink;
