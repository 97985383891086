import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import { Formik } from 'formik';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Media from 'react-media';

import CTAButton from '../CTAButton';
import CheckboxGroup from '../CheckboxGroup';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormAside from '../../../../../../../../components/FormAside/FormAside';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputGroupWrapper from '../../../../../../../../components/InputGroup/components/InputGroupWrapper';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import LegalWrapper from '../LegalWrapper';
import SelectedContract from '../../../SelectedContract';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { schemaML, flexSchema } from './contractFormSchemaDE';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';
import config, { configStage } from '../../../../../../../../config';
import replaceUmlauts from '../../../../../../../../lib/replaceUmlauts';

export class ContractFormDE extends Component {
  static propTypes = {
    austria: PropTypes.bool,
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  static defaultProps = {
    austria: false
  };

  state = {
    lastCheckedIBAN: ''
  };

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  timer;

  handleMarketingLinks = (brand, austria) => {
    switch (brand) {
      case 'mcfit':
        return austria
          ? 'https://www.mcfit.com/at/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/'
          : 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/';
      case 'johnreed':
        return 'https://johnreed.fitness/datenschutz/onlineangebot/datenschutz-vertraege/';
      case 'high5':
        return 'https://www.high5.com/footer-navigation/impressum/#datenschutz';
      default:
        return 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/';
    }
  };

  /*
  Returns special AGB links for specific contracts and brands
  */
  handelAGBLinks = (contractId, countryCode, brand) => {
    if (
      contractId === 1709 ||
      contractId === 1713 ||
      contractId === 1715 ||
      contractId === 1717
    ) {
      return countryCode === 'AT'
        ? 'https://www.mcfit.com/fileadmin/user_upload/PDF-Files/Rechtliche_Hinweise/AT/20190401_McFIT_Oesterreich_AGB_Beachbody.pdf'
        : 'https://www.mcfit.com/fileadmin/user_upload/PDF-Files/Rechtliche_Hinweise/DE/20190401_McFIT_Deutschland_AGB_Beachbody.pdf';
    } else {
      switch (brand) {
        case 'johnreed':
          return 'https://johnreed.fitness/agb';
        default:
          return countryCode === 'AT'
            ? 'https://www.mcfit.com/at/ueber-mcfitcom/agb/agb/'
            : 'https://www.mcfit.com/de/ueber-mcfitcom/agb/agb/';
      }
    }
  };

  render() {
    const contractTags =
      window.location.host.match(/stage/) ||
      process.env.NODE_ENV === 'development'
        ? configStage.contractTags
        : config.contractTags;
    const { brand, selectedStudioOpening, member } = this.props;
    const {
      Id,
      CountryCode,
      tsp,
      tspCharges,
      CardCharges,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate,
      isFebCampaign,
      isUpsell,
      highlight,
      starterPaket1Charges
    } = this.props.selectedContract;
    const hasTSP = tsp && !!tspCharges;
    const isFlexContract =
      tags.length &&
      tags.some(tag => tag.identifier === contractTags[brand]['Flex']);
    const schema = isFlexContract ? flexSchema : schemaML;

    return (
      <Formik
        onSubmit={(
          {
            AccountPassword,
            City,
            Email,
            FirstName,
            Gender,
            HouseNumber,
            IBAN,
            LastName,
            Marketing,
            Phone,
            PostalCode,
            Street,
            StudioMark,
            year,
            month,
            day,
            signature,
            ReferrerType,
            hasPremium,
            selectedStudioOpening,
            referralCode,
            uuid,
            pcConsent
          },
          actions
        ) => {
          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountHolder: replaceUmlauts(`${FirstName} ${LastName}`),
            AccountPassword,
            StudioMark,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: Marketing || pcConsent,
              Phone,
              PostalCode,
              Street,
              ReferrerType
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date(),
            uuid,
            pcConsent
          };

          this.props.handleSubmit(data, actions);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          pcConsent: false,
          CountryCode,
          brand,
          hasPremium,
          referralCode: this.props.referralCode || '',
          ...(member
            ? {
                uuid: member.customerUUID,
                FirstName: member.firstname,
                LastName: member.lastname,
                Gender: member.gender === 'MALE' ? 'M' : 'W',
                Email: member.email,
                day: getDate(new Date(member.dateOfBirth)),
                month: getMonth(new Date(member.dateOfBirth)) + 1,
                year: getYear(new Date(member.dateOfBirth)),
                Phone: member.phone
              }
            : {})
        }}
        validationSchema={schema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) =>
          this.props.success ? (
            <Redirect
              to={`/checkout${this.props.intl.formatMessage({
                id: 'route.success'
              })}`}
            />
          ) : (
            <FormWrapper onSubmit={handleSubmit}>
              <FormSidebar campaign={isFebCampaign && !isUpsell}>
                <FormGroup>
                  <FormGroupHeadlineWrap>
                    <FormGroupHeadline>Accountdaten</FormGroupHeadline>
                    <RequiredNote>*Pflichtfelder</RequiredNote>
                  </FormGroupHeadlineWrap>
                  <InputGroup
                    autoComplete="email"
                    error={errors.Email}
                    label="E-Mail"
                    name="Email"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Email}
                    type="email"
                    value={values.Email}
                    readOnly={!!member?.email}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Persönliche Daten</FormGroupHeadline>
                  <InputGroup
                    autoComplete="sex"
                    error={errors.Gender}
                    label="Geschlecht"
                    name="Gender"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={[
                      { name: 'Männlich', value: 'M' },
                      { name: 'Weiblich', value: 'W' }
                    ]}
                    required
                    touched={touched.Gender}
                    type="radio"
                    value={values.Gender}
                    readOnly={!!member?.gender}
                  />
                  {errors && touched && errors.gender && touched.gender ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  <InputGroup
                    autoComplete="given-name"
                    error={errors.FirstName}
                    label="Vorname"
                    name="FirstName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.FirstName}
                    type="text"
                    value={values.FirstName}
                    readOnly={!!member?.firstname}
                  />
                  <InputGroup
                    autoComplete="family-name"
                    error={errors.LastName}
                    label="Nachname"
                    name="LastName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.LastName}
                    type="text"
                    value={values.LastName}
                    readOnly={!!member?.lastname}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Geburtsdaten</FormGroupHeadline>
                  <FormDescription as="div">
                    Du musst mindestens 18 Jahre alt sein, um online einen
                    Vertrag abzuschließen.
                  </FormDescription>
                  <InputGroup
                    error={errors.day}
                    label="Tag"
                    name="day"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 31 }, (v, k) => ++k)}
                    required
                    touched={touched.day}
                    type="select"
                    value={values.day}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  <InputGroup
                    error={errors.month}
                    label="Monat"
                    name="month"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 12 }, (v, k) => ++k)}
                    required
                    touched={touched.month}
                    type="select"
                    value={values.month}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  <InputGroup
                    error={errors.year}
                    label="Jahr"
                    name="year"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={Array.from(
                      { length: 83 },
                      // eslint-disable-next-line no-return-assign,no-param-reassign
                      (v, k) => (k += getYear(subYears(Date.now(), 100)))
                    ).reverse()}
                    required
                    touched={touched.year}
                    type="select"
                    value={values.year}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  {errors &&
                  touched &&
                  ((errors.day && touched.day) ||
                    (errors.month && touched.month) ||
                    (errors.year && touched.year)) ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  {errors && errors.olderThan18 ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.birthday" />
                    </ErrorMessage>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Kontaktdaten</FormGroupHeadline>
                  <InputGroup
                    autoComplete="address-line1"
                    error={errors.Street}
                    label="Straße"
                    name="Street"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Street}
                    type="text"
                    value={values.Street}
                    width="middle"
                  />
                  <InputGroup
                    error={errors.HouseNumber}
                    label="Nummer"
                    name="HouseNumber"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.HouseNumber}
                    type="text"
                    value={values.HouseNumber}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="postal-code"
                    error={errors.PostalCode}
                    label="PLZ"
                    name="PostalCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.PostalCode}
                    type="text"
                    value={values.PostalCode}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="locality"
                    error={errors.City}
                    label="Ort"
                    name="City"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.City}
                    type="text"
                    value={values.City}
                    width="middle"
                  />
                  <InputGroup
                    autoComplete="tel"
                    error={errors.Phone}
                    label="Telefonnummer"
                    name="Phone"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.Phone}
                    type="tel"
                    value={values.Phone}
                  />
                </FormGroup>
                {brand === 'mcfit' && (
                  <FormGroup>
                    <FormGroupHeadline>
                      <FormattedMessage
                        id="checkout.mcfitplus"
                        defaultMessage="McFIT+"
                      />
                    </FormGroupHeadline>
                    <FormDescription as="div">
                      Hol dir, was du verdienst: kostenfreier Zugang zu
                      Trainings- & Partnervorteilen ab dem 1. Tag deiner
                      Mitgliedschaft. Je länger du dabei bist, umso mehr
                      bekommst du.
                    </FormDescription>
                    <InputGroupWrapper width="full" type="checkbox">
                      <InputField
                        autoComplete="off"
                        id="pcConsent"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.pcConsent}
                        type="checkbox"
                        value={values.pcConsent}
                      />
                      <Label htmlFor="pcConsent" type="checkbox" hide={false}>
                        <StyledCheckbox checkMark checked={values.pcConsent} />
                        <CheckboxText>
                          Ich stimme den{' '}
                          <a
                            href={
                              this.props.selectedStudioISO === 'AT'
                                ? 'https://www.mcfit.com/at/nutzungsbedingungen-mcfit-plus/'
                                : 'https://www.mcfit.com/de/nutzungsbedingungen-mcfit-plus/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Nutzungsbedingungen
                          </a>{' '}
                          von McFIT+ zu und möchte daran teilnehmen, um von der
                          Vorteilen der RSG Group GmbH bzw. der RSG Group
                          Österreich Ges.mbH und weiterer Partner zu
                          profitieren. Ich willige bis auf Widerruf ein,
                          Produkt-, Dienstleistungs- und Aktionsinformationen
                          der RSG Group GmbH und deren{' '}
                          <a
                            href={
                              this.props.selectedStudioISO === 'AT'
                                ? 'https://www.mcfit.com/at/nutzungsbedingungen-mcfit-plus/rsg-group-tochterunternehmen/'
                                : 'https://www.mcfit.com/de/nutzungsbedingungen-mcfit-plus/rsg-group-tochterunternehmen/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Tochterunternehmen
                          </a>
                          , sowie Vorteilsangebote von Partnerunternehmen per
                          Email zu erhalten. Die Informationen zu den
                          Newslettern der RSG Group GmbH in der{' '}
                          <a
                            href={
                              this.props.selectedStudioISO === 'AT'
                                ? 'https://www.mcfit.com/at/ueber-mcfitcom/datenschutz/datenschutzerklaerung/'
                                : 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Datenschutzerklärung
                          </a>{' '}
                          habe ich gelesen und akzeptiere diese.
                        </CheckboxText>
                      </Label>
                    </InputGroupWrapper>
                  </FormGroup>
                )}
                <FormGroup>
                  <FormGroupHeadline>
                    <FormattedMessage
                      id="checkout.referralCode"
                      defaultMessage="Referral Code"
                    />
                  </FormGroupHeadline>
                  <InputGroup
                    error={errors.referralCode}
                    label=""
                    name="referralCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.referralCode}
                    type="text"
                    value={values.referralCode}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Bankverbindung</FormGroupHeadline>
                  <FormDescription as="div">
                    Der Kontoinhaber muss identisch mit dem Antragsteller sein.
                    Falls dies nicht der Fall ist, gehe bitte in eines unserer
                    Studios um den Vertrag abzuschließen.
                  </FormDescription>
                  <InputGroup
                    autoComplete="name"
                    error={errors.AccountHolder}
                    label="Kontoinhaber"
                    name="AccountHolder"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    readOnly
                    required
                    touched={touched.AccountHolder}
                    type="text"
                    value={`${replaceUmlauts(
                      values.FirstName || 'KONTOINHABER'
                    )} ${replaceUmlauts(values.LastName || '')}`}
                  />
                  <InputGroup
                    error={!this.props.BIC && 'checkout.ibanError'}
                    fetching={this.props.fetchingIBAN}
                    label="IBAN"
                    name="IBAN"
                    onBlur={event => {
                      const { value } = event.target;
                      handleBlur(event);
                      handleChange(event);
                      this.handleVerifyIBAN(value);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.IBAN}
                    type="text"
                    uppercase
                    value={values.IBAN}
                  />
                  {/* agreement checkboxes for high5 and jr */}
                  {brand !== 'mcfit' ? (
                    <>
                      <FormGroup>
                        <InputField
                          autoComplete="off"
                          id="SEPA"
                          name="SEPA"
                          onBlur={event => {
                            handleBlur(event);
                            handleChange(event);
                          }}
                          onChange={handleChange}
                          required
                          touched={touched.SEPA}
                          type="checkbox"
                          value={values.SEPA}
                        />
                        <Label htmlFor="SEPA" type="checkbox" hide={false}>
                          <StyledCheckbox
                            checkMark
                            checked={values.SEPA}
                            error={errors.SEPA}
                          />
                          <CheckboxText>
                            {this.props.selectedStudioISO === 'AT'
                              ? '* Ich ermächtige die RSG Group Österreich Ges.mbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der RSG Group Österreich Ges.mbH auf mein Konto gezogenen Lastschriften einzulösen.'
                              : '* Ich ermächtige die RSG Group GmbH oder ihre Rechtsnachfolgerin, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der RSG Group GmbH auf mein Konto gezogenen Lastschriften einzulösen.'}
                          </CheckboxText>
                        </Label>
                      </FormGroup>
                      {isFlexContract ? (
                        <FormGroup>
                          <FormDescription as="div">
                            Der Aktivierungsgebühr in Höhe von{' '}
                            {CardCharges || 0}.00 €, sowie den monatlichen
                            Beiträgen stimme ich zu und möchte Mitglied bei der
                            RSG Group werden.
                          </FormDescription>
                        </FormGroup>
                      ) : null}
                      {!isFlexContract ? (
                        <>
                          <InputGroupWrapper width="full" type="checkbox">
                            <InputField
                              autoComplete="off"
                              id="AGB2"
                              onBlur={event => {
                                handleBlur(event);
                                handleChange(event);
                              }}
                              onChange={handleChange}
                              required
                              touched={touched.AGB2}
                              type="checkbox"
                              value={values.AGB2}
                            />
                            <Label htmlFor="AGB2" type="checkbox" hide={false}>
                              <StyledCheckbox
                                checkMark
                                checked={values.AGB2}
                                error={errors.AGB2}
                              />
                              <CheckboxText>
                                * Hiermit stimme ich
                                {hasTSP
                                  ? ` der wiederkehrenden Trainings-und Servicepauschale in Höhe von ${tspCharges}.00 €** und `
                                  : ' '}
                                der Gebühr für die Aktivierung der Membercard in
                                Höhe von einmalig {CardCharges || 0}.00 €** zu.
                              </CheckboxText>
                            </Label>
                          </InputGroupWrapper>
                        </>
                      ) : null}
                      <CheckboxGroup>
                        {isFlexContract ? (
                          <>
                            <InputGroupWrapper width="full" type="checkbox">
                              <InputField
                                autoComplete="off"
                                id="acceptedTSP"
                                onBlur={event => {
                                  handleBlur(event);
                                  handleChange(event);
                                }}
                                onChange={handleChange}
                                required
                                touched={touched.acceptedTSP}
                                type="checkbox"
                                value={values.acceptedTSP}
                              />
                              <Label
                                htmlFor="acceptedTSP"
                                type="checkbox"
                                hide={false}
                              >
                                <StyledCheckbox
                                  checkMark
                                  checked={values.acceptedTSP}
                                  error={errors.acceptedTSP}
                                />
                                <CheckboxText>
                                  * Hiermit stimme ich der Gebühr für die
                                  Aktivierung der Membercard in Höhe von
                                  einmalig {CardCharges || 0}.00 €** zu.
                                </CheckboxText>
                              </Label>
                            </InputGroupWrapper>
                          </>
                        ) : null}

                        <InputGroupWrapper width="full" type="checkbox">
                          <InputField
                            autoComplete="off"
                            id="Marketing"
                            onBlur={event => {
                              handleBlur(event);
                              handleChange(event);
                            }}
                            onChange={handleChange}
                            touched={touched.Marketing}
                            type="checkbox"
                            value={values.Marketing}
                          />
                          <Label
                            htmlFor="Marketing"
                            type="checkbox"
                            hide={false}
                          >
                            <StyledCheckbox
                              checkMark
                              checked={values.Marketing}
                            />
                            <CheckboxText>
                              Ich möchte Infos zu den Themen Fitness, Training,
                              Ernährung und Lifestyle der{' '}
                              {this.props.selectedStudioISO === 'AT'
                                ? 'RSG Group Österreich Ges.mbH'
                                : 'RSG Group GmbH'}{' '}
                              erhalten. Die Informationen zu unserem Newsletter
                              in{' '}
                              <a
                                href={this.handleMarketingLinks(
                                  brand,
                                  this.props.selectedStudioISO === 'AT'
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                unserer Datenschutzerklärung
                              </a>{' '}
                              habe ich gelesen und akzeptiere diese.
                            </CheckboxText>
                          </Label>
                        </InputGroupWrapper>
                      </CheckboxGroup>
                    </>
                  ) : (
                    <FormGroup>
                      <InputField
                        autoComplete="off"
                        id="payAgree"
                        name="payAgree"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        required
                        touched={touched.payAgree}
                        type="checkbox"
                        value={values.payAgree}
                      />
                      <Label htmlFor="payAgree" type="checkbox" hide={false}>
                        <StyledCheckbox
                          checkMark
                          checked={values.payAgree}
                          error={errors.payAgree}
                        />
                        <CheckboxText>
                          {this.props.selectedStudioISO === 'AT'
                            ? `Ich ermächtige die RSG Group Österreich Ges.mbH oder ihre Rechtsnachfolgerin, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der RSG Group Österreich Ges.mbH auf mein Konto gezogenen Lastschriften einzulösen.${
                                starterPaket1Charges
                                  ? ` Ich stimme der Gebühr für das Starterpaket 1 von einmalig ${starterPaket1Charges}.00€ zu.`
                                  : ''
                              }`
                            : `Ich ermächtige die RSG Group GmbH oder ihre Rechtsnachfolgerin, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der RSG Group GmbH auf mein Konto gezogenen Lastschriften einzulösen${(hasTSP ||
                                CardCharges) &&
                                `. Ich stimme `}${
                                hasTSP
                                  ? `der wiederkehrenden Trainings- und Servicepauschale i.H.v. ${tspCharges}.00€`
                                  : ''
                              }${hasTSP && CardCharges ? ` und ` : ''}${
                                !!CardCharges
                                  ? `der Gebühr für die Aktivierung der Membercard von einmalig ${CardCharges}.00€ zu`
                                  : ''
                              }${hasTSP && !!!CardCharges ? ' zu' : ''}.`}
                        </CheckboxText>
                      </Label>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormDescription style={{ color: '#242f3a' }}>
                      <strong>
                        Es gelten unsere{' '}
                        <a
                          href={this.handelAGBLinks(
                            this.props.selectedContract.Id,
                            this.props.selectedStudioISO,
                            brand
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Allgemeinen Geschäftsbedingungen
                        </a>
                        . Bitte beachte auch die{' '}
                        <a
                          href={
                            this.props.selectedStudioISO === 'AT'
                              ? 'https://www.mcfit.com/at/ueber-mcfitcom/widerrufsbelehrung/'
                              : 'https://www.mcfit.com/de/ueber-mcfitcom/widerrufsbelehrung/'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Widerrufsbelehrung
                        </a>{' '}
                        sowie unsere{' '}
                        <a
                          href={
                            this.props.selectedStudioISO === 'AT'
                              ? 'https://www.mcfit.com/at/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/'
                              : 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Datenschutzerklärung
                        </a>
                        .
                      </strong>
                    </FormDescription>
                  </FormGroup>
                  <CTAButton
                    type="submit"
                    className="mcfit-tracking-checkout-3"
                    disabled={isSubmitting}
                  >
                    {this.props.sending ? (
                      <FormattedMessage
                        id="checkout.sendingButton"
                        defaultMessage="Send"
                      />
                    ) : (
                      <FormattedMessage
                        id="checkout.sendButton"
                        defaultMessage="Send"
                      />
                    )}
                  </CTAButton>
                  <LegalWrapper>
                    ** Alle angegebenen Preise einschließlich Umsatzsteuer |
                    Preise und Öffnungszeiten können in einzelnen Studios
                    abweichen | Personen vor Vollendung des 15. Lebensjahres
                    können nicht Mitglied werden. | Anbieter:{' '}
                    {this.props.selectedStudioISO === 'AT'
                      ? 'RSG Group Österreich Ges.mbH, Doningasse 12/2/5, A-1220 Wien.'
                      : 'RSG Group GmbH, Tannenberg 4, 96132 Schlüsselfeld.'}
                  </LegalWrapper>
                </FormGroup>
              </FormSidebar>
              {(!isFebCampaign || isUpsell) && (
                <Media query="(max-width: 576px)">
                  {isMobile => {
                    const highlight = isMobile
                      ? this.props.selectedContract.highlight
                      : false;
                    return (
                      <FormAside>
                        <SelectedContract
                          button={false}
                          sidebar
                          {...this.props.selectedContract}
                          highlight={highlight}
                        />
                      </FormAside>
                    );
                  }}
                </Media>
              )}
            </FormWrapper>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: async (data, actions) => {
    await dispatch(sendData(data));
    actions.setSubmitting(false);
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractFormDE))
);
