import styled, { css } from 'styled-components/macro';

import { placeholderMixin } from '../../../lib/styledMixins';

export const inputStyles = props => css`
  background-color: ${props.theme.color4};
  border: 2px solid ${props.theme.color9};
  color: ${props.theme.color2};
  display: block;
  font-family: ${props.theme.ffCondensed};
  font-size: 1em;
  padding: 0.75em;

  &:required {
    box-shadow: none;
  }

  ${props.type === 'radio' || props.type === 'checkbox'
    ? 'clip: rect(1px, 1px, 1px, 1px); position: absolute !important;'
    : 'width: 100%'};

  ${props.type === 'date' &&
    `
      color: ${props.theme.color2};
    `};

  ${placeholderMixin`
    color: ${props.theme.color8};
    letter-spacing: 1px;
    opacity: 0.8;
    text-transform: uppercase;
  `};

  ${props.touched &&
    props.error &&
    props.error.length !== 'false' &&
    !props.fetching &&
    `
      border-bottom: 4px solid ${props.theme.alert};

      ${placeholderMixin`
        color: ${props.theme.alert};
        letter-spacing: 1px;
        opacity: 0.8;
        text-transform: uppercase;
      `};
    `};

  ${props.touched &&
    (!props.error || props.error === 'false') &&
    !props.fetching &&
    `
      border-bottom: 4px solid ${props.theme.success};
    `};
`;

const InputField = styled.input`
  ${props => inputStyles(props)};

  ${props =>
    props.type === 'radio' &&
    css`
      &:focus + label div {
        border: 2px solid ${props.theme.active1};
      }
    `};
`;

export default InputField;
