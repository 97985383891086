import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Content from '../../components/Content/Content';
import { H1 } from '../../components/Typo';
import BackButton from './components/BackButton';
import Wrapper from './components/NotFoundWrapper';
import GlitchImage from './components/GlitchImage';
import Glitch from './components/Glitch';
import GlitchContent from './components/GlitchContent';
import './NotFound.css';

const NotFound = () => (
  <Content>
    <Wrapper>
      <GlitchContent>
        <Glitch>
          <GlitchImage />
          <GlitchImage animation="glitch-anim-1" blendColor="transparent" />
          <GlitchImage animation="glitch-anim-2" blendColor="transparent" />
          <GlitchImage animation="glitch-anim-3" blendColor="#FFDD00" />
          <GlitchImage animation="glitch-anim-flash" blendColor="#FED80A" />
        </Glitch>
      </GlitchContent>
      <H1>
        <FormattedMessage id="route.notFound" />
      </H1>
      <p>
        <FormattedMessage id="route.notFound.message" />
      </p>
      <BackButton as={Link} to="/">
        <FormattedMessage id="notfound.homebutton" />
      </BackButton>
    </Wrapper>
  </Content>
);

export default NotFound;
