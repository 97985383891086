import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

export const StyledLink = styled.a`
  margin: 0 auto 0 0;
`;

const LogoImg = styled.img`
  display: block;
  max-height: 50px;
  margin: 0 auto;

  ${media.md`
    margin: 0;
    max-height: 120px;
    width: auto;
    max-width: 170px;
  `};
`;

const Logo = props => (
  <StyledLink href={props.to}>
    <LogoImg alt={props.alt} src={props.src} />
  </StyledLink>
);

Logo.defaultProps = {
  alt: 'Logo'
};

Logo.propTypes = {
  to: PropTypes.string.isRequired,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired
};

export default Logo;
