import uuid from 'uuid/v4';
import { displayMessage, displayAutoCloseMessage } from '../messages';

export const SEND_DECRYPTION_REQUEST = 'data/TSP/SEND_DECRYPTION_REQUEST';
export const SEND_DECRYPTION_SUCCESS = 'data/TSP/SEND_DECRYPTION_SUCCESS';
export const SEND_DECRYPTION_ERROR = 'data/TSP/SEND_DECRYPTION_ERROR';
export const SEND_USERDATA_CYB_REQUEST = 'data/TSP/SEND_USERDATA_CYB_REQUEST';
export const SEND_USERDATA_CYB_SUCCESS = 'data/TSP/SEND_USERDATA_CYB_SUCCESS';
export const SEND_USERDATA_CYB_ERROR = 'data/TSP/SEND_USERDATA_CYB_ERROR';
export const SEND_TSP_LOGIN = 'data/TSP/SEND_TSP_LOGIN';
export const SEND_TSP_LOGIN_CHECK = 'data/TSP/SEND_TSP_LOGIN_CHECK';
export const SEND_TSP_LOGIN_SUCCESS = 'data/TSP/SEND_TSP_LOGIN_SUCCESS';
export const SEND_TSP_LOGIN_ERROR = 'data/TSP/SEND_TSP_LOGIN_ERROR';
export const SEND_RESEND_DATA = 'data/TSP/SEND_RESEND_DATA';
export const SEND_RESEND_DATA_SUCCESS = 'data/TSP/SEND_RESEND_DATA_SUCCESS';
export const SEND_RESEND_DATA_ERROR = 'data/TSP/SEND_RESEND_DATA_ERROR';

function requestDecryption() {
  return {
    type: SEND_DECRYPTION_REQUEST
  };
}

function requestDecryptionSuccess(data) {
  return {
    type: SEND_DECRYPTION_SUCCESS,
    payload: data
  };
}

function requestDecryptionFailure() {
  return {
    type: SEND_DECRYPTION_ERROR
  };
}

export function fetchDecryption(hash) {
  return async dispatch => {
    function onSuccess(data) {
      return dispatch(requestDecryptionSuccess(data));
    }

    function onError(response) {
      dispatch(requestDecryptionFailure());
      dispatch(
        displayMessage(
          {
            text: 'messages.receiveDataError',
            type: 'alert'
          },
          uuid()
        )
      );
      return response;
    }

    dispatch(requestDecryption());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });
      const response = await fetch(`/api/tsp-decrypt`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          hash
        })
      });
      const json = await response.json();

      return onSuccess(json);
    } catch (err) {
      return onError(err);
    }
  };
}

function sendUserDataCybRequest() {
  return {
    type: SEND_USERDATA_CYB_REQUEST
  };
}

function sendUserDataCybSuccess() {
  return {
    type: SEND_USERDATA_CYB_SUCCESS
  };
}

function sendUserDataCybError() {
  return {
    type: SEND_USERDATA_CYB_ERROR
  };
}

export function sendCyberobicsUserData(userData) {
  return async dispatch => {
    function onSuccess({ message, type }) {
      dispatch(sendUserDataCybSuccess());

      if (message) {
        dispatch(
          displayMessage(
            {
              text: message,
              type
            },
            uuid()
          )
        );
      }

      return;
    }

    function onError({ message, type }) {
      dispatch(sendUserDataCybError());
      dispatch(
        displayAutoCloseMessage({
          text: message ? message : 'messages.receiveDataError',
          type
        })
      );
      return message;
    }
    dispatch(sendUserDataCybRequest());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });
      const response = await fetch(`/api/tsp-cyberobics`, {
        method: 'POST',
        headers,
        body: JSON.stringify(userData)
      });
      const json = await response.json();

      if (response.status === 400 || response.status === 500) {
        return onError(json);
      }

      return onSuccess(json);
    } catch (err) {
      return onError(err);
    }
  };
}

function sendLogin() {
  return {
    type: SEND_TSP_LOGIN
  };
}

function sendLoginSuccess() {
  return {
    type: SEND_TSP_LOGIN_SUCCESS
  };
}

function sendLoginError() {
  return {
    type: SEND_TSP_LOGIN_ERROR
  };
}

export function login(data) {
  return async dispatch => {
    function onSuccess({ status }) {
      if (status === 403) {
        dispatch(
          displayAutoCloseMessage({
            text: 'messages.wrongPassword',
            type: 'alert'
          })
        );

        return dispatch(sendLoginError());
      }

      return dispatch(sendLoginSuccess());
    }

    function onError({ message, type }) {
      dispatch(sendLoginError());
      dispatch(
        displayAutoCloseMessage({
          text: message ? message : 'messages.receiveDataError',
          type
        })
      );
      return message;
    }

    dispatch(sendLogin());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const resp = await fetch('/api/tsp-login', {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      });

      return onSuccess(resp);
    } catch (err) {
      return onError(err, 'alert');
    }
  };
}

function sendLoginCheck() {
  return {
    type: SEND_TSP_LOGIN_CHECK
  };
}

export function checkLogin() {
  return async dispatch => {
    function onSuccess({ status }) {
      if (status === 403) {
        dispatch(
          displayAutoCloseMessage({
            text: 'messages.wrongPassword',
            type: 'alert'
          })
        );
        return dispatch(sendLoginError());
      }

      return dispatch(sendLoginSuccess());
    }

    function onError({ message, type }) {
      dispatch(sendLoginError());
      dispatch(
        displayAutoCloseMessage({
          text: message ? message : 'messages.receiveDataError',
          type
        })
      );
      return message;
    }

    dispatch(sendLoginCheck());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const resp = await fetch('/api/tsp-checklogin', {
        method: 'GET',
        headers
      });

      return onSuccess(resp);
    } catch (err) {
      return onError(err, 'alert');
    }
  };
}

function sendResend() {
  return {
    type: SEND_RESEND_DATA
  };
}

function sendResendSuccess(payload) {
  return {
    type: SEND_RESEND_DATA_SUCCESS,
    payload
  };
}

function sendResendError() {
  return {
    type: SEND_RESEND_DATA_ERROR
  };
}

export const resend = function(data) {
  return async dispatch => {
    function onSuccess(data) {
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.cyberobics.resendSuccess',
          type: 'success'
        })
      );
      return dispatch(sendResendSuccess(data));
    }

    function onError({ message, type }) {
      dispatch(sendResendError());
      dispatch(
        displayAutoCloseMessage({
          text: message ? message : 'messages.receiveDataError',
          type
        })
      );
      return message;
    }

    dispatch(sendResend());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const response = await (
        await fetch('/api/tsp-resend', {
          method: 'POST',
          headers,
          body: JSON.stringify(data)
        })
      ).json();

      return onSuccess(response);
    } catch (err) {
      return onError(err, 'alert');
    }
  };
};

export default function reducer(
  state = {
    fetching: false,
    data: [],
    login: false,
    success: false,
    error: false,
    links: null
  },
  action
) {
  switch (action.type) {
    case SEND_DECRYPTION_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case SEND_DECRYPTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case SEND_DECRYPTION_ERROR:
      return {
        ...state,
        fetching: false,
        error: true
      };
    case SEND_USERDATA_CYB_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case SEND_USERDATA_CYB_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: true
      };
    case SEND_USERDATA_CYB_ERROR:
      return {
        ...state,
        fetching: false,
        error: true
      };
    case SEND_TSP_LOGIN:
      return {
        ...state,
        fetching: true
      };
    case SEND_TSP_LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        login: true
      };
    case SEND_TSP_LOGIN_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
        login: false
      };
    case SEND_RESEND_DATA:
      return {
        ...state,
        fetching: true
      };
    case SEND_RESEND_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        links: action.payload
      };
    case SEND_RESEND_DATA_ERROR:
      return {
        ...state,
        fetching: false,
        error: true
      };
    default:
      return state;
  }
}
