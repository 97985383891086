import styled from 'styled-components/macro';

const ContractBenefits = styled.ul`
  color: ${props => props.theme.backgroundSecondary};
  font-size: 1rem;
  letter-spacing: -1px;
  margin-bottom: 2rem;
  text-align: left;
`;

export default ContractBenefits;
