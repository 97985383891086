import styled, { css } from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

const Content = styled.div`
  grid-area: content;
  height: 100%;
  margin: 0 auto;
  max-width: 83em;
  padding: 1.5em;
  width: 100%;

  ${media.md`padding: 4em;`} ${props =>
    props.fullwidth &&
    css`
      max-width: none;
      padding: 0 !important;
    `};
`;

export default Content;
