import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledSelect from './components/StyledSelect';
import StyledLabel from './components/StyledLabel';
import StyledSVG from './components/StyledSVG';
import { SVGIcon } from '../HeaderIcon';
import { IconChevron, IconWeb } from '../../../Icons';
import i18n from '../../../../i18n';

const langsNotForSelect = ['nl', 'en'];

const filterLangs = lang => !langsNotForSelect.includes(lang);

export const LanguageSelect = () => {
  const location = useLocation();
  const history = useHistory();
  const locale = useSelector(state => state.intl.locale);

  const handleSelect = lang => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('lang', lang);
    history.replace({
      pathname: location.pathname,
      search: '?' + queryParams.toString()
    });
  };

  return (
    <StyledLabel as="label" htmlFor="language">
      <SVGIcon>
        <IconWeb />
      </SVGIcon>
      <StyledSelect
        as="select"
        name="language"
        id="language"
        onChange={event => handleSelect(event.target.value)}
        defaultValue={locale}
      >
        {Object.keys(i18n)
          .filter(filterLangs)
          .map(lang => (
            <option key={lang} value={lang}>
              {lang}
            </option>
          ))}
      </StyledSelect>
      <StyledSVG role="presentation" aria-hidden="true">
        <IconChevron />
      </StyledSVG>
    </StyledLabel>
  );
};

export default LanguageSelect;
