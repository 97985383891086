import styled from 'styled-components/macro';

const StyledSelect = styled.select`
  appearance: none;
  background-color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.color9};
  color: ${props => props.theme.color8};
  cursor: pointer;
  font-family: ${props => props.theme.ffCondensed};
  font-size: 1rem;
  padding: 0.75em;
  ${props => (props.readOnly ? 'pointer-events: none;' : '')}
`;

export default StyledSelect;
