import styled from 'styled-components/macro';

const FormGroup = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.9em;
  max-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default FormGroup;
