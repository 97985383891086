import styled from 'styled-components/macro';
import NotFoundImage from '../img/404.svg';

export default styled.div`
  background: url(${NotFoundImage}) no-repeat 50% 50%;
  background-size: contain;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.1;

  ${props =>
    props.animation &&
    `
    opacity: 0;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: ${props.animation};
  `} ${props =>
    props.blendColor &&
    `
    background-color: ${props.blendColor};
  `};
`;
