import styled from 'styled-components';

import { media } from '../../../../lib/styledMixins';

export default styled.div`
  display: none;
  max-width: 600px;
  margin: 0 auto;

  ${media.md`
    display: block;
  `}
`;
