import styled from 'styled-components/macro';

const StudioListWrapper = styled.div`
  color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  text-align: left;
`;

export default StudioListWrapper;
