import styled from 'styled-components';
import { css } from 'styled-components/macro';

import Button from '../Button/Button';

const CTAButton = styled(Button)`
  background-color: ${props =>
    props.background ? props.theme.backgroundMain : props.theme.active1};
  border: 0;
  color: ${props =>
    props.background
      ? props.theme.buttonTextSecondary
      : props.theme.backgroundMain};
  font-size: 1.125em;
  font-weight: 400;
  letter-spacing: 0.9px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `} ${props =>
    props.brand === 'johnreed' &&
    css`
      letter-spacing: 1px;
    `};

  &:hover {
    background: linear-gradient(
      to right,
      ${props =>
        props.background ? props.theme.backgroundHover : props.theme.active1},
      ${props =>
        props.background
          ? props.theme.backgroundMain
          : props.theme.active1Hover}
    );
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export default CTAButton;
