import styled, { keyframes, css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import StyledLocation from './StudioLocation';
import { StyledLink } from './SelectStudioButton';
import {
  StyledLink as DetailLink,
  StudioDetailLinkWrapper
} from './StudioDetailLink';
import { media } from '../../../../../../../../../lib/styledMixins';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const animation = css`
  ${fadeIn};
`;

const moveGradient = keyframes`
  100% {
    transform: translate(-200%, 0) rotate(-45deg);
  }
`;

const animation2 = css`
  ${moveGradient} 1s;
`;

const StudioWrapper = styled(Link)`
  align-items: center;
  animation-delay: ${props => props.i * 0.1}s;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${animation};
  color: ${props => props.theme.color3};
  cursor: pointer;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  opacity: 0;
  overflow: hidden;
  padding: 1em;
  position: relative;
  text-decoration: none;
  transform-origin: center;
  transform: scale(0);

  /* This is the background gradient */
  &::before {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 25%,
      ${props => props.theme.active1} 50%,
      rgba(255, 255, 255, 0)
    );
    content: '';
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transform: translate(0, 0) skew(-15deg);
  }
  /* This is the bottom line */
  &::after {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  ${media.md`
    flex-direction: row;
    padding: 2.25em 4.5em 2.25em 4rem;
    transition: color 0.3s;

    &:hover {
      background: linear-gradient(${props => props.theme.studioActiveLayer});
      color: ${props => props.theme.active4};

      &::before {
        animation: ${animation2};
        animation-iteration-count: 1;
      }

      ${StyledLink}, ${DetailLink}, ${StyledLocation} {
        color: ${props => props.theme.active4};
      }

      ${StudioDetailLinkWrapper} {
        max-height: 2em;
      }

      ${DetailLink} {
        border-bottom: 2px solid ${props => props.theme.active4};
        display: inline-block;
        max-height: 2em;
        transition: max-height 0.3s;
      }
    }
  `};
`;

export default StudioWrapper;
