function replaceUmlauts(string) {
  let value = string.toLowerCase();
  value = value.replace(/ä/g, 'ae');
  value = value.replace(/ö/g, 'oe');
  value = value.replace(/ü/g, 'ue');
  value = value.replace(/ß/g, 'ss');
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export default replaceUmlauts;
