import styled from 'styled-components/macro';

import { media } from '../../../../../../../../../lib/styledMixins';

const StudioLocation = styled.div`
  align-items: center;
  color: ${props => props.theme.secondary};
  display: flex;
  flex-basis: 4em;
  font-weight: 500;
  justify-content: center;
  line-height: 58px;

  ${media.md`
    flex-basis: 25%;
    text-align: center;
  `};
`;

export default StudioLocation;
