import styled from 'styled-components/macro';

import CardSpinner from '../../CardSpinner/CardSpinner';

const InlineSpinner = styled(CardSpinner)`
  height: 30px;
  margin: 0;
  position: absolute;
  right: 1em;
  top: 26px;
  transform: translateY(-50%);
  width: 30px;
`;

export default InlineSpinner;
