import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';

import { fetchDecryption, sendCyberobicsUserData } from '../../../data/tsp';

import { schemaCleeng } from './LandingpageTSPSchema';
import Headline from './components/Headline';
import Subtext from './components/Subtext';
import Wrapper from './components/Wrapper';
import FormWrapper from './components/FormWrapper';
import FieldWrapper from './components/FieldWrapper';
import InputField from './components/InputField';
import ErrorMessage from './components/ErrorMessage';
import InputFieldWrapper from '../../../components/InputFieldWrapper/InputFieldWrapper';
import { IconPassword } from '../../../components/Icons';
import SVGIcon from './components/SVGIcon';
import CTAButton from '../../../components/CTAButton/CTAButton';

function Landingpage({ match, intl, ...props }) {
  const { handleDecryption, handleUserData, email, success } = props;
  const {
    params: { hash, type }
  } = match;
  const service = type?.toLowerCase();
  const schema = schemaCleeng;

  useEffect(() => {
    handleDecryption(hash);
  }, [hash, handleDecryption]);

  return (
    <>
      {type === 'loox' ? (
        <Wrapper>
          <Subtext>
            Vielen Dank für dein Interesse an LOOX. <br />
            <br />
            Leider müssen wir dir mitteilen, dass die LOOX App nicht mehr
            verfügbar ist.
            <br />
            <br /> Aber keine Sorge, stattdessen empfehlen wir dir unsere
            Partner-App Hero Workout, um dir zukünftig ein noch besseres
            Trainingserlebnis bieten zu können.
            <br />
            <br />
            <b>Hero Workout App - Die besten Workouts deines Lebens</b>
            <br /> Ob Anfänger, Fortgeschrittener oder Profi: Hero Workout hat
            für jedes Ziel das richtige Training. Einfach Workout aussuchen und
            loslegen. Dein Coach ist während des gesamten Trainings bei dir,
            gibt dir Tipps und motiviert dich zur richtigen Zeit!
            <br />
            <br /> Wenn du im Rahmen deines Vertrages/der Trainings- und
            Servicepauschale einen Anspruch auf die kostenlose Nutzung von Hero
            Workout und CYBEROBICS hast, erhältst du eine E-Mail mit allen
            weiteren Informationen von deinem Studio. Über den in der Email
            enthaltenen Link kannst du deinen Zugang zu Hero Workout kostenlos
            aktivieren.
            <br />
            <br /> Unabhängig davon steht dir die Hero Workout App natürlich
            kostenlos in deinem AppStore zur Verfügung.
          </Subtext>
        </Wrapper>
      ) : email && success ? (
        <Redirect from={match.url} to={`/tsp/${type}/success`} />
      ) : (
        <Wrapper>
          <Headline>
            {' '}
            {service === 'cyberobics' && (
              <FormattedMessage id="tsp.headline.cyberobics" />
            )}
            {service === 'loox' && <FormattedMessage id="tsp.headline.loox" />}
          </Headline>
          <Subtext>
            {service === 'cyberobics' && (
              <FormattedMessage
                id="tsp.subheadline.cyberobics"
                values={{ email }}
              />
            )}
            {service === 'loox' && (
              <FormattedMessage id="tsp.subheadline.loox" values={{ email }} />
            )}
          </Subtext>
          <Formik
            validationSchema={schema}
            initialValues={{
              hash,
              pw: '',
              pwagain: '',
              service
            }}
            onSubmit={({ hash, pw }) => {
              handleUserData(
                {
                  hash,
                  pw
                },
                service
              );
            }}
            render={({
              handleBlur,
              handleSubmit,
              values,
              errors,
              handleChange
            }) => (
              <FormWrapper onSubmit={handleSubmit}>
                <FieldWrapper>
                  <InputFieldWrapper>
                    <SVGIcon value={values.pw}>
                      <IconPassword />
                    </SVGIcon>
                    <InputField
                      name="pw"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pw}
                      placeholder={intl.formatMessage({
                        id: 'tsp.passwordPlaceholder'
                      })}
                    />
                  </InputFieldWrapper>
                  {errors.pw && (
                    <ErrorMessage>
                      <FormattedMessage id="messages.tsp.password" />
                    </ErrorMessage>
                  )}
                  <InputFieldWrapper>
                    <SVGIcon value={values.pwagain}>
                      <IconPassword />
                    </SVGIcon>
                    <InputField
                      name="pwagain"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pwagain}
                      placeholder={intl.formatMessage({
                        id: 'tsp.passwordPlaceholderAgain'
                      })}
                    />
                  </InputFieldWrapper>
                  {errors.pwagain && (
                    <ErrorMessage>
                      <FormattedMessage id={errors.pwagain} />
                    </ErrorMessage>
                  )}
                </FieldWrapper>
                {service === 'cyberobics' ? (
                  <CTAButton
                    type="submit"
                    disabled={!errors.pwagain && values.pwagain ? false : true}
                  >
                    <FormattedMessage id="tsp.cta" />
                  </CTAButton>
                ) : (
                  <CTAButton
                    type="submit"
                    disabled={!errors.pwagain && values.pwagain ? false : true}
                  >
                    <FormattedMessage id="tsp.cta" />
                  </CTAButton>
                )}
              </FormWrapper>
            )}
          />
        </Wrapper>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  success: state.data.tsp.success,
  email: state.data.tsp.data.Email
});

const mapDispatchToProps = dispatch => ({
  handleDecryption: hash => {
    dispatch(fetchDecryption(hash));
  },
  handleUserData: data => {
    dispatch(sendCyberobicsUserData(data));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Landingpage))
);
