import styled from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

export default styled.div`
  padding: 1em;

  /* styles for campaign data sheet */
  ${props => props.campaign && 'margin: 0 auto;'}
  ${props => props.campaign && media.md`order: 0;`};

  ${media.md`
    flex: 0 1 739px;
  `};

  ${media.lg`
    padding: 3.4em 4em;
  `};
`;
