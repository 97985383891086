import styled from 'styled-components';
import { StyledLink } from '../../HeaderIcon';

const StyledLabel = styled(StyledLink)`
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateY(5px);
`;

export default StyledLabel;
