import React from 'react';
import styled, { css } from 'styled-components/macro';

export const canvasClass = 'signature-pad-canvas';
export const canvasClassSaved = 'signature-pad-canvas--saved';
export const canvasClassError = 'signature-pad-canvas--error';
export const canvasClassSaving = 'signature-pad-canvas--saving';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  transition: border 0.3s;
  width: 100%;

  .${canvasClass} {
    background-color: ${props => props.theme.white};
    border: 2px solid ${props => props.theme.color9};
    color: ${props => props.theme.color2};
  }

  .${canvasClassSaved} {
    background-color: ${props => props.theme.white};
    border: 2px solid ${props => props.theme.success};
    color: ${props => props.theme.color2};
  }

  .${canvasClassError} {
    background-color: ${props => props.theme.white};
    border: 2px solid ${props => props.theme.alert};
    color: ${props => props.theme.color2};
  }

  .${canvasClassSaving} {
    background-color: ${props => props.theme.white};
    border: 0;
    color: ${props => props.theme.color2};
  }

  ${props =>
    props.error === 'true' &&
    css`
      background-color: ${props.theme.white};
      border: 0;
      color: ${props.theme.color2};
    `};
`;

export default class SignaturePadWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.handleSizing();

    window.addEventListener('orientationchange', this.handleSizing);
  }

  handleSizing = () => {
    const { getWidth } = this.props;

    getWidth(0);

    setTimeout(() => getWidth(this.wrapper.current.offsetWidth), 500);
  };

  render() {
    const { children } = this.props;

    return <Wrapper ref={this.wrapper}>{children}</Wrapper>;
  }
}
