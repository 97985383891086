import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const HeaderWrapper = styled.header`
  align-content: center;
  align-items: center;
  background-color: ${props => props.theme.footerBg};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: ${props => props.theme.ffCondensed};
  grid-area: header;
  padding: 1em;

  ${media.md`
    padding: 0 4em;
  `};
`;

export default HeaderWrapper;
