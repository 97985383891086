import styled, { css } from 'styled-components/macro';

const Label = styled.label`
  cursor: pointer;

  ${props =>
    props.hide &&
    css`
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
    `};

  ${props =>
    props.type === 'select' &&
    css`
      margin-bottom: 1em;
    `};

  ${props =>
    props.type === 'radio' &&
    css`
      font-family: ${props.theme.ffCondensed};
      text-transform: uppercase;
    `};

  ${props =>
    props.type === 'checkbox' &&
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    `};
`;

export default Label;
