import uuid from 'uuid/v4';

import config from '../../config';
import { displayMessage, displayAutoCloseMessage } from '../messages';

const { api } = config;

export const SEND_REGISTRATION_REQUEST =
  'data/testtraining/SEND_REGISTRATION_REQUEST';
export const SEND_REGISTRATION_SUCCESS =
  'data/testtraining/SEND_REGISTRATION_SUCCESS';
export const SEND_REGISTRATION_FAILURE =
  'data/testtraining/SEND_REGISTRATION_FAILURE';
export const FETCH_STUDIO_REQUEST = 'data/testtraining/FETCH_STUDIO_REQUEST';
export const FETCH_STUDIO_SUCCESS = 'data/testtraining/FETCH_STUDIO_SUCCESS';
export const FETCH_STUDIO_ERROR = 'data/testtraining/FETCH_STUDIO_ERROR';
export const SELECT_HOMEBASE = 'data/testtraining/SELECT_HOMEBASE';

function sendTesttrainingRequest() {
  return {
    type: SEND_REGISTRATION_REQUEST
  };
}

function sendTesttrainingFailure() {
  return {
    type: SEND_REGISTRATION_FAILURE
  };
}

function sendTesttrainingSuccess(promos) {
  return {
    type: SEND_REGISTRATION_SUCCESS,
    payload: promos
  };
}

export function registerTesttraining(data) {
  return async dispatch => {
    function onError(response) {
      dispatch(sendTesttrainingFailure());
      dispatch(
        displayMessage(
          {
            text: 'messages.sendRegistrationError',
            type: 'alert'
          },
          uuid()
        )
      );
      return response;
    }

    function onSuccess(response) {
      if (response.success !== true) {
        return onError(response);
      }

      dispatch(sendTesttrainingSuccess(response));
      return response;
    }

    dispatch(sendTesttrainingRequest());

    try {
      const response = await (
        await fetch(`${api}/testtraining`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
      ).json();

      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

function fetchStudioRequest() {
  return {
    type: FETCH_STUDIO_REQUEST
  };
}

function fetchStudioSuccess(studio) {
  return {
    type: FETCH_STUDIO_SUCCESS,
    payload: { studio }
  };
}

export function fetchStudio(studioId) {
  return async (dispatch, getState) => {
    function onSuccess(studio) {
      dispatch(fetchStudioSuccess(studio));
      return studio;
    }

    function onError(error) {
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.studioFetchError',
          type: 'alert',
          description: error.toString()
        })
      );
      return error;
    }

    dispatch(fetchStudioRequest());

    try {
      const { brandId } = getState().data.theme.variables;
      const studio = await (
        await fetch(`${api}/studio/${studioId}/${brandId}`)
      ).json();

      return onSuccess(studio);
    } catch (error) {
      return onError(error);
    }
  };
}

export function selectHomebase(id, countryCode) {
  return {
    type: SELECT_HOMEBASE,
    payload: {
      id,
      countryCode
    }
  };
}

// REDUCER
const reducer = (
  state = {
    sending: false,
    success: false,
    error: false,
    selectedStudio: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_STUDIO_SUCCESS:
      return {
        ...state,
        fetching: false,
        selectedStudio: action.payload.studio
      };
    case SELECT_HOMEBASE:
      return {
        ...state,
        success: false,
        selectedStudio: {
          StudioMark: action.payload.id,
          CountryCode: action.payload.countryCode
        }
      };
    case SEND_REGISTRATION_REQUEST:
      return {
        ...state,
        sending: true
      };
    case SEND_REGISTRATION_SUCCESS:
      return {
        ...state,
        sending: false,
        success: true
      };
    case SEND_REGISTRATION_FAILURE:
      return {
        ...state,
        sending: false,
        error: true
      };
    default:
      return state;
  }
};

export default reducer;
