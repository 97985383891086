import styled, { keyframes, css } from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

export const spin = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const animation = css`
  ${spin} 1.8s infinite ease-in-out;
`;

const Spinner = styled.div`
  animation: ${animation};
  animation-delay: -0.16s;
  animation-fill-mode: both;
  border-radius: 50%;
  color: ${props => props.theme.color3};
  height: 0.5em;
  position: absolute;
  right: 1em;
  top: -1.25em;
  width: 0.5em;

  &::after,
  &::before {
    animation: ${spin} 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    content: '';
    height: 0.5em;
    position: absolute;
    top: 0;
    width: 0.5em;
  }

  &::before {
    animation-delay: -0.32s;
    left: -0.5em;
  }

  &::after {
    left: 0.5em;
  }

  ${media.md`
    height: 1em;
    right: 1.5em;
    top: -0.75em;
    width: 1em;

    &::after,
    &::before {
      height: 1em;
      width: 1em;
    }

    &::before {
      left: -1.25em;
    }

    &::after {
      left: 1.25em;
    }
  `};
`;

export default Spinner;
