import styled from 'styled-components';
import { StyledMessage } from '../../HeaderIcon';

const LanguageSelectSelect = styled(StyledMessage)`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-size: 1em;
  margin: 0 0 0 -0.25em;
  padding: 0 0.5em 0.5em;
  position: relative;
  text-transform: uppercase;

  &::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
  }

  &:focus {
    outline: none;
  }

  /** fixes IE11 */
  option {
    color: #000;
  }
`;

export default LanguageSelectSelect;
