import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import config, { configStage } from '../../../config';
import { checkLogin, resend } from '../../../data/tsp';
import schema from './TSPResendSchema';
import Wrapper from './components/Wrapper';
import Headline from './components/Headline';
import FormWrapper from './components/FormWrapper';
import FieldWrapper from './components/FieldWrapper';
import InputField from '../../../components/InputGroup/InputGroup';
import InputFieldWrapper from './components/InputFieldWrapper';
import CTAButton from '../../../components/CTAButton/CTAButton';
import LinksWrapper from './components/LinkWrapper';

function Resend({ match }) {
  const dispatch = useDispatch();
  const { login, brand, links } = useSelector(state => ({
    login: state.data.tsp.login,
    brand: state.data.theme.themeName,
    links: state.data.tsp.links
  }));

  useEffect(() => {
    dispatch(checkLogin());
  }, [dispatch]);

  const contractTags = window.location.host.match(/stage/)
    ? configStage.contractTags
    : config.contractTags;

  return login ? (
    <>
      <Wrapper>
        <Headline>TSP Resend Form</Headline>
        <Formik
          validationSchema={schema}
          onSubmit={({
            birthday,
            countryCode,
            email,
            firstName,
            lastName,
            gender,
            brand,
            tags
          }) => {
            const resendData = {
              FirstName: firstName,
              LastName: lastName,
              Birthday: birthday,
              Email: email,
              CountryCode: countryCode,
              Gender: gender,
              brand: tags === 'jj' ? 'jj' : brand,
              tags: [
                {
                  identifier: tags
                }
              ]
            };
            dispatch(resend(resendData));
          }}
          initialValues={{
            brand
          }}
          render={({
            handleBlur,
            handleSubmit,
            values,
            errors,
            handleChange,
            touched
          }) => (
            <FormWrapper onSubmit={handleSubmit}>
              <FieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="gender"
                    type="radio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    options={[
                      { name: 'Männlich', value: 'M' },
                      { name: 'Weiblich', value: 'W' }
                    ]}
                    error={errors.gender}
                    touched={touched.gender}
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    placeholder="Vorname"
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    placeholder="Nachname"
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="birthday"
                    type="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.birthday}
                    placeholder="Geburtsdatum"
                    error={errors.birthday}
                    touched={touched.birthday}
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="email"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email"
                    error={errors.email}
                    touched={touched.email}
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <InputField
                    name="countryCode"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.countryCode}
                    placeholder="Länderkürzel"
                    error={errors.countryCode}
                    touched={touched.countryCode}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <InputField
                    error={errors.tags}
                    label="Mail"
                    name="tags"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Object.entries(contractTags[brand]).map(tag => ({
                      name: tag[0],
                      value: tag[1]
                    }))}
                    required
                    touched={touched.tags}
                    type="select"
                    value={values.tags}
                  />
                </InputFieldWrapper>
              </FieldWrapper>
              <CTAButton type="submit">Absenden</CTAButton>
            </FormWrapper>
          )}
        />
        {links && (
          <LinksWrapper>
            Cyberobics:
            <p>{links.cyberobicsLink}</p>
            HeroApp:
            <p>{links.heroLink}</p>
          </LinksWrapper>
        )}
      </Wrapper>
    </>
  ) : (
    <Redirect from={match.url} to="/tsp/login" />
  );
}

export default withRouter(Resend);
