import styled, { css } from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const ContractWrapper = styled.div`
  margin: 0 auto 2em;
  max-width: 370px;
  overflow: hidden;
  width: 100%;

  ${props =>
    props.loaded &&
    props.contractsLength > 3 &&
    css`
      height: auto;
    `};

  ${({ isMobileHidden, locale }) =>
    isMobileHidden &&
    locale === 'de' &&
    css`
      display: none;
    `}

  ${media.md`
    display: block;
    margin-bottom: 0;
    flex-grow: 1;
    flex-basis: 50%;
    padding: 1rem;

    ${props =>
      props.sidebar &&
      css`
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-bottom: 2em;
      `}

  `};

  ${media.lg`
    flex-basis: 33%;
  `} ${props =>
    props.sidebar &&
    css`
      max-width: none;
      padding: 0;
      order: 1;

      ${media.md`
          order: 0;
        `};
    `};

  ${props =>
    props.contractForm &&
    css`
      display: block;

      ${media.md`display: none;`};
    `};
`;

export default ContractWrapper;
