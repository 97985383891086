export const REQUEST_USERLOCATION = 'REQUEST_USERLOCATION';
export const REQUEST_USERLOCATION_SUCCESS = 'REQUEST_USERLOCATION_SUCCESS';
export const REQUEST_USERLOCATION_FAILURE = 'REQUEST_USERLOCATION_FAILURE';

function requestUserLocation() {
  return {
    type: REQUEST_USERLOCATION
  };
}

function requestUserLocationSuccess(data) {
  return {
    type: REQUEST_USERLOCATION_SUCCESS,
    payload: data
  };
}

function requestUserLocationFail() {
  return {
    type: REQUEST_USERLOCATION_FAILURE
  };
}

export function getUserLocation() {
  return async dispatch => {
    function onSuccess(country) {
      dispatch(requestUserLocationSuccess(country));
    }

    function onError() {
      dispatch(requestUserLocationFail());
    }

    dispatch(requestUserLocation());

    try {
      const { country } = await (await fetch('https://ipapi.co/json')).json();

      return onSuccess(country);
    } catch (err) {
      return onError(err);
    }
  };
}

const reducer = (
  state = {
    sending: false,
    success: false,
    error: false,
    country: false
  },
  action
) => {
  switch (action.type) {
    case REQUEST_USERLOCATION:
      return {
        ...state,
        sending: true
      };
    case REQUEST_USERLOCATION_SUCCESS:
      return {
        ...state,
        sending: false,
        success: true,
        country: action.payload
      };
    case REQUEST_USERLOCATION_FAILURE:
      return {
        ...state,
        sending: false,
        error: true
      };
    default:
      return state;
  }
};

export default reducer;
