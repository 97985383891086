import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SliderIcon from './components/SliderIcon';
import SliderButton from './components/SliderButton';
import DotList from './components/DotList';
import Dot from './components/Dot';

const Wrapper = styled.div`
  ${props =>
    props.brand === 'mcfit' &&
    css`
      .slider-control-centerleft,
      .slider-control-centerright {
        top: 35% !important;
      }

      .slider-control-centerleft {
        left: -10px !important;
      }

      .slider-control-centerright {
        right: -10px !important;
      }
    `}
`;

export default class NukaWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    Nuka: null,
    slideIndex: 0,
    toShow: 3
  };

  mqlWidth = window.matchMedia('(min-width: 1060px)');

  componentDidMount() {
    import('nuka-carousel').then(Nuka => {
      this.setState({ Nuka: Nuka.default });
    });

    // Calculate initial values
    this.handleWidthChange(this.mqlWidth);
    // Attach change listeners
    this.mqlWidth.addListener(this.handleWidthChange);
  }

  handleWidthChange = event => {
    this.setState({
      toShow: event.matches ? 3 : 2
    });
  };

  setSlide = slideIndex => {
    this.setState({ slideIndex });
  };

  render() {
    const { children, brand } = this.props;
    const { Nuka, slideIndex, toShow } = this.state;

    return Nuka ? (
      <Wrapper brand={brand}>
        <Nuka
          slidesToShow={toShow}
          slidesToScroll={1}
          framePadding={'20px'}
          slideIndex={slideIndex}
          heightMode={'max'}
          renderCenterLeftControls={({ previousSlide }) => (
            <SliderButton onClick={previousSlide}>
              <SliderIcon />
            </SliderButton>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <SliderButton onClick={nextSlide}>
              <SliderIcon right />
            </SliderButton>
          )}
          renderBottomCenterControls={({ currentSlide }) => (
            <DotList>
              {children.map((item, index) => (
                <Dot
                  key={index}
                  onClick={() => this.setSlide(index)}
                  active={currentSlide === index}
                >
                  •
                </Dot>
              ))}
            </DotList>
          )}
        >
          {children}
        </Nuka>
      </Wrapper>
    ) : null;
  }
}
