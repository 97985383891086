import { object, number, string, boolean, mixed } from 'yup';
import subYears from 'date-fns/subYears';
import getYear from 'date-fns/getYear';
import differenceInYears from 'date-fns/differenceInYears';

export const schemaML = object()
  .shape({
    day: number()
      .min(1)
      .max(31)
      .required('validate.required'),
    month: number()
      .min(1)
      .max(12)
      .required('validate.required'),
    year: number()
      .max(getYear(subYears(Date.now(), 18)))
      .min(getYear(subYears(Date.now(), 100)))
      .required('validate.required'),
    StudioMark: string().required(),
    FirstName: string().required('validate.required'),
    LastName: string().required('validate.required'),
    BIC: string(),
    City: string().required('validate.required'),
    CountryCode: string().required(),
    Email: string()
      .email('validate.email')
      .required('validate.required'),
    Gender: string().required('validate.required'),
    HouseNumber: string().required('validate.required'),
    IBAN: string().when('payment', {
      is: 1,
      then: string().required('validate.required'),
      otherwise: string().nullable()
    }),
    creditCard: object().shape({
      tokenizationReference: string().when('payment', {
        is: 0,
        then: string().required('validate.required'),
        otherwise: string().nullable()
      })
    }),
    PostalCode: string()
      .matches(
        /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gim,
        'yup.cf.postalCodeMismatch'
      )
      .required('validate.required'),
    Marketing: boolean(),
    ProudClub: boolean(),
    ProudClubNewsletter: boolean(),
    Street: string().required('validate.required'),
    payment: number(),
    Phone: string(),
    ReferrerType: mixed(),
    hasTSP: boolean(),
    hasGold: boolean(),
    hasPremium: boolean(),
    acceptedTSP: boolean().when('hasTSP', {
      is: true,
      then: boolean().required(),
      otherwise: boolean()
    }),
    brand: string().required(),
    olderThan18: boolean().test('min-age-18', 'validate.birthday', function(
      val
    ) {
      const { day, month, year } = this.parent;
      if (!day || !month || !year) {
        return true;
      }
      const monthIndex = month - 1;

      return (
        differenceInYears(new Date(), new Date(year, monthIndex, day)) >= 18
      );
    }),
    referralCode: string()
  })
  .required();

export const flexSchema = object()
  .shape({
    day: number()
      .min(1)
      .max(31)
      .required('validate.required'),
    month: number()
      .min(1)
      .max(12)
      .required('validate.required'),
    year: number()
      .max(getYear(subYears(Date.now(), 18)))
      .min(getYear(subYears(Date.now(), 100)))
      .required('validate.required'),
    StudioMark: string().required(),
    FirstName: string().required('validate.required'),
    LastName: string().required('validate.required'),
    BIC: string(),
    City: string().required('validate.required'),
    CountryCode: string().required(),
    Email: string()
      .email('validate.email')
      .required('validate.required'),
    Gender: string().required('validate.required'),
    HouseNumber: string().required('validate.required'),
    IBAN: string().when('payment', {
      is: 1,
      then: string().required('validate.required'),
      otherwise: string().nullable()
    }),
    creditCard: object().shape({
      tokenizationReference: string().when('payment', {
        is: 0,
        then: string().required('validate.required'),
        otherwise: string().nullable()
      })
    }),
    PostalCode: string()
      .matches(
        /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gim,
        'yup.cf.postalCodeMismatch'
      )
      .required('validate.required'),
    Marketing: boolean(),
    ProudClub: boolean(),
    ProudClubNewsletter: boolean(),
    Street: string().required('validate.required'),
    Phone: string(),
    payment: number(),
    hasTSP: boolean(),
    hasGold: boolean(),
    hasPremium: boolean(),
    acceptedTSP: boolean().when('hasTSP', {
      is: true,
      then: boolean().required(),
      otherwise: boolean()
    }),
    brand: string().required(),
    olderThan18: boolean().test('min-age-18', 'validate.birthday', function(
      val
    ) {
      const { day, month, year } = this.parent;
      if (!day || !month || !year) {
        return true;
      }
      const monthIndex = month - 1;

      return (
        differenceInYears(new Date(), new Date(year, monthIndex, day)) >= 18
      );
    }),
    referralCode: string()
  })
  .required();
