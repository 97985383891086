import React from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StyledLink, NoLink } from './CrumbLink';

const ListItem = styled.li`
  display: inline-block;
  width: 100%;
`;

export const SingleCrumb = props => {
  return (
    <ListItem>
      {props.step <= props.currentstep && !props.final && props.match ? (
        <StyledLink
          as="div"
          step={props.step}
          currentstep={props.currentstep}
          to=""
        >
          {props.homebase && props.homebase.StudioName && props.step === 1 ? (
            props.homebase.StudioName
          ) : (
            <FormattedMessage
              defaultMessage="Homebase"
              id={`${props.name}.${props.brand}`}
            />
          )}
        </StyledLink>
      ) : (
        <NoLink as="div" {...props} to="">
          <FormattedMessage
            defaultMessage="Homebase"
            id={`${props.name}.${props.brand}`}
          />
        </NoLink>
      )}
    </ListItem>
  );
};

SingleCrumb.propTypes = {
  targetlink: PropTypes.string.isRequired,
  currentstep: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  final: PropTypes.bool.isRequired,
  brand: PropTypes.string.isRequired,
  // eslint-disable-next-line
  intl: intlShape.isRequired,
  homebase: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired
};

const mapStateToProps = state => ({
  homebase: state.data.checkout.selectedStudio,
  brand: state.data.theme.themeName
});

export default injectIntl(connect(mapStateToProps)(SingleCrumb));
