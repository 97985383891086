import React from 'react';
import PropTypes from 'prop-types';

import RadioInput from './components/RadioInput/RadioInput';
import CheckboxInput from './components/CheckboxInput/CheckboxInput';
import SelectInput from './components/SelectInput';
import GenericInput from './components/GenericInput';

const InputGroup = props => {
  switch (props.type) {
    case 'radio':
      return <RadioInput {...props} />;
    case 'select':
      return <SelectInput {...props} />;
    case 'checkbox':
      return <CheckboxInput {...props} />;
    default:
      return <GenericInput {...props} />;
  }
};

InputGroup.propTypes = {
  type: PropTypes.string.isRequired
};

export default InputGroup;
