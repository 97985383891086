import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

const CrumbLine = styled.span`
  background-color: ${props => props.theme.active1};
  height: 4px;
  left: ${props =>
    props.noContracts ? 'calc(100% / 3 / 2)' : 'calc(100% / 4 / 2)'};
  position: absolute;
  right: ${props =>
    props.noContracts ? 'calc(100% / 3 / 2)' : 'calc(100% / 4 / 2)'};
  top: 0;
  width: ${props => (props.noContracts ? '66%' : '75%')};
  transform: scaleX(0);
  transform-origin: 0 0;
  z-index: 99;

  ${props =>
    props.step &&
    css`
      transform: scaleX(${0.33 * (props.step - 1)});
    `};
`;

CrumbLine.propTypes = {
  step: PropTypes.number.isRequired
};

export default CrumbLine;
