import uuid from 'uuid/v4';

import { displayMessage } from '../messages';
import config from '../../config';

const { api } = config;

export const FETCH_CONTRACTS_REQUEST = 'data/contracts/FETCH_CONTRACTS_REQUEST';
export const FETCH_CONTRACTS_FAILURE = 'data/contracts/FETCH_CONTRACTS_FAILURE';
export const FETCH_CONTRACTS_SUCCESS = 'data/contracts/FETCH_CONTRACTS_SUCCESS';
export const SELECT_CONTRACT = 'data/contracts/SELECT_CONTRACT';

function fetchContractsRequest() {
  return {
    type: FETCH_CONTRACTS_REQUEST
  };
}

function fetchContractsSuccess(contracts) {
  return {
    type: FETCH_CONTRACTS_SUCCESS,
    payload: contracts
  };
}

export function fetchContracts(studio) {
  return async dispatch => {
    function onSuccess(contracts) {
      dispatch(fetchContractsSuccess(contracts));
      return contracts;
    }

    function onError(error) {
      dispatch(
        displayMessage(
          {
            text: 'Error fetching contracts.',
            type: 'alert',
            description: error.toString()
          },
          uuid()
        )
      );
      return error;
    }

    const { StudioMark, CountryCode } = studio;

    if (!StudioMark.match(/[0-9]*/)) {
      return onError();
    }

    dispatch(fetchContractsRequest());
    try {
      const contracts = await (
        await fetch(`${api}/contracts/${StudioMark}/${CountryCode}`)
      ).json();

      return onSuccess(contracts.data);
    } catch (error) {
      return onError(error);
    }
  };
}

export function selectContract(id) {
  return {
    type: SELECT_CONTRACT,
    payload: id
  };
}

export default function reducer(
  state = {
    contracts: [],
    fetching: false,
    selectedContract: false
  },
  action
) {
  switch (action.type) {
    case FETCH_CONTRACTS_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case FETCH_CONTRACTS_FAILURE:
      return {
        ...state,
        fetching: false,
        contracts: []
      };
    case FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        contracts: action.payload
      };
    case SELECT_CONTRACT:
      return {
        ...state,
        selectedContract: state.contracts.find(
          contract => contract.Id === parseInt(action.payload, 10)
        )
      };
    default:
      return state;
  }
}
