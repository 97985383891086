import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const MessageListWrapper = styled.ul`
  bottom: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 9999;
  transform: ${props => (props.show ? 'translateY(-0%)' : 'translateY(100%)')};
  transition: ${props =>
    props.show ? 'transform 0.5s cubic-bezier(0.07, 0.43, 0.54, 1)' : 'none'};
  transition: ${props =>
    props.show
      ? 'transform 0.5s cubic-bezier(0.07, 0.43, 0.54, 1.22)'
      : 'none'};

  ${media.md`
    bottom: 40px;
    left: 50%;
    transform: ${props =>
      props.show ? 'translate(-50%, -0%)' : 'translate(-50%, 100%)'};
    width: calc(100% - 40px);
  `};
`;

export default MessageListWrapper;
