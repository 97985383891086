import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import LanguageSelect from './components/LanguageSelect/LanguageSelect';
import Logo from './components/Logo';
import HeaderWrapper from './components/HeaderWrapper';
import { clearSearch, resetInput } from '../../data/checkout';

import logoMcfit from '../../themes/mcfit/images/mcfit.png';
import logoJR from '../../themes/johnreed/images/johnreed.jpg';
import logoHigh5 from '../../themes/high5/images/high5.jpg';

function brandSelector(brand) {
  switch (brand) {
    case 'high5':
      return {
        logo: logoHigh5,
        url: 'https://www.high5.com'
      };
    case 'johnreed':
      return {
        logo: logoJR,
        url: 'https://www.johnreed.fitness'
      };
    case 'mcfit':
      return {
        logo: logoMcfit,
        url: 'https://www.mcfit.com'
      };
    default:
      return {
        logo: logoMcfit,
        url: 'https://www.mcfit.com'
      };
  }
}

export const Header = ({ brand, handleClick }) => (
  <HeaderWrapper>
    <Switch>
      <Route path="/:path(checkout|campaign|upselling|tsp)">
        <Logo
          src={brandSelector(brand).logo}
          alt={`${brand} Logo`}
          to={brandSelector(brand).url}
          onClick={handleClick}
        />
      </Route>
    </Switch>
    {brand === 'mcfit' ? <LanguageSelect /> : null}
  </HeaderWrapper>
);

Header.propTypes = {
  brand: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    dispatch(resetInput());
    dispatch(clearSearch());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
