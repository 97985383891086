import styled from 'styled-components/macro';

import { media } from '../../../../../../../lib/styledMixins';

const LocationInputWrapper = styled.div`
  display: block;
  margin: ${props => (props.locations.length ? '1em auto 0' : '3em auto 0')};
  max-width: 35em;
  position: relative;
  transition: margin 0.3s cubic-bezier(0.69, 0.12, 0.48, 0.83);

  ${media.md`
    margin: ${props => (props.locations.length ? '1em auto 0' : '6em auto')};
    max-width: ${props => (props.locations.length ? '100%' : '35em')};
    transition: max-width 0.3s cubic-bezier(0.69, 0.12, 0.48, 0.83),
      margin 0.3s cubic-bezier(0.69, 0.12, 0.48, 0.83);
  `};
`;

export default LocationInputWrapper;
