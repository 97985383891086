import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { FormattedMessage } from 'react-intl';

import RotateDevice from './components/RotateDevice';
import Wrapper, {
  canvasClass,
  canvasClassSaved,
  canvasClassError
} from './components/SignaturePadWrapper';
import SaveButton from './components/SaveButton';

class SignaturePad extends Component {
  static propTypes = {
    saveSignature: PropTypes.func.isRequired,
    error: PropTypes.string,
    isFr: PropTypes.bool
  };

  static defaultProps = {
    error: ''
  };

  state = {
    minWidth: false,
    dirty: false,
    saved: false,
    signature: '',
    width: null
  };

  mqlOrientation = window.matchMedia('(orientation: landscape)');

  mqlWidth = window.matchMedia('(min-width: 568px)');

  componentDidMount() {
    // Calculate initial values
    this.handleWidthChange(this.mqlWidth);
    // Attach change listeners
    this.mqlWidth.addListener(this.handleWidthChange);
  }

  componentWillUnmount() {
    this.mqlWidth.removeListener(this.handleWidthChange);
  }

  getClassname = () => {
    const { error } = this.props;
    const { saved } = this.state;

    if (error) {
      return canvasClassError;
    }

    if (saved) {
      return canvasClassSaved;
    }

    return canvasClass;
  };

  handleWidthChange = event => {
    this.setState(
      {
        minWidth: event.matches
      },
      () => {
        const { signature } = this.state;

        if (signature.length && this.sigCanvas) {
          this.sigCanvas.fromDataURL(signature);
          this.sigCanvas.off();
        }
      }
    );
  };

  handleFirstDraw = () => {
    this.setState({ dirty: true });
  };

  handleClear = () => {
    this.setState({ dirty: false, saved: false, signature: '' }, () => {
      if (this.sigCanvas) {
        this.sigCanvas.on();
        this.sigCanvas.clear();
      }
    });
  };

  handleSaveSignature = () => {
    const { saved } = this.state;
    const { saveSignature } = this.props;

    if (saved) {
      return this.handleClear();
    }
    const signature = this.sigCanvas.toDataURL('image/svg+xml');

    return this.setState({ saved: true, signature }, () => {
      saveSignature(signature);

      if (this.sigCanvas) {
        this.sigCanvas.off();
      }
    });
  };

  handleWidth = width => {
    this.setState({
      width
    });
  };

  render() {
    const { width, minWidth, dirty, saved } = this.state;
    const { error, isFr } = this.props;

    return minWidth ? (
      <Wrapper error={error && error.length > 0} getWidth={this.handleWidth}>
        {width && (
          <SignatureCanvas
            backgroundColor="rgba(255, 255, 255, 1)"
            canvasProps={{
              width: width,
              height: 300,
              className: this.getClassname()
            }}
            onEnd={this.handleFirstDraw}
            ref={ref => {
              this.sigCanvas = ref;
            }}
          />
        )}
        <SaveButton
          disabled={!dirty}
          onClick={this.handleSaveSignature}
          type="button"
        >
          {saved ? (
            isFr ? (
              'Vider'
            ) : (
              <FormattedMessage id="signature.clear" />
            )
          ) : isFr ? (
            'Confirmation avec une signature'
          ) : (
            <FormattedMessage id="signature.save" />
          )}
        </SaveButton>
      </Wrapper>
    ) : (
      <RotateDevice />
    );
  }
}

export default SignaturePad;
