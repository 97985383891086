import styled from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.ffRegular};

  ${media.lg`
    align-items: flex-start;
    flex-direction: row;
  `};
`;

export default DataWrapper;
