import styled from 'styled-components/macro';

const AppContainer = styled.div`
  background-color: ${props => props.theme.mainBg};
  color: ${props => props.theme.color3};
  font-family: ${props => props.theme.ffRegular};
  height: 100%;
  min-height: 100vh;

  img {
    height: auto;
    max-width: 100%;
  }
`;

export default AppContainer;
