import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
`;

const skBounce = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`;

const animation = css`
  ${skBounce} 2s infinite ease-in-out;
`;

const BounceOne = styled.div`
  background-color: ${props => props.theme.active1};
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;

  animation: ${animation};
`;

const BounceTwo = styled(BounceOne)`
  animation-delay: -1s;
`;

const CardSpinner = props => (
  <Wrapper {...props}>
    <BounceOne />
    <BounceTwo />
  </Wrapper>
);

export default CardSpinner;
