import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../../components/Modal/Modal';
import ContactForm from './components/ContactForm/ContactForm';
import Spinner from '../../components/CardSpinner/CardSpinner';
import { toggleContactForm } from '../../data/contact';

export const Contact = ({ open, sending, handleClose }) => (
  <Modal isOpen={open} customTitle title="Kontakt" close={handleClose}>
    {sending ? <Spinner /> : <ContactForm />}
  </Modal>
);

Contact.propTypes = {
  open: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  open: state.data.contact.open,
  sending: state.data.contact.sending
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => {
    dispatch(toggleContactForm());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
