const selectFavicon = brand => {
  if (brand === 'johnreed') {
    return {
      favicon: '/johnreed/favicon.ico',
      favicon16x16: '/johnreed/favicon-16x16.png',
      favicon32x32: '/johnreed/favicon-32x32.png',
      appleTouch: '/johnreed/apple-touch-icon.png',
      safariTab: '/johnreed/safari-pinned-tab.svg',
      manifest: '/johnreed/manifest.json'
    };
  }
  if (brand === 'high5') {
    return {
      favicon: '/high5/favicon.ico',
      favicon16x16: '/high5/favicon-16x16.png',
      favicon32x32: '/high5/favicon-32x32.png',
      appleTouch: '/high5/apple-touch-icon.png',
      safariTab: '/high5/safari-pinned-tab.svg',
      manifest: '/high5/manifest.json'
    };
  }

  return {
    favicon: '/mcfit/favicon.ico',
    favicon16x16: '/mcfit/favicon-16x16.png',
    favicon32x32: '/mcfit/favicon-32x32.png',
    appleTouch: '/mcfit/apple-touch-icon.png',
    safariTab: '/mcfit/safari-pinned-tab.svg',
    manifest: '/mcfit/manifest.json'
  };
};

export default selectFavicon;
