import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { media } from '../../../../lib/styledMixins';
import SecondaryCTAButton from '../../../../scenes/Checkout/components/Data/components/ContractForm/components/SecondaryCTAButton';

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 0.5em;
  flex: 1;
  width: 100%;

  ${media.md`
    margin-bottom: 0;
    width: auto;
  `}
`;

export const FileUploadInput = ({
  multiple,
  name,
  id,
  accept,
  onChange,
  validateFile
}) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Wrapper>
      <InputField
        multiple={multiple}
        name={name}
        type="file"
        id={id}
        accept={accept}
        onChange={async event => {
          if (event.target.files.length) {
            const foto = event.target.files[0];

            if (validateFile) {
              const isValid = await validateFile(foto);
              if (!isValid) {
                return;
              }
            }

            const fr = new FileReader();
            fr.readAsDataURL(foto);
            fr.addEventListener('load', () => {
              onChange(fr.result);
            });
          }
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      />

      <SecondaryCTAButton hovered={hovered}>
        <FormattedMessage id="checkout.selectFile" defaultMessage="Select" />
      </SecondaryCTAButton>
    </Wrapper>
  );
};
