import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { media } from '../../../lib/styledMixins';

export const ContractPrizeWrapper = styled.div`
  color: ${props => props.theme.backgroundSecondary};
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;

  ${media.lg`
    font-size: 1.4rem;
  `}
`;

const ThePrize = styled.span`
  font-family: 'Novecento', sans-serif;
  font-size: 4.5rem;
  font-weight: bold;
  letter-spacing: -3px;
  position: relative;

  ${media.lg`
    font-size: 4.9rem;
  `}
`;

const TheCents = styled.sup`
  font-size: 1.3rem;
  letter-spacing: 0;
  margin-left: 0.5rem;
  position: absolute;
  top: 1rem;

  ${media.lg`
    font-size: 1.4rem;
  `}
`;

export const ContractPrize = props => {
  const {
    prize,
    locale,
    showMonthly,
    id,
    isRecurring,
    paymentFrequency
  } = props;

  const prizeValue = prize.toString().split('.');
  const priceCent = prizeValue[1] < 10 ? prizeValue[1] + '0' : prizeValue[1];

  return (
    <ContractPrizeWrapper>
      {locale === 'en' && '£'}
      <ThePrize>
        {!showMonthly && !(id === 5465516270 || id === 5465535370)
          ? locale === 'de' || locale === 'at'
            ? '10'
            : locale === 'it'
            ? '25'
            : locale === 'es'
            ? '20'
            : ''
          : prizeValue[0]}
        {showMonthly && prizeValue[1] && <TheCents>{priceCent}</TheCents>}
      </ThePrize>
      {locale !== 'en' && '€'}
      {isRecurring && (
        <FormattedMessage id={`contract.${paymentFrequency.toLowerCase()}`} />
      )}
      <sup>**</sup>
    </ContractPrizeWrapper>
  );
};

ContractPrize.propTypes = {
  prize: PropTypes.number.isRequired,
  locale: PropTypes.string,
  hideMonthlySuffix: PropTypes.bool,
  paymentFrequency: PropTypes.string,
  isRecurring: PropTypes.bool
};

export default ContractPrize;
