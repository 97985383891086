import * as React from 'react';
import styled from 'styled-components/macro';

import AccordionItem from './components/AccordionItem';

const AccordionWarapper = styled.div`
  padding: 0 16px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid #e9e9e9;
  width: 100%;
`;

export const AccordionContext = React.createContext<{
  openIndex?: number;
  setOpenIndex?: React.Dispatch<number>;
  onSelect?: (e: React.SyntheticEvent, index: number) => void
}>({});

const Accordion: React.FC<{ onSelect: (e: React.SyntheticEvent, index: number) => void }> = ({ children, onSelect }) => {
  const [openIndex, setOpenIndex] = React.useState(null);
  const childArray = React.Children.toArray(children);

  return (
    <AccordionContext.Provider value={{ openIndex, setOpenIndex, onSelect }}>
      <AccordionWarapper>
        {childArray.map((child, i) => (
          <AccordionItem key={`accordion_item_${i}`} index={i}>            
            {child}
          </AccordionItem>
        ))}
      </AccordionWarapper>
    </AccordionContext.Provider>
  );
};

export default Accordion;
