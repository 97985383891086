import React from 'react';
import PropTypes from 'prop-types';

import ContractFormDE from './components/ContractFormDE/CampaignFormDE';
import ContractFormES from './components/ContractFormES/CamapaignFormES';
import ContractFormIT from './components/ContractFormIT/CampaignFormIT';

const CampaignForm = ({ lang, match }) => {
  switch (lang) {
    case 'es':
      return <ContractFormES />;
    case 'it':
      return <ContractFormIT />;
    case 'de':
    case 'at':
      return <ContractFormDE austria={lang === 'at'} />;
    default:
      return null;
  }
};

CampaignForm.propTypes = {
  lang: PropTypes.string
};

export default CampaignForm;
