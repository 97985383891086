const es = {
  intl: {
    locale: 'es',
    messages: {
      'add.suspensionMonths': '¿Cuántos meses deseas pausar tu contrato?',
      'add.suspensionTime': 'Solicita una suspensión temporal',
      'add.success':
        'Has registrado tu suspensión temporal con éxito. En cuanto tramitemos la solicitud, esta suspensión temporal quedará reflejada en tu área personal.',
      'add.title': 'añadir',
      'add.cause': '¿Por qué quieres pausar tu contrato?',

      'app.greeting': '¡Hola!',
      'app.switchLanguage': 'Selecciona idioma',

      'backlink.url.high5': 'high5.com',
      'backlink.url.johnreed': 'johnreed.fitness',
      'backlink.url.mcfit': 'mcfit.com',

      'breadcrumb.contract.high5': 'Contrato',
      'breadcrumb.contract.johnreed': 'Contrato',
      'breadcrumb.contract.mcfit': 'Contrato',
      'breadcrumb.data.high5': 'Datos',
      'breadcrumb.data.johnreed': 'Datos',
      'breadcrumb.data.mcfit': 'Datos',
      'breadcrumb.finalize.high5': 'Finalizar',
      'breadcrumb.finalize.johnreed': 'Finalizar',
      'breadcrumb.finalize.mcfit': 'Finalizar',
      'breadcrumb.homebase.high5': 'Tu gimnasio',
      'breadcrumb.homebase.johnreed': 'Tu gimnasio',
      'breadcrumb.homebase.mcfit': 'Tu gimnasio',

      'card.BIC': 'BIC',
      'card.IBAN': 'IBAN',
      'card.L': 'Adeudo SEPA',
      'card.accountOwner': 'Titular de la cuenta',
      'card.address': 'Mi dirección',
      'card.birthday': 'Fecha de nacimiento',
      'card.cancel': 'Próxima fecha límite para solicitar la baja',
      'card.canceld': '',
      'card.comingSoon': 'Coming Soon',
      'card.contact': 'Datos de contacto',
      'card.contractHeadline': 'Datos del contrato',
      'card.duration': 'Duración',
      'card.email': 'E-Mail',
      'card.extension': 'Verlängerung:',
      'card.financial': 'Datos de pago',
      'card.lastBill': 'Última factura',
      'card.memberDataHeadline': 'Datos personales',
      'card.memberNumber': 'N° de socio',
      'card.monthlyFee':
        'Cuota de socio mensual en los primeros {months} meses',
      'card.monthlyFeeFrom': 'Cuota de socio mensual a partir del mes {months}',
      'card.monthlyFeeProlonged':
        'Monatlicher Mitgliedsbeitrag ab Verlängerung',
      'card.currency': 'Euros',
      'card.nextPayment': 'Próxima mensualidad',
      'card.payment': 'Historial de pago',
      'card.paymentType': 'Forma de pago',
      'card.phone': 'Teléfono',
      'card.suspension': 'Suspensiones temporales',

      'change.address': 'Mi nueva dirección',
      'change.bankdata': 'Mis nuevos datos bancarios',
      'change.title': 'Modificar',

      'checkout.description.johnreed':
        'Wähle den JOHN REED Club, in dem du am meisten trainieren wirst.',
      'checkout.description.mcfit':
        'Según el tipo de contrato, podrás entrenar en todos los gimnasios McFIT o solo en uno de ellos. Por ello, elige tu gimnasio de entrenamiento habitual.',
      'checkout.headline.johnreed': 'Wo ist deine Homebase',
      'checkout.headline.mcfit': 'Selecciona tu gimnasio',
      'campaign.headline.mcfit': '1. ¿DÓNDE QUIERES EMPEZAR?',
      'campaign.headline.johnreed': '1. ¿DÓNDE QUIERES EMPEZAR?',
      'campaign.headline.high5': '1. ¿DÓNDE QUIERES EMPEZAR?',
      'checkout.ibanError': 'Su IBAN no es válido. Por favor, compruebe.',
      'checkout.locationPlaceholder': 'Calle/ Código postal/ Localidad',
      'checkout.locationPlaceholderLong':
        'Introducir calle, código postal o localidad',
      'checkout.noStudios': 'No hay gimnasios en la zona',
      'checkout.sendButton': 'Confirmar con vinculación de pago',
      'checkout.sendingButton': 'Enviando...',
      'checkout.sendingFotoButton': 'Enviando Foto...',
      'checkout.studio.mcfit': 'Gimnasio',
      'checkout.studioDetailLink': 'Detalles',
      'checkout.studioSelectButton': 'Gimnasio habitual',
      'checkout.studioSelectButtonLong': 'Gimnasio habitual',
      'checkout.subheadlineStep2': 'Gimnasios cerca de ti:',
      'checkout.uploadPicture': 'Upload Picture',
      'checkout.selectFile': 'Select File',
      'checkout.takePicture': 'Take Picture',
      'checkout.removePicture': 'Remove Picture',
      'contract.expand': 'Detalles',
      'contract.completeButton': 'Elegir contrato',
      'contract.completeButtonShort': 'Elegir',
      'contract.from': ' ',
      'contract.headline.johnreed': 'Welcher Vertrag passt zu dir?',
      'contract.headline.mcfit': 'ELIGE TU CONTRATO ONLINE',
      'contract.upselling.headline.mcfit': 'ELIGE TU CONTRATO ONLINE',
      'contract.campaign.headline.mcfit': '2. TUS SERVICIOS DE UN VISTAZO',
      'contract.week': 'Days',
      'contract.month': '/mes',
      'contract.year': '/año',
      'contract.runtime': 'Duración mínima del contrato: {duration} meses',
      'contract.runtime.flex': '',
      'contract.runtime.single': 'Duración mínima del contrato: {duration} mes',
      'contract.showContract': 'Mostrar contrato',
      'contract.subheadline.johnreed': '',
      'contract.subheadline.mcfit': ' ',
      'contract.legalFootnote':
        '** Todos los precios indicados son IVA incluido | Los horarios de apertura pueden variar de un gimnasio a otro | Puedes hacer un contrato online si eres mayor de edad y eres titular de una cuenta bancaria, si no, pásate por el gimnasio o contacta con el departamento de atención al cliente.',
      'checkout.referralCode': 'Código recomendado',
      'checkout.referralCode.placeholder': 'Introducir el código recomendado',
      'checkout.proudclub': 'PROUD CLUB',
      'checkout.mcfitplus': 'Nuestro programa de fidelización McFIT+ para ti',

      'campaign.btn': 'MÁS',
      'cookie.link': 'aquí.',
      'cookie.linkURL.high5':
        'https://www.high5.com/footer-navigation/impressum/#datenschutz',
      'cookie.linkURL.johnreed': 'https://johnreed.fitness/datenschutz',
      'cookie.linkURL.mcfit': 'https://www.mcfit.com/index.php?id=196&L=2',
      'cookie.message':
        'Utilizamos cookies propias y de terceros para mejorar nuestros servicios y facilitar la navegación. Si continua navegando consideramos que acepta su uso. Más información',

      'dashboard.changeAddress': 'Mi nueva dirección',
      'dashboard.changeData': 'Modificar',
      'dashboard.greeting': '¡Hola',

      'dashboardNav.communication': 'Comunicación',
      'dashboardNav.cyberobicsNow': 'Cyberobics Now',
      'dashboardNav.home': 'Dashboard',
      'dashboardNav.proudClub': 'Proud Club',
      'dashboardNav.services': 'Servicios',
      'dashboardNav.bringafriend': 'Bring A Friend',

      'data.back': 'opciones de contrato',
      'data.headline.johnreed': 'Join the club',
      'data.headline.mcfit': '¡Forma parte de algo grande!',
      'data.subheadline.johnreed': '',
      'data.subheadline.mcfit':
        'Introduce tus datos de contacto y tus datos bancarios',

      'footerNav.career': 'Empleo',
      'footerNav.company': 'Empresa',
      'footerNav.contact': 'Contacto',
      'footerNav.imprint': 'Aviso legal',
      'footerNav.privacy': 'Política de privacidad',

      'header.backlink': 'Volver a la página principal',
      'header.loginButton': 'Login',
      'header.logoutButton': 'Logout',

      'login.becomeMemberButton': 'Hazte socio',
      'login.becomeMemberDescription': '¿Aún no eres socio de McFIT?',
      'login.contactText':
        'Envíanos tus preguntas a través de nuestro formulario de contacto',
      'login.description': 'Aquí puedes acceder a tu área personal.',
      'login.emailPlaceholder': 'E-Mail',
      'login.emailPlaceholderLong': 'Dirección de E-Mail',
      'login.forgotPassword': '¿Has olvidado tu contraseña?',
      'login.headline.johnreed': 'Welcome to the club',
      'login.headline.mcfit': 'Bienvenido a McFIT',
      'login.passwordPlaceholder': 'Contraseña',
      'login.register': 'Registrarse',
      'login.submit': 'Acceder',

      'memberNumber.description': 'Tu número de socio',

      'messages.changeDataError': 'Error en la modificación de tus datos',
      'messages.gpsError': 'Fehler beim Orten per GPS',
      'messages.invalidData': 'Por favor, comprueba los datos introducidos.',
      'messages.invalidFiscalCode': 'Fehler beim Bestätigen des Tax IDs',
      'messages.locationFetchError': 'Error en la carga de la ubicación',
      'messages.newVersion': 'Nueva versión disponible',
      'messages.newVersionDescription':
        'Por favor, carga esta página de nuevo para actualizarla ',
      'messages.promoFetchError':
        'Error en la carga de las acciones del Proud Club',
      'messages.sendDataError': 'Error en el envío de datos',
      'messages.receiveDataError': '',
      'messages.studioFetchError': 'Error en la carga de los gimnasios',
      'messages.userFetchError': 'Error en la carga del área personal',
      'messages.userLoginError': 'Error de Login',
      'messages.userLogoutError': 'Error de Logout',
      'messages.verifyEmail': 'Error en la confirmación del E-Mail',
      'messages.voucherCopySuccess': '',
      'messages.pendingRegistration':
        'Tu solicitud de registro se está tramitando.',
      'messages.mailNotAllowed':
        'Con este correo electrónico no es posible registrarse. Dírigete, por favor, a nuestro departamento de Atención al Cliente.',
      'messages.contractConclusionBlocked':
        'En este momento no es posible ofrecerte ningún contrato. Dírigete, por favor, a nuestro departamento de Atención al Cliente.',
      'messages.alreadyRegistered':
        '¿Tienes ya un contrato? Dírigete, por favor, a nuestro departamento de Atención al Cliente.',

      'newPassword.successDescription':
        'Ya puedes iniciar sesión con tu nueva contraseña ',
      'newPassword.successHeadline': '¡La acción se ha completado con éxito!',

      'notfound.homebutton': 'A la página principal',

      'proudClub.copyCodeToClipboard': '',
      'proudClub.detail.back': '',
      'proudClub.intro.partnerCta': '',
      'proudClub.legal.showLess': '',
      'proudClub.legal.showMore': '',
      'proudClub.loyalty.month': '',
      'proudClub.loyalty.toNextUpgrade': '',
      'proudClub.loyalty.yourProgress': '',
      'proudClub.overview.headline': '',
      'proudClub.overview.intro.bottomLine': '',
      'proudClub.overview.intro.topLine': '',

      'register.accountData': 'Datos de acceso',
      'register.headline': 'PARA REGISTRARTE EN TU ÁREA PERSONAL',
      'register.label.birthday': 'Fecha de nacimiento',
      'register.label.email': 'E-Mail',
      'register.label.password': 'Contraseña',
      'register.personalData': 'Datos personales',

      'resetPassword.description':
        'Introduce aquí tu dirección de correo y te haremos llegar un mensaje con los pasos a seguir',
      'resetPassword.headline': '¿Has olvidado tu contraseña?',
      'resetPassword.submit': 'Enviar',
      'resetPassword.successDescription':
        'Te hemos enviado un correo electrónico con los pasos a seguir',
      'resetPassword.successHeadline': '¡La acción se ha completado con éxito!',

      'route.bringafriend': '/bring-a-friend',
      'route.dashboard': '/dashboard',
      'route.dashboard.communication': '/comunicacion',
      'route.dashboard.cyberobicsNow': '/cyberobics-now',
      'route.dashboard.home': '/',
      'route.dashboard.proudClub': '/proud-club',
      'route.dashboard.proudClub.partner': '/proud-club/partner',
      'route.dashboard.services': '/servicios',
      'route.enterData': '/datos',
      'route.notFound': '404 página no encontrada',
      'route.notFound.message': '404',
      'route.resetPassword': '/contraseña-olvidada',
      'route.selectContract': '/elegir-contrato',
      'route.success': '/bienvenido',

      'validate.birthday': 'Tienes que ser mayor de edad',
      'validate.birthday15': 'Tienes que tener al menos 15 años de edad',
      'validate.birthday27': 'Tienes que tener un máximo de 27 años de edad',
      'validate.city': 'Introduce una ciudad',
      'validate.email': 'Por favor, introduce un correo electrónico válido',
      'validate.firstName': 'Introduce tu nombre',
      'validate.gender': 'Por favor, indica tu sexo',
      'validate.houseNumber': 'Por favor, revisa tu número de casa ',
      'validate.iban': 'Por favor, revisa tu IBAN',
      'validate.lastName': 'Introduce tus apellidos',
      'validate.postalCode': 'Por favor, revisa tu código postal',
      'validate.signature': 'Por favor confirme su firma',
      'validate.street': 'Por favor, revisa tu calle',
      'validate.match': '',

      'verify.success.mcfit': '¡Gracias por la confirmación!',

      'welcome.description.mcfit':
        '¡Ya formas parte de la comunidad McFIT! Ahora solo necesitas tu tarjeta de socio, la cual podrás recoger en la recepción del gimnasio que hayas seleccionado a partir de las 10:00 horas. Muy pronto recibirás los documentos del contrato por correo electrónico. Si tienes cualquier pregunta, puedes ponerte en contacto con nuestro servicio de atención al cliente.',
      'welcome.description.mcfit.noApps': '',
      'welcome.headline.mcfit': '¡BIENVENIDO!',
      'welcome.campaign.headline.mcfit': 'Muchas gracias.',
      'welcome.subheadline.mcfit': '¡Disfruta de tu entrenamiento!',
      'upsell.description':
        'En unos minutos recibirás un correo electrónico con más información. Por favor, revisa tu bandeja de ‘Correo no deseado’ si no encuentras el email en tu bandeja principal.',
      'upsell.subheadline': '¡Diviértete y suerte con el entrenamiento!',
      'upsell.error.headline': 'Algo ha salido mal.',
      'upsell.error.description':
        'Lamentablemente, se ha producido un error. ¿Has introducido tus datos correctamente?<br/><br/>No te preocupes, lo solucionaremos. Solo tienes que venir a un gimnasio McFIT a partir de las 10:00 AM. Nuestro equipo estará encantado de ayudarte para que puedas empezar pronto tu Healthy Pack.',

      high5: 'High5',
      johnreed: 'John Reed',
      mcfit: 'McFit',
      'validate.required': 'Campo obligatorio',
      'yup.cf.passwordMismatch': 'La contraseña introducida no coincide',
      'yup.cf.postalCodeMismatch': 'Código postal inválido',
      'messages.userRequestPasswordFailure':
        'Error en la actualización de tu contraseña',
      'yup.cf.taxNumberMismatch': 'Por favor, revisa tu NIF',
      'yup.cf.phoneMismatch': 'Este número de teléfono no es válido',
      'validate.phone': 'Por favor, revisa tu número de teléfono.',

      'newPassword.headline': 'Escribe tu nueva contraseña',
      'newPassword.description':
        'Por favor, no olvides elegir una contraseña segura',

      'yup.cf.spanishIdentificationMismatch':
        'No es un número válido en España',
      'add.start':
        '¿A partir de cuándo deseas que se haga efectiva la suspensión?',
      'suspension.noSuspensions': 'No figuran suspensiones',
      'suspension.cause1': 'Otros',
      'suspension.cause2': 'Embarazo',
      'suspension.cause3': '',
      'suspension.cause4': 'Motivos económicos',
      'suspension.cause5': 'Estancia en el extranjero',
      'suspension.cause6': 'Enfermedad',
      'signature.save': 'Confirmar firma',
      'signature.clear': 'Borrar',
      'signature.rotateDevice': 'Por favor, gira tu dispositivo',
      'cf.yup.bicMismatch': 'Por favor, revisa tu BIC',
      'messages.validateEMailFailure': 'Este correo electrónico no es válido',
      'messages.validateFiscalCodeFailure': '',
      'messages.uploadFotoFailure': 'Error al enviar la foto',
      'messages.fotoSizeError':
        'Archivo demasiado grande. El tamaño máximo permitido son 5MB.',
      'messages.tsp.password':
        'Das Passwort sollte einen Buchstaben und eine Ziffer (oder ein Sonderzeichen) enthalten und mindestens 8 Zeichen lang sein.',

      'cta.send': 'Enviar',
      'cta.sending': 'Enviando…',
      'welcome.copyMemberNumber': 'Copiar número de socio',
      'messages.wrongPassword': 'No existe ninguna cuenta con estos datos',
      'bringafriend.bullet1.johnreed': '',
      'bringafriend.bullet1.mcfit': '',
      'bringafriend.bullet2': '',
      'bringafriend.bullet3':
        'El personal del gimnasio te entregará un vale para un mes de entrenamiento gratuito',
      'bringafriend.bullet4':
        'Rellena el formulario "Bring a Friend" (situado en la parte izquierda de la página) con los datos que aparecen en tu vale. Elige el mes que quieres obtener gratis y haces click en "enviar".',
      'bringafriend.bulletIntro': 'Es así de facil: ',
      'bringafriend.freemonth': 'Elige tu mes gratis',
      'bringafriend.frienddata': 'Datos de tu amigo',
      'bringafriend.headline': 'Bring a Friend',
      'bringafriend.invalid': 'Código erroneo',
      'bringafriend.messageHeadline.johnreed': '',
      'bringafriend.messageHeadline.mcfit':
        '¡Por cada amigo recomendado te regalamos un mes gratis en McFIT!',
      'bringafriend.success':
        'Hemos recibido tu solicitud y esta será procesada.',
      'bringafriend.sup1.johnreed': '',
      'bringafriend.sup1.mcfit':
        'Quedan excluidos de la promoción los socios actuales de McFIT y los menores de 15 años',
      'bringafriend.sup2.johnreed': '',
      'bringafriend.sup2.mcfit': '',
      'bringafriend.sup3.johnreed': '',
      'bringafriend.sup3.mcfit': '',
      'bringafriend.voucher': 'Código',
      'bringafriend.voucherheadline': 'Tu código',
      'checkout.description.high5': '',
      'checkout.headline.high5': '',
      'checkout.studio.high5': '',
      'checkout.studio.johnreed': '',
      'checkout.studioSelectButton.high5': '',
      'checkout.studioSelectButton.johnreed': '',
      'checkout.studioSelectButton.mcfit': 'Tu código',
      'checkout.fotoUploadDescription':
        'El tamaño de archivo máximo permitido son 5MB.',

      'component.loginLink': 'Acceder',
      'contract.noContracts': 'No se ha encontrado ningún contrato',
      'contract.subheadline.high5': ' ',
      'dashboardNav.proudClub.faq': 'FAQ',
      'dashboardNav.proudClub.info': 'Info',
      'dashboardNav.proudClub.loyalty': 'Tu fidelidad tiene premio',
      'dashboardNav.proudClub.partner': 'Beneficios para partners',
      'data.headline.high5': '',
      'data.headline.campaign': 'INTRODUCE TUS DATOS AHORA',
      'login.headline.high5': '',
      'login.noMember': '¿Aún no tienes cuenta?',
      'messages.bookOptionError': 'Se ha producido un error en la reserva',
      'messages.bookOptionSuccess': 'La reserva se ha realizado con éxito',
      'messages.changeDataSuccess':
        'Hemos recibido correctamente los cambios requeridos. En cuanto tramitemos la solicitud, los nuevos datos quedarán reflejados en tu área personal.',
      'messages.emailAlreadyUsed':
        'Esta dirección de correo electrónico ya está en uso',
      'messages.emailAlreadyUsedLogin': '',
      'messages.loginSuccess': 'Login realizado',
      'messages.memberNumberCopySuccess':
        'Número de socio copiado en el portapapeles',
      'messages.registrationSuccess':
        '¡Listo! Por favor, ahora inicia sesión con tus datos de cuenta',
      'messages.sendOptinError': 'Error en el proceso de alta del PROUD CLUB',
      'messages.sendRegistrationError': 'Error en el registro',
      'messages.sendRegistrationError.description': '',
      'messages.sendRegistrationSuccess': 'Solicitud enviada',
      'messages.sendRegistrationSuccess.description':
        'Estos datos no están asociados a ninguna cuenta. Recibirás un correo electrónico con los pasos a seguir',
      'messages.unconfirmedAccount': 'Tu cuenta aún no ha sido confirmada',
      'messages.unconfirmedAccount.description':
        'Por favor, revisa tu correo electrónico y haz clic en el link de confirmación',
      'messages.userlangFetchErrorDescription':
        'Por favor, revisa tu correo electrónico y haz clic en el link de confirmación',
      'messages.ibanError': 'No hay IBAN válido',
      'messages.iban.invalidFormat': '',
      'messages.iban.invalid': '',
      'messages.iban.prepaid': '',

      'messages.loox.invalidPassword': '',
      'messages.loox.invalidAge': '',
      'messages.loox.accountBlocked': '',
      'messages.loox.accountDisabled': '',
      'messages.loox.existingSubscription': '',
      'messages.loox.created': '',
      'messages.loox.updated': '',
      'messages.cyberobics.success': '',
      'messages.cyberobics.updated': '',
      'messages.cyberobics.alreadySubscribed': '',
      'messages.cyberobics.resendSuccess': '',

      'proudClub.email': '',
      'proudClub.pending':
        'Por favor, confirma tu alta haciendo clic en el link que has recibido por correo electrónico.',
      'proudClub.register.headline': '',
      'register.description':
        'Por favor, introduce tu dirección de correo electrónico y elige una contraseña. Estos datos te permitirán acceder a tu área personal.',
      'register.flagsHeadline':
        'Por favor, selecciona el país en el que has firmado tu contrato de socio',
      'register.label.day': 'Día',
      'register.label.firstName': 'Nombre',
      'register.label.lastName': 'Apellidos',
      'register.label.memberNumber': 'Número de socio',
      'register.label.month': 'Mes',
      'register.label.year': 'Año',
      'register.memberNumber': 'Necesitamos tu número de socio',
      'route.dashboard.loyalty': '/proud-club/loyalty',
      'register.sendButton': 'ENVIAR',
      'register.sendingButton': 'Invio...',
      'registermember.aside': '',
      'registermember.personalData': 'Datos personales',
      'registermember.personalData.description':
        'Nota: por favor, introduce los mismos datos que utilizaste en la firma del contrato',
      'registermember.personalData.memberNumber':
        'Info: puedes consultar tu número de socio en tu carné o en la copia de tu contrato',
      'verify.description.high5':
        'Te has dado de alta con éxito en el área de usuario de McFIT. Accede en cualquier momento y descubre sus ventajas.',
      'verify.description.johnreed':
        'Te has dado de alta con éxito en el área de usuario de McFIT. Accede en cualquier momento y descubre sus ventajas.',
      'verify.description.mcfit':
        'Te has dado de alta con éxito en el área de usuario de McFIT. Accede en cualquier momento y descubre sus ventajas.',
      'verify.success.high5': '',
      'verify.success.johnreed': '',
      'welcome.description.high5': '',
      'welcome.description.johnreed': '',
      'welcome.description.ML': '',
      'welcome.headline.high5': '',
      'welcome.headline.johnreed': '',
      'welcome.subheadline.high5': '',
      'welcome.subheadline.johnreed': '',
      'welcome.description.add': '',
      'change.bankdata.bank': 'Mis nuevos datos bancarios',
      'change.city': 'Localidad',
      'change.number': 'Número',
      'change.postalCode': 'Código postal',
      'change.street': 'Calle',
      'messages.activationNeeded':
        'Por favor, activa tu cuenta haciendo clic en el link que has recibido por correo electrónico.',
      'messages.userlangFetchError':
        'Error en la actualización de tu contraseña',
      'route.contact': '/contact',
      'messages.italy': 'Error al iniciar session',
      'messages.italy.description':
        'Inicie sesión en <a href="https://www.mcfit.com/it/personal/">https://www.mcfit.com/it/personal/</a>.',

      'tsp.headline.cyberobics': '',
      'tsp.headline.loox': '',
      'tsp.subheadline.cyberobics': '',
      'tsp.subheadline.loox': '',
      'tsp.passwordPlaceholder': '',
      'tsp.passwordPlaceholderAgain': '',
      'tsp.cta': '',
      'tsp.successHeadline.cyberobics': '',
      'tsp.successHeadline.loox': '',
      'tsp.success.loox': '',
      'tsp.success.cyberobics': '',
      'tsp.cyberobics.playstore': '',
      'tsp.cyberobics.appstore': '',
      'tsp.loox.playstore': '',
      'tsp.loox.appstore': '',

      'testtraining.headline.mcfit': '',
      'testtraining.headline.johnreed': '',
      'testtraining.gender': '',
      'testtraining.agenote': '',
      'testtraining.bullet1': '',
      'testtraining.bullet2': '',
      'testtraining.bullet3': '',
      'testtraining.bullet4': '',
      'testtraining.success.headline': '',
      'testtraining.success.subheadline': '',
      'testtraining.success.description': ''
    }
  }
};

export default es;
