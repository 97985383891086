import styled, { css } from 'styled-components/macro';

const SliderButton = styled.button`
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: initial;

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    `};

  svg {
    color: ${props => props.theme.active1};
  }
`;

export default SliderButton;
