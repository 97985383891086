import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import StudioListWrapper from './components/StudioListWrapper';
import Studio from './components/Studio/Studio';
import DefaultMessage from './components/DefaultMessage';
import removeDoubleSlash from '../../../../../../lib/removeDoubleSlash';

export const StudioList = props => {
  const { locations, match } = props;
  const filteredStudios = locations.filter(studio => studio.distance <= 100);
  const isCampaign = !!match?.url?.match(/campaign/);

  return (
    <>
      <StudioListWrapper>
        <Route
          path={isCampaign ? '/campaign' : '/checkout'}
          render={() =>
            filteredStudios.map((location, i) => (
              <Studio
                i={i}
                key={location.StudioMark}
                location={location}
                contractId={props.match.params.contractId || false}
                url={
                  props.match.params.contractId
                    ? `${
                        isCampaign ? '/campaign' : '/checkout'
                      }${props.intl.formatMessage({
                        id: 'route.enterData'
                      })}/${props.match.params.contractId}/${
                        location.StudioMark
                      }`
                    : removeDoubleSlash(
                        `${
                          isCampaign ? '/campaign' : '/checkout'
                        }/${props.intl.formatMessage({
                          id: 'route.selectContract'
                        })}/${location.StudioMark}`
                      )
                }
              />
            ))
          }
        />
        <Route
          path="/probetraining"
          render={() =>
            filteredStudios.map((location, i) => (
              <Studio
                key={location.StudioMark}
                i={i}
                location={location}
                url={`/probetraining${props.intl.formatMessage({
                  id: 'route.enterData'
                })}/${location.StudioMark}`}
              />
            ))
          }
        />
        {!filteredStudios.length && (
          <DefaultMessage>
            <FormattedMessage
              id="checkout.noStudios"
              defaultMessage="Leider konntent wir keine Studios in deiner Nähe finden"
            />
          </DefaultMessage>
        )}
      </StudioListWrapper>
    </>
  );
};

StudioList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  locations: state.data.checkout.locations
});

export default injectIntl(connect(mapStateToProps)(StudioList));
