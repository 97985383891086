import * as React from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import './styles.css';

import Message from './Message';

type TRespone = Response & {
  action?: any;
  reference?: string;
  state?: string;
  resultCode?: string;
};

const AdyenPayment: React.FC<{
  studioId: number;
  countryCode: string;
  storeCurrentForm: () => void;
  onSuccess: (ref: string) => void;
}> = ({ studioId, countryCode, storeCurrentForm, onSuccess }) => {
  const dropinRef = React.useRef(null);
  const intervalRef = React.useRef(null);
  const tokenRef = React.useRef('');
  const checkoutInstance = React.useRef(null);
  const [wait, setWait] = React.useState(false);
  const [error, setError] = React.useState('');
  const [tokenizationReference, setTokenizationReference] = React.useState(
    null
  );
  const [defaultConfig] = React.useState({
    containerId: 'dropin-container',
    countryCode,
    currentLocale: 'en-US',
    environment: studioId === 1210001590 ? 'test' : 'live',
    beforeRedirect: () => {
      console.log('REDIRECT');
      storeCurrentForm();
    },
    onLoading: () => {
      console.log('LOADING');
    },
    onSuccess: ref => {
      console.log('SUCCESS');

      onSuccess(ref);
    },
    onFailure: () => {
      console.log('FAIL');
      setWait(false);
    }
  });

  React.useEffect(() => {
    const currentURL = window.location.search;
    const urlParams = new URLSearchParams(currentURL);
    const tokenizationReference = urlParams.get('tokenization');
    if (tokenizationReference) {
      const { origin, pathname } = window.location;
      urlParams.delete('tokenization');
      urlParams.delete('redirectResult');
      window.history.replaceState(
        {},
        '',
        `${origin}${pathname}${urlParams.toString()}`
      );
      onSuccess(tokenizationReference);
    }
  }, [onSuccess]);

  React.useEffect(() => {
    if (!wait || !tokenizationReference) return;
    async function checkTokenizationStatus() {
      const response: TRespone = await (
        await fetch(`/api/token?ref=${tokenizationReference}`)
      ).json();

      if (response.state === 'COMPLETE') {
        clearInterval(intervalRef.current);

        defaultConfig.onSuccess(tokenizationReference);
      }

      if (response.state === 'FAILURE') {
        clearInterval(intervalRef.current);
        checkoutInstance.current.unmount();
        defaultConfig.onFailure();
      }
    }

    function waitForTokenization() {
      defaultConfig.onLoading();
      intervalRef.current = setInterval(checkTokenizationStatus, 2000);
    }

    waitForTokenization();
  }, [wait, defaultConfig, tokenizationReference]);

  React.useEffect(() => {
    if (!dropinRef.current || tokenizationReference) return;

    async function makePayment(data: any) {
      const dto = {
        browserInfo: data.browserInfo,
        origin: window.location.origin,
        paymentMethod: data.paymentMethod,
        returnUrl: `${window.location.origin}${window.location.pathname}${window.location.hash}`,
        studioId
      };

      const response: TRespone = await (
        await fetch('/api/token', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(dto)
        })
      ).json();
      console.log(response);

      setTokenizationReference(response.reference);
      tokenRef.current = response.reference;

      if (!response.action) {
        setError(response.resultCode);
        setWait(true);
        return;
      }

      if (response.action.type === 'redirect') {
        defaultConfig.beforeRedirect();
      }

      checkoutInstance.current.handleAction(response.action);
    }

    async function makeDetailsCall(data: any) {
      console.log('details', tokenRef.current);

      const response: TRespone = await (
        await fetch('/api/payments/details', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            ...data,
            reference: tokenRef.current
          })
        })
      ).json();
      console.log(response);

      if (!response.action) {
        setWait(true);

        return;
      }

      if (response.action.type === 'redirect') {
        defaultConfig.beforeRedirect();
      }

      checkoutInstance.current.handleAction(response.action);
    }

    const createConfig = (paymentMethodsResponse: any) => ({
      paymentMethodsResponse: paymentMethodsResponse, // The `/paymentMethods` response from the server.
      clientKey:
        studioId === 1210001590
          ? 'test_OER26P7XURG73I2CFSWSDAIRCA45AMQE'
          : 'live_A4L6Z7SMZRFSZCOWD56DA3Q6GYFXB4MX', // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
      locale: 'en-US',
      environment: studioId === 1210001590 ? 'test' : 'live',
      onSubmit: (state: any, dropin: any) => {
        // Global configuration for onSubmit
        // Your function calling your server to make the `/payments` request
        makePayment(state.data);
      },
      onAdditionalDetails: (state: any, dropin: any) => {
        console.log('add');

        // Your function calling your server to make a `/payments/details` request
        makeDetailsCall(state.data);
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: false,
          hideCVC: false,
          amount: {
            value: 0,
            currency: 'EUR'
          }
        }
      }
    });

    async function initAdyen() {
      const dropinNode = dropinRef.current || '';
      const response = await (
        await fetch(`/api/payments?studioId=${studioId}`)
      ).json();
      const configuration = createConfig(response);
      checkoutInstance.current = new AdyenCheckout(configuration).create(
        'dropin',
        {
          openFirstPaymentMethod: false
        }
      );
      checkoutInstance.current.mount(dropinNode);
    }

    initAdyen();
  }, [defaultConfig, studioId, tokenizationReference]);

  return (
    <>
      {wait ? (
        <Message>Wait...</Message>
      ) : error && !wait ? (
        <Message error>{error}</Message>
      ) : (
        <div id="dropin-container" ref={dropinRef}></div>
      )}
    </>
  );
};

export default AdyenPayment;
