export const SET_TRACKING_LANGUAGE = 'data/tracking/SET_TRACKING_LANGUAGE';

export function setReferrerLanguage(payload) {
  return {
    type: SET_TRACKING_LANGUAGE,
    payload
  };
}

export default function reducer(
  state = {
    tracklang: 'de'
  },
  action
) {
  switch (action.type) {
    case SET_TRACKING_LANGUAGE:
      return {
        ...state,
        tracklang: action.payload
      };

    default:
      return state;
  }
}
