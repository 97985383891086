import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { IconCross } from '../../Icons';

const StyledLink = styled(Link)`
  color: ${props => props.theme.backgroundMain};
  cursor: pointer;
  height: 22px;
  margin-left: auto;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
`;

const StyledIcon = styled(IconCross)`
  transform: rotate(-180deg);
  transition: transform 0.15s ease-in;

  &:hover {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
  }
`;

const CloseIcon = props =>
  props.close === 'false' ? (
    <StyledLink {...props}>
      <StyledIcon />
    </StyledLink>
  ) : (
    <StyledLink as="div" onClick={props.close}>
      <StyledIcon />
    </StyledLink>
  );

CloseIcon.propTypes = {
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

CloseIcon.defaultProps = {
  close: 'false'
};

export default CloseIcon;
