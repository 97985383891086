import { ThemeProvider } from 'styled-components/macro';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  theme: state.data.theme.variables
});

export default withRouter(injectIntl(connect(mapStateToProps)(ThemeProvider)));
