const backgroundMain = '#fff';
const backgroundThird = '#000';
const backgroundCard = '#fff';

const contractBorder = '1px solid rgba(255, 255, 255, 0)';
const gradient = 'to right, #e84160 0%, #b51b37 100%';

const studioDividerFirstThickness = '1';
const studioDividerThickness = '1';

const johnreed = {
  color1: '#edeff1',
  color2: '#666',
  color3: '#000',
  color4: '#fff',
  color5: '#666',
  color6: '#fff',
  color7: '#E84160',
  color8: '#666',
  color9: '#e9e9e9',
  color10: '#fff',
  color11: '#000',

  active1: '#e84160',
  active2: '#e84160',
  active3: '#fff',
  active4: '#fff',
  active5: '#fff',

  alert: '#e12819',
  warn: '#F85901',
  info: '#33414f',
  success: '#0eda65',

  buttonTextSecondary: '#fff',
  backgroundMain: '#fff',
  backgroundSecondary: '#000',
  backgroundThird: '#000',
  backgroundCard: backgroundCard,
  backgroundForm: '#F0F0F0',

  studioDividerFirstThickness,
  studioDividerThickness,

  // General
  mainBg: backgroundMain,
  footerBg: backgroundThird,
  cardBg: backgroundCard,
  studioActiveLayer: gradient,
  contractBorder,
  ctaMainHover: gradient,
  loginBtn: gradient,
  lightGrey: '#e9e9e9',
  white: '#fff',
  // Font Families
  // Font Families
  ffRegular: "'Open Sans', sans-serif",
  ffCondensed: "'Open Sans Condensed', sans-serif",
  ffBrand: "'Anton', sans-serif",

  brandId: 2,
  title: 'John Reed Fitness',
  logo: 'johnreed.jpg'
};

export default johnreed;
