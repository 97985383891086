import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

export const StyledLink = styled(Link)`
  align-items: center;
  color: ${props => props.theme.color4};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 6em;
  padding: 0 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s;
`;

export const SVGIcon = styled.div`
  margin: auto;
  max-width: 100%;
  height: 1.5em;
  width: 1.5em;
  transform: scale(-1, 1);

  ${media.md`
    display: block;
    margin: 0;
  `};
`;

export const StyledMessage = styled.div`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.2;
  font-weight: 600;

  ${media.md`
    margin-top: 0.875em;
  `};
`;
