const config = {
  api: '/api',
  locale: 'de',
  API_KEY: 'AIzaSyAEogkR3n7-FEkeQlWpJqt0oJPClY39JnU',
  GOOGLE_KEY: 'AIzaSyCXN2qnoxvib7ZiJg0PVWz-rNcPMs7mJvE',
  WOOSMAP_KEY: 'woos-3cb8caa3-ad4d-3e7b-b7d6-221a7b72398d',
  mapsUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
  footerLinks: {
    de: {
      mcfit: [
        {
          name: 'footerNav.contact',
          href: 'https://www.mcfit.com/de/kontakt/kontakt/'
        },
        {
          name: 'footerNav.company',
          href: 'https://www.mcfit.com/de/unternehmen/geschaeftskontakt'
        },
        {
          name: 'footerNav.career',
          href: 'https://www.mcfit.com/de/jobs-bei-mcfit'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.imprint',
          href: 'https://johnreed.fitness/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://johnreed.fitness/datenschutz'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt/'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.high5.com/footer-navigation/impressum/#impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    },
    en: {
      mcfit: [
        {
          name: 'footerNav.contact',
          href: 'https://www.mcfit.com/de/kontakt/kontakt/'
        },
        {
          name: 'footerNav.company',
          href: 'https://www.mcfit.com/de/unternehmen/geschaeftskontakt'
        },
        {
          name: 'footerNav.career',
          href: 'https://www.mcfit.com/de/jobs-bei-mcfit'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.imprint',
          href: 'https://johnreed.fitness/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://johnreed.fitness/datenschutz'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt/'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.high5.com/footer-navigation/impressum/#impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    },
    it: {
      mcfit: [
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/it/mcfitcom/cookie-policy/'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.contact',
          href: 'https://johnreed.fitness/kontakt'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://johnreed.fitness/it/dati-personali'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    },
    es: {
      mcfit: [
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/es/sobre-mcfitcom/proteccion-de-datos/'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.mcfit.com/es/sobre-mcfitcom/aviso-legal/'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.contact',
          href: 'https://johnreed.fitness/kontakt'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://johnreed.fitness/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://johnreed.fitness/datenschutz'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.high5.com/footer-navigation/impressum/#impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    },
    fr: {
      mcfit: [
        {
          name: 'footerNav.contact',
          href: 'https://www.mcfit.com/de/kontakt/kontakt/'
        },
        {
          name: 'footerNav.company',
          href: 'https://www.mcfit.com/de/unternehmen/geschaeftskontakt'
        },
        {
          name: 'footerNav.career',
          href: 'https://www.mcfit.com/de/jobs-bei-mcfit'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.imprint',
          href: 'https://johnreed.fitness/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://johnreed.fitness/datenschutz'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt/'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.high5.com/footer-navigation/impressum/#impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    },
    nl: {
      mcfit: [
        {
          name: 'footerNav.contact',
          href: 'https://www.mcfit.com/de/kontakt/kontakt/'
        },
        {
          name: 'footerNav.company',
          href: 'https://www.mcfit.com/de/unternehmen/geschaeftskontakt'
        },
        {
          name: 'footerNav.career',
          href: 'https://www.mcfit.com/de/jobs-bei-mcfit'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz'
        }
      ],
      johnreed: [
        {
          name: 'footerNav.imprint',
          href: 'https://johnreed.fitness/nl/footer-navigation/impressum'
        },
        {
          name: 'footerNav.privacy',
          href:
            'https://johnreed.fitness/nl/footer-navigation/privacyverklaring'
        }
      ],
      high5: [
        {
          name: 'footerNav.contact',
          href: 'https://www.high5.com/kontakt/'
        },
        {
          name: 'footerNav.imprint',
          href: 'https://www.high5.com/footer-navigation/impressum/#impressum'
        },
        {
          name: 'footerNav.privacy',
          href: 'https://www.high5.com/footer-navigation/impressum/#datenschutz'
        }
      ]
    }
  },
  socialLinks: {
    de: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'https://www.mcfit.com/de/facebook'
        },
        {
          name: 'Twitter',
          href: 'https://www.mcfit.com/de/twitter'
        },
        {
          name: 'Instagram',
          href: 'https://www.mcfit.com/de/instagram'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/user/McFitTV'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessClub'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    },
    en: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'https://www.mcfit.com/de/facebook'
        },
        {
          name: 'Twitter',
          href: 'https://www.mcfit.com/de/twitter'
        },
        {
          name: 'Instagram',
          href: 'https://www.mcfit.com/de/instagram'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/user/McFitTV'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessClub'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    },
    it: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'http://www.facebook.com/mcfit.IT/'
        },
        {
          name: 'Twitter',
          href: 'http://twitter.com/mcfit_it'
        },
        {
          name: 'Instagram',
          href: 'http://www.instagram.com/mcfit_it'
        },
        {
          name: 'Youtube',
          href: 'http://www.youtube.com/user/HappyFitTv'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessClubVeneziaMestre/'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_italy/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    },
    es: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'http://www.facebook.com/mcfit.ES'
        },
        {
          name: 'Twitter',
          href: 'http://twitter.com/mcfit_es'
        },
        {
          name: 'Instagram',
          href: 'http://www.instagram.com/mcfit_es'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessClub'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    },
    fr: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'https://www.mcfit.com/de/facebook'
        },
        {
          name: 'Twitter',
          href: 'https://www.mcfit.com/de/twitter'
        },
        {
          name: 'Instagram',
          href: 'https://www.mcfit.com/de/instagram'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/user/McFitTV'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessClub'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    },
    nl: {
      mcfit: [
        {
          name: 'Facebook',
          href: 'https://www.mcfit.com/de/facebook'
        },
        {
          name: 'Twitter',
          href: 'https://www.mcfit.com/de/twitter'
        },
        {
          name: 'Instagram',
          href: 'https://www.mcfit.com/de/instagram'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/user/McFitTV'
        }
      ],
      johnreed: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/JohnReedFitnessNL'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/johnreedfitness_nl'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCLXERcgkiPLwdmVJ-PrTCzQ'
        }
      ],
      high5: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/high5gym'
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/high5gym'
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/high5gym/'
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCfAf0Yu3ZCTXXKDXbOR92yw/'
        }
      ]
    }
  },
  proudclubLevels: [
    {
      id: 'classic',
      range: [0, 9],
      title: 'Classic'
    },
    {
      id: 'silver',
      range: [10, 19],
      title: 'Silver'
    },
    {
      id: 'silver-plus',
      range: [20, 35],
      title: 'Silver+'
    },
    {
      id: 'gold',
      range: [36, 60],
      title: 'Gold'
    },
    {
      id: 'gold-plus',
      range: [60, Infinity],
      title: 'Gold+'
    }
  ],
  gtmIDs: {
    mcfit: 'GTM-T5H9WSX',
    high5: 'GTM-NRR23V2',
    johnreed: 'GTM-P9KDKN7'
  },
  contractTags: {
    mcfit: {
      TSP: 'tsp',
      Mitarbeiter: 'intern'
    },
    johnreed: {
      TSP: 'tsp',
      JohnAndJane: 'jj',
      Mitarbeiter: 'intern'
    },
    high5: {
      TSP: 'tsp',
      Mitarbeiter: 'intern'
    }
  },
  italianProvinces: [
    'AG',
    'AL',
    'AN',
    'AO',
    'AR',
    'AP',
    'AT',
    'AV',
    'BA',
    'BT',
    'BL',
    'BN',
    'BG',
    'BI',
    'BO',
    'BS',
    'BR',
    'CA',
    'CL',
    'CB',
    'CE',
    'CT',
    'CZ',
    'CH',
    'CO',
    'CS',
    'CR',
    'KR',
    'CN',
    'EN',
    'FM',
    'FE',
    'FI',
    'FG',
    'FC',
    'FR',
    'GE',
    'GO',
    'GR',
    'IM',
    'IS',
    'SP',
    'AQ',
    'LT',
    'LE',
    'LC',
    'LI',
    'LO',
    'LU',
    'MC',
    'MN',
    'MS',
    'MT',
    'ME',
    'MI',
    'MO',
    'MB',
    'NA',
    'NO',
    'NU',
    'OR',
    'PD',
    'PA',
    'PR',
    'PV',
    'PG',
    'PU',
    'PE',
    'PC',
    'PI',
    'PT',
    'PN',
    'PZ',
    'PO',
    'RG',
    'RA',
    'RC',
    'RE',
    'RI',
    'RN',
    'RM',
    'RO',
    'SA',
    'SS',
    'SV',
    'SI',
    'SO',
    'SU',
    'BZ',
    'SR',
    'TA',
    'TE',
    'TR',
    'TP',
    'TN',
    'TV',
    'TS',
    'TO',
    'UD',
    'VA',
    'VE',
    'VB',
    'VC',
    'VR',
    'VV',
    'VI',
    'VT'
  ].sort(),
  firmenFitnessContracts: [
    5338300160,
    5720711920,
    5835107242,
    5996404530,
    5338304300,
    5835093910,
    5835093910,
    6487457270,
    6498030740,
    5276241500,
    2037803501,
    6141332230,
    5996445540,
    6176531480,
    1608431535,
    34666191940,
    5276244070,
    3066920391,
    6498030740,
    5426083271,
    6498030740,
    5395663290,
    5835153221,
    6487457270,
    6487457270,
    6487463321,
    6487457270,
    6487457270,
    1672835831,
    6498030740,
    1608482690,
    4582704950,
    6487457270,
    5046453030,
    6402410060,
    5618473290,
    5720711921,
    3379183730,
    3319686240,
    5338300161,
    5720649150,
    5835107243,
    5996435390,
    5338307870,
    5835107241,
    5835107241,
    6487432690,
    6498025360,
    5276243140,
    41437533520,
    6141327540,
    5996457300,
    6176526250,
    4300255020,
    3466188390,
    5276241501,
    4580373580,
    6498025360,
    5426080850,
    6498025360,
    5395675891,
    1672837191,
    6487432690,
    6487432690,
    3208144950,
    6487457271,
    6487432690,
    6487432690,
    3901465120,
    6498025360,
    4122814061,
    4582694150,
    6487432690,
    5046453031,
    6402418670,
    5618413370,
    5720707302,
    1672838110,
    3379183731,
    3318281301
  ]
};

export const configStage = {
  contractTags: {
    mcfit: {
      TSP: 'tsp',
      Mitarbeiter: 'intern'
    },
    johnreed: {
      TSP: 'tsp',
      JohnAndJane: 'jj',
      Mitarbeiter: 'intern'
    },
    high5: {
      TSP: 'tsp',
      Mitarbeiter: 'intern'
    }
  }
};

export default config;
