import styled, { css } from 'styled-components/macro';

import StyledSelect from './StyledSelect';
import { media } from '../../../lib/styledMixins';
import { inputStyles } from './InputField';

const InputGroupWrapper = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
  ${props =>
    props.margin !== undefined
      ? css`
          margin-bottom: ${props.margin};
        `
      : css`
          margin-bottom: 1.3em;
        `}

  ${props =>
    props.type === 'select' &&
    css`
      display: flex;
      flex-direction: column;
      position: relative;

      ${props =>
        props.width === 'thirds' &&
        css`
          flex-basis: 31%;
          flex-grow: 1;

          ${media.xl`
          flex-grow: ${props.width === 'thirds' ? '0' : '1'};
        `};
        `}

      ${props =>
        props.width === 'small' &&
        css`
          ${media.md`
        flex-basis: 24%;
      `};
        `};

      ${props =>
        props.width === 'middle' &&
        css`
          ${media.md`
        flex-basis: 73%;
      `};
        `};

      ${StyledSelect} {
        padding-right: 5rem;
      }
    `};

  ${props =>
    props.type === 'radio' &&
    css`
      display: flex;
      flex-direction: column;

      ${media.md`
        flex-direction: row;
        flex-wrap: nowrap;
      `};
    `};

  ${props =>
    props.type === 'number' &&
    css`
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `};

  ${props =>
    props.width === 'small' &&
    css`
      ${media.md`
        flex-basis: 24%;
        flex-grow: 1;
      `};
    `};

  ${props =>
    props.width === 'middle' &&
    css`
      ${media.md`
        flex-basis: 73%;
      `};
    `};

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;

    input {
      ${props => inputStyles(props)};
      border-bottom: ${props =>
        props.error ? `4px solid ${props.theme.alert}` : '0'};
      width: 100%;
    }
  }
`;

export default InputGroupWrapper;
