import React from 'react';
import styled from 'styled-components/macro';

import { AccordionContext } from '../Accordion'

const AccordionItem = styled.div`
  position: relative;
  height: ${props => props.open ? 'auto' : '60px'};
  overflow: hidden;
  max-height: 3000px;
  padding-left: 48px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 2px #e84160;
  }

  &::after {
    display: ${props => props.open ? 'block' : 'none'};
    content: '';
    position: absolute;
    left: 4px;
    top: 24px;
    height: 12px;
    width: 12px;
    background-color: #e84160;
    border-radius: 50%;
  }
`

export const AccordionItemHeader = styled.div`
	align-items: center;
	color: #00112c;
	display: flex;
	flex-wrap: nowrap;
	font-size: 1em;
	font-weight: 400;
	justify-content: space-between;
	padding: 16px 0 16px 0;
	position: relative;
	transition: background .1s ease-out;
	width: 100%;
  height: 60px;
  cursor: pointer;
`

const Item: React.FC<{ index: number }> = ({ children, index }) => {
  const { openIndex, setOpenIndex, onSelect } = React.useContext(AccordionContext)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (openIndex === index){
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [openIndex, index])

  return (
    <AccordionItem onClick={(e) => {
      setOpenIndex(index)
      onSelect(e, index)
    }} open={open}>
      {children}
    </AccordionItem>
  )
}

export default Item