import uuid from 'uuid/v4';

import { displayMessage } from '../messages';
import config from '../../config';

export const FETCH_FOTO_UPLOAD_URL_REQUEST =
  'data/foto/FETCH_FOTO_UPLOAD_URL_REQUEST';
export const FETCH_FOTO_UPLOAD_URL_FAILURE =
  'data/foto/FETCH_FOTO_UPLOAD_URL_FAILURE';
export const FETCH_FOTO_UPLOAD_URL_SUCCESS =
  'data/foto/FETCH_FOTO_UPLOAD_URL_SUCCESS';
export const UPLOAD_FOTO_REQUEST = 'data/foto/UPLOAD_FOTO_REQUEST';
export const UPLOAD_FOTO_FAILURE = 'data/foto/UPLOAD_FOTO_FAILURE';
export const UPLOAD_FOTO_SUCCESS = 'data/foto/UPLOAD_FOTO_SUCCESS';
export const UPLOAD_FOTO_RESET = 'data/foto/UPLOAD_FOTO_RESET';
export const FOTO_SIZE_VALIDATION_FAILURE =
  'data/foto/FOTO_SIZE_VALIDATION_FAILURE';
export const FOTO_SIZE_VALIDATION_SUCCESS =
  'data/foto/FOTO_SIZE_VALIDATION_SUCCESS';
export const FOTO_SIZE_VALIDATION_RESET =
  'data/foto/FOTO_SIZE_VALIDATION_RESET';

const FIVE_MEGABYTES = 5000000;

const { api } = config;

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

function fetchFotoUploadUrlRequest() {
  return {
    type: FETCH_FOTO_UPLOAD_URL_REQUEST
  };
}

function fetchFotoUploadUrlFailure(payload) {
  return {
    type: FETCH_FOTO_UPLOAD_URL_FAILURE,
    payload
  };
}

function fetchFotoUploadUrlSuccess(payload) {
  return {
    type: FETCH_FOTO_UPLOAD_URL_SUCCESS,
    payload
  };
}

function uploadFotoFailure(payload) {
  return {
    type: UPLOAD_FOTO_FAILURE,
    payload
  };
}

function uploadFotoSuccess(payload) {
  return {
    type: UPLOAD_FOTO_SUCCESS,
    payload
  };
}

function uploadFotoRequest(payload) {
  return {
    type: UPLOAD_FOTO_REQUEST,
    payload
  };
}

function uploadFotoReset() {
  return {
    type: UPLOAD_FOTO_RESET
  };
}

function fotoSizeValidationFailure() {
  console.log('validation failed');
  return {
    type: FOTO_SIZE_VALIDATION_FAILURE
  };
}

function fotoSizeValidationSuccess() {
  return {
    type: FOTO_SIZE_VALIDATION_SUCCESS
  };
}

function fotoSizeValidationReset() {
  return {
    type: FOTO_SIZE_VALIDATION_RESET
  };
}

export function resetFotoUpload() {
  return dispatch => dispatch(uploadFotoReset());
}

export function fetchFotoUploadUrl() {
  return async dispatch => {
    function onError(error) {
      dispatch(fetchFotoUploadUrlFailure(error));
      dispatch(
        displayMessage(
          {
            text: 'messages.uploadFotoFailure',
            type: 'alert'
          },
          uuid()
        )
      );
      return error;
    }

    function onSuccess(payload) {
      if (!payload) {
        return onError(payload);
      }
      dispatch(fetchFotoUploadUrlSuccess(payload));
      return payload;
    }

    dispatch(fetchFotoUploadUrlRequest);

    try {
      const res = await (await fetch(`${api}/foto/uploadurl`)).json();
      return onSuccess(res.data);
    } catch (error) {
      return onError(error);
    }
  };
}

export function uploadFotoToUrl(foto, url) {
  return async dispatch => {
    function onError(error) {
      dispatch(uploadFotoFailure(error));

      dispatch(
        displayMessage(
          {
            text: 'messages.uploadFotoFailure',
            type: 'alert'
          },
          uuid()
        )
      );
      return error;
    }

    function onSuccess() {
      dispatch(uploadFotoSuccess());
      return;
    }

    dispatch(uploadFotoRequest());
    const blob = dataURItoBlob(foto);
    try {
      await fetch(`${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': blob.type,
          'Content-Length': blob.size
        },
        body: blob
      });

      return onSuccess();
    } catch (error) {
      console.log({ error });
      return onError(error);
    }
  };
}

export function validateFileSize(file) {
  let isValid;
  return async dispatch => {
    if (!file) {
      return;
    }

    if (file.size > FIVE_MEGABYTES) {
      dispatch(fotoSizeValidationFailure());
      return isValid;
    }

    isValid = true;
    dispatch(fotoSizeValidationSuccess());
    return isValid;
  };
}

export function resetFotoValidation() {
  return dispatch => dispatch(fotoSizeValidationReset());
}

export default function reducer(
  state = {
    fetchingUrl: false,
    uploadUrl: '',
    objectKey: '',
    fotoSizeError: undefined,
    sendingFoto: false
  },
  action
) {
  switch (action.type) {
    case FETCH_FOTO_UPLOAD_URL_REQUEST:
      return {
        ...state,
        fetchingUrl: true
      };
    case FETCH_FOTO_UPLOAD_URL_SUCCESS:
      return {
        ...state,
        fetchingUrl: false,
        uploadUrl: action.payload.uploadUrl,
        objectKey: action.payload.objectKey
      };
    case UPLOAD_FOTO_REQUEST:
      return {
        ...state,
        sendingFoto: true
      };
    case UPLOAD_FOTO_SUCCESS:
      return {
        ...state,
        sendingFoto: false
      };
    case UPLOAD_FOTO_FAILURE:
      return {
        ...state,
        sendingFoto: false
      };
    case UPLOAD_FOTO_RESET:
      return {
        ...state,
        uploadUrl: '',
        objectKey: ''
      };
    case FOTO_SIZE_VALIDATION_FAILURE:
      return {
        ...state,
        fotoSizeError: true
      };
    case FOTO_SIZE_VALIDATION_SUCCESS:
      return {
        ...state,
        fotoSizeError: false
      };
    case FOTO_SIZE_VALIDATION_RESET:
      return {
        ...state,
        fotoSizeError: undefined
      };
    default:
      return {
        ...state,
        fetchingUrl: false,
        sendingFoto: false
      };
  }
}
