import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import InputGroupWrapper from './InputGroupWrapper';
import InputField from './InputField';
import Label from './Label';
import InlineSpinner from './InlineSpinner';
import ErrorMessage from './ErrorMessage';

const GenericInput = ({
  error,
  hide,
  label,
  name,
  required,
  uppercase,
  value,
  width,
  fetching,
  ...rest
}) => (
  <InputGroupWrapper width={width}>
    <InputField
      error={error}
      fetching={fetching}
      id={name}
      name={name}
      placeholder={required ? `${label || name}*` : label || name}
      required={required}
      value={uppercase ? value.toUpperCase() : value}
      {...rest}
    />
    <Label htmlFor={name} hide={hide}>
      {label || name}
    </Label>
    {name === 'voucher' && error !== 'false' ? (
      <div>
        <FormattedMessage
          id="bringafriend.invalid"
          defaultMessage="Falscher Code"
        />
      </div>
    ) : null}
    {fetching ? <InlineSpinner /> : null}
    {rest.touched && error && error !== 'false' ? (
      <ErrorMessage>
        <FormattedMessage id={error} />
      </ErrorMessage>
    ) : null}
  </InputGroupWrapper>
);

GenericInput.propTypes = {
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fetching: PropTypes.bool,
  hide: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  type: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.string
};

GenericInput.defaultProps = {
  autocomplete: 'on',
  disabled: false,
  error: '',
  fetching: false,
  hide: true,
  label: '',
  options: [],
  required: false,
  touched: false,
  uppercase: false,
  value: '',
  width: 'full'
};

export default injectIntl(GenericInput);
