import { displayAutoCloseMessage } from '../messages';
import themes from '../../themes';

export const FETCH_THEME_REQUEST = 'data/theme/FETCH_THEME_REQUEST';
export const FETCH_THEME_SUCCESS = 'data/theme/FETCH_THEME_SUCCESS';
export const FETCH_THEME_FAILURE = 'data/theme/FETCH_THEME_FAILURE';

/* ACTIONS */
function fetchThemeRequest() {
  return {
    type: FETCH_THEME_REQUEST
  };
}

function fetchThemeSuccess(payload) {
  return {
    type: FETCH_THEME_SUCCESS,
    payload
  };
}

export function fetchTheme(themeName) {
  return async dispatch => {
    function onSuccess(payload) {
      dispatch(fetchThemeSuccess(payload));
      if (document.title.indexOf('Checkout') === -1) {
        document.title = payload.variables.title;
      }
      return payload;
    }

    function onError(error) {
      dispatch(
        displayAutoCloseMessage({
          text: 'Error fetching theme.',
          type: 'alert',
          description: error
        })
      );
      return error;
    }

    dispatch(fetchThemeRequest());

    try {
      // eslint-disable-next-line max-len
      const variables = themes[themeName];

      return onSuccess({ variables, themeName });
    } catch (error) {
      return onError(error);
    }
  };
}

/* REDUCER */

export default function reducer(
  state = {
    fetching: false,
    variables: {},
    themeName: ''
  },
  action
) {
  switch (action.type) {
    case FETCH_THEME_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case FETCH_THEME_SUCCESS:
      return {
        fetching: false,
        ...action.payload
      };
    default:
      return state;
  }
}
