import styled from 'styled-components/macro';

export default styled.div`
  background-color: ${props => props.theme.cardBg};
  box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  padding: ${props => (props.noPadding ? '0' : '2.6em')};
  position: relative;
`;
