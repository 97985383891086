import { displayAutoCloseMessage } from '../messages';
import config from '../../config';

export const CONTACTFORM_REQUEST = 'data/contact/CONTACTFORM_REQUEST';
export const CONTACTFORM_FAILURE = 'data/contact/CONTACTFORM_FAILURE';
export const CONTACTFORM_SUCCESS = 'data/contact/CONTACTFORM_SUCCESS';
export const TOGGLE_CONTACTFORM = 'data/contact/TOGGLE_CONTACTFORM';

const { api } = config;

function contactFormRequest() {
  return {
    type: CONTACTFORM_REQUEST
  };
}

function contacFormFailure() {
  return {
    type: CONTACTFORM_FAILURE
  };
}

function contacFormSuccess() {
  return {
    type: CONTACTFORM_SUCCESS
  };
}

export function toggleContactForm() {
  return {
    type: TOGGLE_CONTACTFORM
  };
}

export function sendContactForm(data) {
  return async dispatch => {
    function onError(error) {
      dispatch(contacFormFailure());
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.contactFormError',
          type: 'alert'
        })
      );
      return error;
    }

    function onSuccess(payload) {
      if (payload.success) {
        dispatch(contacFormSuccess());
        dispatch(
          displayAutoCloseMessage({
            text: 'messages.contactFormSuccess',
            type: 'success'
          })
        );
      } else {
        onError(payload);
      }
      return payload;
    }

    dispatch(contactFormRequest());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });
      const response = await (await fetch(`${api}/contact`, {
        credentials: 'include',
        headers,
        method: 'POST',
        body: JSON.stringify(data)
      })).json();

      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export default function reducer(
  state = { sending: false, open: false },
  action
) {
  switch (action.type) {
    case CONTACTFORM_REQUEST:
      return {
        ...state,
        sending: true
      };
    case CONTACTFORM_FAILURE:
      return {
        ...state,
        sending: false
      };
    case CONTACTFORM_SUCCESS:
      return {
        ...state,
        sending: false,
        open: false
      };
    case TOGGLE_CONTACTFORM:
      return {
        ...state,
        open: !state.open
      };
    default:
      return state;
  }
}
