import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Content from '../../../../components/Content/Content';
import Headline from '../../../../components/Headline/Headline';
import Subheadline from '../../../../components/Subheadline/Subheadline';
import Description from '../../../../components/Description/Description';
import MemberNumber from './components/MemberNumber';

const CONTRACT_IDS_WITHOUT_APPS = [
  6009573461 // Beach Body Flex
];

class Success extends Component {
  static propTypes = {
    memberNumber: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
      .isRequired,
    brand: PropTypes.string.isRequired,
    studioId: PropTypes.string.isRequired,
    contractId: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    contractPrice: PropTypes.string.isRequired,
    tsp: PropTypes.object.isRequired
  };

  hashCode = str => {
    return Array.from(str).reduce(
      (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
      0
    );
  };

  componentDidMount() {
    const {
      studioId,
      contractId,
      memberNumber,
      email,
      countryCode,
      contractPrice
    } = this.props;

    if (!memberNumber) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        studioId,
        contractId,
        orderId: memberNumber,
        countryCode
      }
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'successTrigger',
        e_mail: this.hashCode(email),
        revenue: contractPrice
      }
    });

    TagManager.dataLayer({
      dataLayer: {
        user_email: email
      }
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'PURCHASE'
      }
    });
  }

  render() {
    const {
      memberNumber,
      brand,
      tsp,
      countryCode,
      isFlex,
      contractId
    } = this.props;

    return memberNumber ? (
      <Content>
        <Breadcrumb step={4} />
        <Headline noMargin>
          <FormattedHTMLMessage
            id={`welcome.headline.${brand}`}
            defaultMessage="Vielen Dank!"
          />
        </Headline>
        {memberNumber === 'ml-ghost' ? (
          <Description>
            <FormattedMessage
              id={`welcome.description.ML`}
              defaultMessage="Deine Anmeldung wird bearbeitet. Du erhälst in Kürze eine Bestätigungs-mail von uns."
            />
          </Description>
        ) : (
          <>
            {countryCode !== 'DE' &&
            countryCode !== 'AT' &&
            countryCode !== 'ES' &&
            countryCode !== 'IT' ? (
              <MemberNumber />
            ) : null}
            <Description>
              {brand === 'mcfit' &&
              CONTRACT_IDS_WITHOUT_APPS.includes(contractId) ? (
                <FormattedHTMLMessage
                  id={`welcome.description.mcfit.noApps`}
                  defaultMessage="Deine Anmeldung war im ersten Schritt erfolgreich. Um deine Registrierung abzuschließen erhältst du in Kürze eine E-Mail von uns mit dem Bestätigungslink."
                />
              ) : (
                <FormattedHTMLMessage
                  id={`welcome.description.${brand}`}
                  defaultMessage="Deine Anmeldung war im ersten Schritt erfolgreich. Um deine Registrierung abzuschließen erhältst du in Kürze eine E-Mail von uns mit dem Bestätigungslink."
                />
              )}
            </Description>
            {tsp && (countryCode === 'DE' || countryCode === 'AT') && (
              <Description>
                <FormattedMessage id={`welcome.description.add`} />
              </Description>
            )}
            {countryCode === 'IT' && isFlex && (
              <Description>
                Via email riceverai anche le informazioni per registrarti alla
                tua area personale McFIT. Effettua l’accesso per eseguire il
                primo pagamento e iniziare subito ad allenarti!
              </Description>
            )}
            <Subheadline>
              <FormattedMessage
                id={`welcome.subheadline.${brand}`}
                defaultMessage="Viel Spaß!"
              />
            </Subheadline>
          </>
        )}
      </Content>
    ) : (
      <Redirect to="/checkout" />
    );
  }
}

const mapStateToProps = state => ({
  memberNumber:
    state.data.checkout.memberNumber &&
    state.data.checkout.selectedStudio &&
    state.data.checkout.success
      ? state.data.checkout.memberNumber
      : false,
  email: state.data.checkout.email,
  brand: state.data.theme.themeName,
  contractId: state.data.contracts.selectedContract.Id,
  contractPrice: state.data.contracts.selectedContract.Price,
  countryCode: state.data.contracts.selectedContract.CountryCode,
  studioId: state.data.checkout.selectedStudio.StudioMark,
  tsp: state.data.contracts.selectedContract.tsp,
  isFlex: state.data.contracts.selectedContract.initialPaymentRequired
});

export default injectIntl(connect(mapStateToProps)(Success));
