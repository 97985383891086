import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MessageListWrapper from './components/MessageListWrapper';
import Message from './components/Message/Message';

const MessageList = props => (
  <MessageListWrapper show={props.messages.length}>
    {props.messages.map(message => (
      <Message key={message.id} {...message} />
    ))}
  </MessageListWrapper>
);

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      closeButton: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = state => ({
  messages: state.data.messages
});

export default connect(mapStateToProps)(MessageList);
