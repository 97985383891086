import React from 'react';
import PropTypes from 'prop-types';

import InputGroupWrapper from '../InputGroupWrapper';
import Label from '../Label';
import InputField from '../InputField';
import StyledCheckbox from '../../../CheckboxInput/StyledCheckbox';
import RadioWrapper from './RadioWrapper';

const RadioInput = ({
  type,
  touched,
  error,
  value,
  onBlur,
  onChange,
  required,
  name,
  autocomplete,
  options,
  ...props
}) => (
  <InputGroupWrapper type={type} touched={touched} error={error}>
    {options.map(option => (
      <RadioWrapper key={option.name} {...props}>
        <InputField
          checked={value === option.value}
          id={option.name}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          type={type}
          name={name}
          value={option.value}
          autoComplete={autocomplete}
        />
        <Label htmlFor={option.name} type={type}>
          <StyledCheckbox checked={value === option.value} />
          {required ? `${option.name}*` : option.name}
        </Label>
      </RadioWrapper>
    ))}
  </InputGroupWrapper>
);

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // eslint-disable-next-line
  options: PropTypes.array.isRequired,
  autocomplete: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string
};

RadioInput.defaultProps = {
  autocomplete: 'on',
  required: false,
  value: '',
  touched: false,
  error: 'false'
};

export default RadioInput;
