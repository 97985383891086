import styled, { css } from 'styled-components/macro';

import { media } from '../../../../../../lib/styledMixins';

const ContractsWrapper = styled.div`
  flex-wrap: wrap;
  margin: 2em 0;
  ${props =>
    props.contractsLength < 4 &&
    css`
      display: flex;
    `};

  ${media.md`
    margin: 0 0 4rem;
  `};

  ${media.lg`
    .slider-list {
      margin-bottom: 40px !important;
    }
  `}

  .slider-control-bottomcenter {
    position: relative !important;
    top: -2rem;
    width: 100%;
    text-align: center;
  }
`;

export default ContractsWrapper;
