import de from './de';
import it from './it';
import es from './es';
import fr from './fr';
import nl from './nl';
import en from './en';

const at = de;

export const i18n = {
  de,
  it,
  es,
  fr,
  nl,
  en
};

export const validTrackingLanguages = {
  de,
  it,
  es,
  at,
  fr,
  en
};

export default i18n;
