export default function determineBrand(location) {
  const { hostname } = location;
  const parts = hostname.split('.');
  const brand = parts[parts.length - 2];

  // return 'mcfit' when on localhost or staging
  if (parts.length === 1 && parts[0] === 'localhost') {
    return 'mcfit';
  }

  switch (brand) {
    case 'johnreed':
    case 'high5':
    case 'mcfit':
      return brand;
    default:
      return 'mcfit';
  }
}
