import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import { Formik } from 'formik';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import CheckboxGroup from '../CheckboxGroup';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormAside from '../../../../../../../../components/FormAside/FormAside';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputGroupWrapper from '../../../../../../../../components/InputGroup/components/InputGroupWrapper';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import LegalWrapper from '../LegalWrapper';
import SelectedContract from '../../../SelectedContract';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { schemaML, flexSchema } from './contractFormSchemaNL';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';
import config, { configStage } from '../../../../../../../../config';

export class ContractFormNL extends Component {
  static propTypes = {
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  state = {
    lastCheckedIBAN: ''
  };

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  timer;

  handleMarketingLinks = brand => {
    switch (brand) {
      case 'mcfit':
        return 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/';
      case 'johnreed':
        return 'https://johnreed.fitness/nl/footer-navigation/privacyverklaring';
      case 'high5':
        return 'https://www.high5.com/footer-navigation/impressum/#datenschutz';
      default:
        return 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/';
    }
  };

  /*
  Returns special AGB links for specific contracts and brands
  */
  handelAGBLinks = brand => {
    switch (brand) {
      case 'johnreed':
        return 'https://johnreed.fitness/nl/footer-navigation-agb';
      default:
        return 'https://www.mcfit.com/de/ueber-mcfitcom/agb/agb/';
    }
  };

  render() {
    const contractTags =
      window.location.host.match(/stage/) ||
      process.env.NODE_ENV === 'development'
        ? configStage.contractTags
        : config.contractTags;
    const { brand, selectedStudioOpening } = this.props;
    const {
      Id,
      CountryCode,
      tsp,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate
    } = this.props.selectedContract;
    const hasTSP = tsp;
    const isFlexContract =
      tags.length &&
      tags.some(tag => tag.identifier === contractTags[brand]['Flex']);
    const schema = isFlexContract ? flexSchema : schemaML;

    return (
      <Formik
        onSubmit={(
          {
            AccountPassword,
            City,
            Email,
            FirstName,
            Gender,
            HouseNumber,
            IBAN,
            LastName,
            Marketing,
            Phone,
            PostalCode,
            ProudClubNewsletter,
            Street,
            StudioMark,
            year,
            month,
            day,
            signature,
            ReferrerType,
            hasPremium,
            selectedStudioOpening,
            referralCode
          },
          actions
        ) => {
          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing,
              Phone,
              PostalCode,
              ProudClub: Marketing,
              ProudClubNewsletter,
              Street,
              ReferrerType
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date()
          };

          this.props.handleSubmit(data, actions);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          CountryCode,
          brand,
          hasPremium,
          referralCode: this.props.referralCode
        }}
        validationSchema={schema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) =>
          this.props.success ? (
            <Redirect
              to={`/checkout${this.props.intl.formatMessage({
                id: 'route.success'
              })}`}
            />
          ) : (
            <FormWrapper onSubmit={handleSubmit}>
              <FormSidebar>
                <FormGroup>
                  <FormGroupHeadlineWrap>
                    <FormGroupHeadline>Accountgegevens</FormGroupHeadline>
                    <RequiredNote>*verplichte velden</RequiredNote>
                  </FormGroupHeadlineWrap>
                  <InputGroup
                    autoComplete="email"
                    error={errors.Email}
                    label="Email"
                    name="Email"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Email}
                    type="email"
                    value={values.Email}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Persoonlijke gegevens</FormGroupHeadline>
                  <InputGroup
                    autoComplete="sex"
                    error={errors.Gender}
                    label="Geschlecht"
                    name="Gender"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={[
                      { name: 'Man', value: 'M' },
                      { name: 'Vrouw', value: 'W' }
                    ]}
                    required
                    touched={touched.Gender}
                    type="radio"
                    value={values.Gender}
                  />
                  {errors && touched && errors.gender && touched.gender ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  <InputGroup
                    autoComplete="given-name"
                    error={errors.FirstName}
                    label="Voornaam"
                    name="FirstName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.FirstName}
                    type="text"
                    value={values.FirstName}
                  />
                  <InputGroup
                    autoComplete="family-name"
                    error={errors.LastName}
                    label="Achternaam"
                    name="LastName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.LastName}
                    type="text"
                    value={values.LastName}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Geboorte datum</FormGroupHeadline>
                  <FormDescription as="div">
                    U moet minimaal 18 jaar oud zijn om online een contract te
                    kunnen ondertekenen.
                  </FormDescription>
                  <InputGroup
                    error={errors.day}
                    label="Dag"
                    name="day"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 31 }, (v, k) => ++k)}
                    required
                    touched={touched.day}
                    type="select"
                    value={values.day}
                    width="thirds"
                  />
                  <InputGroup
                    error={errors.month}
                    label="Maand"
                    name="month"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 12 }, (v, k) => ++k)}
                    required
                    touched={touched.month}
                    type="select"
                    value={values.month}
                    width="thirds"
                  />
                  <InputGroup
                    error={errors.year}
                    label="Jaar"
                    name="year"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={Array.from(
                      { length: 83 },
                      // eslint-disable-next-line no-return-assign,no-param-reassign
                      (v, k) => (k += getYear(subYears(Date.now(), 100)))
                    ).reverse()}
                    required
                    touched={touched.year}
                    type="select"
                    value={values.year}
                    width="thirds"
                  />
                  {errors &&
                  touched &&
                  ((errors.day && touched.day) ||
                    (errors.month && touched.month) ||
                    (errors.year && touched.year)) ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  {errors && errors.olderThan18 ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.birthday" />
                    </ErrorMessage>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Contact details</FormGroupHeadline>
                  <InputGroup
                    autoComplete="address-line1"
                    error={errors.Street}
                    label="Straat"
                    name="Street"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Street}
                    type="text"
                    value={values.Street}
                    width="middle"
                  />
                  <InputGroup
                    error={errors.HouseNumber}
                    label="Nummer"
                    name="HouseNumber"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.HouseNumber}
                    type="text"
                    value={values.HouseNumber}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="postal-code"
                    error={errors.PostalCode}
                    label="Postcode"
                    name="PostalCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.PostalCode}
                    type="text"
                    value={values.PostalCode}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="locality"
                    error={errors.City}
                    label="Plaats"
                    name="City"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.City}
                    type="text"
                    value={values.City}
                    width="middle"
                  />
                  <InputGroup
                    autoComplete="tel"
                    error={errors.Phone}
                    label="Telefoonnummer"
                    name="Phone"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.Phone}
                    type="tel"
                    value={values.Phone}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>
                    <FormattedMessage
                      id="checkout.referralCode"
                      defaultMessage="Referral Code"
                    />
                  </FormGroupHeadline>
                  <InputGroup
                    error={errors.referralCode}
                    label={this.props.intl.formatMessage({
                      id: 'checkout.referralCode.placeholder'
                    })}
                    name="referralCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.referralCode}
                    type="text"
                    value={values.referralCode}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Bankgegevens</FormGroupHeadline>
                  <FormDescription as="div">
                    De rekeninghouder moet identiek zijn aan de aanvrager. Als
                    dit niet het geval is ga dan naar een van onze studio’s om
                    het contract te ondertekenen.
                  </FormDescription>
                  <InputGroup
                    autoComplete="name"
                    error={errors.AccountHolder}
                    label="Rekeninghouder"
                    name="AccountHolder"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    readOnly
                    required
                    touched={touched.AccountHolder}
                    type="text"
                    value={`${values.FirstName ||
                      'REKENINGHOUDER'} ${values.LastName || ''}`}
                  />
                  <InputGroup
                    error={!this.props.BIC && 'checkout.ibanError'}
                    fetching={this.props.fetchingIBAN}
                    label="IBAN"
                    name="IBAN"
                    onBlur={event => {
                      const { value } = event.target;
                      handleBlur(event);
                      handleChange(event);
                      this.handleVerifyIBAN(value);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.IBAN}
                    type="text"
                    uppercase
                    value={values.IBAN}
                  />
                  <FormGroup>
                    <InputField
                      autoComplete="off"
                      id="SEPA"
                      name="SEPA"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.SEPA}
                      type="checkbox"
                      value={values.SEPA}
                    />
                    <Label htmlFor="SEPA" type="checkbox" hide={false}>
                      <StyledCheckbox
                        checkMark
                        checked={values.SEPA}
                        error={errors.SEPA}
                      />
                      <CheckboxText>
                        * Ik machtig de RSG Group Netherlands C.V. of haar
                        rechtsopvolgers om betalingen van mijn rekening te
                        incasseren via automatische incasso. Tegelijkertijd geef
                        ik mijn bank opdracht om de door de RSG Group
                        Netherlands C.V. op mijn rekening opgenomen
                        domiciliering terug te betalen.
                      </CheckboxText>
                    </Label>
                  </FormGroup>
                  {!isFlexContract ? (
                    <>
                      <InputGroupWrapper width="full" type="checkbox">
                        <InputField
                          autoComplete="off"
                          id="AGB2"
                          onBlur={event => {
                            handleBlur(event);
                            handleChange(event);
                          }}
                          onChange={handleChange}
                          required
                          touched={touched.AGB2}
                          type="checkbox"
                          value={values.AGB2}
                        />
                        <Label htmlFor="AGB2" type="checkbox" hide={false}>
                          <StyledCheckbox
                            checkMark
                            checked={values.AGB2}
                            error={errors.AGB2}
                          />
                          <CheckboxText>
                            * Ik ga hierbij akkoord met het terugkerende
                            trainings-en servicetarief van{' '}
                            {this.props.selectedContract.tspCharges || 0},00€**
                            en de vergoeding voor het activeren van de
                            membercard van{' '}
                            {this.props.selectedContract.CardCharges || 0},00**.
                          </CheckboxText>
                        </Label>
                      </InputGroupWrapper>
                    </>
                  ) : null}
                  <CheckboxGroup>
                    {isFlexContract ? (
                      <>
                        <InputGroupWrapper width="full" type="checkbox">
                          <InputField
                            autoComplete="off"
                            id="acceptedTSP"
                            onBlur={event => {
                              handleBlur(event);
                              handleChange(event);
                            }}
                            onChange={handleChange}
                            required
                            touched={touched.acceptedTSP}
                            type="checkbox"
                            value={values.acceptedTSP}
                          />
                          <Label
                            htmlFor="acceptedTSP"
                            type="checkbox"
                            hide={false}
                          >
                            <StyledCheckbox
                              checkMark
                              checked={values.acceptedTSP}
                              error={errors.acceptedTSP}
                            />
                            <CheckboxText>
                              * Ik ga hierbij akkoord met het terugkerende
                              trainings-en servicetarief van{' '}
                              {this.props.selectedContract.tspCharges || 0}
                              ,00€** .
                            </CheckboxText>
                          </Label>
                        </InputGroupWrapper>
                      </>
                    ) : null}

                    <InputGroupWrapper width="full" type="checkbox">
                      <InputField
                        autoComplete="off"
                        id="Marketing"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Marketing}
                        type="checkbox"
                        value={values.Marketing}
                      />
                      <Label htmlFor="Marketing" type="checkbox" hide={false}>
                        <StyledCheckbox checkMark checked={values.Marketing} />
                        <CheckboxText>
                          Ik wil graag informatie ontvangen over fitness,
                          training, voeding en levensstijl van RSG Group GmbH.
                          Ik heb de informatie over de nieuwsbrief in{' '}
                          <a
                            href={this.handleMarketingLinks(brand)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            onze privacybeleid
                          </a>{' '}
                          gelezen en ga hiermee akkoord.
                        </CheckboxText>
                      </Label>
                    </InputGroupWrapper>
                  </CheckboxGroup>
                  <FormGroup>
                    <FormDescription style={{ color: '#242f3a' }}>
                      <strong>
                        Onze{' '}
                        <a
                          href={this.handelAGBLinks(brand)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          algemene voorwaarden
                        </a>{' '}
                        zijn van toepassing. Let ook op het{' '}
                        <a
                          href={
                            'https://johnreed.fitness/nl/footer-navigation/herroepingsrecht'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          annuleringsbeleid
                        </a>{' '}
                        en ons{' '}
                        <a
                          href={this.handleMarketingLinks(brand)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacybeleid
                        </a>
                        .
                      </strong>
                    </FormDescription>
                  </FormGroup>
                  <CTAButton
                    type="submit"
                    className="mcfit-tracking-checkout-3"
                    disabled={isSubmitting}
                  >
                    {this.props.sending
                      ? 'Aan het verzenden'
                      : 'Betalen voor bestelling'}
                  </CTAButton>
                  <LegalWrapper>
                    ** Alle vermelde prijzen zijn inclusief omzetbelasting
                    Prijzen en openingstijdenkunnen per studio verschillen
                    Personen onder de 15 jaar kunnen geen lid worden. Aanbieder:
                    RSG Group Netherlands, C.V. Lichttoren 32, 5611 BJ
                    Eindhoven, Nederland.
                  </LegalWrapper>
                </FormGroup>
              </FormSidebar>
              <FormAside>
                <SelectedContract
                  button={false}
                  sidebar
                  {...this.props.selectedContract}
                  highlight={false}
                />
              </FormAside>
            </FormWrapper>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: async (data, actions) => {
    await dispatch(sendData(data));
    actions.setSubmitting(false);
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractFormNL))
);
