import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import { Formik } from 'formik';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import CheckboxGroup from '../CheckboxGroup';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormAside from '../../../../../../../../components/FormAside/FormAside';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputGroupWrapper from '../../../../../../../../components/InputGroup/components/InputGroupWrapper';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import LegalWrapper from '../LegalWrapper';
import SelectedContract from '../../../SelectedContract';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import SignaturePad from '../../../../../../../../components/SignaturePad/SignaturePad';
import { schemaML } from './contractFormSchemaES';
import { reffererTypeOptions } from './formOptions';
import {
  fetchReferrals,
  sendData
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';

export class ContractFormES extends Component {
  static propTypes = {
    BIC: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  timer;

  state = {
    lastCheckedIBAN: ''
  };

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  render() {
    const { selectedStudioOpening, member, brand } = this.props;
    const {
      termId,
      defaultContractStartDate,
      preuse,
      isFebCampaign,
      isUpsell
    } = this.props.selectedContract;

    return (
      <Formik
        onSubmit={(
          {
            AccountPassword,
            BirthPlace,
            Block,
            City,
            ContractModelId,
            CountryCode,
            DniNie,
            Dni,
            Door,
            Email,
            FirstName,
            Floor,
            Gender,
            HouseNumber,
            IBAN,
            LastName,
            LastName2,
            Marketing,
            Nie,
            Phone,
            Portal,
            PostalCode,
            ProudClubNewsletter,
            Province,
            Stairs,
            Street,
            StreetType,
            StudioMark,
            day,
            month,
            signature,
            year,
            ReferrerType,
            referralCode,
            uuid,
            pcConsent,
            additionalAddressInformation
          },
          actions
        ) => {
          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId,
            FirstName,
            LastName,
            LastName2,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              BirthPlace,
              Block,
              City,
              CountryCode,
              DNINIE: DniNie,
              DNI: Dni,
              Door,
              Email,
              Floor,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: Marketing || pcConsent,
              NIE: Nie,
              Phone,
              Portal,
              PostalCode,
              ProudClub: Marketing,
              ProudClubNewsletter,
              Province,
              Stairs,
              Street,
              StreetType,
              ReferrerType,
              additionalAddressInformation
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            termId,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date(),
            uuid,
            pcConsent
          };
          this.props.handleSubmit(data, actions);
        }}
        initialValues={{
          ContractModelId: this.props.selectedContract.Id,
          CountryCode: this.props.selectedContract.CountryCode,
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          referralCode: this.props.referralCode,
          ...(member
            ? {
                uuid: member.customerUUID,
                FirstName: member.firstname,
                LastName: member.lastname,
                Gender: member.gender === 'MALE' ? 'M' : 'W',
                Email: member.email,
                day: getDate(new Date(member.dateOfBirth)),
                month: getMonth(new Date(member.dateOfBirth)) + 1,
                year: getYear(new Date(member.dateOfBirth)),
                Phone: member.phone
              }
            : {})
        }}
        validationSchema={schemaML}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) =>
          this.props.success ? (
            <Redirect
              to={`/checkout${this.props.intl.formatMessage({
                id: 'route.success'
              })}`}
            />
          ) : (
            <FormWrapper onSubmit={handleSubmit}>
              <FormSidebar campaign={isFebCampaign && !isUpsell}>
                <FormGroup>
                  <FormGroupHeadlineWrap>
                    <FormGroupHeadline>Datos de acceso</FormGroupHeadline>
                    <RequiredNote style={{ fontSize: '0.75em' }}>
                      * Campos obligatorios
                    </RequiredNote>
                  </FormGroupHeadlineWrap>
                  <FormGroupHeadlineWrap style={{ marginBottom: '1.3em' }}>
                    <RequiredNote>
                      Una vez realizado tu contrato online podrás darte de alta
                      y acceder a tu área personal.
                    </RequiredNote>
                  </FormGroupHeadlineWrap>
                  <InputGroup
                    autoComplete="email"
                    error={errors.Email}
                    label="E-Mail"
                    name="Email"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Email}
                    type="email"
                    value={values.Email}
                    readOnly={!!member?.email}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Datos personales</FormGroupHeadline>
                  <InputGroup
                    autoComplete="sex"
                    error={errors.Gender}
                    label="Sr./Sra"
                    name="Gender"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={[
                      { name: 'Hombre', value: 'M' },
                      { name: 'Mujer', value: 'W' }
                    ]}
                    required
                    touched={touched.Gender}
                    type="radio"
                    value={values.Gender}
                    readOnly={!!member?.gender}
                  />
                  {errors && touched && errors.gender && touched.gender ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  <InputGroup
                    autoComplete="given-name"
                    error={errors.FirstName}
                    label="Nombre"
                    name="FirstName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.FirstName}
                    type="text"
                    value={values.FirstName}
                    readOnly={!!member?.firstname}
                  />
                  <InputGroup
                    autoComplete="family-name"
                    error={errors.LastName}
                    label="Primer apellido"
                    name="LastName"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.LastName}
                    type="text"
                    value={values.LastName}
                    readOnly={!!member?.lastname}
                  />
                  <InputGroup
                    error={errors.LastName2}
                    label="Segundo apellido"
                    name="LastName2"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.LastName2}
                    type="text"
                    value={values.LastName2}
                  />
                  <InputGroup
                    error={errors.DniNie}
                    label="dninie"
                    name="DniNie"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={[
                      { name: 'DNI', value: 'dni' },
                      { name: 'NIE', value: 'nie' }
                    ]}
                    required
                    touched={touched.DniNie}
                    type="radio"
                    value={values.DniNie}
                  />
                  {values.DniNie === 'dni' && (
                    <InputGroup
                      error={errors.Dni}
                      label="DNI"
                      name="Dni"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Dni}
                      type="text"
                      value={values.Dni}
                    />
                  )}
                  {values.DniNie === 'nie' && (
                    <InputGroup
                      error={errors.Nie}
                      label="NIE"
                      name="Nie"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Nie}
                      type="text"
                      value={values.Nie}
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Datos de nacimiento</FormGroupHeadline>
                  <InputGroup
                    error={errors.day}
                    label="Día"
                    name="day"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 31 }, (v, k) => ++k)}
                    required
                    touched={touched.day}
                    type="select"
                    value={values.day}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  <InputGroup
                    error={errors.month}
                    label="Mes"
                    name="month"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    // eslint-disable-next-line no-plusplus, no-param-reassign
                    options={Array.from({ length: 12 }, (v, k) => ++k)}
                    required
                    touched={touched.month}
                    type="select"
                    value={values.month}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  <InputGroup
                    error={errors.year}
                    label="Año"
                    name="year"
                    noLabel
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    options={Array.from(
                      { length: 83 },
                      // eslint-disable-next-line no-return-assign,no-param-reassign
                      (v, k) => (k += getYear(subYears(Date.now(), 100)))
                    ).reverse()}
                    required
                    touched={touched.year}
                    type="select"
                    value={values.year}
                    width="thirds"
                    readOnly={!!member?.dateOfBirth}
                  />
                  {errors &&
                  touched &&
                  ((errors.day && touched.day) ||
                    (errors.month && touched.month) ||
                    (errors.year && touched.year)) ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.required" />
                    </ErrorMessage>
                  ) : null}
                  {errors && errors.olderThan18 ? (
                    <ErrorMessage>
                      <FormattedMessage id="validate.birthday" />
                    </ErrorMessage>
                  ) : null}
                  {brand === 'mcfit' ? null : (
                    <InputGroup
                      error={errors.BirthPlace}
                      label="Lugar de nacimiento"
                      name="BirthPlace"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.BirthPlace}
                      type="text"
                      value={values.BirthPlace}
                      required
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <FormGroupHeadline>Datos de contacto</FormGroupHeadline>
                  <InputGroup
                    autoComplete="address-line1"
                    error={errors.Street}
                    label="Nombre de la vía"
                    name="Street"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Street}
                    type="text"
                    value={values.Street}
                    width="middle"
                  />
                  <InputGroup
                    error={errors.HouseNumber}
                    label="N°"
                    name="HouseNumber"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.HouseNumber}
                    type="text"
                    value={values.HouseNumber}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="postal-code"
                    error={errors.PostalCode}
                    label="CP"
                    name="PostalCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.PostalCode}
                    type="text"
                    value={values.PostalCode}
                    width="small"
                  />
                  <InputGroup
                    autoComplete="locality"
                    error={errors.City}
                    label="Localidad/Municipio"
                    name="City"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.City}
                    type="text"
                    value={values.City}
                    width="middle"
                  />
                  <InputGroup
                    error={errors.Province}
                    label="Provincia"
                    name="Province"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Province}
                    type="text"
                    value={values.Province}
                  />
                  <InputGroup
                    error={errors.StreetType}
                    label="Tipo de vía"
                    name="StreetType"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.StreetType}
                    type="text"
                    value={values.StreetType}
                    required
                  />
                  {brand === 'mcfit' ? (
                    <InputGroup
                      error={errors.additionalAddressInformation}
                      label="MÁS INFORMACIÓN"
                      name="additionalAddressInformation"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.additionalAddressInformation}
                      type="text"
                      value={values.additionalAddressInformation}
                    />
                  ) : (
                    <>
                      <InputGroup
                        error={errors.Block}
                        label="Bloque"
                        name="Block"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Block}
                        type="text"
                        value={values.Block}
                      />
                      <InputGroup
                        error={errors.Portal}
                        label="Portal"
                        name="Portal"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Portal}
                        type="text"
                        value={values.Portal}
                      />
                      <InputGroup
                        error={errors.Stairs}
                        label="Escalera"
                        name="Stairs"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Stairs}
                        type="text"
                        value={values.Stairs}
                      />
                      <InputGroup
                        error={errors.Floor}
                        label="Piso/Planta"
                        name="Floor"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Floor}
                        type="text"
                        value={values.Floor}
                      />
                      <InputGroup
                        error={errors.Door}
                        label="Puerta"
                        name="Door"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.Door}
                        type="text"
                        value={values.Door}
                      />
                    </>
                  )}
                  <InputGroup
                    autoComplete="tel"
                    error={errors.Phone}
                    label="TELÉFONO MÓVIL"
                    name="Phone"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.Phone}
                    type="tel"
                    value={values.Phone}
                    required
                  />
                </FormGroup>
                {brand === 'mcfit' && (
                  <>
                    <FormGroupHeadline>
                      <FormattedMessage
                        id="checkout.mcfitplus"
                        defaultMessage="McFIT+"
                      />
                    </FormGroupHeadline>
                    <FormDescription as="div">
                      Ahora es el momento de conseguir las ventajas que te
                      mereces. Cuanto más tiempo estés con nosotros, más
                      beneficios recibirás.
                    </FormDescription>
                    <InputGroupWrapper
                      width="full"
                      type="checkbox"
                      style={{ marginBottom: '3em' }}
                    >
                      <InputField
                        autoComplete="off"
                        id="pcConsent"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        touched={touched.pcConsent}
                        type="checkbox"
                        value={values.pcConsent}
                      />
                      <Label htmlFor="pcConsent" type="checkbox" hide={false}>
                        <StyledCheckbox checkMark checked={values.pcConsent} />
                        <CheckboxText>
                          Acepto las{' '}
                          <a
                            href={
                              'https://www.mcfit.com/es/condiciones-de-uso-mcfit-plus/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            condiciones de uso
                          </a>{' '}
                          de "McFIT+" y me gustaría participar para beneficiarme
                          de las ventajas de RSG Group España, S.L.U. y otros
                          socios. Hasta su revocación, acepto recibir a través
                          de un correo electrónico información sobre productos,
                          servicios y promociones de RSG Group España, así como
                          ofertas de beneficios de{' '}
                          <a
                            href={
                              'https://www.mcfit.com/es/condiciones-de-uso-mcfit-plus/filiales-de-rsg-group/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            empresas del grupo
                          </a>
                          . He leído y acepto la información sobre las
                          newsletters de RSG Group GmbH en la{' '}
                          <a
                            href={
                              'https://www.mcfit.com/es/sobre-mcfitcom/proteccion-de-datos/politica-de-privacidad-oferta-online/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            política de privacidad
                          </a>
                          .
                        </CheckboxText>
                      </Label>
                    </InputGroupWrapper>
                  </>
                )}
                <FormGroup>
                  <FormGroupHeadline>
                    <FormattedMessage
                      id="checkout.referralCode"
                      defaultMessage="Referral Code"
                    />
                  </FormGroupHeadline>
                  <InputGroup
                    error={errors.referralCode}
                    label={this.props.intl.formatMessage({
                      id: 'checkout.referralCode.placeholder'
                    })}
                    name="referralCode"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    touched={touched.referralCode}
                    type="text"
                    value={values.referralCode}
                  />
                </FormGroup>
                <FormGroup
                  style={brand === 'mcfit' ? { marginBottom: '1em' } : {}}
                >
                  <FormGroupHeadline>Datos bancarios</FormGroupHeadline>
                  <FormDescription as="div">
                    El titular de la cuenta debe coincidir con el titular del
                    contrato. Si no es el caso, por favor dirígete al gimnasio
                    para formalizar tu contrato
                  </FormDescription>
                  <InputGroup
                    error={errors.AccountHolder}
                    label="Titular"
                    name="AccountHolder"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    readOnly
                    required
                    touched={touched.AccountHolder}
                    type="text"
                    value={`${values.FirstName ||
                      'TITULAR'} ${values.LastName || ''}`}
                  />
                  <InputGroup
                    error={!this.props.BIC && 'checkout.ibanError'}
                    fetching={this.props.fetchingIBAN}
                    label="IBAN"
                    name="IBAN"
                    onBlur={event => {
                      const { value } = event.target;
                      handleBlur(event);
                      handleChange(event);
                      this.handleVerifyIBAN(value);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.IBAN}
                    type="text"
                    uppercase
                    value={values.IBAN}
                  />
                  <SignaturePad
                    error={
                      errors.signature && touched.signature && !values.signature
                    }
                    saveSignature={data => {
                      setFieldValue('signature', data);
                      setFieldTouched('signature', true);
                    }}
                  />
                  <InputGroup
                    autoComplete="off"
                    error={errors.SEPA}
                    hide={false}
                    label={`
                      Mediante la firma de esta orden de domiciliación, el socio autoriza a RSG Group España, S.L.U. 
                      a girar los recibos bancarios y a cargarlos en su cuenta bancaria siguiendo las instrucciones 
                      de RSG Group España, S.L.U. Acepto el pago único de 
                      ${this.props.selectedContract.CardCharges || 0}
                      .00 €** relativo a la activación del carné de socio.`}
                    name="SEPA"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.SEPA}
                    type="checkbox"
                    value={values.SEPA}
                  />
                </FormGroup>
                <FormGroup>
                  {brand === 'mcfit' ? (
                    <>
                      <LegalWrapper
                        style={{ order: 0, padding: '0 0 2.2em 0' }}
                      >
                        Acepto las Condiciones Particulares, las{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.mcfit.com/es/sobre-mcfitcom/condiciones-generales/"
                        >
                          Condiciones Generales
                        </a>
                        , la{' '}
                        <a
                          href="https://www.mcfit.com/es/sobre-mcfitcom/proteccion-de-datos/politica-de-privacidad-contratos-de-socio/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Politica de Privacidad
                        </a>{' '}
                        y la Información sobre el{' '}
                        <a
                          href="https://www.mcfit.com/es/sobre-mcfitcom/derecho-de-desistimiento/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Derecho de Desistimiento
                        </a>
                        .
                      </LegalWrapper>
                    </>
                  ) : (
                    <>
                      <FormGroupHeadline>Otros</FormGroupHeadline>
                      <InputGroup
                        error={errors.ReferrerType}
                        label="¿Cómo nos has conocido?"
                        name="ReferrerType"
                        noLabel
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        options={
                          this.props.referrals
                            ? this.props.referrals
                            : reffererTypeOptions
                        }
                        touched={touched.ReferrerType}
                        type="select"
                        value={values.ReferrerType}
                      />
                      <CheckboxGroup>
                        <InputGroupWrapper width="full" type="checkbox">
                          <InputField
                            autoComplete="off"
                            id="AGB"
                            onBlur={event => {
                              handleBlur(event);
                              handleChange(event);
                            }}
                            onChange={handleChange}
                            required
                            touched={touched.AGB}
                            type="checkbox"
                            value={values.AGB}
                          />
                          <Label htmlFor="AGB" type="checkbox" hide={false}>
                            <StyledCheckbox
                              checkMark
                              checked={values.AGB}
                              error={errors.AGB}
                            />
                            <CheckboxText>
                              * Acepto las Condiciones Particulares, las{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.mcfit.com/es/sobre-mcfitcom/condiciones-generales/"
                              >
                                Condiciones Generales,
                              </a>{' '}
                              las{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.mcfit.com/index.php?id=436&L=2"
                              >
                                Condiciones de uso del área personal,
                              </a>{' '}
                              la{' '}
                              <a
                                href="https://www.mcfit.com/es/sobre-mcfitcom/proteccion-de-datos/politica-de-privacidad-contratos-de-socio/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Politica de Privacidad
                              </a>{' '}
                              y la Información sobre el{' '}
                              <a
                                href="https://www.mcfit.com/es/sobre-mcfitcom/derecho-de-desistimiento/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Derecho de Desistimiento
                              </a>
                              .
                            </CheckboxText>
                          </Label>
                        </InputGroupWrapper>
                        <InputGroupWrapper width="full" type="checkbox">
                          <InputField
                            autoComplete="off"
                            id="AGB2"
                            onBlur={event => {
                              handleBlur(event);
                              handleChange(event);
                            }}
                            onChange={handleChange}
                            required
                            touched={touched.AGB2}
                            type="checkbox"
                            value={values.AGB2}
                          />
                          <Label htmlFor="AGB2" type="checkbox" hide={false}>
                            <StyledCheckbox
                              checkMark
                              checked={values.AGB2}
                              error={errors.AGB2}
                            />
                            <CheckboxText>
                              * Acepto el pago único de{' '}
                              {this.props.selectedContract.CardCharges || 0}
                              .00 €** relativo a la activación del carné de
                              socio
                            </CheckboxText>
                          </Label>
                        </InputGroupWrapper>
                        <InputGroupWrapper width="full" type="checkbox">
                          <InputField
                            autoComplete="off"
                            id="Marketing"
                            onBlur={event => {
                              handleBlur(event);
                              handleChange(event);
                            }}
                            onChange={handleChange}
                            touched={touched.Marketing}
                            type="checkbox"
                            value={values.Marketing}
                          />
                          <Label
                            htmlFor="Marketing"
                            type="checkbox"
                            hide={false}
                          >
                            <StyledCheckbox
                              checkMark
                              checked={values.Marketing}
                            />
                            <CheckboxText>
                              El socio consiente expresamente que McFIT pueda
                              tratar y comunicar los datos personales recabados
                              en el ámbito del presente contrato para
                              actividades de publicidad y marketing, incluido el
                              envío mediante SMS, correo electrónico u otros
                              medios similares de información comercial y/o
                              publicitaria, relativas a los productos y
                              servicios de las empresas del grupo McFIT,
                              perteneciente al sector de actividades recreativas
                              y deportivas, tales como High 5 (cadena de
                              gimnasios), Cyberobics (productos de entrenamiento
                              virtual), Qi² (suplementos nutricionales), LOOX
                              (aplicaciones informáticas) y McFIT MODEL AGENCY
                              (agencia de modelos). El presente consentimiento
                              para actividades de publicidad y marketing es
                              opcional para el socio y no necesario para la
                              correcta ejecución del presente contrato. El socio
                              podrá en cualquier momento ejercer los derechos de
                              acceso, rectificación, supresión, limitación,
                              portabilidad y oposición en los términos
                              establecidos en la Ley Orgánica 15/1999, de 13 de
                              diciembre, y del RGPD, solicitándolo por escrito a
                              RSG Group España, S.L.U., C/ Fuencarral 6, 3ª
                              Planta, 28004 Madrid, o mediante correo
                              electrónico a atencioncliente@mcfit.com . El
                              responsable del fichero es RSG Group España,
                              S.L.U.
                            </CheckboxText>
                          </Label>
                        </InputGroupWrapper>
                      </CheckboxGroup>
                    </>
                  )}
                  <CTAButton type="submit" disabled={isSubmitting}>
                    {this.props.sending ? (
                      <FormattedMessage
                        id="checkout.sendingButton"
                        defaultMessage="Send"
                      />
                    ) : (
                      <FormattedMessage
                        id="checkout.sendButton"
                        defaultMessage="Send"
                      />
                    )}
                  </CTAButton>
                  <LegalWrapper>
                    ** Todos los precios indicados son IVA incluido | Los
                    horarios de apertura pueden variar de un gimnasio a otro |
                    Puedes hacer un contrato online si eres mayor de edad y eres
                    titular de una cuenta bancaria, si no, pásate por el
                    gimnasio o contacta con el departamento de atención al
                    cliente.
                  </LegalWrapper>
                </FormGroup>
              </FormSidebar>
              {(!isFebCampaign || isUpsell) && (
                <FormAside>
                  <SelectedContract
                    button={false}
                    sidebar
                    {...this.props.selectedContract}
                    highlight={false}
                  />
                </FormAside>
              )}
            </FormWrapper>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudio: state.data.checkout.selectedStudio,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: async (data, actions) => {
    await dispatch(sendData(data));
    actions.setSubmitting(false);
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractFormES))
);
