const it = {
  intl: {
    locale: 'it',
    messages: {
      'add.cause': 'Indica il motivo della sospensione',
      'add.start': 'Inizio',
      'add.suspensionMonths': 'Quanti mesi?',
      'add.suspensionTime': 'Inserisci un periodo di sospensione',
      'add.title': 'Inserire',

      'app.greeting': 'Ciao!',
      'app.switchLanguage': 'Selezionare lingua',

      'backlink.url.high5': 'high5.com',
      'backlink.url.johnreed': 'johnreed.fitness',
      'backlink.url.mcfit': 'mcfit.com',

      'breadcrumb.contract.high5': 'ABBONAMENTO',
      'breadcrumb.contract.johnreed': 'ABBONAMENTO',
      'breadcrumb.contract.mcfit': 'ABBONAMENTO',
      'breadcrumb.data.high5': 'DATI PERSONALI ',
      'breadcrumb.data.johnreed': 'DATI PERSONALI ',
      'breadcrumb.data.mcfit': 'DATI PERSONALI ',
      'breadcrumb.finalize.high5': 'CONCLUSIONE',
      'breadcrumb.finalize.johnreed': 'CONCLUSIONE',
      'breadcrumb.finalize.mcfit': 'CONCLUSIONE',
      'breadcrumb.homebase.high5': 'HOME',
      'breadcrumb.homebase.johnreed': 'HOME',
      'breadcrumb.homebase.mcfit': 'HOME',

      'card.BIC': 'BIC',
      'card.IBAN': 'IBAN',
      'card.L': 'Addebito SEPA',
      'card.accountOwner': 'Titolare del conto corrente',
      'card.address': 'Il mio indirizzo',
      'card.birthday': 'Data di nascita',
      'card.cancel': 'Prossimo termine disdetta',
      'card.canceld': '',
      'card.comingSoon': 'Coming Soon',
      'card.contact': 'Informazioni di contatto',
      'card.contractHeadline': 'Dati del contratto',
      'card.duration': 'Durata',
      'card.email': 'E-Mail',
      'card.financial': 'Dati di pagamento',
      'card.lastBill': 'Ultima fattura',
      'card.memberDataHeadline': 'I tuoi dati',
      'card.memberNumber': 'Membernumber',
      'card.monthlyFee': 'Quota di abbonamento mensile',
      'card.monthlyFeeFrom': '',
      'card.monthlyFeeProlonged': '',
      'card.currency': 'Euro',
      'card.nextPayment': 'Prossimo pagamento in scadenza al',
      'card.payment': 'Overview dei pagamenti',
      'card.paymentType': 'Modalità di pagamento',
      'card.phone': 'Telefono',
      'card.suspension': 'Sospensioni',

      'change.address': 'Il mio nuovo indirizzo',
      'change.bankdata': 'Cambia coordinate bancarie',
      'change.bankdata.bank': 'Coordinate bancarie',
      'change.city': 'Città',
      'change.number': 'N.',
      'change.postalCode': 'CAP',
      'change.street': 'Via',
      'change.title': 'Modifica',

      'checkout.description.johnreed':
        'Scegli il club JOHN REED nel quale ti allenerai',
      'checkout.description.mcfit':
        'A seconda del contratto, hai la possibilità di allenarti in tutti i centri fitness McFIT o in un solo centro, con vincolo ad esso. Seleziona il centro dove ti allenerai più spesso',
      'checkout.headline.johnreed': 'Scegli il tuo centro fitness',
      'checkout.headline.mcfit': 'Scegli il tuo centro fitness',
      'campaign.headline.mcfit': '1. SCEGLI IL CENTRO McFIT DI RIFERIMENTO',
      'campaign.headline.johnreed': '1. SCEGLI IL CENTRO McFIT DI RIFERIMENTO',
      'campaign.headline.high5': '1. SCEGLI IL CENTRO McFIT DI RIFERIMENTO',
      'checkout.ibanError': 'IBAN NON VALIDO',
      'checkout.locationPlaceholder': 'Strada, Città o CAP',
      'checkout.locationPlaceholderLong': 'Strada, Città o CAP',
      'checkout.noStudios':
        'Purtroppo non ci sono centri fitness nella zona selezionata',
      'checkout.sendButton': 'Ordine con vincolo di pagamento',
      'checkout.sendingButton': 'Invio Dati...',
      'checkout.sendingFotoButton': 'Invio Foto...',
      'checkout.studio.mcfit': 'Centro',
      'checkout.studioDetailLink': 'Dettagli',
      'checkout.studioSelectButton': 'Scegli come centro abituale',
      'checkout.studioSelectButtonLong': 'Scegli come centro abituale',
      'checkout.subheadlineStep2':
        'Nelle tue vicinanze ci sono i seguenti centri fitness:',
      'checkout.uploadPicture': 'Carica Foto',
      'checkout.selectFile': 'Selezionare archivio',
      'checkout.takePicture': 'Fare foto',
      'checkout.removePicture': 'Rimuovere Foto',
      'checkout.referralCode': 'Codice di riferimento',
      'checkout.referralCode.placeholder': 'Aggiungi codice di riferimento',
      'checkout.proudclub': 'PROUD CLUB',
      'checkout.mcfitplus': 'Il nostro programma fedeltà McFIT+ per te',
      'campaign.btn': 'AVANTI',
      'contract.runtime': 'Durata minima contrattuale {duration} mesi',
      'contract.runtime.flex': '',
      'contract.runtime.single': 'Durata minima contrattuale {duration} mese',
      'contract.showContract': 'Scelta contratto',
      'contract.completeButtonShort': 'SELEZIONA',
      'contract.completeButton': 'SELEZIONA',
      'contract.from': ' ',
      'contract.headline.johnreed': 'IL TUO CENTRO FITNESS',
      'contract.headline.mcfit': 'IL TUO CENTRO FITNESS',
      'contract.upselling.headline.mcfit': 'IL TUO CENTRO FITNESS',
      'contract.campaign.headline.mcfit': '2. IL TUO CONTRATTO',
      'contract.week': 'Days',
      'contract.month': '/mese',
      'contract.year': '/anno',
      'contract.expand': 'Dettagli',
      'contract.subheadline.johnreed': 'Scegli l’abbonamento',
      'contract.subheadline.mcfit': 'Scegli l’abbonamento',
      'contract.legalFootnote':
        '** Tutti i prezzi sono IVA inclusa | Prezzi e orari di apertura fuori dall’Italia possono variare | Sono escluse le persone che non hanno compiuto il 15esimo anno di età, chi è già Abbonato McFIT e i precedenti Abbonati che, in passato, non abbiano regolarmente versato le proprie quote di abbonamento | Offerente: RSG Group Italia Srl, Viale Fulvio Testi 29, 20162 Milano.',

      'cookie.link': 'Cookie Policy.',
      'cookie.linkURL.high5':
        'https://www.high5.com/footer-navigation/impressum/#datenschutz',
      'cookie.linkURL.johnreed': 'https://johnreed.fitness/datenschutz',
      'cookie.linkURL.mcfit': 'https://www.mcfit.com/index.php?id=196&L=3',
      'cookie.message':
        'Per migliorare la tua navigazione su www.mcfit.com, utilizziamo cookies ed altre tecnologie che ci permettono di riconoscerti. Utilizzando questo sito acconsenti al loro utilizzo, descritto nella nostra',

      'cta.send': 'Invio',
      'cta.sending': 'Invio...',

      'dashboard.greeting': 'Hey',

      'dashboardNav.communication': 'Comunicazione',
      'dashboardNav.cyberobicsNow': 'Cyberobics Now',
      'dashboardNav.home': 'Dashboard',
      'dashboardNav.proudClub': 'Proud Club',
      'dashboardNav.services': 'Servizi',
      'dashboardNav.bringafriend': 'Bring a Friend',

      'data.back': 'Torna alla scelta del contratto',
      'data.headline.johnreed': 'Join the club',
      'data.headline.mcfit': 'Entra a far parte di qualcosa di grande!',
      'data.subheadline.johnreed':
        'Inserisci i tuoi dati di contatto e le tue coordinate bancarie',
      'data.subheadline.mcfit':
        'Inserisci i tuoi dati di contatto e le tue coordinate bancarie',

      'footerNav.career': 'Lavoro',
      'footerNav.company': 'Società',
      'footerNav.contact': 'Contatti',
      'footerNav.imprint': 'Dati legali',
      'footerNav.privacy': 'informativa sulla privacy',

      'header.backlink': 'Torna alla homepage',
      'header.loginButton': 'Login',
      'header.logoutButton': 'Logout',
      'login.becomeMemberButton': 'Abbonati adesso',

      'login.becomeMemberDescription': 'Non sei ancora abbonato da McFIT?',
      'login.contactText':
        'Se hai domande, puoi scriverci compilando il nostro formulario.',
      'login.description':
        'Qui puoi effettuare il login della tua area personale.',
      'login.emailPlaceholder': 'E-Mail',
      'login.emailPlaceholderLong': 'Indirizzo E-Mail',
      'login.forgotPassword': 'Password dimenticata?',
      'login.headline.johnreed': 'Welcome to the club',
      'login.headline.mcfit': 'Benvenuto da McFIT',
      'login.passwordPlaceholder': 'Password',
      'login.register': 'Registrazione',
      'login.submit': 'Login',

      'memberNumber.description': 'Numero Membercard',

      'messages.activationNeeded':
        'Per favore, attiva il tuo account cliccando sul link ricevuto via email.',
      'messages.changeDataError': 'Errore nella modifica dei tuoi dati',
      'messages.gpsError': 'Errore nel caricamento dell luogo',
      'messages.invalidData': 'Bitte überprüfe nochmal deine Eingaben.',
      'messages.invalidFiscalCode': 'Errore nella conferma del codice fiscale.',
      'messages.locationFetchError': 'Errore nel caricamento della località',
      'messages.newVersion': 'Nuova versione disponibile.',
      'messages.newVersionDescription': 'Ricarica la pagina per aggiornarla.',
      'messages.promoFetchError':
        'Errore nel caricamento delle azioni del Proud Club',
      'messages.sendDataError': 'Errore nella trasmissione dei dati',
      'messages.receiveDataError': '',
      'messages.studioFetchError': 'Errore nel caricamento del centro fitness',
      'messages.userFetchError': "Errore nel caricamento dell'abbonato",
      'messages.userLoginError': 'Errore durante il login',
      'messages.userLogoutError': 'Errore durante il logout',
      'messages.userRequestPasswordFailure':
        'Errore durante il ripristino della password',
      'messages.userlangFetchError': 'Errore nel caricamento del tuo Stato',
      'messages.verifyEmail': "Errore nella conferma dell'indirizzo email",
      'messages.voucherCopySuccess': 'Il codice è stato memorizzato',
      'messages.ibanError': 'Questo non è un IBAN valido',
      'messages.iban.invalidFormat': 'IBAN FORMALMENTE NON VALIDO',
      'messages.iban.invalid':
        "DATI BANCARI RIFERITI A BANCA INESISTENTE O NON PIU' ATTIVA",
      'messages.iban.prepaid':
        "CARTA PREPAGATA RILEVATA, NON E' POSSIBILE PROCEDERE CON L'ISCRIZIONE INSERIRE UN CONTO BANCARIO",

      'messages.loox.invalidPassword': '',
      'messages.loox.invalidAge': '',
      'messages.loox.accountBlocked': '',
      'messages.loox.accountDisabled': '',
      'messages.loox.existingSubscription': '',
      'messages.loox.created': '',
      'messages.loox.updated': '',
      'messages.cyberobics.success':
        'Il tuo account è stato creato con successo.',
      'messages.cyberobics.updated':
        'IL TUO ACCOUNT CYBEROBICS è ORA COLLEGATO AL TUO ABBONAMENTO IN PALESTRA.',
      'messages.cyberobics.alreadySubscribed':
        'HAI GIÀ UN ACCOUNT VALIDO. FAI LOGIN CON I TUOI DATI DI ACCESSO. SE HAI DOMANDE, RIVOLGITI AL SERVIZIO CLIENTI SCRIVENDO A SERVICE.IT@CYBEROBICS.COM',
      'messages.cyberobics.resendSuccess': '',
      'messages.tsp.password':
        'La password deve contenere almeno una lettera e un numero (o un carattere speciale) ed essere composta da almeno 8 caratteri.',
      'messages.pendingRegistration':
        'La tua registrazione è attualmente in fase di elaborazione.',
      'messages.mailNotAllowed':
        'Attualmente non è possibile registrarsi con questo indirizzo Email. Si prega di contattare il nostro servizio clienti.',
      'messages.contractConclusionBlocked':
        'Al momento non possiamo offrirti un contratto. Si prega di contattare il nostro servizio clienti.',
      'messages.alreadyRegistered':
        'Hai già un contratto? Si prega di contattare il nostro servizio clienti.',

      'newPassword.successHeadline': 'Operazione conclusa con successo!',
      'newPassword.successDescription':
        'Ora puoi fare longin con la tua nuova password.',
      'newPassword.headline': 'Scegli la tua nuova password',
      'newPassword.description':
        'Fai attenzione a scegliere una password sicura',

      'proudClub.copyCodeToClipboard': '',
      'proudClub.detail.back': '',
      'proudClub.intro.partnerCta': '',
      'proudClub.legal.showLess': '',
      'proudClub.legal.showMore': '',
      'proudClub.loyalty.month': '',
      'proudClub.loyalty.toNextUpgrade': '',
      'proudClub.loyalty.yourProgress': '',
      'proudClub.overview.headline': '',
      'proudClub.overview.intro.bottomLine': '',
      'proudClub.overview.intro.topLine': '',

      'register.accountData': 'Login',
      'register.headline': 'Registrati qui',
      'register.label.birthday': 'Data di nascita',
      'register.label.email': 'E-Mail',
      'register.label.password': 'Password',
      'register.personalData': 'Dati Personali',

      'resetPassword.description':
        'Inserisci la tua email, ti manderemo un messaggio con i prossimi passi.',
      'resetPassword.headline': 'Hai dimenticato la password?',
      'resetPassword.submit': 'Invio',
      'resetPassword.successDescription':
        "Abbiamo mandato un'altra email all'indirizzo indicato, con le restanti istruzioni.",
      'resetPassword.successHeadline': 'Operazione conclusa con successo!',

      'route.bringafriend': '/bring-a-friend',
      'route.dashboard': '/dashboard',
      'route.dashboard.communication': '/comunicazione',
      'route.dashboard.cyberobicsNow': '/cyberobics-now',
      'route.dashboard.home': '/',
      'route.dashboard.proudClub': '/proud-club',
      'route.dashboard.proudClub.partner': '/proud-club/partner',
      'route.dashboard.services': '/servizi',
      'route.enterData': '/i-tuoi-dati',
      'route.notFound': '404 Pagina non trovata',
      'route.notFound.message': '404',
      'route.resetPassword': '/password-dimenticata',
      'route.selectContract': '/scelta-contratto',
      'route.success': '/benvenuto',

      'signature.clear': 'Reimposta',
      'signature.save': 'Conferma la firma',
      'signature.rotateDevice': 'Capovolgi il tuo device',

      'suspension.cause1': 'Non specificato',
      'suspension.cause2': 'Gravidanza',
      'suspension.cause3': 'Servizio di leva/militare',
      'suspension.cause4': 'Motivi economici',
      'suspension.cause5': "Periodo all'estero",
      'suspension.cause6': 'Malattia',

      'validate.birthday': 'Devi avere almeno 18 anni.',
      'validate.birthday15': 'Devi avere almeno 15 anni.',
      'validate.birthday27': 'Devi avere massimo 27 anni.',
      'validate.city': 'Inserisci la tua città',
      'validate.email': 'Per favore, inserisci un indirizzo email valido',
      'validate.firstName': 'Inserisci il tuo nome',
      'validate.gender': 'Inserisci il sesso',
      'validate.houseNumber': 'Controlla il numero civico',
      'validate.iban': "Controlla l'IBAN",
      'validate.lastName': 'Inserisci il tuo cognome',
      'validate.postalCode': 'Controlla il tuo CAP',
      'validate.signature': 'Per favore conferma la tua firma',
      'validate.street': 'Controlla il tuo indirizzo',
      'validate.match': 'I dati inseriti non corrispondono.',

      'verify.description.mcfit':
        'Ti sei registrato con successo per il login in McFIT. Accedi quando vuoi e scopri i vantaggi che ti aspettano.',
      'verify.success.mcfit': 'Grazie per la tua conferma!',

      'welcome.description.mcfit':
        'Ora fai parte della community di McFIT! Ti manca solo la Membercard, che potrai ritirare alla reception del tuo centro fitness. Riceverai a breve via email i tuoi documenti contrattuali. Se hai domande, ti preghiamo di rivolgerti al nostro Servizio Clienti, di cui trovi tutti i riferimenti su <a href="https://www.mcfit.com/it/">mcfit.com</a>.',
      'welcome.description.mcfit.noApps': '',
      'welcome.description.ML': '',
      'welcome.headline.mcfit': 'BENVENUTO!',
      'welcome.campaign.headline.mcfit': 'Grazie mille',
      'welcome.subheadline.mcfit': 'Buon allenamento!',
      'welcome.description.add': '',
      'upsell.description':
        'A breve riceverai una mail con descritti i prossimi passi. Controlla sempre anche la cartella spam, se non ti arriva subito.',
      'upsell.subheadline': 'Buon allenamento! ',
      'upsell.error.headline': 'Oops, qualcosa è andato storto',
      'upsell.error.description':
        'Si è verificato un errore. Controlla di aver inserito tutti i dati correttamente.<br/><br/>Non ti preoccupare, ti basterà recarti nel centro McFIT che hai selezionato e chiedere aiuto al personale, per poter iniziare quanto prima col tuo pacchetto per tornare in forma.',

      high5: 'High5',
      johnreed: 'John Reed',
      mcfit: 'McFit',
      'yup.cf.taxNumberMismatch': 'Per favore, verifica il codice fiscale',
      'validate.required': 'Campo obbligatorio',
      'yup.cf.passwordMismatch': 'Le password inserite non coincidono',
      'yup.cf.postalCodeMismatch': 'Questo CAP non è valido. Ricontrollalo.',
      'card.extension': 'Rinnovo',
      'yup.cf.phoneMismatch': 'Il numero inserito non è valido',
      'validate.phone': 'Per favore, verifica il numero inserito',
      'suspension.noSuspensions':
        'Al momento non hai fatto richieste di sospensione',

      'cf.yup.bicMismatch': 'Ricontrolla il tuo BIC',
      'messages.validateEMailFailure': 'Questa email non è valida',
      'messages.validateFiscalCodeFailure':
        'Per favore, verifica il codice fiscale',
      'welcome.copyMemberNumber': 'Copia il codice cliente',
      'add.success':
        'Abbiamo ricevuto la tua richiesta di sospensione. Appena l’avremo rielaborata, potrai visualizzare il periodo di sospensione nella tua Area Personale.',
      'messages.wrongPassword':
        'Non abbiamo trovato nessun account corrispondente a questi dati.',
      'messages.uploadFotoFailure': 'The picture could not be uploaded',
      'messages.fotoSizeError':
        "L'archivio sorpassa la dimensione massima permessa de 5 MB.",

      'bringafriend.bullet1.johnreed':
        'Sei abbonato da almeno un mese e porti un amico in un club JOHN REED a tua scelta',
      'bringafriend.bullet1.mcfit':
        'Sei abbonato da almeno un mese e porti un amico in un centro McFIT a tua scelta',
      'bringafriend.bullet2':
        "Il tuo amico sottoscrive un contratto (di durata non inferiore a 6 mesi). L'alternativa è che presentiate in palestra il contratto fatto online dal tuo amico.",
      'bringafriend.bullet3':
        'Lo staff della palestra ti intesta un buono <sup>2</sup> per un mese di allenamento gratuito.',
      'bringafriend.bullet4':
        'Compila il modulo „Bring a Friend“ qui a sinistra inserendo i dati del tuo buonomit Hilfe der Daten auf deinem Gutschein aus, wählst deinen Freimonat<sup>3</sup> aus und drückst auf „Absenden“.',
      'bringafriend.bulletIntro': 'Funziona così:',
      'bringafriend.freemonth': 'Mese desiderato',
      'bringafriend.frienddata': "Dati dell'amico",
      'bringafriend.headline': 'Bring a Friend',
      'bringafriend.invalid': 'Codice errato',
      'bringafriend.messageHeadline.johnreed':
        'Per ogni amico che porti ti regaliamo un mese di allenamento gratis da JOHN REED!',
      'bringafriend.messageHeadline.mcfit':
        'Per ogni amico che porti ti regaliamo un mese di allenamento gratis da McFIT!',
      'bringafriend.success':
        'Abbiamo ricevuto la tua richiesta e la elaboreremo al più presto.',
      'bringafriend.sup1.johnreed': '',
      'bringafriend.sup1.mcfit':
        "L'amico che si iscrive deve aver già compiuto 15 anni, non essere già Abbonato McFIT e, se fosse ex Abbonato, non deve avere insoluti nei confronti di McFIT",
      'bringafriend.sup2.johnreed':
        '<sup>2</sup> Non sono possibili pagamenti in contanti, pagamenti parziali, scambi merce né cessioni a terzi',
      'bringafriend.sup2.mcfit':
        '<sup>2</sup> Non sono possibili pagamenti in contanti, pagamenti parziali, scambi merce né cessioni a terzi',
      'bringafriend.sup3.johnreed': '',
      'bringafriend.sup3.mcfit':
        "<sup>3</sup> Il mese gratuito inizia sempre il primo giorno del mese e viene erogato al più presto due mesi dopo la richiesta. McFIT si riserva la possibilità di non erogare il mese gratuito, in alcuni casi (per es. in caso di insoluti, sospensioni o recesso da parte dell'amico, etc.). Offerente: McFIT Italia S.r.l., Viale Fulvio Testi 29, 20162 Milano",
      'bringafriend.voucher': 'Codice del voucher',
      'bringafriend.voucherheadline': 'Codice del voucher',
      'checkout.description.high5': '',
      'checkout.headline.high5': '',
      'checkout.studio.high5': '',
      'checkout.studio.johnreed': 'Club',
      'checkout.studioSelectButton.high5': '',
      'checkout.studioSelectButton.johnreed': 'Scegli il club',
      'checkout.studioSelectButton.mcfit': 'Scegli il club',
      'checkout.fotoUploadDescription':
        "La dimensione d'archivio massima permessa è di 5 MB.",

      'component.loginLink': 'Scegli il club',
      'contract.noContracts': 'Non abbiamo trovato contratti',
      'contract.subheadline.high5': '',
      'dashboardNav.proudClub.faq': 'FAQ',
      'dashboardNav.proudClub.info': 'Info',
      'dashboardNav.proudClub.loyalty': 'Premi fedeltà',
      'dashboardNav.proudClub.partner': 'Vantaggi per i partner',
      'data.headline.high5': '',
      'data.headline.campaign': 'INSERISCI I TUOI DATI',
      'login.headline.high5': '',
      'login.noMember': 'Non hai ancora un conto?',
      'messages.bookOptionError':
        "C'è stato un errore nella scelta dell'opzione PROUD CLUB",
      'messages.bookOptionSuccess':
        "Hai scelto con successo l'opzione PROUD CLUB",
      'messages.changeDataSuccess':
        'Abbiamo ricevuto la tua richiesta di modifica. Appena l’avremo rielaborata, potrai visualizzare le modifiche nella tua Area Personale.',
      'messages.emailAlreadyUsed': 'La tua email è già stata registrata.',
      'messages.emailAlreadyUsedLogin': '',
      'messages.loginSuccess': 'Login avvenuto',
      'messages.memberNumberCopySuccess':
        'Il codice cliente è stato copiato negli appunti.',
      'messages.registrationSuccess':
        'Fatto! Ora entra con i tuoi dati di accesso.',
      'messages.sendOptinError': 'Errore nella registrazione al PROUD CLUB',
      'messages.sendRegistrationError': 'Errore nella registrazione',
      'messages.sendRegistrationError.description':
        "E' emerso un problema con i tuoi dati. Per favore, rivolgiti al Servizio Clienti.",
      'messages.sendRegistrationSuccess': 'Richiesta accolta',
      'messages.sendRegistrationSuccess.description':
        'Non esiste ancora nessun account con questi dati, perciò riceverai una mail con delle istruzioni',
      'messages.unconfirmedAccount':
        'Il tuo account non è ancora stato confermato',
      'messages.unconfirmedAccount.description':
        'Hai ricevuto una email contenente un link di conferma da cliccare',
      'messages.userlangFetchErrorDescription':
        'Il tuo numero Abbonato non è registrato',
      'notfound.homebutton': 'Vai alla pagina iniziale',
      'proudClub.email':
        'Ti abbiamo inviato una email di conferma. Clicca sul link al suo interno per confermare la tua registrazione.',
      'proudClub.pending':
        "La tua richiesta verrà elaborata. Abbi ancora un po' di pazienza",
      'proudClub.register.headline': 'Registrazione PROUD CLUB',
      'register.description':
        'Inserisci il tuo indirizzo email e scegli una password. Questi saranno i dati di accesso del tuo account. <br>BETAPHASE: questa funzione è momentaneamente in fase di test',
      'register.flagsHeadline':
        'Inserisci lo Stato in cui hai sottoscritto il contratto.',
      'register.label.day': 'Giorno',
      'register.label.firstName': 'Nome',
      'register.label.lastName': 'Cognome',
      'register.label.memberNumber': 'Numero Abbonato',
      'register.label.month': 'Mese',
      'register.label.year': 'Anno',
      'register.memberNumber': 'Abbiamo bisogno del tuo numero Abbonato',
      'route.dashboard.loyalty': '/proud-club/loyalty',
      'register.sendButton': 'Invio',
      'register.sendingButton': 'Invio...',
      'registermember.aside': 'Abbiamo bisogno del tuo numero Abbonato',
      'registermember.personalData': 'Dati anagrafici',
      'registermember.personalData.description':
        'Abbiamo bisogno del tuo numero Abbonato',
      'registermember.personalData.memberNumber':
        'Info: trovi il numero Abbonato sulla tua Membercard o sul contratto.',
      'verify.description.high5': '',
      'verify.description.johnreed':
        'Grazie per la conferma, abbiamo creato il tuo conto. Ora ti puoi loggare e gestire i tuoi dati.',
      'verify.success.high5': '',
      'verify.success.johnreed': 'Welcome to the Club!',
      'welcome.description.high5': '',
      'welcome.description.johnreed':
        "Inizia subito ad allenarti: vai al tuo club JOHN REED con il numero Abbonato. Lì riceverai la tua Membercard e potrai allenarti. Non dimenticare l'abbigliamento sportivo e l'asciugamano! Per poter utilizzare il tuo Account online, riceverai a breve una email con il link di conferma. Buon allenamento!",
      'welcome.headline.high5': '',
      'welcome.headline.johnreed': 'WELCOME TO THE CLUB',
      'welcome.subheadline.high5': '',
      'welcome.subheadline.johnreed': 'Comincia subito!',
      'dashboard.changeAddress': 'Il mio nuovo indirizzo',
      'dashboard.changeData': 'Modifica',
      'yup.cf.spanishIdentificationMismatch': '',
      'route.contact': '/contact',
      'messages.italy': 'Login non riuscito',
      'messages.italy.description':
        'Per favore esegui l’accesso da https://www.mcfit.com/it/personale/>https://www.mcfit.com/it/personale/</a>',

      'tsp.headline.cyberobics': 'La tua registrazione a CYBEROBICS ',
      'tsp.headline.loox': '',
      'tsp.subheadline.cyberobics':
        'Fornisci una password per {email}, per attivare il tuo account CYBEROBICS Premium.',
      'tsp.subheadline.loox': '',
      'tsp.passwordPlaceholder': 'Password',
      'tsp.passwordPlaceholderAgain': 'Ripeti password',
      'tsp.cta': 'Invio',
      'tsp.successHeadline.cyberobics': 'Ora sei registrato a CYBEROBICS!',
      'tsp.successHeadline.loox': '',
      'tsp.success.loox': '',
      'tsp.success.cyberobics':
        'L’attivazione è avvenuta con successo. Per cominciare a usare CYBEROBICS, scarica gratuitamente la app CYBEROBICS dal tuo App Store o vai su <a href="https://www.cyberobics.com" target="_blank">cyberobics.com</a>. Ora puoi accedere inserendo l’email e la password con cui ti sei registrato. Attenzione: se possiedi già un account, fai login con i tuoi dati di accesso. Se hai domande, rivolgiti al servizio clienti, scrivendo a service.it@cyberobics.com',
      'tsp.cyberobics.playstore':
        '<a href="https://play.google.com/store/apps/details?id=tv.accedo.ott.flow.cyberobics.now" target="_blank"><img src="/playstore.png" /></a>',
      'tsp.cyberobics.appstore':
        '<a href="https://apps.apple.com/it/app/cyberobics-fitness-workout/id1234963164" target="_blank"><img src="/appstore.png" /></a>',
      'tsp.loox.playstore': '',
      'tsp.loox.appstore': '',

      'testtraining.headline.mcfit': '',
      'testtraining.headline.johnreed': '',
      'testtraining.gender': '',
      'testtraining.agenote': '',
      'testtraining.bullet1': '',
      'testtraining.bullet2': '',
      'testtraining.bullet3': '',
      'testtraining.bullet4': '',
      'testtraining.success.headline': '',
      'testtraining.success.subheadline': '',
      'testtraining.success.description': ''
    }
  }
};

export default it;
