import styled, { css } from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

const CloseButton = styled.div`
  border-radius: 1em;
  color: ${props => props.theme.white};
  cursor: pointer;
  display: flex;
  height: 1.8em;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  width: 1.8em;

  svg {
    height: 0.9em;
    margin: auto;
    width: 0.9em;
  }

  ${media.md`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.info};
    right: 0;
    top: 0;
    transform: translate(25%, 50%);

    ${props =>
      props.type === 'success' &&
      css`
        color: ${props.theme.success};
      `};
    ${props =>
      props.type === 'alert' &&
      css`
        color: ${props.theme.alert};
      `};
  `};
`;

export default CloseButton;
