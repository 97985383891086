import uuid from 'uuid/v4';

import { displayMessage } from '../messages';
import config from '../../config';

export const VALIDATE_FISCAL_CODE_REQUEST =
  'data/fiscal_code/VALIDATE_FISCAL_CODE_REQUEST';
export const VALIDATE_FISCAL_CODE_FAILURE =
  'data/fiscal_code/VALIDATE_FISCAL_CODE_FAILURE';
export const VALIDATE_FISCAL_CODE_SUCCESS =
  'data/fiscal_code/VALIDATE_FISCAL_CODE_SUCCESS';

const { api } = config;

function validateFiscalCodeRequest(fiscalCode) {
  return {
    type: VALIDATE_FISCAL_CODE_REQUEST,
    payload: fiscalCode
  };
}

function validateFiscalCodeFailure(payload) {
  return {
    type: VALIDATE_FISCAL_CODE_FAILURE,
    payload
  };
}

function validateFiscalCodeSuccess(payload) {
  return {
    type: VALIDATE_FISCAL_CODE_SUCCESS,
    payload
  };
}

export function validateFiscalCode(fiscalCode) {
  return async dispatch => {
    function onError(error) {
      dispatch(validateFiscalCodeFailure(error));
      dispatch(
        displayMessage(
          {
            text: 'messages.validateFiscalCodeFailure',
            type: 'alert'
          },
          uuid()
        )
      );
      return error;
    }
    function onSuccess(validFiscalCode) {
      dispatch(validateFiscalCodeSuccess(validFiscalCode));
      return validFiscalCode;
    }

    dispatch(validateFiscalCodeRequest(fiscalCode));

    try {
      const response = await (
        await fetch(`${api}/fiscal-code`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fiscalCode: fiscalCode.toUpperCase().replace(/\s/g, '')
          })
        })
      ).json();

      if (response.result.codiceFiscale) {
        const {
          result: { codiceFiscale, birthInformationDto, gender }
        } = response;

        return onSuccess({
          codiceFiscale,
          birthInformationDto,
          gender
        });
      }

      if (!response.result.codiceFiscale) {
        const { result } = response;
        return onError(result);
      }
    } catch (error) {
      return onError(error);
    }
  };
}

export default function reducer(
  state = {
    fetching: false,
    valid: false,
    tested: '',
    data: {
      birthInformationDto: {
        placeOfBirth: '',
        dateOfBirth: ''
      }
    }
  },
  action
) {
  switch (action.type) {
    case VALIDATE_FISCAL_CODE_REQUEST:
      return {
        ...state,
        fetching: true,
        valid: false,
        tested: action.payload
      };
    case VALIDATE_FISCAL_CODE_SUCCESS:
      return {
        ...state,
        fetching: false,
        valid: true,
        data: action.payload
      };
    case VALIDATE_FISCAL_CODE_FAILURE:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}
