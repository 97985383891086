import { validTrackingLanguages, i18n } from '../i18n/index';

export function getReferrerLanguage(referrer, tracking = true) {
  if (!referrer) {
    return false;
  }

  const referrerLanguageArray = referrer.split('/');
  const referrerLanguage =
    referrerLanguageArray.length > 3 ? referrerLanguageArray[3] : 'de';
  const definedLanguages = tracking === true ? validTrackingLanguages : i18n;

  return Object.keys(definedLanguages).indexOf(
    referrerLanguage.toLowerCase()
  ) !== -1
    ? referrerLanguage
    : false;
}

export default getReferrerLanguage;
