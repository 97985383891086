import uuid from 'uuid/v4';

export const DISPLAY_MESSAGE = 'data/messages/DISPLAY_MESSAGE';
export const HIDE_MESSAGE = 'data/messages/HIDE_MESSAGE';

export function displayMessage(message, id) {
  return {
    type: DISPLAY_MESSAGE,
    payload: {
      id: process.env.NODE_ENV === 'test' ? 1 : id,
      ...message
    }
  };
}

export function hideMessage(messageId) {
  return {
    type: HIDE_MESSAGE,
    payload: { id: messageId }
  };
}

export function displayAutoCloseMessage(message) {
  return dispatch => {
    const id = uuid();

    dispatch(displayMessage(message, id));

    setTimeout(() => {
      dispatch(hideMessage(id));
    }, 10000);
  };
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case DISPLAY_MESSAGE:
      return [...state, action.payload];
    case HIDE_MESSAGE:
      return state.filter(message => message.id !== action.payload.id);
    default:
      return state;
  }
}
