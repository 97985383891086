import styled from 'styled-components';

import CTAButton from '../../CTAButton/CTAButton';

const ContractCTA = styled(CTAButton)`
  bottom: 1.5rem;
  display: ${props => (props.button === 'true' ? 'block' : 'none')};
  left: 50%;
  position: absolute;
  text-decoration: none;
  transform: translateX(-50%);
`;

export default ContractCTA;
