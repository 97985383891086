import React from 'react';
import styled from 'styled-components/macro';

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      className="Chevron__ChevronDown-mnje8e-0 gDVQgD"
      viewBox="0 0 36 20"
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M0 2.13C0 1.602.189 1.037.603.622c.83-.83 2.15-.83 2.979 0L17.908 14.91 32.196.622c.83-.83 2.149-.83 2.978 0 .83.83.83 2.149 0 2.978L19.416 19.397c-.415.414-.943.603-1.508.603a2.242 2.242 0 01-1.508-.603L.603 3.6A2.052 2.052 0 010 2.13z"
      ></path>
    </svg>
  );
}

export default Arrow;

export const ArrowWrapper = styled.span`
  margin-left: 6px;
  svg {
    transform: ${({ flip }) => flip && 'rotate(180deg)'};
  }
`;
