export default function getBacklink(brand) {
  switch (brand) {
    case 'mcfit':
      return 'https://mcfit.com';
    case 'high5':
      return 'https://www.high5.com/';
    case 'johnreed':
      return 'https://johnreed.fitness';
    default:
      return false;
  }
}
