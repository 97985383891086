import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Content from '../../../../components/Content/Content';
import Headline from '../../../../components/Headline/Headline';
import Subheadline from '../../../../components/Subheadline/Subheadline';
import Description from '../../../../components/Description/Description';

interface RootState {
  data: any;
}

const Success = ({ intl }) => {
  const { brand } = useSelector((state: RootState) => ({
    brand: state.data.theme.themeName
  }));
  const translated: string = intl.formatMessage({ id: 'upsell.description' });

  return (
    <Content>
      <Breadcrumb step={4} />
      <Headline noMargin>
        <FormattedHTMLMessage
          id={`welcome.campaign.headline.${brand}`}
          defaultMessage="Vielen Dank!"
        />
      </Headline>

      <>
        <Description campaign>
          <div dangerouslySetInnerHTML={{ __html: translated }} />
        </Description>
        <Subheadline>
          <FormattedMessage
            id={`upsell.subheadline`}
            defaultMessage="Viel Spaß!"
          />
        </Subheadline>
      </>
    </Content>
  );
};

export default injectIntl(Success);
