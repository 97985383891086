import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { injectIntl } from 'react-intl';

import FooterNavigationLink from './FooterNavigationLink';
import BrandBacklink from './BrandBacklink/BrandBacklink';
import config from '../../../config';
import { media } from '../../../lib/styledMixins';
import { toggleContactForm } from '../../../data/contact';

const { footerLinks } = config;

const FooterNavigationWrapper = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em 0;
  width: 100%;

  ${media.md`
    align-items: center;
    flex-direction: row;
    margin: 0;
    width: auto;
  `};
`;

const FooterNavigation = props => (
  <FooterNavigationWrapper>
    {props.brand ? <BrandBacklink /> : null}
    {props.brand
      ? footerLinks[props.lang][props.brand].map(item => (
          <FooterNavigationLink key={item.name} {...item} />
        ))
      : null}
  </FooterNavigationWrapper>
);

FooterNavigation.propTypes = {
  brand: PropTypes.string.isRequired,
  handleContactForm: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName,
  lang: state.intl.locale
});

const mapDispatchToProps = dispatch => ({
  handleContactForm: () => {
    dispatch(toggleContactForm());
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FooterNavigation)
);
