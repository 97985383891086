import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import LegalWrapper from '../LegalWrapper';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { schemaCampaign } from './contractFormSchemaDE';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';

export class CampaignFormDE extends Component {
  static propTypes = {
    austria: PropTypes.bool,
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  static defaultProps = {
    austria: false
  };

  state = {
    lastCheckedIBAN: ''
  };

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  timer;

  /*
  Returns special AGB links for specific contracts and brands
  */
  handelAGBLinks = (contractId, countryCode, brand) => {
    if (
      contractId === 1709 ||
      contractId === 1713 ||
      contractId === 1715 ||
      contractId === 1717
    ) {
      return countryCode === 'AT'
        ? 'https://www.mcfit.com/fileadmin/user_upload/PDF-Files/Rechtliche_Hinweise/AT/20190401_McFIT_Oesterreich_AGB_Beachbody.pdf'
        : 'https://www.mcfit.com/fileadmin/user_upload/PDF-Files/Rechtliche_Hinweise/DE/20190401_McFIT_Deutschland_AGB_Beachbody.pdf';
    } else {
      switch (brand) {
        case 'johnreed':
          return 'https://johnreed.fitness/agb';
        default:
          return countryCode === 'AT'
            ? 'https://www.mcfit.com/at/ueber-mcfitcom/agb/agb/'
            : 'https://www.mcfit.com/de/ueber-mcfitcom/agb/agb/';
      }
    }
  };

  render() {
    const { brand, selectedStudioOpening } = this.props;
    const {
      Id,
      CountryCode,
      tsp,
      tspCharges,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate
    } = this.props.selectedContract;
    const hasTSP = tsp && !!tspCharges;

    if (this.props.success) {
      window.location.href = `${this.props.redirectUrl}&redirectSuccess=${window.location.origin}/campaign/success&redirectFailure=${window.location.origin}/campaign/error`;
    }

    return (
      <Formik
        onSubmit={({
          AccountPassword,
          City,
          Email,
          FirstName,
          Gender,
          HouseNumber,
          IBAN,
          LastName,
          Phone,
          PostalCode,
          ProudClubNewsletter,
          Street,
          StudioMark,
          year,
          month,
          day,
          signature,
          ReferrerType,
          hasPremium,
          selectedStudioOpening,
          referralCode,
          isCampaign
        }) => {
          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: true,
              Phone,
              PostalCode,
              ProudClub: false,
              ProudClubNewsletter,
              Street,
              ReferrerType
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date(),
            isCampaign
          };

          this.props.handleSubmit(data);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          IBAN: '',
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          CountryCode,
          brand,
          hasPremium,
          referralCode: '',
          Street: '*',
          HouseNumber: '*',
          PostalCode: '*',
          City: '*',
          isCampaign: true
        }}
        validationSchema={schemaCampaign}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <FormSidebar campaign>
              <FormGroup>
                <FormGroupHeadlineWrap>
                  <FormGroupHeadline>Accountdaten</FormGroupHeadline>
                  <RequiredNote>*Pflichtfelder</RequiredNote>
                </FormGroupHeadlineWrap>
                <InputGroup
                  autoComplete="email"
                  error={errors.Email}
                  label="E-Mail"
                  name="Email"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.Email}
                  type="email"
                  value={values.Email}
                />
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Persönliche Daten</FormGroupHeadline>
                <InputGroup
                  autoComplete="sex"
                  error={errors.Gender}
                  label="Geschlecht"
                  name="Gender"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  options={[
                    { name: 'Männlich', value: 'M' },
                    { name: 'Weiblich', value: 'W' }
                  ]}
                  required
                  touched={touched.Gender}
                  type="radio"
                  value={values.Gender}
                />
                {errors && touched && errors.gender && touched.gender ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.required" />
                  </ErrorMessage>
                ) : null}
                <InputGroup
                  autoComplete="given-name"
                  error={errors.FirstName}
                  label="Vorname"
                  name="FirstName"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.FirstName}
                  type="text"
                  value={values.FirstName}
                />
                <InputGroup
                  autoComplete="family-name"
                  error={errors.LastName}
                  label="Nachname"
                  name="LastName"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.LastName}
                  type="text"
                  value={values.LastName}
                />
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Geburtsdaten</FormGroupHeadline>
                <FormDescription as="div">
                  Du musst mindestens 15 Jahre alt sein, um online einen Vertrag
                  abzuschließen.
                </FormDescription>
                <InputGroup
                  error={errors.day}
                  label="Tag"
                  name="day"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  // eslint-disable-next-line no-plusplus, no-param-reassign
                  options={Array.from({ length: 31 }, (v, k) => ++k)}
                  required
                  touched={touched.day}
                  type="select"
                  value={values.day}
                  width="thirds"
                />
                <InputGroup
                  error={errors.month}
                  label="Monat"
                  name="month"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  // eslint-disable-next-line no-plusplus, no-param-reassign
                  options={Array.from({ length: 12 }, (v, k) => ++k)}
                  required
                  touched={touched.month}
                  type="select"
                  value={values.month}
                  width="thirds"
                />
                <InputGroup
                  error={errors.year}
                  label="Jahr"
                  name="year"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  options={Array.from(
                    { length: 86 },
                    // eslint-disable-next-line no-return-assign,no-param-reassign
                    (v, k) => (k += getYear(subYears(Date.now(), 100)))
                  ).reverse()}
                  required
                  touched={touched.year}
                  type="select"
                  value={values.year}
                  width="thirds"
                />
                {errors &&
                touched &&
                ((errors.day && touched.day) ||
                  (errors.month && touched.month) ||
                  (errors.year && touched.year)) ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.required" />
                  </ErrorMessage>
                ) : null}
                {errors && errors.olderThan15 ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.birthday15" />
                  </ErrorMessage>
                ) : null}
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Kontaktdaten</FormGroupHeadline>
                <InputGroup
                  autoComplete="tel"
                  error={errors.Phone}
                  label="Telefonnummer"
                  name="Phone"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  touched={touched.Phone}
                  type="tel"
                  value={values.Phone}
                />
              </FormGroup>
              <FormGroup>
                <FormGroup>
                  <FormDescription style={{ color: '#242f3a' }}>
                    <strong>
                      Es gelten unsere{' '}
                      <a
                        href={this.handelAGBLinks(
                          this.props.selectedContract.Id,
                          this.props.selectedStudioISO,
                          brand
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Allgemeinen Geschäftsbedingungen
                      </a>
                      . Bitte beachte auch die{' '}
                      <a
                        href={
                          this.props.selectedStudioISO === 'AT'
                            ? 'https://www.mcfit.com/at/ueber-mcfitcom/widerrufsbelehrung/'
                            : 'https://www.mcfit.com/de/ueber-mcfitcom/widerrufsbelehrung/'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Widerrufsbelehrung
                      </a>{' '}
                      sowie unsere{' '}
                      <a
                        href={
                          this.props.selectedStudioISO === 'AT'
                            ? 'https://www.mcfit.com/at/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/'
                            : 'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/datenschutzerklaerung-probetrainings-und-mitgliedsvertraege/'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Datenschutzerklärung
                      </a>
                      .
                    </strong>
                  </FormDescription>
                </FormGroup>
                <CTAButton
                  type="submit"
                  className="mcfit-tracking-checkout-3"
                  disabled={isSubmitting}
                >
                  {this.props.sending ? (
                    <FormattedMessage
                      id="checkout.sendingButton"
                      defaultMessage="Send"
                    />
                  ) : (
                    <FormattedMessage
                      id="checkout.sendButton"
                      defaultMessage="Send"
                    />
                  )}
                </CTAButton>
                <LegalWrapper>
                  ** Alle angegebenen Preise einschließlich Umsatzsteuer |
                  Preise und Öffnungszeiten können in einzelnen Studios
                  abweichen | Personen vor Vollendung des 15. Lebensjahres
                  können nicht Mitglied werden. | Anbieter:{' '}
                  {this.props.selectedStudioISO === 'AT'
                    ? 'RSG Group Österreich Ges.mbH, Doningasse 12/2/5, A-1220 Wien.'
                    : 'RSG Group GmbH, Tannenberg 4, 96132 Schlüsselfeld.'}
                </LegalWrapper>
              </FormGroup>
            </FormSidebar>
          </FormWrapper>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  redirectUrl: state.data.checkout.redirectUrl,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: data => {
    dispatch(sendData(data));
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(CampaignFormDE))
);
