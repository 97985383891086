import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormAside from '../../../../components/FormAside/FormAside';
import FormSidebar from '../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../components/FormWrapper/FormWrapper';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import InputGroupWrapper from '../../../../components/InputGroup/components/InputGroupWrapper';
import InputField from '../../../../components/InputGroup/components/InputField';
import StyledSelect from '../../../../components/InputGroup/components/StyledSelect';
import SelectArrow from '../../../../components/InputGroup/components/SelectArrow';
import Label from '../../../../components/InputGroup/components/Label';
import CTAButton from '../../../../components/CTAButton/CTAButton';
import contactFormSchema from './contactFormSchema';
import { sendContactForm } from '../../../../data/contact';

const options = [
  'Mitgliederverwaltung',
  'Presse',
  'Soziales',
  'Marketing',
  'Website',
  'Beschwerde',
  'Firmenfitness'
];

export const ContactForm = ({ member, sending, ...rest }) => (
  <Formik
    initialValues={{
      MemberNumber: (member && member.MemberNumber) || undefined,
      reason: '',
      name: member ? `${member.FirstName} ${member.LastName}` : '',
      email: member ? member.Email : '',
      text: ''
    }}
    validateOnBlur
    validateOnChange
    validationSchema={contactFormSchema}
    onSubmit={data => {
      rest.handleSubmit(data);
    }}
    render={({
      handleChange,
      values,
      handleBlur,
      handleSubmit,
      touched,
      errors
    }) => (
      <FormWrapper onSubmit={handleSubmit}>
        <FormSidebar>
          <FormGroup>
            <InputGroupWrapper type="select">
              <StyledSelect
                id="reason"
                onChange={handleChange}
                required
                name="reason"
                value={values.reason}
                touched={touched.reason}
                error={errors.reason}
              >
                <option value="" disabled>
                  {'Bitte wähle den Grund für deine Anfrage'}
                </option>
                {options.map(option => (
                  <option
                    value={option.value || option}
                    key={option.name || option}
                  >
                    {option.name || option}
                  </option>
                ))}
              </StyledSelect>
              <SelectArrow />
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputField
                autoComplete="name"
                id="name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Name*"
                required
                touched={touched.name}
                type="text"
                value={values.name}
                error={errors.name}
                name="name"
              />
              <Label htmlFor="name" hide>
                Name
              </Label>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputField
                autoComplete="email"
                id="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="E-Mail*"
                required
                touched={touched.email}
                type="email"
                value={values.email}
                error={errors.email}
                name="email"
              />
              <Label htmlFor="email" hide>
                E-Mail
              </Label>
            </InputGroupWrapper>
            <InputGroupWrapper>
              <InputField
                as="textarea"
                autoComplete="off"
                id="text"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Deine Anfrage*"
                required
                touched={touched.text}
                value={values.text}
                error={errors.text}
                name="text"
                rows={10}
              />
              <Label htmlFor="text" hide>
                Deine Anfrage
              </Label>
            </InputGroupWrapper>
          </FormGroup>
          <CTAButton disabled={sending} type="submit">
            Anfrage Abschicken
          </CTAButton>
          <p>
            Ja, ich habe die{' '}
            <a
              href="https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/"
              target="blank"
              rel="noopener"
            >
              Datenschutzerklärung
            </a>{' '}
            zur Kenntnis genommen und bin damit einverstanden, dass die von mir
            angegebenen Daten elektronisch erhoben und gespeichert werden. Meine
            Daten werden dabei nur streng zweckgebunden zur Bearbeitung und
            Beantwortung meiner Anfrage benutzt. Mit dem Absenden des
            Kontaktformulars erkläre ich mich mit der Verarbeitung
            einverstanden.
          </p>
        </FormSidebar>
        <FormAside>
          <p>
            Du hast Fragen, Anregung oder Wünsche? Wir haben immer ein offenes
            Ohr für dich. Schreibe uns dein Anliegen und hinterlasse deine
            Kontaktdaten inklusive E-Mail Adresse. Wir werden uns sobald wie
            möglich bei dir melden. Für Drehanfragen in unseren Studios, wende
            dich bitte an unsere Presseabteilung.
          </p>
        </FormAside>
      </FormWrapper>
    )}
  />
);

ContactForm.propTypes = {
  member: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  sending: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  member: state.data.user.member,
  sending: state.data.contact.sending
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: data => {
    dispatch(sendContactForm(data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm);
