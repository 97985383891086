import styled from 'styled-components';
import { H1 } from '../../../../components/Typo';
import { media } from '../../../../lib/styledMixins';

export default styled(H1)`
  color: ${props => props.theme.primary};
  font-size: 1.5em;
  font-weight: 700;
  margin: 3em 0 0.625em;
  text-align: center;
  text-transform: uppercase;

  span {
    text-transform: none;
  }

  ${media.sm`
    font-size: 2em;
  `}

  ${media.md`
    font-size: 3em;
    margin: 1em auto 0.625em;
    max-width: 48rem;
    transition: margin 0.3s ease-out;
  `};
`;
