import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { resetInput } from '../../../../../../../data/checkout';
import { IconCross } from '../../../../../../../components/Icons';
import { media } from '../../../../../../../lib/styledMixins';

export const ClearInputIconWrapper = styled.div`
  color: ${props => props.theme.white};
  cursor: pointer;
  height: 0.75em;
  position: absolute;
  right: 1.5rem;
  top: 0.75em;
  width: 0.75em;
  z-index: 99;

  ${media.md`
    height: 1.25em;
    top: 1.5em;
    width: 1.25em;
  `};
`;

export const ClearInputIcon = props => (
  <ClearInputIconWrapper
    onClick={() => {
      props.handleClear();
      props.handleClick();
    }}
  >
    <IconCross />
  </ClearInputIconWrapper>
);

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    dispatch(resetInput());
  }
});

ClearInputIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(ClearInputIcon);
