export const reffererTypeOptions = [
  {
    name: 'Amigos o familiares',
    value: '0'
  },
  {
    name: 'Publicidad en el exterior del gimnasio',
    value: 1
  },
  {
    name: 'TV',
    value: 2
  },
  {
    name: 'Radio',
    value: 3
  },
  {
    name: 'Carteles / Vallas publicitarias',
    value: 4
  },
  {
    name: 'Folleto en buzón',
    value: 5
  },
  {
    name: 'Folleto en mano',
    value: 6
  },
  {
    name: 'Prensa y revistas',
    value: 7
  },
  {
    name: 'Página web',
    value: 8
  },
  {
    name: 'Búsquedas en Google',
    value: 9
  },
  {
    name: 'Facebook',
    value: 10
  },
  {
    name: 'Instagram',
    value: 11
  },
  {
    name: 'Publicidad online',
    value: 12
  },
  {
    name: 'Otros',
    value: 13
  }
];

export default reffererTypeOptions;
