import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import './Modal.css';

import ModalHeader from './components/ModalHeader';
import CloseIcon from './components/CloseIcon';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

const Modal = ({
  children,
  backTo,
  close,
  oneColumn,
  brand,
  title,
  customTitle,
  ...props
}) => (
  <ReactModal
    className={`Modal ${oneColumn && 'Modal--one-column'}`}
    overlayClassName="Overlay"
    {...props}
  >
    <ModalHeader brand={brand}>
      {customTitle ? title : <FormattedMessage id={title} />}
      {backTo ? <CloseIcon to={backTo} /> : null}
      {close ? <CloseIcon close={close} /> : null}
    </ModalHeader>
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  title: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  customTitle: PropTypes.bool,
  backTo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  oneColumn: PropTypes.oneOfType([PropTypes.bool])
};

Modal.defaultProps = {
  backTo: false,
  close: false,
  oneColumn: false,
  customTitle: false
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName
});

export default connect(mapStateToProps)(Modal);
