import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';

import MessageWrapper from './components/MessageWrapper';
import Text from './components/Text';
import Description from './components/Description';
import Icon from './components/Icon';
import CloseButton from './components/CloseButton';
import {
  IconItalic,
  IconCheckmark,
  IconWrong,
  IconCross
} from '../../../Icons';
import { hideMessage } from '../../../../data/messages';

const renderIcon = type => {
  switch (type) {
    case 'alert':
    case 'warn':
      return <IconWrong />;
    case 'success':
      return <IconCheckmark />;
    case 'message':
    default:
      return <IconItalic />;
  }
};

export const Message = props => {
  function handleClick() {
    props.handleClose(props.id);
  }

  return (
    <MessageWrapper
      {...props}
      show={props.text.length || props.text.description}
    >
      <Icon>{renderIcon(props.type)}</Icon>
      <div>
        <Text>
          <FormattedMessage id={props.text} />
        </Text>
        <Description>
          {props.customDescription ? (
            <FormattedHTMLMessage id={props.description} />
          ) : (
            props.description.toString()
          )}
        </Description>
      </div>
      <CloseButton onClick={handleClick} type={props.type}>
        <IconCross />
      </CloseButton>
    </MessageWrapper>
  );
};

Message.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closeButton: PropTypes.string,
  customDescription: PropTypes.bool
};

Message.defaultProps = {
  closeButton: 'false',
  description: '',
  customDescription: false
};

const mapDispatchToProps = dispatch => ({
  handleClose: id => {
    dispatch(hideMessage(id));
  }
});

export default connect(null, mapDispatchToProps)(Message);
