import React from 'react';
import PropTypes from 'prop-types';

import InputGroupWrapper from './InputGroupWrapper';
import Label from './Label';
import StyledSelect from './StyledSelect';
import SelectArrow from './SelectArrow';

const SelectInput = ({
  type,
  width,
  name,
  label,
  required,
  autocomplete,
  onBlur,
  onChange,
  value,
  options,
  noLabel,
  margin,
  ...props
}) => (
  <InputGroupWrapper type={type} width={width} margin={margin}>
    {label !== '' && !noLabel ? (
      <Label htmlFor={name} type={type}>
        {required ? `${label}*` : label}
      </Label>
    ) : (
      ''
    )}
    <StyledSelect
      autoComplete={autocomplete}
      id={name}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      name={name}
      value={value}
      {...props}
    >
      <option value="" disabled>
        {label}
      </option>
      {options.map(option => (
        <option value={option.value || option} key={option.name || option}>
          {option.name || option}
        </option>
      ))}
    </StyledSelect>
    <SelectArrow />
  </InputGroupWrapper>
);

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  noLabel: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // eslint-disable-next-line
  options: PropTypes.array,
  width: PropTypes.string,
  autocomplete: PropTypes.string,
  type: PropTypes.string.isRequired
};

SelectInput.defaultProps = {
  autocomplete: 'on',
  label: '',
  required: false,
  noLabel: false,
  options: [],
  value: '',
  width: 'full'
};

export default SelectInput;
