import styled from 'styled-components/macro';

const ContractCopy = styled.div`
  color: ${props => props.theme.backgroundSecondary};
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 2rem;
`;

export default ContractCopy;
