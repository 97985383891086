const backgroundMain = '#fff';
const backgroundThird = '#292929';
const backgroundCard = '#ececec';

const contractBorder = '1px solid rgba(125, 125, 125, .5)';
const gradient = 'to right, #e12819 0%, #aa1e14 100%';

const studioDividerFirstThickness = '1';
const studioDividerThickness = '1';

const high5 = {
  color1: '#bababa',
  color2: '#777',
  color3: '#292929',
  color4: '#fff',
  color5: '#292929',
  color6: '#fff',
  color7:
    'linear-gradient(136deg, #292929 0%, #454545 31%, #454545 74%, #292929;)',
  color8: '#bababa',
  color9: '#E9E9E9',
  color10: '#292929',
  color11: '#292929',

  active1: '#E12819',
  active2: '#E6BE73',
  active3: '#fff',
  active4: '#fff',
  active5: '#E6BE73',

  alert: '#e12819',
  warn: '#F85901',
  info: '#33414f',
  success: '#0eda65',

  buttonTextSecondary: '#242f3a',
  backgroundMain: '#fff',
  backgroundSecondary: '#292929',
  backgroundThird: '#fff',
  backgroundCard: '#33414f',
  backgroundForm: '#F0F0F0',

  studioDividerFirstThickness,
  studioDividerThickness,

  // General
  mainBg: backgroundMain,
  footerBg: backgroundThird,
  cardBg: backgroundCard,
  studioActiveLayer: gradient,
  contractBorder,
  ctaMainHover: gradient,
  loginBtn: gradient,
  lightGrey: '#e9e9e9',
  white: '#fff',
  // Font Families
  ffRegular: "'Open Sans', sans-serif",
  ffCondensed: "'Open Sans Condensed', sans-serif",
  ffBrand: "'Franklin-Italic', sans-serif",

  brandId: 4,
  title: 'HIGH5',
  logo: 'high5.jpg'
};

export default high5;
