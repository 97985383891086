import styled from 'styled-components/macro';

const CrumbLineInactive = styled.span`
  background-color: ${props => props.theme.color5};
  height: 2px;
  left: ${props =>
    props.noContracts ? 'calc(100% / 3 / 2)' : 'calc(100% / 4 / 2)'};
  position: absolute;
  right: ${props =>
    props.noContracts ? 'calc(100% / 3 / 2)' : 'calc(100% / 4 / 2)'};
  top: 0;
  width: ${props => (props.noContracts ? '66%' : '75%')};
  transform-origin: 0 0;
`;

export default CrumbLineInactive;
