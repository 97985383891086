import styled from 'styled-components/macro';

const Table = styled.div`
  max-width: 85em;
  margin: 0 auto 4em auto;
  padding: 1em 2em;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
`;

export default Table;
