import haversine from 'haversine';

export default function calculateDistance({ latitude, longitude }, locations) {
  // calculate distance to location for all studios
  const studiosWithDistance = locations.map(studio => ({
    ...studio,
    distance: haversine(
      { latitude, longitude },
      { latitude: studio.Latitude, longitude: studio.Longitude }
    )
  }));
  // sort studios by distance
  const sortedStudios = studiosWithDistance.sort(
    (a, b) => a.distance - b.distance
  );

  return sortedStudios;
}
