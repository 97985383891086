import styled from 'styled-components/macro';

export const H1 = styled.h1`
  font-family: ${props => props.theme.ffBrand};
  line-height: 1.2;
`;

export const H2 = styled.h2``;

export const P = styled.p``;
