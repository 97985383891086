import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { IconExit } from '../../../../../../../../../components/Icons';
import { media } from '../../../../../../../../../lib/styledMixins';

export const StudioDetailLinkWrapper = styled.div``;

export const StyledLink = styled.a`
  color: ${props => props.theme.white};
  display: inline-block;
  display: none;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.9px;
  padding-bottom: 3px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: max-height 0.3s;

  ${media.md`
    max-height: 0;
  `};
`;

const SVGIcon = styled.div`
  height: 1.5em;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0.5em, -0.6em);
  width: 1.5em;
`;

const StudioDetailLink = props => (
  <StudioDetailLinkWrapper>
    <StyledLink href={props.to}>
      <FormattedMessage id="checkout.studioDetailLink" />
      <SVGIcon>
        <IconExit />
      </SVGIcon>
    </StyledLink>
  </StudioDetailLinkWrapper>
);

StudioDetailLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default StudioDetailLink;
