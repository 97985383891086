import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { IconCheckmark } from '../Icons';

const BulletWrapper = styled.li`
  list-style: none;
  margin-bottom: 1em;
  padding-left: 0.5em;
  position: relative;
`;

const StyledCheckmark = styled(IconCheckmark)`
  color: ${props => props.theme.active1};
  height: 1.4em;
  left: -1.8em;
  position: absolute;
  top: 0;
  width: 1.4em;
`;

const BulletPoint = props => (
  <BulletWrapper>
    {props.children !== '' ? (
      <span>
        <StyledCheckmark />
        {props.children}
      </span>
    ) : null}
  </BulletWrapper>
);

BulletPoint.propTypes = {
  children: PropTypes.string.isRequired
};

export default BulletPoint;
