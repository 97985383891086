import { combineReducers } from 'redux';

import checkout from './checkout';
import contracts from './contracts';
import iban from './iban';
import fiscalCode from './fiscalcode';
import messages from './messages';
import theme from './theme';
import userLocation from './location';
import tracking from './tracking';
import contact from './contact';
import testtraining from './testTraining';
import tsp from './tsp';
import foto from './foto';
import referralCode from './referralCode';
import upselling from './upselling';

export default combineReducers({
  checkout,
  contact,
  contracts,
  iban,
  fiscalCode,
  messages,
  testtraining,
  theme,
  userLocation,
  tracking,
  tsp,
  foto,
  referralCode,
  upselling
});
