import styled from 'styled-components/macro';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.footerBg};
  font-family: ${props => props.theme.ffCondensed};
  grid-area: footer;
  padding: 0 0 2em;
`;

export default FooterWrapper;
