import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';

import removeDoubleSlash from '../../../../../../../../../lib/removeDoubleSlash';
import addResponsive from '../../../../../../../../../components/addResponsive/addResponsive';
import { IconChevron } from '../../../../../../../../../components/Icons';
import CTAButton from '../../../../../../../../../components/CTAButton/CTAButton';
import { media } from '../../../../../../../../../lib/styledMixins';

export const StyledLink = styled(CTAButton)`
  font-size: 0.9em;
  text-decoration: none;
  padding: 1em 2em 1em 1em;
  position: relative;

  ${media.md`
    background-color: transparent;
    color: ${props => props.theme.color3};
    display: flex;
    align-items: center;
    font-size: 1em;
    padding: 0;

    &:hover {
      background: transparent;
    }
  `};
`;

export const Chevron = styled.div`
  height: 25px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-8px, -13px) rotate(-90deg);
  width: 25px;

  ${media.md`
    transform: translate(125%, -55%) rotate(-90deg);
  `};
`;

const SelectStudioButton = props => (
  <StyledLink
    to={removeDoubleSlash(`
      ${props.match.url}${props.intl.formatMessage({
      id: 'route.selectContract'
    })}/${props.id}
    `)}
  >
    <FormattedMessage id="checkout.studioSelectButton" />
    <Chevron>
      <IconChevron />
    </Chevron>
  </StyledLink>
);

SelectStudioButton.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: intlShape.isRequired,
  match: PropTypes.shape().isRequired,
  wide: PropTypes.bool.isRequired
};

export default compose(
  addResponsive,
  withRouter,
  injectIntl
)(SelectStudioButton);
