import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import LegalWrapper from '../LegalWrapper';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';

import { campaignSchema } from './contractFormSchemaIT';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import {
  fetchFotoUploadUrl,
  uploadFotoToUrl,
  resetFotoValidation,
  validateFileSize,
  resetFotoUpload
} from '../../../../../../../../data/foto';
import { validateIBAN } from '../../../../../../../../data/iban';
import { validateFiscalCode } from '../../../../../../../../data/fiscalcode';
import config from '../../../../../../../../config';

export class CampaignFormIT extends Component {
  static propTypes = {
    austria: PropTypes.bool,
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  static defaultProps = {
    austria: false
  };

  state = {
    lastCheckedIBAN: '',
    fotoSrc: '',
    showCameraModal: false
  };

  timer;
  webcamRef = React.createRef();

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleFiscalCode = fiscalCode => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (
        fiscalCode &&
        fiscalCode.length > 0 &&
        fiscalCode !== this.state.lastCheckedFicalCode
      ) {
        this.setState({ lastCheckedFicalCode: fiscalCode }, () => {
          this.props.handleFiscalCode(fiscalCode);
        });
      }
    }, 500);
  };

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  handleNewFoto = fotoSrc => {
    if (fotoSrc) {
      this.setState({ fotoSrc });
      this.props.handleFetchFotoUploadUrl();
    }
  };

  render() {
    const {
      brand,
      selectedStudioOpening,
      codiceFiscale,
      dateOfBirth,
      placeOfBirth,
      countryOfBirth,
      uploadUrl,
      handleUploadFoto,
      imageObjectKey
    } = this.props;

    const {
      Id,
      CountryCode,
      tsp,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate
    } = this.props.selectedContract;
    const hasTSP = tsp;

    const fiscalBirthDate = dateOfBirth.split('-');

    if (this.props.success) {
      window.location.href = `${this.props.redirectUrl}&redirectSuccess=${window.location.origin}/campaign/success&redirectFailure=${window.location.origin}/campaign/error`;
    }

    return (
      <Formik
        onSubmit={async ({
          AccountPassword,
          City,
          Email,
          FirstName,
          Gender,
          HouseNumber,
          IBAN,
          LastName,
          Phone,
          PostalCode,
          ProudClubNewsletter,
          Street,
          StudioMark,
          year,
          month,
          day,
          signature,
          ReferrerType,
          hasPremium,
          selectedStudioOpening,
          fiscalCode,
          placeOfBirth,
          countryOfBirth,
          province,
          documentNumber,
          documentType,
          referralCode,
          isCampaign
        }) => {
          const { fotoSrc } = this.state;
          if (fotoSrc && uploadUrl) {
            const uploadError = await handleUploadFoto(fotoSrc, uploadUrl);

            if (uploadError) {
              return;
            }
          }

          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: true,
              Phone,
              PostalCode,
              ProudClub: false,
              ProudClubNewsletter,
              Street,
              ReferrerType,
              Province: province,
              imageObjectKey
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            fiscalCode,
            placeOfBirth,
            countryOfBirth,
            documentIdentification: {
              documentNumber,
              documentType
            },
            currentTime: new Date(),
            isCampaign,
            referralCode
          };

          this.props.handleSubmit(data);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          IBAN: '',
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          CountryCode,
          brand,
          hasPremium,
          placeOfBirth,
          countryOfBirth,
          referralCode: '',
          isCampaign: true
        }}
        validationSchema={campaignSchema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          isSubmitting
        }) => {
          if (values.fiscalCode !== codiceFiscale && !!codiceFiscale) {
            setFieldValue('fiscalCode', codiceFiscale);
          }

          if (values.placeOfBirth !== placeOfBirth && !!placeOfBirth) {
            setFieldValue('placeOfBirth', placeOfBirth);
          }

          if (values.countryOfBirth !== countryOfBirth && !!countryOfBirth) {
            setFieldValue('countryOfBirth', countryOfBirth);
          }

          if (
            values.day !== fiscalBirthDate[2]?.replace(/^0/, '') &&
            !!fiscalBirthDate
          ) {
            setFieldValue('day', fiscalBirthDate[2]?.replace(/^0/, ''));
          }

          if (
            values.month !== fiscalBirthDate[1]?.replace(/^0/, '') &&
            !!fiscalBirthDate
          ) {
            setFieldValue('month', fiscalBirthDate[1]?.replace(/^0/, ''));
          }

          if (values.year !== fiscalBirthDate[0] && !!fiscalBirthDate) {
            setFieldValue('year', fiscalBirthDate[0]);
          }

          const isSending = this.props.sending || this.props.sendingFoto;

          if (this.props.success) {
            window.location.href = `${this.props.redirectUrl}&redirectSuccess=${window.location.origin}/campaign/success&redirectFailure=${window.location.origin}/campaign/error`;
          }

          return (
            <>
              <FormWrapper onSubmit={handleSubmit}>
                <FormSidebar campaign>
                  <FormGroup>
                    <FormGroupHeadlineWrap>
                      <FormGroupHeadline>Login</FormGroupHeadline>
                      <RequiredNote>* Campi obbligatori</RequiredNote>
                    </FormGroupHeadlineWrap>
                    <InputGroup
                      autoComplete="email"
                      error={errors.Email}
                      label="E-Mail"
                      name="Email"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Email}
                      type="email"
                      value={values.Email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Dati anagrafici</FormGroupHeadline>
                    <InputGroup
                      autoComplete="sex"
                      error={errors.Gender}
                      label="Geschlecht"
                      name="Gender"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={[
                        { name: 'Uomo', value: 'M' },
                        { name: 'Donna', value: 'W' }
                      ]}
                      required
                      touched={touched.Gender}
                      type="radio"
                      value={values.Gender}
                    />
                    {errors && touched && errors.gender && touched.gender ? (
                      <ErrorMessage>
                        <FormattedMessage id="validate.required" />
                      </ErrorMessage>
                    ) : null}
                    <InputGroup
                      autoComplete="given-name"
                      error={errors.FirstName}
                      label="Nome"
                      name="FirstName"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.FirstName}
                      type="text"
                      value={values.FirstName}
                    />
                    <InputGroup
                      autoComplete="family-name"
                      error={errors.LastName}
                      label="Cognome"
                      name="LastName"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.LastName}
                      type="text"
                      value={values.LastName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Codice fiscale</FormGroupHeadline>
                    <InputGroup
                      error={errors.cf}
                      label="Codice Fiscale"
                      name="fiscalCode"
                      onBlur={event => {
                        const { value } = event.target;
                        handleBlur(event);
                        handleChange(event);
                        this.handleFiscalCode(value);
                      }}
                      fetching={this.props.fetchingFiscalCode}
                      onChange={handleChange}
                      required
                      touched={touched.fiscalCode}
                      type="text"
                      value={values.fiscalCode}
                    />
                    <InputGroup
                      error={errors.placeOfBirth}
                      label="Luogo di nascita"
                      name="placeOfBirth"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.placeOfBirth}
                      type="text"
                      value={values.placeOfBirth}
                    />
                    <InputGroup
                      error={errors.countryOfBirth}
                      label="Paese di nascita (p.e. IT)"
                      name="countryOfBirth"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.countryOfBirth}
                      type="text"
                      value={values.countryOfBirth}
                    />
                    <InputGroup
                      error={errors.documentType}
                      label="TIPO DI DOCUMENTO"
                      name="documentType"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={[
                        {
                          name: "CARTA D'IDENTITA",
                          value: 'ID_CARD'
                        },
                        {
                          name: 'PASSPORTO',
                          value: 'PASSPORT'
                        },
                        {
                          name: 'PATENTE',
                          value: 'DRIVERS_LICENCE'
                        },
                        {
                          name: 'ALTRO',
                          value: 'OTHERS'
                        }
                      ]}
                      required
                      touched={touched.documentType}
                      type="select"
                      value={values.documentType}
                    />
                    <InputGroup
                      error={errors.documentNumber}
                      label="Numero del documento"
                      name="documentNumber"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.documentNumber}
                      type="text"
                      required
                      value={values.documentNumber}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Data di nascita</FormGroupHeadline>
                    <FormDescription as="div">
                      Per poter sottoscrivere il contratto online, devi aver
                      compiuto 18 anni.
                    </FormDescription>
                    <InputGroup
                      error={errors.day}
                      label="GIORNO"
                      name="day"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      options={Array.from({ length: 31 }, (v, k) => ++k)}
                      required
                      touched={touched.day}
                      type="select"
                      value={values.day}
                      width="thirds"
                    />
                    <InputGroup
                      error={errors.month}
                      label="MESE"
                      name="month"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      options={Array.from({ length: 12 }, (v, k) => ++k)}
                      required
                      touched={touched.month}
                      type="select"
                      value={values.month}
                      width="thirds"
                    />
                    <InputGroup
                      error={errors.year}
                      label="ANNO"
                      name="year"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={Array.from(
                        { length: 83 },
                        // eslint-disable-next-line no-return-assign,no-param-reassign
                        (v, k) => (k += getYear(subYears(Date.now(), 100)))
                      ).reverse()}
                      required
                      touched={touched.year}
                      type="select"
                      value={values.year}
                      width="thirds"
                    />
                    {errors &&
                    touched &&
                    ((errors.day && touched.day) ||
                      (errors.month && touched.month) ||
                      (errors.year && touched.year)) ? (
                      <ErrorMessage>
                        <FormattedMessage id="validate.required" />
                      </ErrorMessage>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Recapiti</FormGroupHeadline>
                    <InputGroup
                      autoComplete="address-line1"
                      error={errors.Street}
                      label="Via"
                      name="Street"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Street}
                      type="text"
                      value={values.Street}
                      width="middle"
                    />
                    <InputGroup
                      error={errors.HouseNumber}
                      label="N. Civico"
                      name="HouseNumber"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.HouseNumber}
                      type="text"
                      value={values.HouseNumber}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="postal-code"
                      error={errors.PostalCode}
                      label="CAP"
                      name="PostalCode"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.PostalCode}
                      type="text"
                      value={values.PostalCode}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="locality"
                      error={errors.City}
                      label="Città"
                      name="City"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.City}
                      type="text"
                      value={values.City}
                      width="small"
                    />
                    <InputGroup
                      error={errors.province}
                      label="PROVINCIA"
                      name="province"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={config.italianProvinces}
                      required
                      touched={touched.province}
                      type="select"
                      value={values.province}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="tel"
                      error={errors.Phone}
                      label="Telefono"
                      name="Phone"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.Phone}
                      type="tel"
                      value={values.Phone}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputField
                      autoComplete="off"
                      id="agb"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      required
                      onChange={handleChange}
                      touched={touched.Marketing}
                      type="checkbox"
                      value={values.Marketing}
                    />
                    <Label htmlFor="agb" type="checkbox" hide={false}>
                      <StyledCheckbox
                        checkMark
                        checked={values.agb}
                        error={errors.agb}
                      />
                      <CheckboxText>
                        * Accetto i termini e le{' '}
                        <a
                          href={
                            'https://www.mcfit.com/it/mcfitcom/condizioni-generali-di-contratto/'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          condizioni generali di contratto
                        </a>
                        . Confermo di aver preso visione{' '}
                        <a
                          href={
                            'https://www.mcfit.com/it/mcfitcom/diritto-di-recesso/'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          dell'informativa relativa al diritto di recesso
                        </a>{' '}
                        (incl. modulo per l’esercizio del recesso) e
                        dell’informativa sulla{' '}
                        <a
                          href={
                            'https://www.mcfit.com/it/mcfitcom/dati-personali/'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy
                        </a>
                        .
                      </CheckboxText>
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <FormGroup>
                      <CTAButton
                        type="submit"
                        className="mcfit-tracking-checkout-3"
                        disabled={this.props.fetchingUploadUrl || isSubmitting}
                      >
                        {this.props.sending ? (
                          <FormattedMessage
                            id="checkout.sendingButton"
                            defaultMessage="Send"
                          />
                        ) : (
                          <FormattedMessage
                            id="checkout.sendButton"
                            defaultMessage="Send"
                          />
                        )}
                      </CTAButton>
                    </FormGroup>
                    <FormDescription as="div">
                      <LegalWrapper>
                        ** Tutti i prezzi sono IVA inclusa | Prezzi e orari di
                        apertura fuori dall’Italia possono variare | Sono
                        escluse le persone che non hanno compiuto il 15esimo
                        anno di età, chi è già Abbonato McFIT e i precedenti
                        Abbonati che, in passato, non abbiano regolarmente
                        versato le proprie quote di abbonamento | Offerente: RSG
                        Group Italia Srl, Viale Fulvio Testi 29, 20162 Milano.
                      </LegalWrapper>
                    </FormDescription>
                  </FormGroup>
                </FormSidebar>
              </FormWrapper>
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  fetchingFiscalCode: state.data.fiscalCode.fetching,
  codiceFiscale: state.data.fiscalCode?.data.codiceFiscale,
  placeOfBirth: state.data.fiscalCode?.data.birthInformationDto.placeOfBirth,
  dateOfBirth: state.data.fiscalCode?.data.birthInformationDto.dateOfBirth,
  countryOfBirth:
    state.data.fiscalCode?.data.birthInformationDto.countryOfBirth,
  imageObjectKey: state.data.foto.objectKey,
  uploadUrl: state.data.foto.uploadUrl,
  fotoSizeError: state.data.foto.fotoSizeError,
  sendingFoto: state.data.foto.sendingFoto,
  fetchingUploadUrl: state.data.foto.fetchingUrl,
  referralCode: state.data.referralCode.referralCode,
  redirectUrl: state.data.checkout.redirectUrl
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: data => {
    dispatch(sendData(data));
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleFiscalCode: fiscalCode => {
    dispatch(validateFiscalCode(fiscalCode));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  },
  handleFetchFotoUploadUrl: () => {
    dispatch(fetchFotoUploadUrl());
  },
  handleUploadFoto: (foto, url) => {
    return dispatch(uploadFotoToUrl(foto, url));
  },
  resetFotoValidation: () => {
    dispatch(resetFotoValidation());
  },
  validateFileSize: file => {
    return dispatch(validateFileSize(file));
  },
  resetFotoUpload: () => {
    dispatch(resetFotoUpload());
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(CampaignFormIT))
);
