import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';

import LegalWrapper from '../LegalWrapper';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { campaignSchema } from './contractFormSchemaES';
import {
  fetchReferrals,
  sendData
} from '../../../../../../../../data/checkout';
import { validateIBAN } from '../../../../../../../../data/iban';

export class CampaignFormES extends Component {
  static propTypes = {
    BIC: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  timer;

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  render() {
    const { selectedStudioOpening } = this.props;
    const {
      termId,
      defaultContractStartDate,
      preuse
    } = this.props.selectedContract;

    if (this.props.success) {
      window.location.href = `${this.props.redirectUrl}&redirectSuccess=${window.location.origin}/campaign/success&redirectFailure=${window.location.origin}/campaign/error`;
    }

    return (
      <Formik
        onSubmit={({
          AccountPassword,
          BirthPlace,
          Block,
          City,
          ContractModelId,
          CountryCode,
          DniNie,
          Dni,
          Door,
          Email,
          FirstName,
          Floor,
          Gender,
          HouseNumber,
          IBAN,
          LastName,
          LastName2,
          Nie,
          Phone,
          Portal,
          PostalCode,
          ProudClubNewsletter,
          Province,
          Stairs,
          Street,
          StreetType,
          StudioMark,
          day,
          month,
          signature,
          year,
          ReferrerType,
          referralCode,
          isCampaign
        }) => {
          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId,
            FirstName,
            LastName,
            LastName2,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              BirthPlace,
              Block,
              City,
              CountryCode,
              DNINIE: DniNie,
              DNI: Dni,
              Door,
              Email,
              Floor,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: true,
              NIE: Nie,
              Phone,
              Portal,
              PostalCode,
              ProudClub: true,
              ProudClubNewsletter,
              Province,
              Stairs,
              Street,
              StreetType,
              ReferrerType
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            termId,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            referralCode,
            currentTime: new Date(),
            isCampaign
          };

          this.props.handleSubmit(data);
        }}
        initialValues={{
          ContractModelId: this.props.selectedContract.Id,
          CountryCode: this.props.selectedContract.CountryCode,
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          IBAN: '',
          referralCode: '',
          Street: '*',
          HouseNumber: '*',
          PostalCode: '*',
          City: '*',
          Province: '*',
          BirthPlace: '*',
          StreetType: '*',
          Block: '*',
          Portal: '*',
          Stairs: '*',
          Floor: '*',
          Door: '*',
          isCampaign: true
        }}
        validationSchema={campaignSchema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          isSubmitting
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <FormSidebar campaign>
              <FormGroup>
                <FormGroupHeadlineWrap>
                  <FormGroupHeadline>Datos de acceso</FormGroupHeadline>
                  <RequiredNote>* Campos obligatorios</RequiredNote>
                </FormGroupHeadlineWrap>
                <FormGroupHeadlineWrap style={{ marginBottom: '1.3em' }}>
                  <RequiredNote>
                    Una vez realizado tu contrato online podrás darte de alta y
                    acceder a tu área personal.
                  </RequiredNote>
                </FormGroupHeadlineWrap>
                <InputGroup
                  autoComplete="email"
                  error={errors.Email}
                  label="E-Mail"
                  name="Email"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.Email}
                  type="email"
                  value={values.Email}
                />
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Datos personales</FormGroupHeadline>
                <InputGroup
                  autoComplete="sex"
                  error={errors.Gender}
                  label="Sr./Sra"
                  name="Gender"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  options={[
                    { name: 'Hombre', value: 'M' },
                    { name: 'Mujer', value: 'W' }
                  ]}
                  required
                  touched={touched.Gender}
                  type="radio"
                  value={values.Gender}
                />
                {errors && touched && errors.gender && touched.gender ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.required" />
                  </ErrorMessage>
                ) : null}
                <InputGroup
                  autoComplete="given-name"
                  error={errors.FirstName}
                  label="Nombre"
                  name="FirstName"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.FirstName}
                  type="text"
                  value={values.FirstName}
                />
                <InputGroup
                  autoComplete="family-name"
                  error={errors.LastName}
                  label="Primer apellido"
                  name="LastName"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.LastName}
                  type="text"
                  value={values.LastName}
                />
                <InputGroup
                  error={errors.LastName2}
                  label="Segundo apellido"
                  name="LastName2"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  touched={touched.LastName2}
                  type="text"
                  value={values.LastName2}
                />
                <InputGroup
                  error={errors.DniNie}
                  label="dninie"
                  name="DniNie"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={[
                    { name: 'DNI', value: 'dni' },
                    { name: 'NIE', value: 'nie' }
                  ]}
                  required
                  touched={touched.DniNie}
                  type="radio"
                  value={values.DniNie}
                />
                {values.DniNie === 'dni' && (
                  <InputGroup
                    error={errors.Dni}
                    label="DNI"
                    name="Dni"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Dni}
                    type="text"
                    value={values.Dni}
                  />
                )}
                {values.DniNie === 'nie' && (
                  <InputGroup
                    error={errors.Nie}
                    label="NIE"
                    name="Nie"
                    onBlur={event => {
                      handleBlur(event);
                      handleChange(event);
                    }}
                    onChange={handleChange}
                    required
                    touched={touched.Nie}
                    type="text"
                    value={values.Nie}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Datos de nacimiento</FormGroupHeadline>
                <FormDescription as="div">
                  Tienes que tener mínimo 15 años para comprar el Healthy Pack.
                </FormDescription>
                <InputGroup
                  error={errors.day}
                  label="Día"
                  name="day"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  // eslint-disable-next-line no-plusplus, no-param-reassign
                  options={Array.from({ length: 31 }, (v, k) => ++k)}
                  required
                  touched={touched.day}
                  type="select"
                  value={values.day}
                  width="thirds"
                />
                <InputGroup
                  error={errors.month}
                  label="Mes"
                  name="month"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  // eslint-disable-next-line no-plusplus, no-param-reassign
                  options={Array.from({ length: 12 }, (v, k) => ++k)}
                  required
                  touched={touched.month}
                  type="select"
                  value={values.month}
                  width="thirds"
                />
                <InputGroup
                  error={errors.year}
                  label="Año"
                  name="year"
                  noLabel
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  options={Array.from(
                    { length: 86 },
                    // eslint-disable-next-line no-return-assign,no-param-reassign
                    (v, k) => (k += getYear(subYears(Date.now(), 100)))
                  ).reverse()}
                  required
                  touched={touched.year}
                  type="select"
                  value={values.year}
                  width="thirds"
                />
                {errors &&
                touched &&
                ((errors.day && touched.day) ||
                  (errors.month && touched.month) ||
                  (errors.year && touched.year)) ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.required" />
                  </ErrorMessage>
                ) : null}
                {errors && errors.olderThan15 ? (
                  <ErrorMessage>
                    <FormattedMessage id="validate.birthday15" />
                  </ErrorMessage>
                ) : null}
              </FormGroup>
              <FormGroup>
                <FormGroupHeadline>Datos de contacto</FormGroupHeadline>
                <InputGroup
                  autoComplete="tel"
                  error={errors.Phone}
                  label="TELÉFONO MÓVIL"
                  name="Phone"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  touched={touched.Phone}
                  type="tel"
                  value={values.Phone}
                />
              </FormGroup>
              <FormGroup>
                <InputField
                  autoComplete="off"
                  id="AGB"
                  onBlur={event => {
                    handleBlur(event);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  required
                  touched={touched.AGB}
                  type="checkbox"
                  value={values.AGB}
                />
                <Label htmlFor="AGB" type="checkbox" hide={false}>
                  <StyledCheckbox
                    checkMark
                    checked={values.AGB}
                    error={errors.AGB}
                  />
                  <CheckboxText>
                    * Acepto las Condiciones Particulares, las{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.mcfit.com/es/sobre-mcfitcom/condiciones-generales/"
                    >
                      Condiciones Generales,
                    </a>{' '}
                    las{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.mcfit.com/index.php?id=436&L=2"
                    >
                      Condiciones de uso del área personal,
                    </a>{' '}
                    la{' '}
                    <a
                      href="https://www.mcfit.com/es/sobre-mcfitcom/proteccion-de-datos/politica-de-privacidad-contratos-de-socio/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Politica de Privacidad
                    </a>{' '}
                    y la Información sobre el{' '}
                    <a
                      href="https://www.mcfit.com/es/sobre-mcfitcom/derecho-de-desistimiento/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Derecho de Desistimiento
                    </a>
                    .
                  </CheckboxText>
                </Label>
              </FormGroup>
              <FormGroup>
                <CTAButton type="submit" disabled={isSubmitting}>
                  {this.props.sending ? (
                    <FormattedMessage
                      id="checkout.sendingButton"
                      defaultMessage="Send"
                    />
                  ) : (
                    <FormattedMessage
                      id="checkout.sendButton"
                      defaultMessage="Send"
                    />
                  )}
                </CTAButton>
                <LegalWrapper>
                  ** Todos los precios indicados son IVA incluido | Los horarios
                  de apertura pueden variar de un gimnasio a otro | Puedes hacer
                  un contrato online si eres mayor de edad y eres titular de una
                  cuenta bancaria, si no, pásate por el gimnasio o contacta con
                  el departamento de atención al cliente.
                </LegalWrapper>
              </FormGroup>
            </FormSidebar>
          </FormWrapper>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudio: state.data.checkout.selectedStudio,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  referralCode: state.data.referralCode.referralCode,
  redirectUrl: state.data.checkout.redirectUrl
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: data => {
    dispatch(sendData(data));
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(CampaignFormES))
);
