import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter } from 'react-router-dom';

import Landingpage from './components/LandingpageTSP';
import Success from './components/components/Success/Sucess';
import Login from './components/TSPLogin';
import Resend from './components/TSPResend';

const TSP = ({ match }) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Switch>
      <Route exact path={`${match.url}`} component={Landingpage} />
      <Route
        path={`${match.url}/:type/success`}
        render={props => <Success {...props} />}
      />
      <Route path={`${match.url}/:type/:hash`} component={Landingpage} />
      <Route path={`${match.url}/login`} component={Login} />
      <Route path={`${match.url}/resend`} component={Resend} />
    </Switch>
  </>
);

export default withRouter(TSP);
