import styled from 'styled-components';

import CTAButton from '../../../components/CTAButton/CTAButton';
import { media } from '../../../lib/styledMixins';

const BackButton = styled(CTAButton)`
  z-index: 1;
  display: inline-block;
  margin-top: 2em;
  text-decoration: none;

  ${media.md`
    margin-top: 4em;
  `};
`;

export default BackButton;
