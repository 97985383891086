import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { IconClockwise } from '../../Icons';

const rotation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
`;

const animation = css`
  ${rotation} 2s infinite ease-in-out;
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.color9};
  color: ${props => props.theme.color2};
  display: flex;
  flex-direction: column;
  height: 320px;
  justify-content: center;
`;
const Text = styled.p``;
const RotatingIcon = styled(IconClockwise)`
  animation: ${animation};
  transform-origin: 14px 14px;
  width: 30px;
`;

const RotateDevice = () => (
  <Wrapper>
    <RotatingIcon />
    <Text>
      <FormattedMessage
        id="signature.rotateDevice"
        default="Bitte drehe dein Gerät"
      />
    </Text>
  </Wrapper>
);

export default RotateDevice;
