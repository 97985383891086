import styled from 'styled-components';

import { media } from '../../../../../../../lib/styledMixins';

const RemoveButtonWrapper = styled.div`
  width: 100%;

  ${media.md`
    width: auto;
  `}
`;

export default RemoveButtonWrapper;
