import { object, number, string, boolean, mixed } from 'yup';

import subYears from 'date-fns/subYears';
import getYear from 'date-fns/getYear';
import differenceInYears from 'date-fns/differenceInYears';

export const schemaML = object()
  .shape({
    day: number()
      .min(1)
      .max(31)
      .required('validate.required'),
    month: number()
      .min(1)
      .max(12)
      .required('validate.required'),
    year: number()
      .max(getYear(subYears(Date.now(), 18)))
      .min(getYear(subYears(Date.now(), 100)))
      .required('validate.required'),
    StudioMark: string().required(),
    FirstName: string().required('validate.required'),
    LastName: string().required('validate.required'),
    LastName2: string(),
    DniNie: string().required('validate.required'),
    Dni: string().when('DniNie', {
      is: val => val === 'dni',
      then: string().required('yup.cf.spanishIdentificationMismatch'),
      otherwise: string()
    }),
    Nie: string().when('DniNie', {
      is: val => val === 'nie',
      then: string().required('yup.cf.spanishIdentificationMismatch'),
      otherwise: string()
    }),
    IBAN: string().required('validate.required'),
    BIC: string(),
    City: string().required('validate.required'),
    CountryCode: string().required(),
    Email: string()
      .email('validate.email')
      .required('validate.required'),
    Gender: string().required('validate.required'),
    HouseNumber: string().required('validate.required'),
    PostalCode: string()
      .min(5, 'validate.postalCode')
      .max(5, 'validate.postalCode')
      .required('validate.required'),
    Marketing: boolean(),
    ProudClub: boolean(),
    ProudClubNewsletter: boolean(),
    Street: string().required('validate.required'),
    Province: string().required('validate.required'),
    StreetType: string().required('validate.required'),
    Phone: string().nullable(),
    Block: string(),
    Portal: string(),
    Stairs: string(),
    Floor: string(),
    Door: string(),
    ReferrerType: mixed(),
    olderThan18: boolean().test('min-age-18', 'validate.birthday', function(
      val
    ) {
      const { day, month, year } = this.parent;
      if (!day || !month || !year) {
        return true;
      }
      const monthIndex = month - 1;

      return (
        differenceInYears(new Date(), new Date(year, monthIndex, day)) >= 18
      );
    }),
    referralCode: string(),
    signature: string().required('validate.required')
  })
  .required();

export const campaignSchema = object()
  .shape({
    day: number()
      .min(1)
      .max(31)
      .required('validate.required'),
    month: number()
      .min(1)
      .max(12)
      .required('validate.required'),
    year: number()
      .max(getYear(subYears(Date.now(), 15)))
      .min(getYear(subYears(Date.now(), 100)))
      .required('validate.required'),
    StudioMark: string().required(),
    FirstName: string().required('validate.required'),
    LastName: string().required('validate.required'),
    LastName2: string(),
    DniNie: string().required('validate.required'),
    Dni: string().when('DniNie', {
      is: val => val === 'dni',
      then: string().required('yup.cf.spanishIdentificationMismatch'),
      otherwise: string()
    }),
    Nie: string().when('DniNie', {
      is: val => val === 'nie',
      then: string().required('yup.cf.spanishIdentificationMismatch'),
      otherwise: string()
    }),
    IBAN: string(),
    BIC: string(),
    City: string(),
    CountryCode: string(),
    Email: string()
      .email('validate.email')
      .required('validate.required'),
    Gender: string().required('validate.required'),
    Marketing: boolean(),
    ProudClub: boolean(),
    ProudClubNewsletter: boolean(),
    ReferrerType: mixed(),
    olderThan15: boolean().test('min-age-15', 'validate.birthday15', function(
      val
    ) {
      const { day, month, year } = this.parent;
      if (!day || !month || !year) {
        return true;
      }
      const monthIndex = month - 1;

      return (
        differenceInYears(new Date(), new Date(year, monthIndex, day)) >= 15
      );
    }),
    referralCode: string(),
    signature: string(),
    additionalAddressInformation: string()
  })
  .required();
