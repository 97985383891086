import styled from 'styled-components/macro';

const CheckboxText = styled.div`
  flex-basis: auto;

  p {
    margin-top: 0;
  }
`;

export default CheckboxText;
