import styled, { css } from 'styled-components';

export default styled.li`
  display: inline-block;
  font-size: 30px;
  line-height: 20px;
  margin-right: 20px;
  color: ${props => props.theme.active1};
  cursor: pointer;
  transform: scale(0.8);

  ${props =>
    props.active &&
    css`
      transform: scale(1);
    `}
`;
