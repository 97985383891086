import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import StudioFinder from './components/StudioFinder/StudioFinder';
import Contracts from './components/Contracts/Contracts';
import Data from './components/Data/Data';
import Success from './components/Success/Success';
import CampaignSuccess from './components/Success/CampaignSuccess';
import CampaingError from './components/Error/CampaignError';
import Upselling from './components/Upselling/Upselling';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    // Redirect High5 to new page
    if (props.brand === 'high5') {
      window.location.href = 'https://www.high5.com';
      return undefined;
    }
    if (props.brand === 'mcfit') {
      /*  Find match in pathname between "/studio/" and a possible "?" or end 
        of string. Eg: "/studio/12345" or "/studio/12345?lang=es". In this case, 
        the match will be a contract ID. */
      const regex = new RegExp(/\/studio\/(.*?)(\?|$)/g);
      const match = regex.exec(props.location.pathname);
      const contractId = parseFloat(match?.[1]);

      if (!contractId || !props.location?.pathname?.includes('/studio/')) {
        /*  If the contractId is not found in the firmenFitnessContracts array, 
          redirect to the new checkout */
        window.location.href = `https://www.mcfit.com/${props.trackLang ||
          'de'}/checkout`;
        return undefined;
      }

      // Redirect spanish firmenFitness contracts to the new checkout
      if (props.firmenFitnessContracts.includes(contractId)) {
        window.location.href = `https://www.mcfit.com/es/checkout`;
        return undefined;
      }
    }
  }

  render() {
    const { match, intl } = this.props;

    return (
      <Switch>
        <Redirect from="/:url*(/+)" to={match.url.slice(0, -1)} />
        <Route exact path={match.url} component={StudioFinder} />
        <Route path={`/checkout/upsell`} component={Upselling} />
        <Route
          path={`${match.url}/studio/:contractId`}
          component={StudioFinder}
        />
        <Route
          path={`${match.url}${intl.formatMessage({
            id: 'route.selectContract'
          })}/:studioId?`}
          render={renderProps => (
            <Contracts
              studioId={renderProps.match.params.studioId}
              {...renderProps}
            />
          )}
        />
        <Route
          path={`${match.url}${intl.formatMessage({
            id: 'route.enterData'
          })}/:contractId/:studioId`}
          render={() => <Data />}
        />
        <Route
          path={`/checkout${intl.formatMessage({
            id: 'route.success'
          })}`}
          render={renderProps => <Success {...renderProps} />}
        />
        <Route
          path={`/campaign/success`}
          render={renderProps => <CampaignSuccess {...renderProps} />}
        />
        <Route
          path={`/campaign/error`}
          render={renderProps => <CampaingError {...renderProps} />}
        />
      </Switch>
    );
  }
}
Checkout.propTypes = {
  match: PropTypes.shape().isRequired,
  brand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // eslint-disable-next-line react/no-typos
  intl: intlShape.isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName,
  trackLang: state.data.tracking.tracklang,
  firmenFitnessContracts: state.data.checkout.firmenFitnessContracts
});

export default withRouter(injectIntl(connect(mapStateToProps)(Checkout)));
