import styled from 'styled-components';
import { P } from '../Typo/index';

import { media } from '../../lib/styledMixins';

const Description = styled(P)`
  color: ${props => props.theme.color3};
  font-size: 0.9em;
  margin: ${props => (props.campaign ? '2em auto 2em' : '4em auto 0')};
  max-width: 35rem;
  text-align: center;

  ${media.md`
    font-size: 1.125em;
    line-height: 1.44;
    margin: 0 auto 1em;
  `};

  a,
  a:link,
  a:visited {
    color: ${props => props.theme.color3};
  }
`;

export default Description;
