import { object, string, date } from 'yup';

export default object().shape({
  gender: string().required('validate.required'),
  firstName: string().required('validate.required'),
  lastName: string().required('validate.required'),
  birthday: date().required('validate.required'),
  email: string()
    .email()
    .required('validate.required'),
  countryCode: string()
    .matches(/^[a-z]{2}$/, 'Länderkürzel bestehen aus zwei Zeichen')
    .required('validate.required')
});
