import uuid from 'uuid/v4';
import { displayMessage } from '../messages';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';
export const FETCH_CONTRACT_DATA = 'FETCH_CONTRACT_DATA';
export const FETCH_CONTRACT_DATA_SUCCESS = 'FETCH_CONTRACT_DATA_SUCCESS';
export const FETCH_CONTRACT_DATA_ERROR = 'FETCH_CONTRACT_DATA_ERROR';

function setMemberData(data) {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    payload: data
  };
}

function getMemberError() {
  return {
    type: FETCH_USER_DATA_ERROR
  };
}

export function getMemberData(token: string) {
  return async dispatch => {
    function onSuccess(data) {
      return dispatch(setMemberData(data));
    }

    function onError(response) {
      dispatch(getMemberError());
      dispatch(
        displayMessage(
          {
            text: 'messages.receiveDataError',
            type: 'alert'
          },
          uuid()
        )
      );
      return response;
    }

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });
      const response = await fetch(`/api/member/${token}`, {
        method: 'POST',
        headers
      });
      const json = await response.json();

      return onSuccess(json);
    } catch (err) {
      return onError(err);
    }
  };
}

function setContractData(data) {
  return {
    type: FETCH_CONTRACT_DATA_SUCCESS,
    payload: data
  };
}

function getContractError() {
  return {
    type: FETCH_CONTRACT_DATA_ERROR
  };
}

export function getUpsellContract(studioId: number) {
  return async (dispatch, getState) => {
    const { brandId } = getState().data.theme.variables;

    function onSuccess(data) {
      return dispatch(setContractData(data));
    }

    function onError(response) {
      dispatch(getContractError());
      dispatch(
        displayMessage(
          {
            text: 'messages.receiveDataError',
            type: 'alert'
          },
          uuid()
        )
      );
      return response;
    }

    let studio = null;
    try {
      studio = (
        await (await fetch(`/api/studio/${studioId}/${brandId}`)).json()
      )?.studio;
    } catch (error) {
      return onError(error);
    }

    if (!studio) {
      return onError('');
    }

    try {
      const response = await fetch(
        `/api/contracts/${studioId}/${studio.CountryCode}`
      );
      const json = await response.json();
      const contract = json?.data.find(contract => contract.isFebCampaign);

      return onSuccess(contract);
    } catch (error) {
      return onError(error);
    }
  };
}

export default function reducer(
  state = {
    contract: null,
    member: null,
    isFetching: false
  },
  action
) {
  switch (action.type) {
    case FETCH_USER_DATA:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        member: {
          ...action.payload
        }
      };
    case FETCH_CONTRACT_DATA:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_CONTRACT_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        contract: {
          ...action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
}
