import styled from 'styled-components';

import { media } from '../../../../lib/styledMixins';

export default styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 20%;
  transform: translate(-50%, 6px);

  ${media.md`
    display: block;
  `}

  img {
    max-width: 30vh;
  }
`;
