import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import config from '../../../config';
import SocialLink from './SocialLink';

const { socialLinks } = config;

const SocialNavigationWrapper = styled.div``;

const FooterNavigation = ({ brand, lang }) => (
  <SocialNavigationWrapper>
    {brand
      ? socialLinks[lang][brand].map(item => (
          <SocialLink key={item.name} {...item} />
        ))
      : null}
  </SocialNavigationWrapper>
);

FooterNavigation.propTypes = {
  brand: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName,
  lang: state.intl.locale
});

export default connect(mapStateToProps)(FooterNavigation);
