import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Clipboard from 'react-clipboard.js';

import { media } from '../../../../../lib/styledMixins';
import { displayAutoCloseMessage } from '../../../../../data/messages';

const MemberNumberWrapper = styled.div`
  background-color: ${props => props.theme.backgroundCard};
  box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.3);
  margin: 2em auto;
  max-width: 32em;
  padding: 3em;
  text-align: center;

  ${media.md`
    margin: 4em auto;
  `};
`;

const MemberNumberDescription = styled.p`
  color: ${props => props.theme.color2};
  font-family: ${props => props.theme.ffCondensed};
  margin-top: 0;
  margin-bottom: 1.25em;
  text-align: center;
  text-align: left;
  text-transform: uppercase;
`;

const MemberNumberNumberContainer = styled.div``;

const MemberNumberNumber = styled.p`
  color: ${props => props.theme.color3};
  display: block;
  font-family: ${props => props.theme.ffRegular};
  font-size: 1.5em;
  letter-spacing: 0.5em;
  margin-bottom: 1em;
`;

const MemberNumberCTA = styled.div`
  display: block;
  white-space: nowrap;

  a,
  button {
    display: inline-block;
    cursor: pointer;
    background: transparent;
    letter-spacing: 1px;
    line-height: 50px;
    font-size: 1rem;
    height: 50px;
    color: ${props => props.theme.color3};
    font-family: ${props => props.theme.ffCondensed};
    text-transform: uppercase;
    border: 2px solid ${props => props.theme.lightGrey};
    padding: 0 20px;
    text-decoration: none;

    ${media.lg`min-width: 270px;`};
  }
`;

const MemberNumber = props => (
  <MemberNumberWrapper>
    <MemberNumberDescription>
      <FormattedMessage
        id="memberNumber.description"
        defaultMessage="Deine Mitgliedsnummer"
      />
    </MemberNumberDescription>
    <MemberNumberNumberContainer>
      <MemberNumberNumber>{props.memberNumber}</MemberNumberNumber>
      <MemberNumberCTA>
        <Clipboard
          data-clipboard-text={props.memberNumber}
          onSuccess={props.handleCopy}
        >
          <FormattedMessage id="welcome.copyMemberNumber" />
        </Clipboard>
      </MemberNumberCTA>
    </MemberNumberNumberContainer>
  </MemberNumberWrapper>
);

MemberNumber.propTypes = {
  memberNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  handleCopy: PropTypes.func.isRequired
};

MemberNumber.defaultProps = {
  memberNumber: false
};

const mapStateToProps = state => ({
  memberNumber: state.data.checkout.memberNumber
});

const mapDispatchToProps = dispach => ({
  handleCopy: () => {
    dispach(
      displayAutoCloseMessage({
        text: 'messages.memberNumberCopySuccess',
        type: 'success'
      })
    );
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MemberNumber)
);
