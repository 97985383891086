import styled, { css } from 'styled-components/macro';

const StyledCheckbox = styled.div`
  background-color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.color9};
  display: block;
  flex-basis: 32px;
  flex-shrink: 0;
  height: 32px;
  margin-right: 1em;
  position: relative;

  &::after {
    background-color: ${props => props.theme.active1};
    content: '';
    height: 20px;
    left: 50%;
    margin: -10px 0 0 -10px;
    position: absolute;
    top: 50%;
    transform: scale(0);
    width: 20px;
  }

  ${props =>
    props.checked &&
    css`
      &::after {
        transform: scale(1);
        transition: transform 0.1s cubic-bezier(0.57, 0.15, 0.69, 1);
        transition: transform 0.1s cubic-bezier(0.57, 0.15, 0.69, 1.26);
      }
    `};

  ${props =>
    props.error &&
    css`
      border: 2px solid ${props.theme.alert};
    `};
`;

export default StyledCheckbox;
