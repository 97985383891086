import styled, { keyframes, css } from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';

import {
  IconFacebook,
  IconInstagram,
  IconYoutube,
  IconTwitter
} from '../../Icons';

const LinkWrapper = styled.a`
  color: ${props => props.theme.color8};
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease-out;
  position: relative;
  margin-left: 1.5rem;

  &:first-child {
    margin: 0;
  }
`;

const highlight = keyframes`
  0% {
    transform: scale(.9);
  }

  25% {
    transform: scale(1);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(.9);
  }
`;

const animation = css`
  ${highlight} 0.5s;
`;

const SVGWrapper = styled.div`
  height: 2.2rem;
  transform: scale(0.9);
  width: 2.2rem;

  &:hover {
    color: ${props => props.theme.color8Hover};
    animation: ${animation};
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
`;

const A11y = styled.span`
  border: 0;
  height: 1px;
  overflow: hidden;
  position: absolute;
  padding: 0;
  width: 1px;
`;

export const FooterNavigationLink = ({ href, name }) => (
  <LinkWrapper href={href} target="_blank">
    <SVGWrapper>
      {name === 'Facebook' && <IconFacebook />}
      {name === 'Twitter' && <IconTwitter />}
      {name === 'Youtube' && <IconYoutube />}
      {name === 'Instagram' && <IconInstagram />}
      <A11y className="a11y">{name}</A11y>
    </SVGWrapper>
  </LinkWrapper>
);

FooterNavigationLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
  // icon: PropTypes.func.isRequired,
};

export default FooterNavigationLink;
