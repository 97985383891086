import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import getDate from 'date-fns/getDate';
import { Formik } from 'formik';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import CTAButton from '../CTAButton';
import CheckboxText from '../../../../../../../../components/CheckboxInput/CheckboxText';
import ErrorMessage from '../../../../../../../../components/InputGroup/components/ErrorMessage';
import FormAside from '../../../../../../../../components/FormAside/FormAside';
import FormDescription from '../../../../../../../../components/FormDescription/FormDescription';
import FormGroup from '../../../../../../../../components/FormGroup/FormGroup';
import FormGroupHeadline from '../../../../../../../../components/FormGroupHeadline/FormGroupHeadline';
import FormSidebar from '../../../../../../../../components/FormSidebar/FormSidebar';
import FormWrapper from '../../../../../../../../components/FormWrapper/FormWrapper';
import InputField from '../../../../../../../../components/InputGroup/components/InputField';
import InputGroup from '../../../../../../../../components/InputGroup/InputGroup';
import InputGroupWrapper from '../../../../../../../../components/InputGroup/components/InputGroupWrapper';
import Label from '../../../../../../../../components/InputGroup/components/Label';
import LegalWrapper from '../LegalWrapper';
import SelectedContract from '../../../SelectedContract';
import PictureUploadWrapper from '../PictureUploadWrapper';
import StyledCheckbox from '../../../../../../../../components/CheckboxInput/StyledCheckbox';
import FormGroupHeadlineWrap from '../../../../../../../../components/FormGroupHeadlineWrap/FormGroupHeadlineWrap';
import RequiredNote from '../../../../../../../../components/RequiredNote/RequiredNote';
import { FileUploadInput } from '../../../../../../../../components/InputGroup/components/FileUploadInput/FileUploadInput';
import { ImagePreview } from '../../.././../../../../../components/ImagePreview/ImagePreview';
import { CameraFeed } from '../../../../../../../../components/CameraFeed/CameraFeed';
import RemoveButtonWrapper from '../RemoveButtonWrapper';
import SecondaryCTAButton from '../SecondaryCTAButton';
import PreviewPictureWrapper from '../PreviewPictureWrapper';

import { schemaML, flexSchema } from './contractFormSchemaIT';
import {
  sendData,
  fetchReferrals
} from '../../../../../../../../data/checkout';
import {
  fetchFotoUploadUrl,
  uploadFotoToUrl,
  resetFotoValidation,
  validateFileSize,
  resetFotoUpload
} from '../../../../../../../../data/foto';
import { validateIBAN } from '../../../../../../../../data/iban';
import { validateFiscalCode } from '../../../../../../../../data/fiscalcode';
import config, { configStage } from '../../../../../../../../config';

export class ContractFormIT extends Component {
  static propTypes = {
    austria: PropTypes.bool,
    BIC: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    fetchingIBAN: PropTypes.bool.isRequired,
    handleIBAN: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    selectedContract: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired
  };

  static defaultProps = {
    austria: false
  };

  state = {
    lastCheckedIBAN: '',
    fotoSrc: '',
    showCameraModal: false
  };

  timer;
  webcamRef = React.createRef();

  componentDidMount() {
    const {
      handleReferrals,
      match: {
        params: { studioId }
      }
    } = this.props;

    handleReferrals(studioId);
  }

  handleFiscalCode = fiscalCode => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (
        fiscalCode &&
        fiscalCode.length > 0 &&
        fiscalCode !== this.state.lastCheckedFicalCode
      ) {
        this.setState({ lastCheckedFicalCode: fiscalCode }, () => {
          this.props.handleFiscalCode(fiscalCode);
        });
      }
    }, 500);
  };

  handleVerifyIBAN = IBAN => {
    clearTimeout(this.timer);
    const trimmedIBAN = IBAN.replace(/\s/g, '');

    this.timer = setTimeout(() => {
      if (
        trimmedIBAN.length >= 15 &&
        trimmedIBAN !== this.state.lastCheckedIBAN
      ) {
        this.setState({ lastCheckedIBAN: trimmedIBAN }, () => {
          this.props.handleIBAN(trimmedIBAN);
        });
      }
    }, 500);
  };

  handleNewFoto = fotoSrc => {
    if (fotoSrc) {
      this.setState({ fotoSrc });
      this.props.handleFetchFotoUploadUrl();
    }
  };

  render() {
    const contractTags =
      window.location.host.match(/stage/) ||
      process.env.NODE_ENV === 'development'
        ? configStage.contractTags
        : config.contractTags;
    const {
      brand,
      selectedStudioOpening,
      codiceFiscale,
      dateOfBirth,
      placeOfBirth,
      countryOfBirth,
      uploadUrl,
      handleUploadFoto,
      imageObjectKey,
      member
    } = this.props;

    const {
      Id,
      CountryCode,
      tsp,
      termId,
      hasGold,
      hasPremium,
      tags,
      preuse,
      defaultContractStartDate,
      isFebCampaign,
      isUpsell
    } = this.props.selectedContract;
    const hasTSP = tsp;
    const isFlexContract = !!tags.some(
      tag => tag.identifier === contractTags[brand]['Flex']
    );
    const schema = isFlexContract ? flexSchema : schemaML;
    const fiscalBirthDate = dateOfBirth.split('-');

    return (
      <Formik
        onSubmit={async (
          {
            AccountPassword,
            City,
            Email,
            FirstName,
            Gender,
            HouseNumber,
            IBAN,
            LastName,
            Marketing,
            Phone,
            PostalCode,
            ProudClubNewsletter,
            Street,
            StudioMark,
            year,
            month,
            day,
            signature,
            ReferrerType,
            hasPremium,
            selectedStudioOpening,
            fiscalCode,
            placeOfBirth,
            countryOfBirth,
            province,
            documentNumber,
            documentType,
            referralCode,
            uuid,
            pcConsent
          },
          actions
        ) => {
          const { fotoSrc } = this.state;
          if (fotoSrc && uploadUrl) {
            const uploadError = await handleUploadFoto(fotoSrc, uploadUrl);

            if (uploadError) {
              return;
            }
          }

          const optionData =
            hasTSP || hasGold || hasPremium
              ? [
                  {
                    Date: format(Date.now(), 'yyyy-MM-dd'),
                    Id: Date.now(),
                    AttribJSON: null
                  }
                ]
              : [];

          const data = {
            Birthday: `${year}-${month}-${day}`,
            AccountPassword,
            StudioMark,
            ContractModelId: Id,
            FirstName,
            LastName,
            AdditionalMemberData: {
              BIC: this.props.BIC,
              City,
              CountryCode,
              Email,
              Gender,
              HouseNumber,
              IBAN: IBAN.replace(/\s/g, ''),
              Marketing: Marketing || pcConsent,
              Phone,
              PostalCode,
              ProudClubNewsletter,
              Street,
              ReferrerType,
              Province: province,
              imageObjectKey
            },
            SignaturesData: {
              SIGNATURE_ACCOUNTHOLDER_MANDATE: signature
            },
            OptionData: optionData,
            termId,
            brand,
            hasPremium,
            tags,
            selectedStudioOpening,
            preuse,
            defaultContractStartDate,
            fiscalCode,
            placeOfBirth,
            countryOfBirth,
            documentIdentification: {
              documentNumber,
              documentType
            },
            referralCode,
            currentTime: new Date(),
            uuid,
            pcConsent
          };

          this.props.handleSubmit(data, actions);
        }}
        initialValues={{
          StudioMark: this.props.match.params.studioId,
          selectedStudioOpening,
          BIC: '',
          hasTSP,
          acceptedTSP: false,
          pcConsent: false,
          CountryCode,
          brand,
          hasPremium,
          placeOfBirth,
          countryOfBirth,
          referralCode: this.props.referralCode,
          ...(member
            ? {
                uuid: member.customerUUID,
                FirstName: member.firstname,
                LastName: member.lastname,
                Gender: member.gender === 'MALE' ? 'M' : 'W',
                Email: member.email,
                day: getDate(new Date(member.dateOfBirth)),
                month: getMonth(new Date(member.dateOfBirth)) + 1,
                year: getYear(new Date(member.dateOfBirth)),
                Phone: member.phone
              }
            : {})
        }}
        validationSchema={schema}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          isSubmitting
        }) => {
          if (values.fiscalCode !== codiceFiscale && !!codiceFiscale) {
            setFieldValue('fiscalCode', codiceFiscale);
          }

          if (values.placeOfBirth !== placeOfBirth && !!placeOfBirth) {
            setFieldValue('placeOfBirth', placeOfBirth);
          }

          if (values.countryOfBirth !== countryOfBirth && !!countryOfBirth) {
            setFieldValue('countryOfBirth', countryOfBirth);
          }

          if (
            values.day !== fiscalBirthDate[2]?.replace(/^0/, '') &&
            !!fiscalBirthDate
          ) {
            setFieldValue('day', fiscalBirthDate[2]?.replace(/^0/, ''));
          }

          if (
            values.month !== fiscalBirthDate[1]?.replace(/^0/, '') &&
            !!fiscalBirthDate
          ) {
            setFieldValue('month', fiscalBirthDate[1]?.replace(/^0/, ''));
          }

          if (values.year !== fiscalBirthDate[0] && !!fiscalBirthDate) {
            setFieldValue('year', fiscalBirthDate[0]);
          }

          const isSending = this.props.sending || this.props.sendingFoto;

          return this.props.success ? (
            <Redirect
              to={`/checkout${this.props.intl.formatMessage({
                id: 'route.success'
              })}`}
            />
          ) : (
            <>
              <FormWrapper onSubmit={handleSubmit}>
                <FormSidebar campaign={isFebCampaign && !isUpsell}>
                  <FormGroup>
                    <FormGroupHeadlineWrap>
                      <FormGroupHeadline>Login</FormGroupHeadline>
                      <RequiredNote>* Campi obbligatori</RequiredNote>
                    </FormGroupHeadlineWrap>
                    <InputGroup
                      autoComplete="email"
                      error={errors.Email}
                      label="E-Mail"
                      name="Email"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Email}
                      type="email"
                      value={values.Email}
                      readOnly={!!member?.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Dati anagrafici</FormGroupHeadline>
                    <InputGroup
                      autoComplete="sex"
                      error={errors.Gender}
                      label="Geschlecht"
                      name="Gender"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={[
                        { name: 'Uomo', value: 'M' },
                        { name: 'Donna', value: 'W' }
                      ]}
                      required
                      touched={touched.Gender}
                      type="radio"
                      value={values.Gender}
                      readOnly={!!member?.gender}
                    />
                    {errors && touched && errors.gender && touched.gender ? (
                      <ErrorMessage>
                        <FormattedMessage id="validate.required" />
                      </ErrorMessage>
                    ) : null}
                    <InputGroup
                      autoComplete="given-name"
                      error={errors.FirstName}
                      label="Nome"
                      name="FirstName"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.FirstName}
                      type="text"
                      value={values.FirstName}
                      readOnly={!!member?.firstname}
                    />
                    <InputGroup
                      autoComplete="family-name"
                      error={errors.LastName}
                      label="Cognome"
                      name="LastName"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.LastName}
                      type="text"
                      value={values.LastName}
                      readOnly={!!member?.lastname}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Codice fiscale</FormGroupHeadline>
                    <InputGroup
                      error={errors.cf}
                      label="Codice Fiscale"
                      name="fiscalCode"
                      onBlur={event => {
                        const { value } = event.target;
                        handleBlur(event);
                        handleChange(event);
                        this.handleFiscalCode(value);
                      }}
                      fetching={this.props.fetchingFiscalCode}
                      onChange={handleChange}
                      required
                      touched={touched.fiscalCode}
                      type="text"
                      value={values.fiscalCode}
                    />
                    <InputGroup
                      error={errors.placeOfBirth}
                      label="Luogo di nascita"
                      name="placeOfBirth"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.placeOfBirth}
                      type="text"
                      value={values.placeOfBirth}
                    />
                    <InputGroup
                      error={errors.countryOfBirth}
                      label="Paese di nascita (p.e. IT)"
                      name="countryOfBirth"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.countryOfBirth}
                      type="text"
                      value={values.countryOfBirth}
                    />
                    <InputGroup
                      error={errors.documentType}
                      label="TIPO DI DOCUMENTO"
                      name="documentType"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={[
                        {
                          name: "CARTA D'IDENTITA",
                          value: 'ID_CARD'
                        },
                        {
                          name: 'PASSPORTO',
                          value: 'PASSPORT'
                        },
                        {
                          name: 'PATENTE',
                          value: 'DRIVERS_LICENCE'
                        },
                        {
                          name: 'ALTRO',
                          value: 'OTHERS'
                        }
                      ]}
                      required
                      touched={touched.documentType}
                      type="select"
                      value={values.documentType}
                    />
                    <InputGroup
                      error={errors.documentNumber}
                      label="Numero del documento"
                      name="documentNumber"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.documentNumber}
                      type="text"
                      required
                      value={values.documentNumber}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Data di nascita</FormGroupHeadline>
                    <FormDescription as="div">
                      Per poter sottoscrivere il contratto online, devi aver
                      compiuto 18 anni.
                    </FormDescription>
                    <InputGroup
                      error={errors.day}
                      label="GIORNO"
                      name="day"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      options={Array.from({ length: 31 }, (v, k) => ++k)}
                      required
                      touched={touched.day}
                      type="select"
                      value={values.day}
                      width="thirds"
                      readOnly={!!member?.dateOfBirth}
                    />
                    <InputGroup
                      error={errors.month}
                      label="MESE"
                      name="month"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      options={Array.from({ length: 12 }, (v, k) => ++k)}
                      required
                      touched={touched.month}
                      type="select"
                      value={values.month}
                      width="thirds"
                      readOnly={!!member?.dateOfBirth}
                    />
                    <InputGroup
                      error={errors.year}
                      label="ANNO"
                      name="year"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={Array.from(
                        { length: 83 },
                        // eslint-disable-next-line no-return-assign,no-param-reassign
                        (v, k) => (k += getYear(subYears(Date.now(), 100)))
                      ).reverse()}
                      required
                      touched={touched.year}
                      type="select"
                      value={values.year}
                      width="thirds"
                      readOnly={!!member?.dateOfBirth}
                    />
                    {errors &&
                    touched &&
                    ((errors.day && touched.day) ||
                      (errors.month && touched.month) ||
                      (errors.year && touched.year)) ? (
                      <ErrorMessage>
                        <FormattedMessage id="validate.required" />
                      </ErrorMessage>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Recapiti</FormGroupHeadline>
                    <InputGroup
                      autoComplete="address-line1"
                      error={errors.Street}
                      label="Via"
                      name="Street"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.Street}
                      type="text"
                      value={values.Street}
                      width="middle"
                    />
                    <InputGroup
                      error={errors.HouseNumber}
                      label="N. Civico"
                      name="HouseNumber"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.HouseNumber}
                      type="text"
                      value={values.HouseNumber}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="postal-code"
                      error={errors.PostalCode}
                      label="CAP"
                      name="PostalCode"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.PostalCode}
                      type="text"
                      value={values.PostalCode}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="locality"
                      error={errors.City}
                      label="Città"
                      name="City"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.City}
                      type="text"
                      value={values.City}
                      width="small"
                    />
                    <InputGroup
                      error={errors.province}
                      label="PROVINCIA"
                      name="province"
                      noLabel
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      options={config.italianProvinces}
                      required
                      touched={touched.province}
                      type="select"
                      value={values.province}
                      width="small"
                    />
                    <InputGroup
                      autoComplete="tel"
                      error={errors.Phone}
                      label="Telefono"
                      name="Phone"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.Phone}
                      type="tel"
                      required
                      value={values.Phone}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>
                      <FormattedMessage
                        id="checkout.uploadPicture"
                        defaultMessage="Upload Picture"
                      />
                    </FormGroupHeadline>
                    <FormDescription as="div">
                      <FormattedMessage
                        id="checkout.fotoUploadDescription"
                        defaultMessage="Take Picture"
                      />
                    </FormDescription>
                    {!this.state.fotoSrc ? (
                      <PictureUploadWrapper>
                        <FileUploadInput
                          multiple={false}
                          name="UploadFileButton-input"
                          type="file"
                          id="UploadFileButton-input"
                          accept={'image/jpeg, image/png'}
                          onChange={this.handleNewFoto}
                          fileSelected={!!this.state.fotoSrc}
                          validateFile={this.props.validateFileSize}
                        />
                        <SecondaryCTAButton
                          onClick={e => {
                            e.preventDefault();
                            this.props.resetFotoValidation();
                            this.setState({ showCameraModal: true });
                          }}
                        >
                          <FormattedMessage
                            id="checkout.takePicture"
                            defaultMessage="Take Picture"
                          />
                        </SecondaryCTAButton>
                      </PictureUploadWrapper>
                    ) : (
                      <PreviewPictureWrapper>
                        <ImagePreview
                          src={this.state.fotoSrc}
                          alt="preview of selected file"
                        />
                        <RemoveButtonWrapper>
                          <SecondaryCTAButton
                            onClick={e => {
                              e.preventDefault();
                              this.props.resetFotoValidation();
                              this.props.resetFotoUpload();
                              this.setState({ fotoSrc: '' });
                            }}
                            disabled={this.props.sendingFoto}
                          >
                            <FormattedMessage
                              id="checkout.removePicture"
                              defaultMessage="Remove Picture"
                            />
                          </SecondaryCTAButton>
                        </RemoveButtonWrapper>
                      </PreviewPictureWrapper>
                    )}
                    {this.props.fotoSizeError && (
                      <ErrorMessage>
                        <FormattedMessage
                          id="messages.fotoSizeError"
                          defaultMessage="L'archivio sorpassa la dimensione massima permessa de 5 MB."
                        />
                      </ErrorMessage>
                    )}
                  </FormGroup>
                  {brand === 'mcfit' && (
                    <FormGroup>
                      <FormGroupHeadline>
                        <FormattedMessage
                          id="checkout.mcfitplus"
                          defaultMessage="McFIT+"
                        />
                      </FormGroupHeadline>
                      <FormDescription as="div">
                        E’il momento di ottenere i vantaggi che ti meriti:
                        ingresso gratuito e benefici per i tuoi amici a partire
                        dal primo giorno di abbonamento. Più a lungo resti con
                        noi, maggiori sono i vantaggi che ricevi.
                      </FormDescription>
                      <InputGroupWrapper width="full" type="checkbox">
                        <InputField
                          autoComplete="off"
                          id="pcConsent"
                          onBlur={event => {
                            handleBlur(event);
                            handleChange(event);
                          }}
                          onChange={handleChange}
                          touched={touched.pcConsent}
                          type="checkbox"
                          value={values.pcConsent}
                        />
                        <Label htmlFor="pcConsent" type="checkbox" hide={false}>
                          <StyledCheckbox
                            checkMark
                            checked={values.pcConsent}
                          />
                          <CheckboxText>
                            Esprimo il mio consenso a prendere parte al
                            programma fedeltà{' '}
                            <a
                              href={'https://www.mcfit.com/it/mcfit-plus/'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              McFIT+
                            </a>
                            , per approfittare dei vantaggi offerti da RSG Group
                            Italia S.r.l. o Fitness S.r.l. e i suoi partner.
                            Autorizzo fino a eventuale revoca la ricezione di
                            informazioni relative a prodotti, prestazioni e
                            offerte della RSG Group Italia S.r.l. o Fitness
                            S.r.l. e del gruppo RSG Group GmbH, così come
                            offerte e vantaggi da parte delle{' '}
                            <a
                              href={
                                'https://www.mcfit.com/it/condizioni-generali-mcfit-plus/rsg-group-brands/?_ga=2.13201151.950896803.1675064698-124186773.1673262961'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              aziende partner
                            </a>
                            . Ho letto e accetto le informazioni in merito alla
                            newsletter della RSG Group Italia S.r.l. o Fitness
                            S.r.l. contenute nell’
                            <a
                              href={
                                'https://www.mcfit.com/it/mcfitcom/dati-personali/?_ga=2.17522751.744759013.1674460843-124186773.1673262961'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              informativa privacy
                            </a>
                            . Dichiaro inoltre di aver letto e accettato le{' '}
                            <a
                              href={
                                'https://www.mcfit.com/it/condizioni-generali-mcfit-plus/?_ga=2.17522751.744759013.1674460843-124186773.1673262961'
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              condizioni generali di McFIT+
                            </a>
                            .
                          </CheckboxText>
                        </Label>
                      </InputGroupWrapper>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormGroupHeadline>
                      <FormattedMessage
                        id="checkout.referralCode"
                        defaultMessage="Referral Code"
                      />
                    </FormGroupHeadline>
                    <InputGroup
                      error={errors.referralCode}
                      label={this.props.intl.formatMessage({
                        id: 'checkout.referralCode.placeholder'
                      })}
                      name="referralCode"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      touched={touched.referralCode}
                      type="text"
                      value={values.referralCode}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupHeadline>Coordinate bancarie</FormGroupHeadline>
                    <FormDescription as="div">
                      L’intestatario del conto corrente deve coincidere con il
                      contraente. Se così non fosse, la stipula del contratto
                      sarà possibile solo in palestra.
                    </FormDescription>
                    <InputGroup
                      autoComplete="name"
                      error={errors.AccountHolder}
                      label="INTESTATARIO DEL CONTO CORRENTE"
                      name="AccountHolder"
                      onBlur={event => {
                        handleBlur(event);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      readOnly
                      required
                      touched={touched.AccountHolder}
                      type="text"
                      value={`${values.FirstName ||
                        'INTESTATARIO DEL CONTO CORRENTE'} ${values.LastName ||
                        ''}`}
                    />
                    <InputGroup
                      error={!this.props.BIC && 'checkout.ibanError'}
                      fetching={this.props.fetchingIBAN}
                      label="IBAN"
                      name="IBAN"
                      onBlur={event => {
                        const { value } = event.target;
                        handleBlur(event);
                        handleChange(event);
                        this.handleVerifyIBAN(value);
                      }}
                      onChange={handleChange}
                      required
                      touched={touched.IBAN}
                      type="text"
                      uppercase
                      value={values.IBAN}
                    />
                    <FormGroup>
                      <InputField
                        autoComplete="off"
                        id="SEPA"
                        name="SEPA"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        required
                        touched={touched.SEPA}
                        type="checkbox"
                        value={values.SEPA}
                      />
                      <Label htmlFor="SEPA" type="checkbox" hide={false}>
                        <StyledCheckbox
                          id="SEPA"
                          name="SEPA"
                          checkMark
                          checked={values.SEPA}
                          error={errors.SEPA}
                        />
                        <CheckboxText>
                          * La sottoscrizione del presente mandato comporta
                          l'autorizzazione del creditore a richiedere alla banca
                          del debitore di procedere a tale addebito
                          conformemente alle disposizioni impartite dal
                          creditore.
                        </CheckboxText>
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <InputField
                        autoComplete="off"
                        id="agb"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        required
                        onChange={handleChange}
                        touched={touched.Marketing}
                        type="checkbox"
                        value={values.Marketing}
                      />
                      <Label htmlFor="agb" type="checkbox" hide={false}>
                        <StyledCheckbox
                          checkMark
                          checked={values.agb}
                          error={errors.agb}
                        />
                        <CheckboxText>
                          * Accetto i termini e le{' '}
                          <a
                            href={
                              'https://www.mcfit.com/it/mcfitcom/condizioni-generali-di-contratto/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            condizioni generali di contratto
                          </a>
                          . Confermo di aver preso visione{' '}
                          <a
                            href={
                              'https://www.mcfit.com/it/mcfitcom/diritto-di-recesso/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            dell'informativa relativa al diritto di recesso
                          </a>{' '}
                          (incl. modulo per l’esercizio del recesso) e
                          dell’informativa sulla{' '}
                          <a
                            href={
                              'https://www.mcfit.com/it/mcfitcom/dati-personali/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy
                          </a>
                          .
                        </CheckboxText>
                      </Label>
                    </FormGroup>
                    {brand !== 'mcfit' && (
                      <FormGroup>
                        <InputField
                          autoComplete="off"
                          id="Marketing"
                          onBlur={event => {
                            handleBlur(event);
                            handleChange(event);
                          }}
                          onChange={handleChange}
                          touched={touched.Marketing}
                          type="checkbox"
                          value={values.Marketing}
                        />
                        <Label htmlFor="Marketing" type="checkbox" hide={false}>
                          <StyledCheckbox
                            checkMark
                            checked={values.Marketing}
                          />
                          <CheckboxText>
                            Esprimo il consenso all'invio di materiale
                            informativo/promozionale relativo alle attività e ai
                            servizi di RSG Group Italia Srl.
                          </CheckboxText>
                        </Label>
                      </FormGroup>
                    )}
                    <FormGroup>
                      <InputField
                        autoComplete="off"
                        id="rights"
                        name="rights"
                        onBlur={event => {
                          handleBlur(event);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        required
                        touched={touched.rights}
                        type="checkbox"
                        value={values.rights}
                      />
                      <Label htmlFor="rights" type="checkbox" hide={false}>
                        <StyledCheckbox
                          checkMark
                          checked={values.rights}
                          error={errors.rights}
                        />
                        <CheckboxText>
                          * Ai sensi e per gli effetti degli articoli 1341 e
                          1342 c.c., l’Abbonato dichiara di avere
                          approfonditamente esaminato nonché di approvare
                          specificatamente il contenuto delle seguenti clausole:
                          1.1. (Validità e Decorrenza applicazione delle
                          presenti Condizioni Generali); 1.2. (Contratto di
                          Abbonamento in Palestra. Diritto di Recesso); 1.3.a.
                          (Contratto di Abbonamento attraverso il sito web.
                          Diritto di recesso); 1.3.b. (Contratto di Abbonamento
                          fuori dalla Palestra. Diritto di recesso); 2.2.
                          (Membercard. Accesso Esclusivo); 3.2 (Costo per
                          l’attivazione e per eventuali successivi rilasci della
                          Membercard); 3.4 (Divieto di cessione del Contratto di
                          Abbonamento e della Membercard. Penale); 3.5.
                          (Attività e Prodotti Vietati. Sanzione. Penale); 3.8
                          (Utilizzo degli armadietti); 3.9 (Condizioni fisiche
                          Abbonato); 4.2 (Diritto di Adeguamento della Quota di
                          Abbonamento); 4.4 (Ritardato o Mancato Pagamento.
                          Decadenza dal Beneficio del Termine. Clausola
                          Risolutiva Espressa. Spese. Danni); 5.1 (Durata.
                          Rinnovo Tacito); 6 (Responsabilità di RSG Group Italia
                          Srl); 7.2 (Modifica alle Condizioni Generali di
                          Contratto).
                        </CheckboxText>
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <CTAButton
                        type="submit"
                        className="mcfit-tracking-checkout-3"
                        disabled={this.props.fetchingUploadUrl || isSubmitting}
                      >
                        {isSending ? (
                          <FormattedMessage
                            id={
                              this.props.sendingFoto
                                ? 'checkout.sendingFotoButton'
                                : 'checkout.sendingButton'
                            }
                            defaultMessage="Send"
                          />
                        ) : (
                          <FormattedMessage
                            id="checkout.sendButton"
                            defaultMessage="Send"
                          />
                        )}
                      </CTAButton>
                    </FormGroup>
                    <FormDescription as="div">
                      <LegalWrapper>
                        ** Tutti i prezzi sono IVA inclusa | Prezzi e orari di
                        apertura fuori dall’Italia possono variare | Sono
                        escluse le persone che non hanno compiuto il 15esimo
                        anno di età, chi è già Abbonato McFIT e i precedenti
                        Abbonati che, in passato, non abbiano regolarmente
                        versato le proprie quote di abbonamento | Offerente: RSG
                        Group Italia Srl, Viale Fulvio Testi 29, 20162 Milano.
                      </LegalWrapper>
                    </FormDescription>
                  </FormGroup>
                </FormSidebar>
                {(!isFebCampaign || isUpsell) && (
                  <FormAside>
                    <SelectedContract
                      button={false}
                      sidebar
                      {...this.props.selectedContract}
                      highlight={false}
                    />
                  </FormAside>
                )}
              </FormWrapper>
              {this.state.showCameraModal && (
                <CameraFeed
                  brand={brand}
                  onClose={() => {
                    this.setState({ showCameraModal: false });
                  }}
                  onPictureTaken={foto => {
                    this.setState({ showCameraModal: false });
                    this.handleNewFoto(foto);
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  BIC: state.data.iban.bic,
  brand: state.data.theme.themeName,
  fetchingIBAN: state.data.iban.fetching,
  selectedContract: state.data.contracts.selectedContract,
  selectedStudioId: state.data.checkout.selectedStudio.StudioMark,
  selectedStudioISO: state.data.checkout.selectedStudio.CountryCode,
  selectedStudioOpening: state.data.checkout.selectedStudio.openingDate,
  sending: state.data.checkout.fetching,
  success: state.data.checkout.success,
  referrals: state.data.checkout.referrals,
  fetchingFiscalCode: state.data.fiscalCode.fetching,
  codiceFiscale: state.data.fiscalCode?.data.codiceFiscale,
  placeOfBirth: state.data.fiscalCode?.data.birthInformationDto.placeOfBirth,
  dateOfBirth: state.data.fiscalCode?.data.birthInformationDto.dateOfBirth,
  countryOfBirth:
    state.data.fiscalCode?.data.birthInformationDto.countryOfBirth,
  imageObjectKey: state.data.foto.objectKey,
  uploadUrl: state.data.foto.uploadUrl,
  fotoSizeError: state.data.foto.fotoSizeError,
  sendingFoto: state.data.foto.sendingFoto,
  fetchingUploadUrl: state.data.foto.fetchingUrl,
  referralCode: state.data.referralCode.referralCode,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: async (data, actions) => {
    await dispatch(sendData(data));
    actions.setSubmitting(false);
  },
  handleIBAN: iban => {
    dispatch(validateIBAN(iban));
  },
  handleFiscalCode: fiscalCode => {
    dispatch(validateFiscalCode(fiscalCode));
  },
  handleReferrals: studioId => {
    dispatch(fetchReferrals(studioId));
  },
  handleFetchFotoUploadUrl: () => {
    dispatch(fetchFotoUploadUrl());
  },
  handleUploadFoto: (foto, url) => {
    return dispatch(uploadFotoToUrl(foto, url));
  },
  resetFotoValidation: () => {
    dispatch(resetFotoValidation());
  },
  validateFileSize: file => {
    return dispatch(validateFileSize(file));
  },
  resetFotoUpload: () => {
    dispatch(resetFotoUpload());
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractFormIT))
);
