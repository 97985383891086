import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';

import DataWrapper from './components/DataWrapper';
import ContractForm from './components/ContractForm/ContractForm';
import CampaignForm from './components/ContractForm/CampaignForm';
import Modal from '../../../../components/Modal/Modal';
import Spinner from '../../../../components/CardSpinner/CardSpinner';
import { selectContract, fetchContracts } from '../../../../data/contracts';
import { selectHomebase, fetchStudio } from '../../../../data/checkout';
import { getMemberData } from '../../../../data/upselling';

export class Data extends Component {
  static propTypes = {
    brand: PropTypes.string.isRequired,
    contracts: PropTypes.array.isRequired,
    handleStudio: PropTypes.func.isRequired,
    handleContract: PropTypes.func.isRequired,
    handleContractSelect: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired
  };

  componentDidMount() {
    const {
      handleStudio,
      handleContract,
      handleMember,
      match: {
        params: { studioId }
      },
      studio,
      member
    } = this.props;
    const url = new URL(window.location.href);
    const identityToken = new URLSearchParams(url.searchParams).get(
      'identityToken'
    );

    if (!!identityToken && !member) {
      handleMember(identityToken);
    }

    if (!studio) {
      return handleStudio(studioId);
    }
    handleContract(studio);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { contractId }
      },
      contracts,
      studio,
      handleContract,
      handleContractSelect
    } = this.props;

    if (studio && prevProps.studio.StudioMark !== studio.StudioMark) {
      return handleContract(studio, contractId);
    }

    if (contracts && prevProps.contracts.length !== contracts.length) {
      return handleContractSelect(contractId);
    }
  }

  render() {
    const { selectedContract, brand, match, intl } = this.props;
    const isCampaign = !!match.url?.match(/campaign/);
    const isUpselling = !!match?.url?.match(/upselling/);

    const countryCode =
      selectedContract && selectedContract.CountryCode.toLowerCase();
    const { studioId, contractId } = match.params;

    if (!selectedContract) {
      return <Spinner />;
    }

    return selectedContract || (studioId && contractId) ? (
      <Modal
        isOpen
        title={isCampaign ? `data.headline.campaign` : `data.headline.${brand}`}
        backTo={`/${
          isCampaign ? 'campaign' : isUpselling ? 'upselling' : 'checkout'
        }${intl.formatMessage({
          id: 'route.selectContract'
        })}/${studioId}`}
        brand={brand}
      >
        <DataWrapper>
          {isCampaign ? (
            <CampaignForm lang={countryCode} />
          ) : (
            <ContractForm lang={countryCode} />
          )}
        </DataWrapper>
      </Modal>
    ) : (
      <Redirect to="/checkout" />
    );
  }
}

const mapStateToProps = state => ({
  contracts: state.data.contracts.contracts,
  selectedContract: state.data.contracts.selectedContract,
  brand: state.data.theme.themeName,
  countryCode: state.data.tracking.tracklang,
  studio: state.data.checkout.selectedStudio,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleStudio: studioId => {
    dispatch(fetchStudio(studioId));
  },
  handleContract: studio => {
    dispatch(
      selectHomebase(studio.StudioMark, studio.CountryCode, studio.openingDate)
    );
    dispatch(fetchContracts(studio));
  },
  handleContractSelect: contractId => {
    dispatch(selectContract(contractId));
  },
  handleMember: uuid => {
    dispatch(getMemberData(uuid));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Data))
);
