export const CrumbSteps = [
  {
    step: 1,
    name: 'breadcrumb.homebase',
    link: 'route.dashboard.home'
  },
  {
    step: 2,
    name: 'breadcrumb.contract',
    link: 'route.selectContract'
  },
  {
    step: 3,
    name: 'breadcrumb.data',
    link: 'route.enterData'
  },
  {
    step: 4,
    name: 'breadcrumb.finalize',
    link: 'route.enterData'
  }
];

export default CrumbSteps;
