import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

const StudioNameWrapper = styled.div`
  font-weight: 500;
  font-size: 1.5em;
  flex-grow: 1;
`;

const StudioAddress = styled.div`
  font-weight: 200;
  font-size: 1.125rem;
  margin-bottom: 1rem;
`;

const StudioLocation = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const StudioNameElement = props => {
  const { StudioName, Street, PostalCode, City, HouseNumber } = props.location;
  return (
    <StudioNameWrapper>
      <StudioLocation>
        <FormattedMessage
          id={`checkout.studio.${props.brand}`}
          defaultMessage="Studio"
        />{' '}
        {StudioName}
      </StudioLocation>
      <StudioAddress>
        {Street}
        {HouseNumber && ' ' + HouseNumber},<br />
        {PostalCode} {City}
      </StudioAddress>
    </StudioNameWrapper>
  );
};

StudioNameElement.propTypes = {
  location: PropTypes.shape({
    StudioName: PropTypes.string.isRequired,
    Street: PropTypes.string.isRequired,
    PostalCode: PropTypes.string.isRequired,
    City: PropTypes.string.isRequired,
    HouseNumber: PropTypes.string
  }).isRequired,
  brand: PropTypes.string.isRequired
};

export default StudioNameElement;
