import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Media from 'react-media';

import ContractWrapper from './components/ContractWrapper';
import ContractCTA from './components/ContractCTA';
import ContractName from './components/ContractName';
import ContractInnerWrapper from './components/ContractInnerWrapper';
import ContractPrize from './components/ContractPrize';
import ContractMinimumTerm from './components/ContractMinimumTerm';
import ContractCopy from './components/ContractCopy';
import ContractExpand from './components/ContractExpand';
import ContractBenefits from './components/ContractBenefits';
import BulletPoint from '../BulletPoint/BulletPoint';
import { selectContract } from '../../data/contracts';
import addResponsive from '../addResponsive/addResponsive';
import Arrow, { ArrowWrapper } from './components/Arrow';

export class Contract extends Component {
  static propTypes = {
    className: PropTypes.string,
    PrintDescription: PropTypes.string,
    Price: PropTypes.number,
    MinimumTerm: PropTypes.number,
    Id: PropTypes.number,
    bulletPoints: PropTypes.array,
    description: PropTypes.array.isRequired,
    handleSelect: PropTypes.func,
    intl: intlShape.isRequired,
    button: PropTypes.bool,
    isMobileHidden: PropTypes.bool,
    wide: PropTypes.bool,
    sidebar: PropTypes.bool,
    sticky: PropTypes.bool,
    brand: PropTypes.string.isRequired,
    showContract: PropTypes.bool,
    contractForm: PropTypes.bool,
    trackLang: PropTypes.string,
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        PrintDescription: PropTypes.string,
        Price: PropTypes.number
      })
    ),
    selectedStudioID: PropTypes.string,
    rateStartPrice: PropTypes.number
  };

  static defaultProps = {
    className: '',
    PrintDescription: '',
    Price: 0,
    MinimumTerm: 0,
    Id: 0,
    handleSelect: undefined,
    button: true,
    wide: true,
    contracts: [],
    sidebar: false,
    sticky: false,
    showContract: false,
    contractForm: false,
    selectedStudioID: 'false',
    trackLang: '',
    bulletPoints: []
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.Id !== 0) {
      return { loaded: true };
    }
    return null;
  }

  state = { loaded: false, showList: false };

  getButtonText = () => {
    const { wide, match } = this.props;
    const isCampaign = !!match?.url?.match(/campaign/);

    if (isCampaign) {
      return 'campaign.btn';
    }
    if (wide) {
      return 'contract.completeButton';
    }
    return 'contract.completeButtonShort';
  };

  handleListCLick = () => {
    this.setState(prev => ({ showList: !prev.showList }));
  };

  handleSelectClick = () => {
    const { handleSelect, Id } = this.props;

    TagManager.dataLayer({
      dataLayer: {
        event: 'contractsTrigger',
        contractId: Id
      }
    });

    handleSelect(Id);
  };

  render() {
    /* eslint-disable camelcase */
    const {
      PrintDescription,
      Price,
      Id,
      MinimumTerm,
      intl,
      button,
      sidebar,
      contracts,
      sticky,
      brand,
      showContract,
      contractForm,
      bulletPoints,
      description,
      selectedStudioID,
      className,
      rateStartPrice,
      highlight,
      type,
      isMobileHidden,
      locale,
      match,
      member,
      isFebCampaign,
      isUpsell,
      isFlex,
      CountryCode,
      isRecurring,
      paymentFrequency,
      name
    } = this.props;
    const { formatMessage } = intl;
    const { loaded, showList } = this.state;
    const isCampaign = !!match.url.match(/campaign/);
    const isUpselling = !!match?.url?.match(/upselling/);

    return (
      <ContractWrapper
        isMobileHidden={isMobileHidden}
        className={className}
        loaded={loaded}
        showContract={showContract}
        sidebar={sidebar}
        contractForm={contractForm}
        contractsLength={contracts && contracts.length}
        locale={locale}
      >
        <ContractInnerWrapper
          sticky={sticky}
          button={button.toString()}
          loading={!Id ? true : undefined}
          brand={brand}
          highlight={highlight}
        >
          <ContractName highlight={highlight}>
            {PrintDescription || '_-_-_-_-_-_-_ _-_-_-_-'}
          </ContractName>
          <ContractPrize
            prize={
              typeof rateStartPrice === 'undefined' ? Price : rateStartPrice
            }
            locale={locale}
            isRecurring={isRecurring}
            paymentFrequency={paymentFrequency}
            showMonthly={
              !isFebCampaign && !(Id === 5465516270 || Id === 5465535370)
            }
            id={Id}
          />
          {Id === 6459711700 ? (
            <ContractMinimumTerm>
              <FormattedMessage
                id={'contract.runtime.special'}
                defaultMessage="{duration} DAYS CONTRACT DURATION"
                values={{ duration: MinimumTerm }}
              />
            </ContractMinimumTerm>
          ) : Id !== 5465516270 && Id !== 5465535370 ? (
            isFlex &&
            (CountryCode === 'FR' ||
              CountryCode === 'DE' ||
              CountryCode === 'AT') ? (
              <ContractMinimumTerm>
                <FormattedMessage
                  id={'contract.runtime.flex'}
                  defaultMessage="Monate Mindestlaufzeit"
                />
              </ContractMinimumTerm>
            ) : !isFebCampaign ? (
              <ContractMinimumTerm>
                <FormattedMessage
                  id={
                    MinimumTerm === 1
                      ? 'contract.runtime.single'
                      : 'contract.runtime'
                  }
                  defaultMessage="Monate Mindestlaufzeit"
                  values={{ duration: MinimumTerm }}
                />
              </ContractMinimumTerm>
            ) : null
          ) : null}
          {!!description[0] ? <ContractCopy>{description}</ContractCopy> : null}
          {bulletPoints?.length && (!isFebCampaign || isUpsell) ? (
            <Media query="(max-width: 576px)">
              {matches =>
                matches && !highlight ? (
                  <ContractExpand onClick={this.handleListCLick}>
                    <FormattedMessage
                      id="contract.expand"
                      defaultMessage="Details"
                    />
                    <ArrowWrapper flip={showList}>
                      <Arrow />
                    </ArrowWrapper>
                  </ContractExpand>
                ) : null
              }
            </Media>
          ) : null}
          {bulletPoints && bulletPoints.length ? (
            <Media query="(max-width: 576px)">
              {matches =>
                matches ? (
                  showList || highlight || (isFebCampaign && !isUpsell) ? (
                    <ContractBenefits>
                      {bulletPoints.map((bullet, index) => (
                        <BulletPoint key={`${bullet}_${index}`}>
                          {bullet}
                        </BulletPoint>
                      ))}
                    </ContractBenefits>
                  ) : null
                ) : (
                  <ContractBenefits>
                    {bulletPoints.map((bullet, index) => (
                      <BulletPoint key={`${bullet}_${index}`}>
                        {bullet}
                      </BulletPoint>
                    ))}
                  </ContractBenefits>
                )
              }
            </Media>
          ) : null}
          <ContractCTA
            as={Link}
            button={button.toString()}
            to={`/${
              isCampaign ? 'campaign' : isUpselling ? 'upselling' : 'checkout'
            }${formatMessage({
              id: 'route.enterData'
            })}/${Id}/${selectedStudioID !== 'false' ? selectedStudioID : ''}${
              member?.customerUUID
                ? '?identityToken=' + member?.customerUUID
                : ''
            }`}
            onClick={this.handleSelectClick}
            className={`mcfit-tracking-checkout-2-${Id} cta_${type}`}
          >
            <FormattedMessage id={this.getButtonText()} />
          </ContractCTA>
        </ContractInnerWrapper>
      </ContractWrapper>
    );
  }
  /* eslint-enable camelcase */
}

const mapStateToProps = state => ({
  contracts: state.data.contracts.contracts,
  brand: state.data.theme.themeName,
  currentBrand: state.data.theme.themeName,
  trackLang: state.data.tracking.tracklang,
  selectedContractID: state.data.contracts.selectedContract.Id,
  selectedStudioID: state.data.checkout.selectedStudio.StudioMark,
  final: state.data.checkout.success,
  locale: state.intl.locale,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleSelect: contractId => {
    dispatch(selectContract(contractId));
  }
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(addResponsive(Contract))
  )
);
