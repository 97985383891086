import React from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams(param) {
  const [params, setParams] = React.useState(undefined);
  const { search } = useLocation();

  React.useEffect(() => {
    setParams(new URLSearchParams(search));
  }, [search]);

  if (param) {
    return params?.get(param);
  }

  return params;
}
