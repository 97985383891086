import styled from 'styled-components/macro';

import Label from '../Label';
import { media } from '../../../../lib/styledMixins';

const RadioWrapper = styled.div`
  margin-bottom: 1em;
  flex: 1;
  ${props => (props.readOnly ? 'pointer-events: none;' : '')}

  ${media.md`
    margin: 0;
  `} ${Label} {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`;

export default RadioWrapper;
