import styled from 'styled-components/macro';

const Button = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid ${props => props.theme.color9};
  border-radius: 0;
  color: ${props =>
    // eslint-disable-next-line
    props.background ? props.theme.buttonTextSecondary : props.theme.color3};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.ffBrand};
  font-size: 1.125em;
  font-weight: 600;
  letter-spacing: 0.9px;
  margin: 0 auto;
  max-width: 100%;
  min-width: 12.5em;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.secondary};
  }
`;

export default Button;
