import styled from 'styled-components/macro';

const Example = styled.div`
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: ${props => props.color};
  margin-left: 1.5em;
`;

export default Example;
