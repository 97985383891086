import { object, string, ref } from 'yup';

export const schemaLoox = object().shape({
  pw: string().required('validate.required'),
  pwagain: string().when('pw', {
    is: val => (val && val.length > 0 ? true : false),
    then: string().oneOf([ref('pw')], 'validate.match')
  }),
  service: string().required()
});

export const schemaCleeng = object().shape({
  pw: string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d|.*[@$!%*#?&+-])[A-Za-z\d@$!%*#?&+-]{8,}$/,
      'messages.tsp.password'
    )
    .required('validate.required'),
  pwagain: string().when('pw', {
    is: val => (val && val.length > 0 ? true : false),
    then: string().oneOf([ref('pw')], 'validate.match')
  }),
  service: string().required()
});
