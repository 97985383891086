import React from 'react';
import styled from 'styled-components/macro';

import { media } from '../../../lib/styledMixins';

const Wrapper = styled.div`
  border-left: 1px solid ${props => props.theme.lightGrey};
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 50px;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: background-color: .3s;
  width: 2rem;

  ${media.md`
    width: 5rem;
  `}
`;

const StyledSVG = styled.svg`
  margin: auto;
  width: 1.125em;
`;

const SelectArrow = props => (
  <Wrapper>
    <StyledSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
      <path
        fill="#e9e9e9"
        d="M46 63c-1.1 0-2.1-.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1.1-5.7 1.6-1.5 4.1-1.5 5.7.1l22.1 23 22.1-23c1.5-1.6 4.1-1.6 5.7-.1 1.6 1.5 1.6 4.1.1 5.7l-25 26c-.8.8-1.8 1.2-2.9 1.2z"
      />
    </StyledSVG>
  </Wrapper>
);

export default SelectArrow;
