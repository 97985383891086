import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Headline from '../../../../components/Headline/Headline';
import StudioList from './components/StudioList/StudioList';
import LocationInput from './components/LocationInput/LocationInput';
import Content from '../../../../components/Content/Content';
import MetaTitle from '../../../../components/MetaTitle/MetaTitle';
import { getMemberData } from '../../../../data/upselling';

export class StudioFinder extends Component {
  static propTypes = {
    searchResults: PropTypes.number.isRequired,
    brand: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape()).isRequired
  };

  static defaultProps = {
    brand: 'mcfit'
  };

  componentDidMount() {
    const { userCountry, member, handleMember } = this.props;

    const url = new URL(window.location.href);
    const identityToken = new URLSearchParams(url.searchParams).get(
      'identityToken'
    );

    if (!!identityToken && !member) {
      handleMember(identityToken);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'geo',
        country: userCountry
      }
    });
  }

  render() {
    const { searchResults, brand, locations, match, member, intl } = this.props;
    const isCampaign = !!match?.url?.match(/campaign/);

    return member?.studioId ? (
      <Redirect
        to={`/checkout${intl.formatMessage({
          id: 'route.selectContract'
        })}/${member?.studioId}?identityToken=${member?.customerUUID}`}
      />
    ) : (
      <>
        <Content>
          <MetaTitle title="Checkout" />
          <Route
            exact
            path="/:path(checkout|campaign)"
            render={() => <Breadcrumb step={1} match={match} />}
          />
          <Route
            path="/checkout/studio/:contractId"
            render={() => <Breadcrumb step={1} match={match} noContracts />}
          />
          <Route
            path="/probetraining"
            render={() => <Breadcrumb step={1} match={match} noContracts />}
          />
          <Headline locations={searchResults}>
            <FormattedMessage
              id={`${isCampaign ? 'campaign' : 'checkout'}.headline.${brand}`}
            />
          </Headline>
          <LocationInput />
          {searchResults && locations ? <StudioList {...this.props} /> : null}
        </Content>
      </>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.data.checkout.locations.length,
  brand: state.data.theme.themeName,
  countryCode: state.data.tracking.tracklang,
  locations: state.data.checkout.locations,
  userCountry: state.data.tracking.tracklang,
  member: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleMember: uuid => {
    dispatch(getMemberData(uuid));
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StudioFinder)
);
