import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import { injectIntl } from 'react-intl';
import Cookies from 'js-cookie';

import useQueryParams from '../../data/useQueryParams';
import { setReferrerLanguage } from '../../data/tracking';
import i18n from '../../i18n';
import { setReferralCode } from '../../data/referralCode';

const WithParams = ({ intl, children }) => {
  const lang = useQueryParams('lang');
  const referralCodeFromParams = useQueryParams('referralCode');
  const referralCode = useSelector(
    state => state.data.referralCode.referralCode
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    const updateLanguage = lang => {
      console.log(lang, i18n[lang]);
      const int = i18n[lang] || i18n.de;
      const { locale, messages } = int.intl;
      Cookies.set('language', locale, { expires: 10 }); // 1 Day
      dispatch(updateIntl({ locale, messages }));
      dispatch(setReferrerLanguage(locale));
    };

    if (lang && intl.locale !== lang) {
      updateLanguage(lang);
    }
  }, [lang, dispatch, intl]);

  React.useEffect(() => {
    if (referralCodeFromParams && referralCodeFromParams !== referralCode) {
      dispatch(setReferralCode(referralCodeFromParams));
    }
  }, [dispatch, referralCodeFromParams, referralCode]);

  return children;
};

export default injectIntl(WithParams);
