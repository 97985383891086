import uuid from 'uuid/v4';

import { displayMessage, displayAutoCloseMessage } from '../messages';
import reverseGeocode from '../../lib/reverseGeocode';
import calculateDistance from '../../lib/calculateDistance';
import config from '../../config';

export const FETCH_LOCATIONS_REQUEST = 'data/checkout/FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'data/checkout/FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'data/checkout/FETCH_LOCATIONS_ERROR';
export const FETCH_STUDIO_REQUEST = 'data/checkout/FETCH_STUDIO_REQUEST';
export const FETCH_STUDIO_SUCCESS = 'data/checkout/FETCH_STUDIO_SUCCESS';
export const FETCH_STUDIO_ERROR = 'data/checkout/FETCH_STUDIO_ERROR';
export const GET_GPS_REQUEST = 'data/checkout/GET_GPS_REQUEST';
export const GET_GPS_SUCCESS = 'data/checkout/GET_GPS_SUCCESS';
export const RESET_INPUT = 'data/checkout/RESET_INPUT';
export const CLEAR_SEARCH = 'data/checkout/CLEAR_SEARCH';
export const SELECT_HOMEBASE = 'data/checkout/SELECT_HOMEBASE';
export const CACHE_LOCATIONS = 'data/checkout/CACHE_LOCATIONS';
export const SEND_DATA_REQUEST = 'data/checkout/SEND_DATA_REQUEST';
export const SEND_DATA_SUCCESS = 'data/checkout/SEND_DATA_SUCCESS';
export const SEND_DATA_ERROR = 'data/checkout/SEND_DATA_ERROR';
export const FETCH_REFERRAL_REQUEST = 'data/checkout/FETCH_REFERRAL_REQUEST';
export const FETCH_REFERRAL_REQUEST_SUCCESS =
  'data/checkout/FETCH_REFERRAL_REQUEST_SUCCESS';
export const FETCH_REFERRAL_REQUEST_FAILURE =
  'data/checkout/FETCH_REFERRAL_REQUEST_FAILURE';
export const STORE_FORM_DATA = 'data/checkout/STORE_FORM_DATA';
export const STORE_FORM_DATA_SUCCESS = 'data/checkout/STORE_FORM_DATA_SUCESS';
export const READ_FORM_DATA = 'data/checkout/READ_FORM_DATA';

const { api, WOOSMAP_KEY, GOOGLE_KEY, firmenFitnessContracts } = config;

/* ACTIONS */
export function cacheLocations(locations) {
  return {
    type: CACHE_LOCATIONS,
    payload: locations
  };
}

function fetchLocationsRequest() {
  return {
    type: FETCH_LOCATIONS_REQUEST
  };
}

function fetchLocationsSuccess(studios) {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: studios
  };
}

export function fetchLocationsError() {
  return {
    type: FETCH_LOCATIONS_ERROR
  };
}

export function fetchLocations(search, id) {
  return async (dispatch, getState) => {
    function onSuccess(studios) {
      dispatch(fetchLocationsSuccess(studios));
      return studios;
    }

    function onError(error) {
      dispatch(fetchLocationsError());
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.locationFetchError',
          type: 'alert',
          description: error.toString()
        })
      );
      return error;
    }

    dispatch(fetchLocationsRequest());

    const getCountry = locale => {
      switch (locale) {
        case 'de':
          return ['DE', 'AT'];
        case 'en':
          return ['GB'];
        default:
          return [locale.toLocaleUpperCase()];
      }
    };

    const { locale } = getState().intl;
    const countries = getCountry(locale);
    const multisearch = window.woosmap.multisearch({
      apiOrder: ['localities', 'address', 'places'],
      // stores: { key: woosKey },
      localities: {
        key: WOOSMAP_KEY,
        params: {
          language: locale,
          components: { country: countries }
        }
      },
      address: {
        key: WOOSMAP_KEY,
        params: {
          language: locale,
          components: { country: countries }
        }
      },
      places: {
        key: GOOGLE_KEY,
        params: {
          components: { country: countries }
        }
      }
    });

    try {
      const { brandId } = getState().data.theme.variables;
      let locations;
      // if no search was supplied just get all studios but don't calculate any distances
      if (!search) {
        const studios = await (await fetch(`${api}/studios/${brandId}`)).json();
        const { studioList } = studios;

        dispatch(cacheLocations(studioList));
        return onSuccess(studioList);
      }

      locations = await (await fetch(`${api}/studios/${brandId}`)).json();

      // get coordinates of supplied location
      const result = await multisearch.autocompleteMulti(search);
      const { api: woosAPI, id } = result[0];
      const finalResult = await multisearch.detailsMulti({ api: woosAPI, id });
      const { studioList } = locations;

      dispatch(cacheLocations(studioList));

      const { lat, lng } = finalResult.item.geometry.location;
      // get list of all studios
      const sortedStudios = calculateDistance(
        {
          latitude: lat,
          longitude: lng
        },
        studioList
      );

      return onSuccess(sortedStudios);
    } catch (error) {
      return onError(error);
    }
  };
}

function fetchStudioRequest() {
  return {
    type: FETCH_STUDIO_REQUEST
  };
}

function fetchStudioSuccess(studio) {
  return {
    type: FETCH_STUDIO_SUCCESS,
    payload: { studio }
  };
}

export function fetchStudio(studioId) {
  return async (dispatch, getState) => {
    function onSuccess(studio) {
      dispatch(fetchStudioSuccess(studio));
      return studio;
    }

    function onError(error) {
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.studioFetchError',
          type: 'alert',
          description: error.toString()
        })
      );
      return error;
    }

    dispatch(fetchStudioRequest());

    try {
      const { brandId } = getState().data.theme.variables;
      const { studio } = await (
        await fetch(`${api}/studio/${studioId}/${brandId}`)
      ).json();
      return onSuccess(studio);
    } catch (error) {
      return onError(error);
    }
  };
}

export function resetInput() {
  return {
    type: RESET_INPUT
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH
  };
}

export function selectHomebase(id, countryCode, openingDate) {
  return {
    type: SELECT_HOMEBASE,
    payload: {
      id,
      countryCode,
      openingDate
    }
  };
}

function getGPSRequest() {
  return {
    type: GET_GPS_REQUEST
  };
}

function getGPSSuccess(success) {
  return {
    type: GET_GPS_SUCCESS,
    payload: {
      latitude: success.latitude,
      longitude: success.longitude,
      accuracy: success.accuracy
    }
  };
}

export function getGPS() {
  return async dispatch => {
    async function onSuccess(success) {
      const { latitude, longitude, accuracy } = success.coords;

      const location = await reverseGeocode({ latitude, longitude });

      dispatch(
        getGPSSuccess({
          latitude,
          longitude,
          accuracy,
          location
        })
      );
      dispatch(fetchLocations(location));
      return success;
    }

    function onError(error) {
      dispatch(
        displayAutoCloseMessage({
          text: 'messages.gpsError',
          type: 'alert',
          description: error.toString()
        })
      );
      dispatch(fetchLocationsError());
      return error;
    }

    dispatch(getGPSRequest());

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  };
}

function sendDataRequest() {
  return {
    type: SEND_DATA_REQUEST
  };
}

function sendDataError() {
  return {
    type: SEND_DATA_ERROR
  };
}

function sendDataSuccess({ memberNumber, email, redirectUrl }) {
  return {
    type: SEND_DATA_SUCCESS,
    payload: {
      memberNumber,
      email,
      redirectUrl
    }
  };
}

function handleErrors(err) {
  switch (true) {
    case err.includes('PDPR'):
      return 'messages.pendingRegistration';
    case err.includes('EMOD'):
      return 'messages.mailNotAllowed';
    case err.includes('WCOD'):
      return 'messages.contractConclusionBlocked';
    case err.includes('8FEL'):
      return 'messages.contractConclusionBlocked';
    case err.includes('DFOC'):
      return 'messages.alreadyRegistered';
    case err.includes('IBAN'):
      return 'messages.ibanError';
    default:
      return err;
  }
}

export function sendData(data) {
  return async dispatch => {
    function onSuccess(response) {
      const { statusCode, error, message } = response;
      if (statusCode === 6 || statusCode === 500 || statusCode === 400) {
        dispatch(sendDataError());
        return dispatch(
          displayMessage(
            {
              text: handleErrors(error ?? message),
              type: 'alert'
            },
            uuid()
          )
        );
      }

      if (statusCode === 1 || statusCode === 3) {
        dispatch(sendDataError());
        return dispatch(
          displayMessage(
            {
              text: 'messages.emailAlreadyUsed',
              type: 'alert'
            },
            uuid()
          )
        );
      }

      if (statusCode === 4) {
        dispatch(sendDataError());
        return dispatch(
          displayMessage(
            {
              text: 'messages.invalidData',
              type: 'alert'
            },
            uuid()
          )
        );
      }

      if (statusCode === 7) {
        dispatch(sendDataError());
        return dispatch(
          displayMessage({
            text: 'messages.invalidFiscalCode',
            type: 'alert'
          })
        );
      }

      if (error) {
        return onError(error);
      }

      const { memberNumber, email, redirectUrl } = response;

      const data = {
        memberNumber,
        email,
        redirectUrl
      };

      return dispatch(sendDataSuccess(data));
    }

    function onError(error) {
      dispatch(sendDataError());
      dispatch(
        displayMessage(
          {
            text: 'messages.sendDataError',
            type: 'alert'
          },
          uuid()
        )
      );
      return error;
    }

    dispatch(sendDataRequest());

    try {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });
      const request = await fetch(`${api}/member`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      });

      const response = await request.json();

      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

function fetchReferrealSuccess(data) {
  return {
    type: FETCH_REFERRAL_REQUEST_SUCCESS,
    payload: data
  };
}

function fetchReferrealFailure() {
  return {
    type: FETCH_REFERRAL_REQUEST_FAILURE
  };
}

function fetchReferrealRequest() {
  return {
    type: FETCH_REFERRAL_REQUEST
  };
}

export function fetchReferrals(studioId) {
  return async dispatch => {
    function onError() {
      dispatch(fetchReferrealFailure());
      return dispatch(
        displayMessage(
          {
            text: 'messages.receiveDataError',
            type: 'alert'
          },
          uuid()
        )
      );
    }

    function onSuccess(success) {
      return dispatch(fetchReferrealSuccess(success));
    }

    dispatch(fetchReferrealRequest());

    try {
      const response = await (
        await fetch(`${api}/referral/${studioId}`)
      ).json();

      return onSuccess(response.data);
    } catch (error) {
      return onError(error);
    }
  };
}

function storeData(data) {
  return {
    type: STORE_FORM_DATA,
    payload: data
  };
}

export function storeFormData(data) {
  return dispatch => {
    dispatch(storeData(data));
    localStorage.setItem('formData', JSON.stringify(data));
    dispatch({
      type: STORE_FORM_DATA_SUCCESS
    });
  };
}

export function readStoredFormData() {
  return dispatch => {
    dispatch({
      type: READ_FORM_DATA
    });

    const data = localStorage.getItem('formData');
    const formData = JSON.parse(data);

    return formData;
  };
}

// REDUCER
export default function reducer(
  state = {
    locations: [],
    cachedLocations: [],
    gpsLocation: { latitude: '', longitude: '', accuracy: Infinity },
    selectedStudio: false,
    fetching: false,
    success: false,
    verified: false,
    memberNumber: '',
    email: '',
    firmenFitnessContracts
  },
  action
) {
  switch (action.type) {
    case FETCH_LOCATIONS_REQUEST:
    case SEND_DATA_REQUEST:
    case GET_GPS_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case SEND_DATA_ERROR:
    case FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        fetching: false
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        locations: action.payload
      };
    case FETCH_STUDIO_SUCCESS:
      return {
        ...state,
        fetching: false,
        selectedStudio: action.payload.studio
      };
    case RESET_INPUT:
      return {
        ...state,
        locations: [],
        selectedStudio: false
      };
    case SELECT_HOMEBASE:
      return {
        ...state,
        success: false,
        selectedStudio: state.locations.find(
          studio => studio.StudioMark === action.payload.id
        ) || {
          StudioMark: action.payload.id,
          CountryCode: action.payload.countryCode,
          openingDate: action.payload.openingDate
        }
      };
    case GET_GPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        gpsLocation: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          accuracy: action.payload.accuracy
        }
      };
    case CACHE_LOCATIONS:
      return {
        ...state,
        cachedLocations: action.payload
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        locations: []
      };
    case SEND_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: !!action.payload.memberNumber,
        ...(action.payload.redirectUrl
          ? { redirectUrl: action.payload.redirectUrl }
          : {}),
        memberNumber: action.payload.memberNumber,
        email: action.payload.email
      };
    case FETCH_REFERRAL_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case FETCH_REFERRAL_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
        referrals: action.payload
      };
    case FETCH_REFERRAL_REQUEST_FAILURE:
      return {
        ...state,
        fetching: false
      };
    case STORE_FORM_DATA:
      return {
        ...state,
        form: action.payload
      };
    default:
      return state;
  }
}
