import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import NukaWrapper from './components/NukaWrapper';

import CardSpinner from '../../../../components/CardSpinner/CardSpinner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Headline from '../../../../components/Headline/Headline';
import Subheadline from '../../../../components/Subheadline/Subheadline';
import Contract from '../../../../components/Contract/Contract';
import ContractsWrapper from './components/ContractsWrapper/ContractsWrapper';
import Content from '../../../../components/Content/Content';
import { fetchContracts } from '../../../../data/contracts';
import { selectHomebase, fetchStudio } from '../../../../data/checkout';
import ContractLoadingWrapper from './components/ContractLoadingWrapper';
import ContractsMessageWrapper from './components/ContractsMessageWrapper';
import ContractFootnote from './components/ContractFootnote';
import addResponsive from '../../../../components/addResponsive/addResponsive';
import { toggleContactForm } from '../../../../data/contact';
import { getMemberData } from '../../../../data/upselling';

export class Contracts extends React.Component {
  static propTypes = {
    studio: PropTypes.oneOfType([
      PropTypes.shape({
        StudioName: PropTypes.string
      }),
      PropTypes.bool
    ]),
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        PrintDescription: PropTypes.string.isRequired,
        Price: PropTypes.number.isRequired
      }).isRequired
    ).isRequired,
    studioId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleURLParam: PropTypes.func.isRequired,
    brand: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    wide: PropTypes.bool.isRequired,
    handleContactForm: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired
  };

  static defaultProps = {
    studioId: false,
    studio: false
  };

  componentDidMount() {
    const { studioId, studio, handleURLParam, handleMemberData } = this.props;
    const identityToken = new URLSearchParams(window.location.search).get(
      'identityToken'
    );

    if (identityToken) {
      return handleMemberData(identityToken);
    }

    if (!studio) {
      handleURLParam(studioId);
    }
  }

  componentDidUpdate(prevProps) {
    const { handleContracts, studio, memberData, handleURLParam } = this.props;

    if (!!memberData?.studioId && !studio) {
      handleURLParam(memberData.studioId);
    }

    if (prevProps.studio !== studio) {
      handleContracts(studio);
    }
  }

  sliderDecision = contracts => {
    const { wide, brand } = this.props;

    if ((contracts && contracts?.length < 4) || !wide) {
      return [
        contracts?.map(contract => (
          <Contract
            key={contract.Id}
            contractsLength={contracts.length}
            {...contract}
          />
        ))
      ];
    }
    if (contracts?.length > 2) {
      return (
        <NukaWrapper brand={brand}>
          {contracts?.map(contract => (
            <Contract
              key={contract.Id}
              contractsLength={contracts.length}
              {...contract}
            />
          ))}
        </NukaWrapper>
      );
    }
    return (
      <ContractsMessageWrapper>
        <FormattedMessage id="contract.noContracts" />
      </ContractsMessageWrapper>
    );
  };

  render() {
    const {
      brand,
      fetching,
      contracts: fetchedContracts,
      match,
      studio
    } = this.props;
    const isCampaign = !!match?.url?.match(/campaign/);
    const isUpselling = !!match?.url?.match(/upselling/);
    const contracts = fetchedContracts
      ?.filter(
        contract =>
          (!isCampaign && !isUpselling) ||
          contract.isFebCampaign ||
          contract.isUpsell
      )
      .filter(
        contract =>
          !contract.isHidden ||
          (isCampaign && contract.isFebCampaign && !contract.isJunior) ||
          (isUpselling && contract.isUpsell && !contract.isJunior)
      );

    const hideSubheadline =
      isCampaign || (brand === 'johnreed' && studio?.CountryCode === 'GB');

    return (
      <Content>
        {!isUpselling && <Breadcrumb step={2} match={match} />}
        <Headline noMargin>
          <FormattedMessage
            id={`contract${
              isCampaign ? '.campaign' : isUpselling ? '.upselling' : ''
            }.headline.${brand}`}
            defaultMessage="Deine Homebase"
          />
        </Headline>
        {!hideSubheadline && (
          <Subheadline>
            <FormattedMessage id={`contract.subheadline.${brand}`} />
          </Subheadline>
        )}
        {fetching ? (
          <ContractLoadingWrapper>
            <CardSpinner />
          </ContractLoadingWrapper>
        ) : (
          <ContractsWrapper contractsLength={contracts && contracts?.length}>
            {this.sliderDecision(contracts)}
          </ContractsWrapper>
        )}
        <ContractFootnote>
          <FormattedMessage
            id="contract.legalFootnote"
            values={{
              company:
                studio?.CountryCode === 'AT'
                  ? 'RSG Group Österreich Ges.mbH, Doningasse 12/2/5, A-1220 Wien.'
                  : 'RSG Group GmbH, Tannenberg 4, 96132 Schlüsselfeld.'
            }}
          />
        </ContractFootnote>
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.data.contracts.fetching,
  contracts: state.data.contracts.contracts,
  studio: state.data.checkout.selectedStudio,
  brand: state.data.theme.themeName,
  countryCode: state.data.tracking.tracklang,
  memberData: state.data.upselling.member
});

const mapDispatchToProps = dispatch => ({
  handleMemberData: async token => {
    dispatch(getMemberData(token));
  },
  handleURLParam: async studioId => {
    const { studio } = await dispatch(fetchStudio(studioId));

    if (studio) {
      dispatch(selectHomebase(studioId, studio.CountryCode));
    }
  },
  handleContracts: studio => {
    dispatch(fetchContracts(studio));
  },
  handleContactForm: () => {
    dispatch(toggleContactForm());
  }
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(addResponsive(Contracts, 769))
);
