import styled from 'styled-components/macro';

export default styled.div`
  overflow: hidden;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
