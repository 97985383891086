import styled from 'styled-components/macro';

const Icon = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 2em;
  justify-content: center;
  margin-right: 1em;

  svg {
    width: 100%;
  }
`;

export default Icon;
