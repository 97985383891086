import config from '../config';

const { mapsUrl, API_KEY } = config;

export default async function reverseGeocode(location) {
  const { latitude, longitude } = location;
  const address = await (await fetch(
    `${mapsUrl}?latlng=${latitude},${longitude}&key=${API_KEY}`
  )).json();
  // this gets the post_code from the google response
  const aproximateLocation = Object.values(
    address.results[0].address_components
  ).find(component => component.types.includes('postal_code')).long_name;

  return aproximateLocation;
}
