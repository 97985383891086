import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import TagManager from 'react-gtm-module';

import AppContainer from './components/AppContainer/AppContainer';
import AppGrid from './components/AppGrid';
import Checkout from './scenes/Checkout/Checkout';
import TestTraining from './scenes/TestTraining/TestTraining';
import Colors from './scenes/Colors/Colors';
import Contact from './scenes/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LoadingScreen from './components/LoadingScreen';
import UnderConstruction from './scenes/UnderConstruction/UnderConstruction';
import MessageList from './components/MessageList/MessageList';
import NotFound from './scenes/NotFound/NotFound';
import TSP from './scenes/TSP/TSP';
import determineBrand from './lib/determineBrand';
import { fetchTheme } from './data/theme';
import config from './config';
export class App extends React.Component {
  componentDidMount() {
    const { selectTheme } = this.props;
    const brand = determineBrand(window.location);

    if (brand in config.gtmIDs) {
      const gtmId = config.gtmIDs[brand];

      TagManager.initialize({ gtmId });
    }
    selectTheme(brand);
  }

  render() {
    const { brand, trackLang } = this.props;

    return (
      <AppContainer>
        <AppGrid>
          <Header />
          {brand ? (
            <Switch>
              <Redirect exact path="/" to="/checkout" />
              <Route
                path="/login"
                component={() => {
                  if (brand === 'mcfit') {
                    window.location =
                      'https://my.mcfit.com/?dD1yc2ctZ3JvdXAmdz00JnY9dHJ1ZQ==#/login';
                    return <></>;
                  }
                  if (brand === 'johnreed') {
                    window.location =
                      'https://my.johnreed.fitness/?dD1yc2ctZ3JvdXAmdz0yJnY9dHJ1ZQ==#/login';
                    return <></>;
                  }
                  if (brand === 'high5')
                    return (
                      <UnderConstruction>
                        Der Login-Bereich ist zur Zeit nicht verfügbar
                      </UnderConstruction>
                    );
                }}
              />
              <Route path="/checkout" component={Checkout} />
              <Route path="/campaign" component={Checkout} />
              <Route path="/upselling" component={Checkout} />
              <Route path="/probetraining" component={TestTraining} />
              <Route path="/tsp" component={TSP} />
              <Route path="/colors" component={Colors} />
              <Route component={NotFound} />
            </Switch>
          ) : (
            <LoadingScreen />
          )}
          <Footer />
        </AppGrid>
        <MessageList />
        <Contact />
      </AppContainer>
    );
  }
}

App.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: intlShape.isRequired,
  selectTheme: PropTypes.func.isRequired,
  brand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName,
  locations: state.data.checkout.locations,
  messages: state.data.messages,
  selectedStudio: state.data.checkout.selectedStudio,
  successfulCheckout: state.data.checkout.success,
  themeName: state.data.theme.themeName,
  trackLang: state.data.tracking.tracklang
});

const mapDispatchToProps = dispatch => ({
  selectTheme: brand => {
    dispatch(fetchTheme(brand));
  }
});

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(App))
);
