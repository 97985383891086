import styled, { css } from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

const MessageWrapper = styled.li`
  align-items: center;
  background-color: ${props => props.theme.info};
  box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.3);
  bottom: 0;
  color: ${props => props.theme.color3};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  padding: 0.75em 1em;
  position: relative;
  width: 100%;
  z-index: 9999;

  ${media.md`
    margin-top: 1em;
  `}
  ${props =>
    props.type === 'success' &&
    css`
      background-color: ${props.theme.success};
    `};
  ${props =>
    props.type === 'alert' &&
    css`
      background-color: ${props.theme.alert};
    `};
  ${props =>
    props.type === 'warn' &&
    css`
      background-color: ${props.theme.warn};
    `};
`;

export default MessageWrapper;
