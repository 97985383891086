import React from 'react';
import {  FormattedHTMLMessage, injectIntl } from 'react-intl';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Content from '../../../../components/Content/Content';
import Headline from '../../../../components/Headline/Headline';
import Description from '../../../../components/Description/Description';

const Error = ({ intl }) => {
  const translated: string = intl.formatMessage(
    { id: 'upsell.error.description' },
  );

  return (
    <Content>
    <Breadcrumb step={4} />
    <Headline noMargin>
      <FormattedHTMLMessage
        id={`upsell.error.headline`}
      />
    </Headline>

      <>
        <Description>
          <div dangerouslySetInnerHTML={{ __html: translated }} />
        </Description>
      </>
  </Content>
  )
}

export default injectIntl(Error)

