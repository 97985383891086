import styled from 'styled-components/macro';

import { media } from '../../../../../lib/styledMixins';

const StyledSVG = styled.div`
  display: flex;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-0.75em, 27px);

  svg {
    height: 1em;
    margin: auto;
    width: 1em;
  }

  ${media.md`
  transform: translate(-8px, 40px);
  `};
`;

export default StyledSVG;
