const en = {
  intl: {
    locale: 'en',
    messages: {
      'add.cause': 'Was ist der Grund für die Unterbrechung?',
      'add.start': 'Ab wann soll diese gelten?',
      'add.success':
        'Deine Ruhezeit ist erfolgreich bei uns eingegangen. Sobald der Antrag bearbeitet wurde, wird deine Ruhezeit in deinem Memberbereich angezeigt.',
      'add.suspensionMonths': 'Wie viele Monate soll diese sein?',
      'add.suspensionTime': 'Füge eine Ruhezeit für Deinen Vertrag hinzu',
      'add.title': 'Hinzufügen',

      'app.greeting': 'Hallo!',
      'app.switchLanguage': 'Sprache ändern',

      'backlink.url.high5': 'high5.com',
      'backlink.url.johnreed': 'johnreed.fitness',
      'backlink.url.mcfit': 'mcfit.com',

      'breadcrumb.contract.high5': 'Membership',
      'breadcrumb.contract.johnreed': 'Membership',
      'breadcrumb.contract.mcfit': 'Membership',
      'breadcrumb.data.high5': 'Data',
      'breadcrumb.data.johnreed': 'Data',
      'breadcrumb.data.mcfit': 'Data',
      'breadcrumb.finalize.high5': 'Contract conclusion',
      'breadcrumb.finalize.johnreed': 'Contract conclusion',
      'breadcrumb.finalize.mcfit': 'Contract conclusion',
      'breadcrumb.homebase.high5': 'Studio',
      'breadcrumb.homebase.johnreed': 'Club',
      'breadcrumb.homebase.mcfit': 'Studio',

      'bringafriend.bullet1.johnreed':
        'Du bist bereits seit mindestens einem Monat Mitglied und gehst mit deinem Freund / deiner Freundin in ein JOHN REED Studio deiner Wahl.',
      'bringafriend.bullet1.mcfit':
        'Du bist bereits seit mindestens einem Monat Mitglied und gehst mit deinem Freund / deiner Freundin in ein McFIT Studio deiner Wahl.',
      'bringafriend.bullet2':
        'Dein Freund / deine Freundin schließt in deiner Anwesenheit einen Vertrag mit einer Vertragslaufzeit von mindestens sechs Monaten ab. Alternativ bringt ihr einfach den Onlinevertrag deines Freundes / deiner Freundin mit ins Studio.',
      'bringafriend.bullet3':
        'Das Studiopersonal stellt dir einen Gutschein<sup>2</sup> für einen Freimonat aus.',
      'bringafriend.bullet4':
        'Du füllst das „Bring a Friend“-Formular links mit Hilfe der Daten auf deinem Gutschein aus, wählst deinen Freimonat<sup>3</sup> aus und drückst auf „Absenden“.',
      'bringafriend.bulletIntro': 'So einfach gehts:',
      'bringafriend.freemonth': 'Gewünschter Freimonat',
      'bringafriend.frienddata': 'Daten des Freundes/ der Freundin',
      'bringafriend.headline': 'Bring a Friend',
      'bringafriend.invalid': 'Falscher Code',
      'bringafriend.messageHeadline.johnreed':
        'Für jede(n) geworbenen Freund<sup>1</sup> / geworbene Freundin<sup>1</sup> schenken wir dir einen Freimonat bei JOHN REED!',
      'bringafriend.messageHeadline.mcfit':
        'Für jede(n) geworbenen Freund<sup>1</sup> / geworbene Freundin<sup>1</sup> schenken wir dir einen Freimonat bei McFIT!',
      'bringafriend.success':
        'Wir haben Deine Anfrage erhalten und werden diese nun bearbeiten.',
      'bringafriend.sup1.johnreed':
        '<sup>1</sup> Ausgeschlossen als Freund sind Personen, die das 15. Lebensjahr noch nicht vollendet haben oder bereits JOHN REED Mitglied sind.',
      'bringafriend.sup1.mcfit':
        '<sup>1</sup> Ausgeschlossen als Freund sind Personen, die das 15. Lebensjahr noch nicht vollendet haben oder bereits McFIT-Mitglied sind.',
      'bringafriend.sup2.johnreed':
        '<sup>2</sup> Die Barauszahlung, Teileinlösung oder Umwandlung in andere Leistungen von JOHN REED (z. B. Getränke, Nahrungsmittel) oder eine übertragung auf Dritte sind nicht möglich.',
      'bringafriend.sup2.mcfit':
        '<sup>2</sup> Die Barauszahlung, Teileinlösung oder Umwandlung in andere Leistungen von McFIT (z. B. Getränke, Nahrungsmittel) oder eine übertragung auf Dritte sind nicht möglich.',
      'bringafriend.sup3.johnreed':
        '<sup>3</sup> Der Freimonat erfolgt immer ab dem ersten eines Monats und wird frühestens ab dem übernächsten Monat gewährt. JOHN REED behält sich vor in bestimmten Fällen (bei Zahlungsrückständen, Stilllegungen oder Vertragswiderruf durch den Freund etc.), den Freimonat nicht zu gewähren. Anbieter Deutschland: RSG Group GmbH, Tannenberg 4, 96132 Schlüsselfeld. Anbieter Österreich: RSG Group Österreich Ges.mbH, Doningasse 12/2/5, 1220 Wien.',
      'bringafriend.sup3.mcfit':
        '<sup>3</sup> Der Freimonat erfolgt immer ab dem ersten eines Monats und wird frühestens ab dem übernächsten Monat gewährt. McFIT behält sich vor in bestimmten Fällen (bei Zahlungsrückständen, Stilllegungen oder Vertragswiderruf durch den Freund etc.), den Freimonat nicht zu gewähren. Anbieter Deutschland: RSG Group GmbH, Tannenberg 4, 96132 Schlüsselfeld. Anbieter Österreich: RSG Group Österreich Ges.mbH, Doningasse 12/2/5, 1220 Wien.',
      'bringafriend.voucher': 'Gutscheincode',
      'bringafriend.voucherheadline': 'Dein Gutscheincode',

      'card.BIC': 'BIC',
      'card.IBAN': 'IBAN',
      'card.L': 'Lastschrift-SEPA',
      'card.accountOwner': 'Kontoinhaber',
      'card.address': 'Meine Adresse',
      'card.birthday': 'Geburtstag',
      'card.cancel': 'Nächste Kündigungsfrist',
      'card.canceld': 'Dieser Vertrag ist gekündigt.',
      'card.comingSoon': 'Coming Soon',
      'card.contact': 'Kontaktinformation',
      'card.contractHeadline': 'Vertragsdaten',
      'card.duration': 'Laufzeit',
      'card.email': 'E-Mail',
      'card.extension': 'Verlängerung:',
      'card.financial': 'Zahlungsdaten',
      'card.lastBill': 'Letzte Rechnung',
      'card.memberDataHeadline': 'Deine Mitgliedsdaten',
      'card.memberNumber': 'Membernumber',
      'card.monthlyFee':
        'Monatlicher Mitgliedsbeitrag in den ersten {months} Monaten',
      'card.monthlyFeeFrom':
        'Monatlicher Mitgliedsbeitrag ab dem {months}. Monat',
      'card.monthlyFeeProlonged':
        'Monatlicher Mitgliedsbeitrag ab Verlängerung',
      'card.currency': 'Euro',
      'card.nextPayment': 'Nächste Zahlung fällig am',
      'card.payment': 'Zahlungsübersicht',
      'card.paymentType': 'Bezahlart',
      'card.phone': 'Phone number',
      'card.suspension': 'Ruhezeiten',

      'change.address': 'Meine neue Adresse',
      'change.bankdata': 'Meine neue Bankverbindung',
      'change.bankdata.bank': 'Bank - wird automatisch ausgefüllt',
      'change.city': 'City',
      'change.number': 'House number',
      'change.postalCode': 'Postcode',
      'change.street': 'Street',
      'change.title': 'Ändern',

      'checkout.description.high5':
        'Je nach Mitgliedschaft hast du die Möglichkeit, in einem ausgewählten High5 Gym oder allen High5 Gyms und McFIT-Studios europaweit zu trainieren.',
      'checkout.description.johnreed':
        'Wähle den JOHN REED Club, in dem du am meisten trainieren wirst.',
      'checkout.description.mcfit':
        'Je nach Vertrag hast Du die Möglichkeit, in allen Studios von McFIT oder nur in einem Studio mit fester Bindung zu trainieren. Wähle deshalb das Studio aus, indem du in Zukunft am häufigsten trainierst.',
      'checkout.headline.high5': 'Wähle dein Gym',
      'checkout.headline.johnreed': 'Where is your club?',
      'checkout.headline.mcfit': '1. Find your club',
      'campaign.headline.mcfit': '1. WO MÖCHTEST DU STARTEN?',
      'campaign.headline.johnreed': '1. WO MÖCHTEST DU STARTEN?',
      'campaign.headline.high5': '1. WO MÖCHTEST DU STARTEN?',
      'checkout.ibanError': 'Your IBAN is not valid. Please check',
      'checkout.locationPlaceholder': 'Street, city or zip code',
      'checkout.locationPlaceholderLong': 'Street, city or zip code',
      'checkout.noStudios':
        'Leider konnten wir keine Studios in deiner Nähe finden',
      'checkout.sendButton': 'Buy Now',
      'checkout.sendingButton': 'Sending...',
      'checkout.sendingFotoButton': 'Bild wird gesendet...',
      'checkout.studio.high5': 'Gym',
      'checkout.studio.johnreed': 'Studio',
      'checkout.studio.mcfit': 'Studio',
      'checkout.studioDetailLink': 'Details',
      'checkout.studioSelectButton': 'Choose studio',
      'checkout.studioSelectButton.high5': 'Home Gym wählen',
      'checkout.studioSelectButton.johnreed': 'Studio wählen',
      'checkout.studioSelectButton.mcfit': 'Studio wählen',
      'checkout.studioSelectButtonLong': 'Zu meiner Home-base machen',
      'checkout.subheadlineStep2': 'In Deiner Nähe gibt es folgende Studios:',
      'checkout.uploadPicture': 'Bild Hochladen',
      'checkout.selectFile': 'Datei auswählen',
      'checkout.takePicture': 'Bild aufnehmen',
      'checkout.removePicture': 'Bild entfernen',
      'checkout.fotoUploadDescription':
        'Die maximale Dateigröße entspricht 5MB',
      'checkout.referralCode': 'Referral Code',
      'checkout.referralCode.placeholder': '',
      'checkout.proudclub': 'PROUD CLUB',
      'checkout.mcfitplus': 'McFIT+',
      'campaign.btn': 'Weiter',
      'component.loginLink': 'Zum Login',

      'contract.expand': 'Details',
      'contract.completeButton': 'Join',
      'contract.completeButtonShort': 'Join',
      'contract.from': 'Ab',
      'contract.headline.johnreed': 'What membership suits you best?',
      'contract.headline.mcfit': '2. Welchen Vertrag möchtest du auswählen',
      'contract.upselling.headline.mcfit':
        '2. Welchen Vertrag möchtest du auswählen',
      'contract.campaign.headline.mcfit': '2. DEINE LEISTUNGEN AUF EINEN BLICK',
      'contract.week': '/14 Days',
      'contract.month': 'mth.',
      'contract.year': 'ann.',
      'contract.noContracts': 'Keine Verträge gefunden.',
      'contract.runtime': '{duration} months minimum term',
      'contract.runtime.flex': '',
      'contract.runtime.single': '{duration} month minimum term',
      'contract.showContract': 'Vertrag anzeigen',
      'contract.subheadline.high5': ' ',
      'contract.subheadline.johnreed': '',
      'contract.subheadline.mcfit': ' ',
      'contract.legalFootnote':
        "**All prices quoted include VAT | Prices and opening hours may vary in individual gyms | Persons under the age of 18 aren't allowed to become members. | Provider: RSG Group UK Limited, Roedl & Partner Legal Limited, 170 Edmund Street, Birmingham, B3 2HB, England.",

      'cookie.link': 'Datenschutzerklärung klicken.',
      'cookie.linkURL.high5':
        'https://www.high5.com/footer-navigation/impressum/#datenschutz',
      'cookie.linkURL.johnreed': 'https://johnreed.fitness/datenschutz',
      'cookie.linkURL.mcfit':
        'https://www.mcfit.com/de/ueber-mcfitcom/datenschutz/',
      'cookie.message':
        'Wir verwenden Cookies, um Ihnen einen besseren Service zu bieten, den Seitenverkehr zu analysieren, Inhalte zu personalisieren und gezielte Werbung zu schalten. Wenn Sie diese Seite weiterhin nutzen, stimmen Sie der Verwendung von Cookies zu. Lesen Sie, wie wir Cookies verwenden und wie Sie sie steuern können, indem Sie auf',

      'cta.send': 'Absenden',
      'cta.sending': 'Absenden...',

      'dashboard.greeting': 'Hey',

      'dashboardNav.bringafriend': 'Bring a Friend',
      'dashboardNav.communication': 'Kommunikation',
      'dashboardNav.cyberobicsNow': 'Cyberobics Now',
      'dashboardNav.home': 'Dashboard',
      'dashboardNav.proudClub': 'Proud Club',
      'dashboardNav.proudClub.faq': 'FAQ',
      'dashboardNav.proudClub.info': 'Info',
      'dashboardNav.proudClub.loyalty': 'Treuevorteile',
      'dashboardNav.proudClub.partner': 'Partnervorteile',
      'dashboardNav.services': 'Services',

      'data.back': 'Zur Vertragsübersicht',
      'data.headline.high5': 'Werde jetzt Teil der High5 Community',
      'data.headline.johnreed': 'JOIN THE CLUB',
      'data.headline.mcfit': 'Trage jetzt deine Daten ein und werde Mitglied',
      'data.headline.campaign': 'Trage jetzt deine Daten ein',
      'data.subheadline.johnreed':
        'Gib hier deine Kontaktdaten und deine Bankverbindung an.',
      'data.subheadline.mcfit':
        'Gib hier deine Kontaktdaten und deine Bankverbindung an.',

      'footerNav.career': 'Jobs',
      'footerNav.company': 'Company',
      'footerNav.contact': 'Contact',
      'footerNav.imprint': 'Imprint',
      'footerNav.privacy': 'Privacy',

      'header.backlink': 'Zurück zur Hauptwebsite',
      'header.loginButton': 'Login',
      'header.logoutButton': 'Logout',

      'login.becomeMemberButton': 'Jetzt Mitglied werden',
      'login.becomeMemberDescription': 'Du bist noch kein McFit-Mitglied?',
      'login.contactText':
        'Falls Du noch Fragen hast, kannst Du dich über unser Kontaktformular melden.',
      'login.description':
        'Hier kannst du dich in deinen persönlichen Nutzerbereich einloggen.',
      'login.emailPlaceholder': 'E-Mail',
      'login.emailPlaceholderLong': 'E-Mail Adresse',
      'login.forgotPassword': 'Passwort vergessen?',
      'login.headline.high5': 'Welcome to the Gym',
      'login.headline.johnreed': 'Welcome to the club',
      'login.headline.mcfit': 'Willkommen bei McFIT',
      'login.noMember': 'Noch kein Mitgliedskonto?',
      'login.passwordPlaceholder': 'Passwort',
      'login.register': 'Jetzt registrieren',
      'login.submit': 'Einloggen',

      'memberNumber.description': 'YOUR MEMBERSHIP NUMBER',

      'messages.activationNeeded':
        'Bitte aktiviere zuerst deinen Account, indem du den Link in deiner E-Mail anklickst.',
      'messages.bookOptionError':
        'Es gab einen Fehler beim Buchen der PROUD CLUB Option',
      'messages.bookOptionSuccess':
        'PROUD CLUB Option wurde erfolgreich gebucht',
      'messages.changeDataError': 'Fehler beim Ändern deiner Daten',
      'messages.changeDataSuccess':
        'Deine Änderungsanfrage ist erfolgreich bei uns eingegangen. Sobald sie bearbeitet wurde, werden die neuen Daten in deinem Memberbereich angezeigt.',
      'messages.emailAlreadyUsed': 'Deine E-Mail ist bereits registriert.',
      'messages.emailAlreadyUsedLogin':
        'Diese Mailadresse existiert bereits. Bitte logge dich mit deinen vorhandenen Benutzerdaten ein.',
      'messages.gpsError': 'Fehler beim Orten per GPS',
      'messages.invalidData': 'Bitte überprüfe nochmal deine Eingaben.',
      'messages.invalidFiscalCode': 'Fehler beim Bestätigen des Tax IDs',
      'messages.locationFetchError': 'Fehler beim Bestimmen des Standortes',
      'messages.loginSuccess': 'Login erfolgreich',
      'messages.memberNumberCopySuccess':
        'Membernumber in Zwischenablage kopiert',
      'messages.newVersion': 'Neue Version verfügbar.',
      'messages.newVersionDescription':
        'Bitte lade diese Seite neu um sie zu aktualisieren.',
      'messages.promoFetchError': 'Fehler beim Abrufen der Proudclub Aktionen',
      'messages.registrationSuccess':
        'Erfolg! Bitte logge dich jetzt mit deinen Accountdaten ein.',
      'messages.sendDataError': 'Fehler beim Senden der Daten',
      'messages.receiveDataError': 'Fehler beim Empfangen von Daten',
      'messages.sendOptinError': 'Fehler bei der PROUD CLUB Anmeldung',
      'messages.sendRegistrationError': 'Fehler beim Registrieren',
      'messages.sendRegistrationError.description':
        'Es gab beim Abgleichen deiner Daten ein Problem. Bitte wende dich an unser Studiopersonal.',
      'messages.sendRegistrationSuccess': 'Anfrage erfolgreich',
      'messages.sendRegistrationSuccess.description':
        'Sofern noch kein Account mit diesen Daten existiert, erhältst du eine E-Mail mit weiteren Anweisungen.',
      'messages.studioFetchError': 'Fehler beim Laden der Studios',
      'messages.unconfirmedAccount': 'Dein Account wurde noch nicht bestätigt',
      'messages.unconfirmedAccount.description':
        'Bitte schaue in dein E-Mail Postfach und klicke auf den Bestätigungslink',
      'messages.userFetchError': 'Fehler beim Laden des Members',
      'messages.userLoginError': 'Fehler beim Login',
      'messages.userLogoutError': 'Fehler beim Logout',
      'messages.userRequestPasswordFailure':
        'Fehler beim zurücksetzen des Passworts',
      'messages.userlangFetchError': 'Fehler beim Abrufen deines Landes',
      'messages.userlangFetchErrorDescription':
        'Deine Mitgliedsnummer ist nicht bei uns registriert.',
      'messages.validateEMailFailure': 'Diese E-Mail Adresse ist nicht erlaubt',
      'messages.verifyEmail': 'Fehler beim Bestätigen der E-Mail',
      'messages.validateFiscalCodeFailure': '',
      'messages.voucherCopySuccess': 'Code in Zwischenablage kopiert',
      'messages.wrongPassword':
        'Es wurde kein Account mit diesen Daten gefunden.',
      'messages.ibanError': 'This IBAN is invalid. Please check.',
      'messages.iban.invalidFormat': '',
      'messages.iban.invalid': '',
      'messages.iban.prepaid': '',
      'messages.uploadFotoFailure': 'Das Bild konnte nicht hochgeladen werden',
      'messages.fotoSizeError':
        'Die Datei ist zu groß. Datei darf maximal 5 MB groß sein.',

      'messages.loox.invalidPassword':
        'Das Passwort ist ungültig. Bitte verwende mind. einen Kleinbuchstaben, mind. einen Großbuchstaben, mind. eine Zahl und mind. 6 Zeichen. Bei Fragen wende dich bitte an unseren Support unter services@loox.com.',
      'messages.loox.invalidAge': 'Du musst mindestens 18 Jahre alt sein.',
      'messages.loox.accountBlocked':
        'Dein LOOX-Account ist gegenwärtig gesperrt. Du erhältst in Kürze eine Mail, um ihn wieder zu reaktivieren. Bei Fragen wende dich bitte an unseren Support unter services@loox.com.',
      'messages.loox.accountDisabled':
        'Dein LOOX-Account ist gegenwärtig gesperrt. Bei Fragen wende dich bitte an unseren Support unter services@loox.com.',
      'messages.loox.existingSubscription':
        'Dein neues LOOX Abo wurde erfolgreich angelegt. Auf deinem LOOX-Account besteht allerdings schon ein Abonnement, das du jetzt in deinem Store kündigen kannst. Bei Fragen wende dich bitte an unseren Support unter services@loox.com.',
      'messages.loox.created': 'Dein Account wurde erfolgreich angelegt.',
      'messages.loox.updated':
        'Dein LOOX Account wurde erfolgreich mit deiner Studio-Mitgliedschaft verknüpft.',
      'messages.cyberobics.success': 'Dein Account wurde erfolgreich angelegt.',
      'messages.cyberobics.alreadySubscribed':
        'Du hast bereits einen gültigen Account. Logge dich bitte mit deinen bestehenden Nutzerdaten ein. Bei Fragen wende dich bitte an unseren Support unter service@cyberobics.com.',
      'messages.cyberobics.resendSuccess':
        'TSP Mail wurde erfolgreich an das Mitglied versendet.',
      'messages.cyberobics.updated':
        'Dein CYBEROBICS Account wurde erfolgreich mit deiner Studio-Mitgliedschaft verknüpft.',
      'messages.tsp.password':
        'Das Passwort sollte einen Buchstaben und eine Ziffer (oder ein Sonderzeichen) enthalten und mindestens 8 Zeichen lang sein.',

      'newPassword.description':
        'Bitte achte darauf, ein ausreichend sicheres Passwort zu wählen.',
      'newPassword.headline': 'Wähle dein neues Passwort',
      'newPassword.successDescription':
        'Du kannst dich nun mit deinem neuen Passwort einloggen.',
      'newPassword.successHeadline': 'Erfolg!',

      'notfound.homebutton': 'Zur Startseite',

      'proudClub.copyCodeToClipboard': 'Code kopieren',
      'proudClub.detail.back': 'Zurück zur Partnerübersicht',
      'proudClub.email':
        'Wir haben dir eine Bestätigungsmail gesendet. Bitte klicke auf den dort enthaltenen Link, um deine Anmeldung zu bestätigen.',
      'proudClub.intro.partnerCta': 'Zur Partnerseite',
      'proudClub.legal.showLess': 'weniger anzeigen',
      'proudClub.legal.showMore': 'mehr anzeigen',
      'proudClub.loyalty.month': 'Monate',
      'proudClub.loyalty.toNextUpgrade': 'bis zum nächsten Upgrade',
      'proudClub.loyalty.yourProgress': 'Dein Fortschritt',
      'proudClub.overview.headline': 'Deine Partnervorteile',
      'proudClub.overview.intro.bottomLine':
        'Sicher dir deine Partnervorteile im McFIT PROUD CLUB. Exclusiv für PROUD CLUB-Mitglieder beinhaltet:',
      'proudClub.overview.intro.topLine': 'Hol dir jetzt, was du verdienst',
      'proudClub.pending':
        'Deine Anfrage wird gerade bearbeitet. Bitte habe noch ein wenig Geduld.',
      'proudClub.register.headline': 'PROUD CLUB Anmeldung',

      'register.accountData': 'Account Daten',
      'register.description':
        'Bitte gib zunächst deine E-Mail Adresse an und lege ein Passwort fest. Mit diesen Daten wirst du dich zukünftig in deinem Mitgliedskonto anmelden.<br>BETAPHASE: Diese Funktion befindet sich in einer Testphase.',
      'register.flagsHeadline':
        'Bitte wähle das Land aus, indem du deinen Vertrag abgeschlossen hast.',
      'register.headline':
        'Jetzt für dein persönliches Mitgliedskonto registrieren.',
      'register.label.birthday': 'Date of Birth',
      'register.label.day': 'Day',
      'register.label.email': 'E-Mail Adresse',
      'register.label.firstName': 'First name',
      'register.label.lastName': 'Last name',
      'register.label.memberNumber': 'Mitgliedsnummer',
      'register.label.month': 'Month',
      'register.label.password': 'Passwort',
      'register.label.year': 'Year',
      'register.memberNumber': 'Zuerst benötigen wir deine Mitgliedsnummer',
      'register.personalData': 'Personal data',
      'register.sendButton': 'Absenden',
      'register.sendingButton': 'Wird gesendet...',

      'registermember.aside':
        '<p>Bitte gib nun deine restlichen Mitgliedsdaten ein. Nach erfolgreicher Registrierung kannst du jederzeit deine persönlichen Daten einsehen.</p><p>Wir arbeiten bereits an weiteren Funktionen. In Zukunft kannst du unter anderem alle Details zu deinem Vertrag abrufen und deine persönlichen Daten ändern.</p>',
      'registermember.personalData': 'Persönliche Daten',
      'registermember.personalData.description':
        'Hinweis: Bitte verwende hier genau die Daten, die du beim Vertragsabschluss angegeben hast.',
      'registermember.personalData.memberNumber':
        'Info: Deine Mitgliedsnummer findest du auf deiner Membercard oder in deinen Vertragsunterlagen.',

      'resetPassword.description':
        'Gib hier deine E-Mail Adresse an, wir schicken dir dann eine Mail mit den weiteren Schritten.',
      'resetPassword.headline': 'Passwort Vergessen?',
      'resetPassword.submit': 'Absenden',
      'resetPassword.successDescription':
        'Wir haben eine E-Mail mit weiteren Anweisungen an die angegebene Adresse geschickt.',
      'resetPassword.successHeadline': 'Erfolg!',

      'route.bringafriend': '/bring-a-friend',
      'route.contact': '/contact',
      'route.dashboard': '/dashboard',
      'route.dashboard.communication': '/kommunikation',
      'route.dashboard.cyberobicsNow': '/cyberobics-now',
      'route.dashboard.home': '/',
      'route.dashboard.loyalty': '/proud-club/loyalty',
      'route.dashboard.proudClub': '/proud-club',
      'route.dashboard.proudClub.partner': '/proud-club/partner',
      'route.dashboard.services': '/services',
      'route.enterData': '/your-data',
      'route.notFound': 'Hier ging was schief',
      'route.notFound.message':
        'Hier kannst Du Dich in Deinen persönlichen Nutzerbereich einloggen.',
      'route.resetPassword': '/passwort-vergessen',
      'route.selectContract': '/choose-contract',
      'route.success': '/willkommen',

      'signature.clear': 'Zurücksetzen',
      'signature.rotateDevice': 'Bitte drehe dein Gerät',
      'signature.save': 'Unterschrift bestätigen',

      'suspension.cause1': 'keine Angabe',
      'suspension.cause2': 'Schwangerschaft',
      'suspension.cause3': 'Bundeswehr',
      'suspension.cause4': 'finanzielle Gründe',
      'suspension.cause5': 'Auslandsaufenthalt',
      'suspension.cause6': 'Krankheit',
      'suspension.noSuspensions':
        'Momentan sind noch keine Ruhezeiten hinterlegt',

      'tsp.headline.cyberobics': 'Deine CYBEROBICS Registrierung',
      'tsp.headline.loox': 'Deine LOOX Registrierung',
      'tsp.subheadline.cyberobics':
        'Vergib ein Passwort für {email}, um deinen Premium-Account für CYBEROBICS zu aktivieren.',
      'tsp.subheadline.loox':
        'Vergib ein Passwort für {email}, um deinen Premium-Account für LOOX zu aktivieren.',
      'tsp.passwordPlaceholder': 'Passwort',
      'tsp.passwordPlaceholderAgain': 'Passwort wiederholen',
      'tsp.cta': 'Senden',
      'tsp.successHeadline.cyberobics':
        'Deine CYBEROBICS Registrierung war erfolgreich!',
      'tsp.successHeadline.loox': 'Deine LOOX Registrierung war erfolgreich!',
      'tsp.success.cyberobics':
        'Die Aktivierung war erfolgreich. Um <a href="https://www.cyberobics.com" target="_blank">CYBEROBICS</a> nutzen zu können, lade dir die CYBEROBICS App kostenlos aus dem App Store herunter oder gehe auf cyberobics.com. Nun kannst du dich mit deiner E-Mail Adresse und dem festgelegten Passwort einloggen. Achtung: Wenn du bereits zuvor einen Account hattest, logge dich bitte mit dem alten Passwort ein. Solltest du noch Fragen haben, wende dich gern an unser Support-Team unter service@cyberobics.com',
      'tsp.success.loox':
        'Die Aktivierung war erfolgreich. Um den LOOX Fitnessplaner nutzen zu können, lade dir die LOOX App kostenlos aus dem App Store herunter. Nun kannst du dich mit deiner E-Mail Adresse und dem festgelegten Passwort einloggen. Achtung: Wenn du bereits zuvor einen Account hattest, logge dich bitte mit dem alten Passwort ein. Solltest du noch Fragen haben, wende dich gern an unser Support-Team unter services@loox.com',
      'tsp.cyberobics.playstore':
        '<a href="https://play.google.com/store/apps/details?id=tv.accedo.ott.flow.cyberobics.now" target="_blank"><img src="/playstore.png" /></a>',
      'tsp.cyberobics.appstore':
        '<a href="https://apps.apple.com/de/app/cyberobics-fitness-workout/id1234963164" target="_blank"><img src="/appstore.png" /></a>',
      'tsp.loox.playstore':
        '<a href="https://play.google.com/store/apps/details?id=com.loox.android" target="_blank"><img src="/playstore.png" /></a>',
      'tsp.loox.appstore':
        '<a href="https://apps.apple.com/de/app/loox-fitness-planer/id732003576" target="_blank"><img src="/appstore.png" /></a>',

      'testtraining.headline.mcfit': 'ANMELDUNG ZUM PROBETRAINING',
      'testtraining.headline.johnreed': 'Dein Probetraining bei JOHN REED',
      'testtraining.gender': 'Geschlecht',
      'testtraining.agenote':
        'Du musst mindestens 15 Jahre alt sein, um dich zum Probetraining anzumelden. Für Jugendliche vor Vollendung des 18. Lebensjahres kann eine Probemitgliedschaft nur mit Zustimmung eines gesetzlichen Vertreters geschlossen werden.',
      'testtraining.bullet1': 'Einmal kostenlos trainieren',
      'testtraining.bullet2':
        'Trainiere eigenständig oder unter professioneller Anleitung',
      'testtraining.bullet3':
        'Entdecke das komplette Trainingsangebot bei {name} – inkl. Live Kursen',
      'testtraining.bullet4': 'Kostenlose Open Group Workouts',
      'testtraining.success.headline':
        'VIELEN DANK FÜR DEINE ANMELDUNG ZUM PROBETRAINING!',
      'testtraining.success.subheadline': 'Viel Spaß!',
      'testtraining.success.description':
        'Deine Daten sind nun in unserem System angelegt. Zur Bestätigung deines Probetrainings erhältst du in Kürze eine E-Mail von uns.',

      'validate.birthday':
        'To sign up for a membership it is necessary to be at least 18 years of age.',
      'validate.birthday15': 'Du musst mindestens 15 Jahre alt sein',
      'validate.birthday27': 'Du musst maximal 27 Jahre alt sein',
      'validate.city': 'Du musst eine Stadt angeben',
      'validate.email': 'Bitte überprüfe, ob deine E-Mail korrekt ist',
      'validate.firstName': 'Du musst einen Vornamen angeben',
      'validate.gender': 'Bitte gib dein Geschlecht an',
      'validate.houseNumber': 'Bitte überprüfe deine Hausnummer',
      'validate.iban': 'Bitte überprüfe deine IBAN',
      'validate.lastName': 'Du musst einen Nachnamen angeben',
      'validate.postalCode': 'Bitte überprüfe deine Postleitzahl',
      'validate.signature': 'Bitte bestätige deine Unterschrift.',
      'validate.street': 'Bitte überprüfe deine Straße',
      'validate.match': 'Die Eingabe stimmt nicht überein.',

      'verify.description.high5':
        'Ab sofort kannst du dich mit deinen Mitgliedsdaten einloggen.',
      'verify.description.johnreed':
        'Danke für Deine Bestätigung, wir haben dein Benutzerkonto erfolgreich erstellt. Du kannst dich nun einloggen und Daten verwalten.',
      'verify.description.mcfit':
        'Du hast dich erfolgreich für den McFIT-Login-Bereich angemeldet. Logge dich jederzeit ein und entdecke die Vorteile.',
      'verify.success.high5': 'Vielen Dank für deine Bestätigung!',
      'verify.success.johnreed': 'Welcome to the Club!',
      'verify.success.mcfit': 'Vielen Dank für die Bestätigung!',

      'welcome.copyMemberNumber': 'Copy membership number',
      'welcome.description.high5':
        'Willkommen in der High5 Community. Deine Membership wurde erfolgreich angelegt. Melde dich mit deinem Namen und deiner Mitgliedsnummer bei unserem Gym Team vor Ort und lege direkt mit dem Training los. Um deinen Online Account nutzen zu können, erhältst du in Kürze eine E-Mail mit einem Bestätigungslink.',
      'welcome.description.johnreed':
        "How to start your training: Just visit your Homebase JOHN REED Club and show your membership number. In order to start the training immediately you just need to receive your Membercard. Don't forget your sportswear and towel. Soon you will receive an email with a confirmation link so you can use your online account. Have fun!",
      'welcome.description.mcfit':
        'Du bist jetzt Teil der McFIT-Community! Hole deine Membercard zwischen 8:00 und 22:00 Uhr am Tresen in deinem McFIT-Studio ab und starte dein Training. Um dich in den McFIT-Login-Bereich einloggen zu können, erhältst du in Kürze eine E-Mail mit Bestätigungslink.',
      'welcome.description.mcfit.noApps': '',
      'welcome.description.ML':
        'Deine Anmeldung wird bearbeitet. Du erhälst in Kürze eine Bestätigungs-Mail von uns.',
      'welcome.headline.high5': 'WELCOME TO THE GYM!',
      'welcome.headline.johnreed': 'WELCOME TO THE CLUB',
      'welcome.headline.mcfit': 'Vielen Dank!',
      'welcome.campaign.headline.mcfit': 'Vielen Dank!',
      'welcome.subheadline.high5': 'Let’s get it started!',
      'welcome.subheadline.johnreed': 'Let’s start!',
      'welcome.subheadline.mcfit': 'Viel Spaß beim Training!',
      'welcome.description.add':
        'Außerdem erhältst du eine weitere Mail mit den Aktivierungslinks deiner Premium-Accounts für die CYBEROBICS und Hero Workout App.',
      'upsell.description':
        'Super, Schritt 1 ist geschafft! <br /><br /> Was jetzt noch fehlt, ist deine Membercard. Hole sie einfach in den Servicezeiten im McFIT-Studio am Tresen ab. Außerdem erhältst du in Kürze eine E-Mail mit einem Link zur Schnell-Registrierung in der McFIT App. Wichtig: Klicke den Link auf deinem Smartphone an.',
      'upsell.subheadline': 'Viel Spaß und Erfolg beim Abnehmen!',
      'upsell.error.headline': 'Da ist wohl was schief gelaufen.',
      'upsell.error.description':
        'Leider ist ein Fehler aufgetreten. Hast du deine Daten korrekt eingegeben? <br/><br/>Aber mach dir keine Sorgen, wir regeln das. Komm einfach in ein McFIT-Studio deiner Wahl. Unser Studiopersonal wird dir gern weiterhelfen, so dass du bald mit deinem Abnehm-Programm starten kannst.',

      high5: 'High5',
      johnreed: 'John Reed',
      mcfit: 'McFit',
      'yup.cf.passwordMismatch': 'Deine Passwörter stimmen nicht überein',
      'yup.cf.postalCodeMismatch': 'This postcode is invalid.',
      'validate.required': 'Required field',
      'cf.yup.bicMismatch': 'Bitte überprüfe deine BIC',
      'validate.phone':
        'Bitte überprüfe deine Telefonnummer. Keine Sonderzeichen und Buchstaben erlaubt.',
      'yup.cf.phoneMismatch': 'Diese Telefonnummer ist ungültig',
      'yup.cf.taxNumberMismatch': 'Bitte übeprüfe die Steuernummer',
      'dashboard.changeAddress': 'Meine neue Adresse',
      'dashboard.changeData': 'Ändern',
      'yup.cf.spanishIdentificationMismatch': '',
      'messages.italy': 'Login fehlgeschlagen',
      'messages.italy.description':
        'Bitte logge dich auf <a href="https://www.mcfit.com/it/personale/">https://www.mcfit.com/it/personale/</a> ein.'
    }
  }
};

export default en;
