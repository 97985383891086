import styled, { css } from 'styled-components/macro';
import { IconChevron } from '../../../../../../components/Icons';

const SliderButton = styled(IconChevron)`
  height: 50px;
  width: 50px;

  transform: rotate(90deg)
    ${props =>
      props.right &&
      css`
      scale(-1);
    `};
`;

export default SliderButton;
