import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 0.5em;
  border: 2px solid #e9e9e9;
`;

const Image = styled.img`
  object-fit: contain;
  max-width: 400px;
  max-height: 400px;
`;

export const ImagePreview = ({ src, alt }) => (
  <Wrapper>
    <Image src={src} alt={alt} />
  </Wrapper>
);
