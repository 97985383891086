import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import SingleCrumb from './components/SingleCrumb';
import CrumbListWrapper from './components/CrumbListWrapper';
import CrumbLine from './components/CrumbLine';
import CrumbLineInactive from './components/CrumbLineInactive';
import CrumbSteps from './components/CrumbSteps';
import { media } from '../../lib/styledMixins';

const BreadcrumbWrapper = styled.div`
  display: none;

  ${media.md`
    display: block;
    max-width: 35rem;
    margin: 0 auto 5rem;
    position: relative;
  `};
`;

class Breadcrumb extends React.Component {
  static propTypes = {
    step: PropTypes.number.isRequired,
    noContracts: PropTypes.bool,
    final: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    match: PropTypes.shape().isRequired
  };

  static defaultProps = {
    noContracts: false
  };

  constructor(props) {
    super(props);

    this.intl = props.intl;
  }

  render() {
    const { step, final, noContracts, match } = this.props;
    return (
      <BreadcrumbWrapper>
        <CrumbListWrapper>
          {CrumbSteps.map(item =>
            noContracts ? (
              item.step !== 2 && (
                <SingleCrumb
                  key={item.step}
                  step={item.step}
                  name={item.name}
                  currentstep={step}
                  targetlink={item.link}
                  final={final}
                  match={match}
                />
              )
            ) : (
              <SingleCrumb
                key={item.step}
                step={item.step}
                name={item.name}
                currentstep={step}
                targetlink={item.link}
                final={final}
                match={match}
              />
            )
          )}
        </CrumbListWrapper>
        <CrumbLine step={step} noContracts={noContracts} />
        <CrumbLineInactive noContracts={noContracts} />
      </BreadcrumbWrapper>
    );
  }
}

const mapStateToProps = state => ({
  trackLang: state.data.tracking.tracklang,
  selectedContractID: state.data.contracts.selectedContract.Id,
  selectedStudioID: state.data.checkout.selectedStudio.StudioMark,
  final: state.data.checkout.success,
  aktionsVertrag: state.data.contracts.selectedContract.FilterAction
});

export default injectIntl(connect(mapStateToProps)(Breadcrumb));
