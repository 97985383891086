import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';

import Modal from '../../../components/Modal/Modal';

class TestTraining extends Component {
  static propTypes = {
    brand: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const {
      brand,
      match: {
        params: { studioId }
      }
    } = this.props;

    return studioId ? (
      <Modal
        isOpen
        title={
          brand === 'johnreed'
            ? 'Aktuell ist eine Online-Registrierung für ein Probetraining nicht möglich. Wende dich gerne an das John Reed Team im Club.'
            : `Dieses Studio bietet im Moment keine Probetrainings an.`
        }
        backTo="/probetraining"
        brand={brand}
      ></Modal>
    ) : (
      <Redirect to="/probetraining" />
    );
  }
}

const mapStateToProps = state => ({
  brand: state.data.theme.themeName
});

export default withRouter(injectIntl(connect(mapStateToProps)(TestTraining)));
