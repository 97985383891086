import styled from 'styled-components/macro';

const CrumbListWrapper = styled.ol`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;

export default CrumbListWrapper;
