import { css } from 'styled-components/macro';

const breakpoints = {
  sm: 576,
  md: 769,
  lg: 992,
  xl: 1200
};

// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
// iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16;
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

// eslint-disable-next-line
export function placeholderMixin(...args) {
  return css`
    &::placeholder {
      ${css(...args)};
    }

    &::-webkit-input-placeholder {
      ${css(...args)};
    }

    &:-ms-input-placeholder {
      ${css(...args)};
    }

    &::-moz-placeholder {
      ${css(...args)};
    }
  `;
}
