import React from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function addResponsive(WrappedComponent, breakpoint = 500) {
  return class AddResponsive extends React.Component {
    static displayName = `AddResponsive(${getDisplayName(WrappedComponent)})`;

    constructor(props) {
      super(props);

      this.state = {
        wide: false
      };
    }

    componentDidMount() {
      this.updateWindowDimensions();

      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      if (window.innerWidth >= breakpoint) {
        this.setState({ wide: true });
      } else {
        this.setState({ wide: false });
      }
    };

    render() {
      return <WrappedComponent wide={this.state.wide} {...this.props} />;
    }
  };
}
