import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components/macro';
import { connect } from 'react-redux';

import { getGPS } from '../../../../../../../data/checkout';
import { IconLocation } from '../../../../../../../components/Icons';
import LocationInputWrapper from './LocationInputWrapper';
import { media } from '../../../../../../../lib/styledMixins';

const bounce = keyframes`
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translatey(0);
  }

  75% {
    transform: translatey(-33%);
 }

  100% {
    transform: translatey(0);
  }
`;

const animation = css`
  ${bounce} 0.8s infinite ease-in-out;
`;

export const IconWrapper = styled.div`
  color: ${props => props.theme.color3};
  cursor: pointer;
  height: 1em;
  position: absolute;
  left: 0.4em;
  top: 0.9em;
  width: 1em;
  z-index: 99;

  ${media.md`
    height: 2.5em;
    left: 0.75em;
    opacity: 0.5;
    padding: 0.5em;
    top: 0.8em;
    width: 2.5em;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s;
    }

    ${LocationInputWrapper}:hover & {
      animation: ${animation};
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
  `};
`;

export const LocationPinIcon = props => (
  <IconWrapper {...props} onClick={props.handleClick}>
    <IconLocation />
  </IconWrapper>
);

LocationPinIcon.propTypes = {
  handleClick: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    dispatch(getGPS());
  }
});

export default connect(
  null,
  mapDispatchToProps
)(LocationPinIcon);
