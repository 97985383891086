import styled, { css } from 'styled-components';
import { H2 } from '../../Typo';

const ContractName = styled(H2)`
  color: ${props => props.theme.backgroundSecondary};
  font-family: ${props => props.theme.ffBrand};
  font-size: 1.6rem;
  text-transform: uppercase;
  word-break: break-word;
  margin-top: 30px;
  margin-bottom: 32px;

  ${props =>
    props.highlight &&
    css`
      margin: -0.5rem -0.5rem 0 -0.5rem;
      padding: 2rem 0;
      background: ${props.theme.active1};
    `}
`;

export default ContractName;
