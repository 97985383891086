import styled from 'styled-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LinkButton from '../../LinkButton';
import { media } from '../../../lib/styledMixins';

export const InternalLink = styled(LinkButton)`
  border-color: transparent;
  color: ${props => props.theme.color8};
  display: inline-block;
  font-family: ${props => props.theme.ffCondensed};
  font-size: 1em;
  margin: 0;

  &:hover {
    color: ${props => props.theme.colorHover};
    transition: color 0.3s;
  }

  ${media.md`
    padding: 1.9rem 0;
    margin: 0 1em;
  `};
`;

export const FooterNavigationLink = props =>
  props.internal ? (
    <InternalLink as={Link} to={props.href}>
      <FormattedMessage id={props.name} />
    </InternalLink>
  ) : (
    <InternalLink
      as="a"
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id={props.name} />
    </InternalLink>
  );

FooterNavigationLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string,
  internal: PropTypes.bool
};

FooterNavigationLink.defaultProps = {
  internal: false,
  href: null
};

export default FooterNavigationLink;
