import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import selectFavicon from './SelectFavs';

const MetaTitle = ({ title, brandTitle, brand }) => {
  const fav = selectFavicon(brand);

  return (
    <Helmet>
      <title>
        {brandTitle} -{title}
      </title>
      <link rel="manifest" href={`${fav.manifest}?v=${brand}`} />
      <link rel="shortcut icon" href={`${fav.favicon}?v=${brand}`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${fav.appleTouch}?v=${brand}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${fav.favicon16x16}?v=${brand}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${fav.favicon32x32}?v=${brand}`}
      />
      <link
        rel="mask-icon"
        href={`${fav.safariTab}?v=${brand}`}
        color="#000000"
      />
    </Helmet>
  );
};

MetaTitle.propTypes = {
  title: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  brandTitle: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  brandTitle: state.data.theme.variables.title || 'mcfit',
  brand: state.data.theme.themeName || 'mcfit'
});
export default connect(mapStateToProps)(MetaTitle);
