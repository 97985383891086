import styled from 'styled-components/macro';

import { media } from '../../lib/styledMixins';

const FormWrapper = styled.form`
  display: flex;
  background-color: ${props => props.theme.backgroundForm};
  color: ${props => props.theme.color2};
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: ${props => props.theme.ffRegular};
  text-align: left;
  flex-basis: 0 0 100%;

  ${media.md`
    flex: 0 0 100%;
    flex-direction: row;
  `};
`;

export default FormWrapper;
