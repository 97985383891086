import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components/macro';

import Contract from '../../../../../components/Contract/Contract';

const StyledContract = styled(Contract)`
  height: auto;
  width: 100%;
  margin-left: 0;
  padding: 0;
`;

const SelectedContract = props => <StyledContract button={false} {...props} />;

export default injectIntl(SelectedContract);
