import React from 'react';
import PropTypes from 'prop-types';

import InputGroupWrapper from '../InputGroupWrapper';
import InputField from '../InputField';
import Label from '../Label';
import StyledCheckbox from '../../../CheckboxInput/StyledCheckbox';
import CheckboxText from './CheckboxText';

const CheckboxInput = ({
  activatePrice,
  agbFile,
  agbText,
  autocomplete,
  hide,
  label,
  legal,
  legalLink,
  name,
  onBlur,
  onChange,
  privacy,
  privacyLink,
  required,
  touched,
  type,
  value,
  width,
  disabled,
  proudClubLegal,
  proudClubLegalLink
}) => {
  function checkboxText(text) {
    let transformedText = text;
    if (agbText) {
      transformedText = transformedText.replace(
        '{AGB}',
        `<a download="AGB" href="data:application/pdf;base64,${agbFile}">${agbText}</a>`
      );
    }
    if (privacy) {
      transformedText = transformedText.replace(
        '{PRIVACY}',
        `<a href=${privacyLink} target="_blank" rel="noopener noreferrer">${privacy}</a>`
      );
    }

    if (legal) {
      transformedText = transformedText.replace(
        '{LEGAL}',
        `<a href=${legalLink} target="_blank" rel="noopener noreferrer">${legal}</a>`
      );
    }

    if (activatePrice) {
      transformedText = transformedText.replace(
        '{ACTIVATE_PRICE}',
        activatePrice
      );
    }

    if (proudClubLegal) {
      transformedText = transformedText.replace(
        '{PC_LEGAL}',
        `<a href=${proudClubLegalLink} target="_blank" rel="noopener noreferrer">${proudClubLegal}</a>`
      );
    }

    return transformedText;
  }

  function createMarkup() {
    return { __html: checkboxText(`${required ? '* ' : ''}${label || name}`) };
  }

  return (
    <InputGroupWrapper width={width} type={type}>
      <InputField
        autoComplete={autocomplete}
        id={name}
        onBlur={onBlur}
        onChange={onChange}
        required={required}
        touched={touched}
        type={type}
        value={value}
        disabled={disabled}
      />
      <Label htmlFor={name} type={type} hide={hide}>
        <StyledCheckbox
          checkMark
          checked={value}
          error={required && !value && touched}
        />
        <CheckboxText dangerouslySetInnerHTML={createMarkup()} />
      </Label>
    </InputGroupWrapper>
  );
};

CheckboxInput.propTypes = {
  agbFile: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  agbText: PropTypes.string,
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  label: PropTypes.string,
  legal: PropTypes.string,
  legalLink: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  privacy: PropTypes.string,
  privacyLink: PropTypes.string,
  proudClubLegal: PropTypes.string,
  proudClubLegalLink: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.string
};

CheckboxInput.defaultProps = {
  agbFile: '',
  agbText: '',
  autocomplete: 'on',
  hide: true,
  label: '',
  legal: '',
  legalLink: '',
  privacy: '',
  privacyLink: '',
  proudClubLegal: '',
  proudClubLegalLink: '',
  required: false,
  touched: false,
  value: '',
  width: 'full',
  disabled: false
};

export default CheckboxInput;
