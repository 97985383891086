import styled from 'styled-components/macro';

const FormGroupHeadline = styled.h1`
  color: ${props => props.theme.backgroundSecondary};
  flex-basis: 100%;
  flex-shrink: 1;
  font-family: ${props => props.theme.ffRegular};
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 1.3em;
`;

export default FormGroupHeadline;
