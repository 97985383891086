import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';

import i18n from './i18n';
import rootReducer from './reducer';
import getReferrerLanguage from './lib/getReferrerLanguage';

export const history = createBrowserHistory();

export function getUserLang(referrer) {
  const lang =
    getReferrerLanguage(referrer, false) || Cookies.get('language') || 'de';

  return lang;
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = createStore(
  rootReducer,
  i18n[getUserLang()],
  composeEnhancers(applyMiddleware(thunk))
);

export default configureStore;
