import styled from 'styled-components';

import { media } from '../../../../../../../lib/styledMixins';

const PictureUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${media.md`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export default PictureUploadWrapper;
