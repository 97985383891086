import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Wrapper from './components/BrandBacklinkWrapper';
import Icon from './components/IconWrapper';
import { IconArrowLeft } from '../../../Icons';
import getBacklink from '../../../../lib/getBacklink';

const BrandBacklink = ({ brand }) => (
  <Wrapper href={getBacklink(brand)}>
    <Icon>
      <IconArrowLeft />
    </Icon>
    <FormattedMessage id={`backlink.url.${brand}`} />
  </Wrapper>
);

BrandBacklink.propTypes = {
  brand: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  brand: state.data.theme.themeName
});

export default injectIntl(connect(mapStateToProps)(BrandBacklink));
