import { displayAutoCloseMessage } from '../messages';
import config from '../../config';

export const VALIDATE_IBAN_SUCCESS = 'data/iban/VALIDATE_IBAN_SUCCESS';
export const VALIDATE_IBAN_REQUEST = 'data/iban/VALIDATE_IBAN_REQUEST';
export const VALIDATE_IBAN_ERROR = 'data/iban/VALIDATE_IBAN_ERROR';

const { api } = config;

function validateIBANRequest() {
  return {
    type: VALIDATE_IBAN_REQUEST
  };
}

function validateIBANError() {
  return {
    type: VALIDATE_IBAN_ERROR
  };
}

function validateIBANSuccess(bankData) {
  return {
    type: VALIDATE_IBAN_SUCCESS,
    payload: bankData
  };
}

export function validateIBAN(IBAN) {
  return async dispatch => {
    function onSuccess(bankData) {
      dispatch(validateIBANSuccess(bankData));
      return bankData;
    }

    function errorMessage(err) {
      console.log(err);
      switch (true) {
        case !!err.match(/^(1|2)/)?.length:
          return 'messages.iban.invalidFormat';
        case !!err.match(/^(3|4)/)?.length:
          return 'messages.iban.invalid';
        case !!err.match(/^5/)?.length:
          return 'messages.iban.prepaid';
        default:
          return 'messages.ibanError';
      }
    }

    function onError(error) {
      dispatch(validateIBANError());
      const message = errorMessage(error);
      dispatch(
        displayAutoCloseMessage({
          text: message,
          type: 'alert'
        })
      );
      return error;
    }

    dispatch(validateIBANRequest());

    try {
      const validatedIBAN = await (await fetch(`${api}/iban/${IBAN}`)).json();

      if (validatedIBAN.error) {
        return onError(validatedIBAN.error);
      }

      return onSuccess(validatedIBAN);
    } catch (error) {
      return onError(error);
    }
  };
}

export default function reducer(
  state = {
    fetching: false,
    bic: ''
  },
  action
) {
  switch (action.type) {
    case VALIDATE_IBAN_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case VALIDATE_IBAN_ERROR:
      return {
        ...state,
        fetching: false,
        bic: ''
      };
    case VALIDATE_IBAN_SUCCESS:
      return {
        ...state,
        fetching: false,
        bic: action.payload.BIC
      };
    default:
      return state;
  }
}
