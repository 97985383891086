import styled from 'styled-components/macro';

const ContractMinimumTerm = styled.div`
  color: ${props => props.theme.backgroundSecondary};
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

export default ContractMinimumTerm;
