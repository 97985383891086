export const SET_REFERRAL_CODE = 'data/referralCode/SET_REFERRAL_CODE';

export function setReferralCode(payload) {
  return {
    type: SET_REFERRAL_CODE,
    payload
  };
}

export default function reducer(state = { referralCode: undefined }, action) {
  switch (action.type) {
    case SET_REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.payload
      };
    default:
      return state;
  }
}
