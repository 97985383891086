import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { getMemberData, getUpsellContract } from '../../../../data/upselling';
import useQueryParams from '../../../../data/useQueryParams';
import Spinner from '../../../../components/Spinner/Spinner';

interface RootState {
  data: any;
}

const Upselling = ({ intl }) => {
  const dispatch = useDispatch();
  const { member, contract, isLoading } = useSelector(
    (state: RootState) => ({
      member: state.data.upselling.member,
      contract: state.data.upselling.contract,
      isLoading: state.data.upselling.isFetching,
    })
  );
  const identityToken = useQueryParams('identityToken');

  React.useEffect(() => {
    identityToken && dispatch(getMemberData(identityToken));
  }, [dispatch, identityToken]);

  React.useEffect(() => {
    member?.studioId && dispatch(getUpsellContract(member.studioId));
  }, [dispatch, member]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {contract && member ? (
        <Redirect
          to={`/checkout${intl.formatMessage({
            id: 'route.enterData'
          })}/${contract.Id}/${member?.studioId}?identityToken=${identityToken}`}
        />
      ) : null}
    </>
  );
};

export default injectIntl(Upselling);
