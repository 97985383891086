import styled from 'styled-components';
import CTAButton from '../../CTAButton/CTAButton';

const SaveButton = styled(CTAButton)`
  display: block;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 20px;
`;

export default SaveButton;
